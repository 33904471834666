<app-language-selector></app-language-selector>
<div class="row mx-0 justify-content-md-center">
    <div class="col-md-8">
        <div class="faqs" id="div{{'Faq.ttlId' | translate}}">
            <h3 class="title" id="ttl{{'Faq.ttlId' | translate}}">{{ "Faq.title" | translate }}</h3>
            <div class="spinner-border text-center" role="status" *ngIf="loading">
                <span class="sr-only">Loading...</span>
            </div>
            <accordion [isAnimated]="true" [closeOthers]="oneSection" *ngIf="!loading" id="accordion">
                <ng-container *ngFor="let faq of faqs">
                    <accordion-group [isOpen]="faq.isOpen" class="accordion-group">
                        <button class="btn btn-block clearfix" accordion-heading type="button" id="btn{{faq.id}}">
                            <h4 [id]="'ttlFaqQuestion' + index" class="text-left">{{ faq.question}}</h4>
                        </button>
                        <div [id]="'divFaqQuestion' + index">
                            <p [id]="'pFaqQuestion' + index">{{ faq.answer }}</p>
                        </div>
                    </accordion-group>
                </ng-container>
            </accordion>
            <div class="addQuestion">
                <div id="div{{ 'Faq.addQuestion.id' | translate }}">
                    <h6 id="ttl{{ 'Faq.addQuestion.id' | translate }}">{{ 'Faq.addQuestion.title' | translate }}</h6>
                    <p id="pDescription">{{ 'Faq.addQuestion.disc' | translate }}</p>
                </div>
                <button class="btn btn-confirm" id="btn{{ 'Faq.addQuestion.id' | translate }}" data-toggle="modal"
                    (click)="askFaqQuestionModal.show()">{{ 'Faq.addQuestion.button' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<app-ui-modal #askFaqQuestionModal [dialogClass]="'modal-lg'" [containerClick]="false" class="faq-form">
    <div class="app-modal-header">
        <h5 class="modal-title" id="ttl{{ 'Faq.faqForm.id' | translate }}">{{ 'Faq.faqForm.title' | translate }}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="clearFields();askFaqQuestionModal.hide()" id="btnClose"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" id="div{{ 'Faq.faqForm.id' | translate }}">
        <form id="frmAskQuestion" [formGroup]="myForm" #myNgForm="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label id="lblName">{{ 'Faq.faqForm.Name' | translate }}</label>
                        <input type="text" id="txtName" class="form-control" formControlName="name"
                            placeholder="{{ 'Faq.faqForm.namePlace' | translate }}"
                            [ngClass]="{'is-invalid':inValid(f.name, isSubmit)}">
                        <div class="invalid-feedback" *ngIf="inValid(f.name, isSubmit)">
                            <div *ngIf="f.name.errors.required" id="err_Name">Please enter name</div>
                            <div *ngIf="f.name.errors.minlength" id="err_minLength_name">Name should contain at least 3
                                characters</div>
                            <div *ngIf="f.name.errors.maxlength" id="err_maxLength_name">Should not exceed 35 characters
                            </div>
                            <div *ngIf="f.name.errors.hasNoSpecialCharacters" id="err_specialChar_name">Must not contain
                                any Special Character</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="exampleInputEmail1" id="lblEmail">{{ 'Faq.faqForm.email' | translate }}</label>
                        <input type="email" id="txtEmail" class="form-control" id="exampleInputEmail1"
                            formControlName="email" placeholder="{{ 'Faq.faqForm.emailPlace' | translate }}"
                            [ngClass]="{'is-invalid':inValid(f.email, isSubmit)}">
                        <div class="invalid-feedback" *ngIf="inValid(f.email, isSubmit)">
                            <div *ngIf="f.email.errors.required" id="err_EmailId">Please enter email address</div>
                            <div *ngIf="f.email.errors.email" id="err_ValidEmailId">Please enter valid email address
                            </div>
                            <div *ngIf="f.email.errors.maxlength" id="err_maxLength_email">Should not exceed 100
                                characters</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label id="lblYourQuestion">{{ 'Faq.faqForm.YourQuestion' | translate }}</label>
                        <textarea class="form-control" id="txtourQuestion" rows="3" formControlName="question"
                            placeholder="{{ 'Faq.faqForm.quesPlace' | translate }}"
                            [ngClass]="{'is-invalid':inValid(f.question, isSubmit)}"></textarea>
                        <div class="invalid-feedback" *ngIf="inValid(f.question, isSubmit)">
                            <div *ngIf="f.question.errors.required" id="err_QuestionRequired">Please enter your question
                            </div>
                            <div *ngIf="f.question.errors.minlength" id="err_minLength_question">Question should contain
                                at least 3 characters</div>
                            <div *ngIf="f.question.errors.maxlength" id="err_maxLength_quesitn">Should not exceed 275
                                characters</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-confirm" id="btnSubmit" (click)="saveQuestion()">{{ 'Faq.faqForm.button' |
            translate }}</button>
    </div>
</app-ui-modal>