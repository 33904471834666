<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12">
    <div id="divSmeYourGrowthCard" class="your-growth">
      <h3 id="ttlSmeYourGrowthCard" class="title">{{ "yourGrowth.title" | translate }}</h3>
      <div class="icon-img">
        <img id="imgYourGrowth" src="assets/images/icon@2x.png" alt="alt" />
      </div>
      <h4 class="sub-title">{{ "yourGrowth.subTitle" | translate }}</h4>
      <p class="discp">
        {{ "yourGrowth.description" | translate }}
      </p>
      <button id="btnYourGrowthStart" type="button" class="btn btn-primary">
        {{ "buttons.getStarted" | translate }}
      </button>
    </div>
  </div>
</div>

<app-ui-modal #yourGrowth [hideFooter]="true" [dialogClass]="'modal-lg  build-service-provider'"
  [containerClick]="false" class="service-providerModalBg yourGrowth-plan">
  <div class="app-modal-header">
    <h5 id="ttlSmeYourGrowthModal" class="modal-title">{{'StartYourOwnGrowth.title' | translate}}</h5>
    <p>{{'StartYourOwnGrowth.disc' | translate}}</p>
    <button id="btnBuildProviderClose" type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="yourGrowth.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <app-your-own-growth-plan></app-your-own-growth-plan>
  </div>
</app-ui-modal>