import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-error-page-forbidden',
  templateUrl: './error-page-forbidden.component.html',
  styleUrls: ['./error-page-forbidden.component.scss']
})
export class ErrorPageForbiddenComponent implements OnInit {

  forbiddenUrl: string = '';
  smeconnectURL: any;

  constructor(
    private router: Router,
    public authService: UserAuthService
  ) { }

  ngOnInit() {
    this.smeconnectURL = environment.smeconnectURL
    this.forbiddenUrl = sessionStorage.getItem("forbidden-url");
    if (this.forbiddenUrl) {
      sessionStorage.removeItem("forbidden-url");
    }
  }

  goToPage(name: string) {
    if (name && name == 'landing') {
      window.location.href = this.smeconnectURL;
    }
    let url = '';
    if (name && name == 'signin') {
      url = '/signin'
    }
    if (name && name == 'feed') {
      url = '/feed';
      /* if (this.authService.isCommunityManager()) {
        url = '/cm-feed';
      } */
    } else {
      this.authService.clearLocalData()
    }
    this.router.navigate([url]);
  }

  isValidUser() {
    return this.authService.isSME() || this.authService.isCommunityManager()
      || this.authService.isServiceProvider() || this.authService.isInvester() || this.authService.isRelationShipManager();
  }
}
