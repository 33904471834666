import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { ActivatedRoute } from '@angular/router';

import { ProfileService } from '../../core/services/profile-service/profile.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { MessageService } from 'primeng/api';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-investor-banner',
  templateUrl: './investor-banner.component.html',
  styleUrls: ['./investor-banner.component.scss']
})
export class InvestorBannerComponent implements OnInit {
  public followCount: any;

  userId: any;
  isPublic: boolean = false;
  loading: boolean;
  profile: any = {};
  canFollow: boolean = false;
  isUserFollowing: boolean = true;
  buttonPressed: boolean;
  unFollowButtonPressed: boolean;
  followersList: any = [];
  hasItems = false;
  about: string = "";
  followersModal: boolean;
  address: string = "";
  contactNo: string = "";
  email: string = "";
  linkedIn: string = "";
  website: string = "";

  constructor(
    private commonService: CommonService,
    private profileService: ProfileService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private gaService: GoogleAnalyticsService
  ) {
    this.profileService.updateConatact.subscribe(
      () => {
        this.address = this.profileService.address;
        this.contactNo = this.profileService.contactNo;
        this.email = this.profileService.email;
        this.linkedIn = this.profileService.linkedIn;
        this.website = this.profileService.website;
      }
    );
  }

  ngOnInit(): void {
    this.commonService.getCommonData().subscribe(
      res => {
        this.followCount = res.followCount.data;
      }
    );
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.getProfile();
      if (!this.authService.isSessionExpired()) {
        if (this.isPublic) {
          this.isFollowing();
        }
        if (!this.isPublic) {
          this.getFollowers();
        }
      }
    });
    this.getOrgAbout();
  }

  getProfile() {
    this.loading = true;
    this.profileService.getProfile(this.userId ? this.userId : 0).subscribe(
      (data) => {
        this.profile = data ? data : {};
        this.loading = false;
        if (this.isPublic) {
          this.gaService.logCustomEvent("Viewed", "Company Page", this.profile.name);
        }
      },
      (error) => {
        this.profile = {};
        this.loading = false;
        // console.log(error);
      }
    );
  }
  followMe() {
    if (this.buttonPressed) {
      return false;
    }
    this.buttonPressed = true;
    this.profileService.addFollow({ userId: this.userId }).subscribe(
      data => {
        this.messageService.add({
          key: 'app', life: 3000, severity: 'success',
          summary: "You're now following " + this.profile.name + " and will receive updates about products & services in your mailbox.",
          detail: ''
        });
        this.buttonPressed = false;
        this.isUserFollowing = true;
      },
      error => {
        this.buttonPressed = false;
      }
    );
  }

  unFollowMe() {
    if (this.unFollowButtonPressed) {
      return false;
    }
    this.unFollowButtonPressed = true;
    this.profileService.unFollow(this.userId).subscribe(
      data => {
        this.messageService.add({
          key: 'app', life: 3000, severity: 'success',
          summary: "You are no longer following " + this.profile.name + ". You’ll stop receiving updates in your mailbox.",
          detail: ''
        });
        this.unFollowButtonPressed = false;
        this.isUserFollowing = false;
      },
      error => {
        this.unFollowButtonPressed = false;
      }
    );
  }

  isFollowing() {
    this.profileService.isFollowing(this.userId).subscribe(
      (data) => {
        this.isUserFollowing = data && data.count > 0;
        if (data && data.count == -1) {
          this.canFollow = false;
        } else {
          this.canFollow = this.authService.isSME() || this.authService.isServiceProvider() || this.authService.isInvester();
        }
      },
      (error) => {
      }
    );
  }

  getFollowers() {
    this.followersList = [];
    this.profileService.getFollowers(10, 0, "companyName", "asc").subscribe(
      (data) => {
        this.followersList = data && data["followList"] ? data["followList"] : [];
      },
      (error) => {

      }
    );
  }

  getOrgAbout() {
    this.loading = true;
    this.profileService.getOrgAbout(this.userId).subscribe(
      (data) => {
        this.about = data.about;
        if (this.about) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.about = "";
        this.loading = false;
        // console.log(error);
      }
    );
  }

}
