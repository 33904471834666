<app-startup-card cardTitle="Meet Our Team" subTitle="Let your audience know who is on your Team"
  description="Your team members are a valuable part of your organisation. Let your audience know you’re not alone."
  componentId="SmeTeamCard" iconPath="assets/images/team.png" btnLabel="Add Team Member" (btnClick)="addteamMember()"
  *ngIf="(!hasItems || members.length==0) && !loading && !isPublic"></app-startup-card>

<app-startup-card cardTitle="Meet Our Team" subTitle="Team"
  description="The user has not shared who is part of their team yet." componentId="SmeTeamCard"
  iconPath="assets/images/team.png" *ngIf="(!hasItems || members.length==0) && !loading && isPublic"></app-startup-card>


<div class="row" *ngIf="hasItems && !loading && members.length>0">
  <div class="col-md-12">
    <div id="divSmeTeamCard" class="meet-our-team">
      <h3 id="ttlSmeTeamCard" class="title mb-4 roducts sp-service"> {{ "meetOurTeam.title" | translate }}
        <em class="fas fa-user-plus" *ngIf="!isPublic" (click)="addteamMember()"></em>
      </h3>

      <div class="carousel" *ngIf="members && members.length>0">
        <div class="product-carousel">
          <div class="car-details">
            <div class="p-grid p-nogutter">
              <div class="p-col-12 row">
                <div class="d-flex" *ngFor="let  member of members">
                  <div class="service-card team-display">
                    <div class="app-card-header position-relative">
                      <div class="cover-img-block text-center">
                        <div class="meet-card">
                          <img [id]="'img' + member.id"
                            [src]="member.image?member.image:'assets/images/team-member.png'" alt="alt"
                            class="img-fluid">
                        </div>
                      </div>
                      <div class="user-about-block text-center" *ngIf="!isPublic">
                        <div class="row align-items-end">
                          <div class="col text-right">
                            <div class="card-action">
                              <div class="dropdown text-right">
                                <button class="deletecardbtn" (click)="remove(member)"><em
                                    class="fas fa-trash-alt"></em>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="team-name" pTooltip="{{member?.empName}}" tooltipPosition="top">
                      {{ member?.empName?.trim().slice(0,17)}}
                      <span *ngIf="member.empName.length>17x">..</span>
                    </div>
                    <p class="details">{{ member.empPosition }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="my-profile shareFeed_dialog ">
  <p-dialog header="" [(visible)]="addTeamModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="" [maximizable]="false" [draggable]="false"
    [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
    (onHide)="clearFields()">
    <div class="app-modal-body editpersonalinfo">
      <div class="app-modal-header text-center">
        <h5 class="modal-title" id="ttlShareArticle">
          <span>Add Team Member</span>
        </h5>
      </div>

      <div class="">
        <div class="">
          <!-- <h5 id="ttlSmeAddTeamMemberModal" class="modal-title text-center">Add Team Member</h5> -->
          <form id="frmSmeAddTeamMember" [formGroup]="memberForm">
            <div class="form-group">
              <label id="lblMemberName">Name</label>
              <input id="txtMemberName" type="text" class="form-control" formControlName="empName"
                [ngClass]="{'is-invalid':inValid(f.empName, isSubmit)}" placeholder="e.g John Doe">
              <div class="invalid-feedback" *ngIf="inValid(f.empName, isSubmit)">
                <div *ngIf="f.empName.errors.required" id="err_Name">Please enter name</div>
                <div *ngIf="f.empName.errors.maxlength" id="err_Name">Name should be maximum 50 characters</div>
                <div *ngIf="f.empName.errors.pattern" id="err_Name">Name should only contain alphabets</div>
              </div>
            </div>
            <div class="form-group">
              <label id="lblMemberTitle">Title</label>
              <input id="txtMemberTitle" type="text" class="form-control" formControlName="empPosition"
                [ngClass]="{'is-invalid':inValid(f.empPosition, isSubmit)}" placeholder="e.g Managing Director">
              <div class="invalid-feedback" *ngIf="inValid(f.empPosition, isSubmit)">
                <div *ngIf="f.empPosition.errors.required" id="err_Position">Please enter position</div>
                <div *ngIf="f.empPosition.errors.maxlength" id="err_Position">Title should be maximum 150 characters
                </div>
                <div *ngIf="f.empPosition.errors.pattern" id="err_Position">Title should only contain alphabets</div>
              </div>
            </div>
            <div class="form-group">
              <label id="lblMemberLinkedIn">LinkedIn Profile</label>
              <input id="txtMemberLinkedIn" type="text" class="form-control" formControlName="linkedin"
                [ngClass]="{'is-invalid':inValid(f.linkedin, isSubmit)}"
                placeholder="https://www.linkedin.com/in/username">
              <div class="invalid-feedback" *ngIf="inValid(f.linkedin, isSubmit)">
                <div *ngIf="f.linkedin.errors.required" id="err_Linkedin">Please enter linkedin profile url</div>
              </div>
            </div>
          </form>
        </div>
        <div class="">
          <input #fileInput type="file" (change)="onFileChanged($event)" class="hidden" accept=".jpeg,.jpg,.png">
          <div class="profile-pic" id="imgEditProfilePic" *ngIf="!cropimage">
            <span *ngIf="!srcData" class="camera-show" (click)="fileInput.click();" id="btnUploadHere">
              <em class="upload-text">{{
                "editProfile.personalDetails.UploadHere" |
                translate }}</em>
            </span>
            <img *ngIf="croppedImageBase64 || srcData" [src]="croppedImageBase64 ? croppedImageBase64 : srcData || ''"
              class="profile-img" alt="feed" (click)="fileInput.click()" id="imgProfilePic" />
          </div>

          <div class='cropimagestyle' *ngIf="cropimage">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
              format="png" (imageCropped)="imageCropped($event)" output="base64"></image-cropper>
            <button class="btn btn-confirm crop" *ngIf="imageCroppedFlag" (click)="onButtonClicked()">Crop
              Image</button>
          </div>
        </div>
        <div *ngIf="showdata" class="text-danger text-center">Only images in PNG, JPG, and GIF formats are
          permitted</div>
      </div>



      <div class="app-modal-footer text-right mt-5">
        <button id="btnSmeAddTeamCancel" type="button" class="btn btn-secondary mr-3"
          (click)="addTeamModal = false ; clearFields()">Cancel</button>
        <button id="btnSmeAddTeamSave" type="button" class="btn btn-confirm" (click)="saveMember();">
          <span class="btn-text">Save</span>
        </button>
      </div>

    </div>
    <!-- <div class="app-modal-footer text-right mt-5">
      <button id="btnSmeAddTeamCancel" type="button" class="btn btn-secondary mr-3"
        (click)="addTeamModal = false ; clearFields()">Cancel</button>
      <button id="btnSmeAddTeamSave" type="button" class="btn btn-confirm" (click)="saveMember();">
        <span class="btn-text">Save</span>
      </button>
    </div> -->
  </p-dialog>
</div>

<!-- <app-ui-modal #SmeAddTeamModal [containerClick]="false" [dialogClass]="'modal-lg modal-border-no'">
  <div class="app-modal-header">
    <h5 id="ttlSmeAddTeamMemberModal" class="modal-title">Add Team Member</h5>
    <button id="btnSmeTeamClose" type="button" class="close basic-close"
      (click)="clearFields();SmeAddTeamModal.hide()"><span>&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-6">
        <form id="frmSmeAddTeamMember" [formGroup]="memberForm">
          <div class="form-group">
            <label id="lblMemberName">Name</label>
            <input id="txtMemberName" type="text" class="form-control" formControlName="empName"
              [ngClass]="{'is-invalid':inValid(f.empName, isSubmit)}" placeholder="e.g John Doe">
            <div class="invalid-feedback" *ngIf="inValid(f.empName, isSubmit)">
              <div *ngIf="f.empName.errors.required" id="err_Name">Please enter name</div>
              <div *ngIf="f.empName.errors.maxlength" id="err_Name">Name should be maximum 10 characters</div>
            </div>

          </div>
          <div class="form-group">
            <label id="lblMemberTitle">Title</label>
            <input id="txtMemberTitle" type="text" class="form-control" formControlName="empPosition"
              [ngClass]="{'is-invalid':inValid(f.empPosition, isSubmit)}" placeholder="e.g Managing Director">
            <div class="invalid-feedback" *ngIf="inValid(f.empPosition, isSubmit)">
              <div *ngIf="f.empPosition.errors.required" id="err_Position">Please enter position</div>
            </div>
          </div>
          <div class="form-group">
            <label id="lblMemberLinkedIn">LinkedIn Profile</label>
            <input id="txtMemberLinkedIn" type="text" class="form-control" formControlName="linkedin"
              [ngClass]="{'is-invalid':inValid(f.linkedin, isSubmit)}"
              placeholder="https://www.linkedin.com/in/username">
            <div class="invalid-feedback" *ngIf="inValid(f.linkedin, isSubmit)">
              <div *ngIf="f.linkedin.errors.required" id="err_Linkedin">Please enter linkedin profile url</div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-6 text-center">
        <div id="icoTeamMemberPic" class="user-grey-circle pointer" (click)="file.click()">
          <em class="fas fa-user" *ngIf="!previewURL"></em>
          <img id="imgTeamUserPreview" [src]="previewURL" class="img-radius img-fluid" style="width: 100%; height: 100%"
            *ngIf="previewURL" alt="user" />
          <input #file type="file" accept='image/*' (change)="preview(file.files)" style="display: none;" />
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button id="btnSmeAddTeamCancel" type="button" class="btn btn-secondary mr-3"
      (click)="clearFields();SmeAddTeamModal.hide()">Cancel</button>
    <button id="btnSmeAddTeamSave" type="button" class="btn btn-confirm" (click)="saveMember();">
      <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
      <span class="btn-text">Save</span>
    </button>
  </div>
</app-ui-modal> -->


<p-dialog header="" [(visible)]="deleteTeamMemberModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
  class=" product-card-modal" [maximizable]="false" [draggable]="false" [resizable]="false"
  id="appDeleteTeamMemberModal" [blockScroll]="true" [resizable]="true" [style]="{width: '30vw'}">
  <div class="app-modal-body text-center mb-4">
    <h6>You are about to remove a team member</h6>
    <p>Are you sure you want to proceed?</p>
  </div>
  <div class="app-modal-footer text-center mb-4">
    <button id="btnSmeAddProductCancel" type="button" class="btn btn-secondary mr-3"
      (click)="deleteTeamMemberModal = false ; clearFields()">Cancel</button>
    <button id="btnSmeAddProductSave" type="button" class="btn btn-confirm" (click)="deleteConfirmed()">
      <!-- <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span> -->
      <span class="btn-text">Remove</span>
    </button>
  </div>
</p-dialog>