import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';

@Component({
  selector: 'app-first-login-popup',
  templateUrl: './first-login-popup.component.html',
  styleUrls: ['./first-login-popup.component.scss']
})
export class FirstLoginPopupComponent implements OnInit {

  userName: string = '';
  firstTimeLoginModal: boolean;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authService: UserAuthService
  ) { }

  ngOnInit() {
    this.authService.firstLogin.subscribe((firstLogin => this.firstTimeLoginModal = firstLogin));
    this.userName = this.profileService.userName;
    this.profileService.updateEvent.subscribe(() => this.userName = this.profileService.userName);
  }

  viewMyPage() {
    this.authService.setFirstLogin(false);
    setTimeout(() => this.router.navigate(['service-provider']), 300);
  }

  onModelHide(e: any) {
    this.authService.setFirstLogin(false);
  }

}
