<app-startup-card [cardTitle]="'SmeServices.title' | translate" [subTitle]="'SmeServices.subTitle1' | translate"
  [description]="'SmeServices.description1' | translate" componentId="SmeServicesCard"
  iconPath="assets/images/service-image.png" btnLabel="Add Service" (btnClick)="addServiceModal = true"
  *ngIf="(!hasItems || services.length==0) && !loading && !isPublic"></app-startup-card>
<app-startup-card [cardTitle]="'SmeServices.title' | translate" [subTitle]="'SmeServices.subTitle2' | translate"
  [description]="'SmeServices.description2' | translate" componentId="SmeServicesCard"
  iconPath="assets/images/service-image.png" *ngIf="(!hasItems || services.length==0) && !loading && isPublic">
</app-startup-card>
<div class="row products-sec" *ngIf="hasItems && !loading && services.length>0">
  <div class="col-md-12">
    <div id="divSmeServicesCard" class="products sp-service">
      <h3 id="ttlSmeServicesCard" class="title mb-2">
        <span>
          <span *ngIf="isSME || !isPublic">{{ "SmeServices.title" | translate }}</span>
          <span *ngIf="isSP">{{ "Growth cards" | translate }}</span>
          <em class="fas fa-plus-circle" *ngIf="!isPublic" (click)="onAddBtnClick()"></em>
        </span>
        <span class="addServiceBtn" @fadeAnimation *ngIf=" services.length>3">
          <a id="aSmeAddService" href="javascript:void(0);"
            [routerLink]=" [userId ? '/all-services/' + userId : '/all-services']">View
            all</a>
        </span>
      </h3>

      <div class="row" @fadeAnimation>
        <div class="col-md-12">
          <div class="products mb-0 service-cards" *ngIf="services && services.length>0">
            <div class="product-carousel">
              <div class="car-details">
                <div class="p-grid p-nogutter">
                  <div class="p-col-12 row">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" *ngFor="let  service of services| slice:0:3">
                      <app-services-card [gcId]="service.id" [serviceToProvide]="service.name"
                        [description]="service.description" [componentId]="'SmeServiceCard' + index"
                        [cost]="service.priceEstimate"
                        [thumbnail]="service.image?service.image:'assets/images/service.png'"
                        [duration]="formatTimeEstimate(service)" [isHours]="service.isHours"
                        [durationType]="getDurationType(service)" [isGrowthCard]="service.growthCard"
                        [serviceDescriptionUrl]="service.serviceDescriptionUrl" (edit)="onEditBtnClick(service)"
                        (delete)="onDeleteClick(service)"
                        (book)="service.booked ? showMessage() : onBookBtnClick(service)" [canEdit]="!isPublic"
                        [canBook]="service?.isOwnService ? false : userCanBook(service)"
                        [bookingdone]="service?.isOwnService ? false : bookingdoneuser(service)">
                      </app-services-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="add-products shareFeed_dialog">
  <p-dialog header="" [(visible)]="addServiceModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class=" service-card-modal " [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
    (onHide)="clearFields()">
    <div class="app-modal-body">
      <h5 id="ttlSmeAddServiceModal" class="modal-title text-center">{{editMode?'Edit':'Add'}} Service</h5>
      <form id="frmSmeAddService" [formGroup]="serviceForm" #myNgForm="ngForm">
        <div class="form-group mb-4">
          <label id="lblBusinessSelect">Title of your service</label>
          <input id="dpBusinessselect" class="form-control" formControlName="serviceName"
            [ngClass]="{'is-invalid':inValid(f.serviceName, isSubmit)}"
            placeholder="'Ex:'Bring your business online”" />
          <div class="invalid-feedback" *ngIf="inValid(f.serviceName, isSubmit)">
            <div *ngIf="f.serviceName.errors.required" id="err_Name">Please enter the service name
            </div>
            <div *ngIf="f.serviceName.errors.minlength" id="err_minLength_serviceName">Should be
              minimum
              3 characters</div>
            <div *ngIf="f.serviceName.errors.maxlength" id="err_maxLength_serviceName">Should not
              exceed
              100 characters</div>
            <div *ngIf="f.serviceName.errors.hasNoSpecialCharacters" id="err_specialChar_serviceName">
              Service name should contain alphabetical characters only</div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label id="lblDescription">Describe your service</label>
          <textarea id="texDescription" class="form-control" formControlName="description"
            placeholder="Ex: Increase your online visibility & sales by showcasing your products with a modern & mobile friendly website"
            [ngClass]="{'is-invalid':inValid(f.description, isSubmit)}" rows="3" name="description"></textarea>
          <div class="invalid-feedback" *ngIf="inValid(f.description, isSubmit)">
            <div *ngIf="f.description.errors.required" id="err_Description">Please enter description
            </div>
            <div *ngIf="f.description.errors.minlength" id="err_minLength_description">Should be
              minimum
              3 characters
            </div>
            <div *ngIf="f.description.errors.maxlength" id="err_maxLength_description">Should not
              exceed
              500 characters
            </div>
            <div *ngIf="f.description.errors.hasNoSpecialCharacters" id="err_specialChar_description">
              Must not contain any
              Special Character</div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label id="lblCategory">Category</label>
          <select id="dpCategory" class="form-control" formControlName="category"
            [ngClass]="{'is-invalid':inValid(f.category, isSubmit)}" placeholder="Visibile or hidden">
            <option value="">Select category</option>
            <ng-container *ngFor="let item of categoriesOptions">
              <option [value]="item.value">{{ item.label }}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback" *ngIf="inValid(f.category, isSubmit)">
            <div *ngIf="f.category.errors.required" id="err_Category">Please select category</div>
          </div>
        </div>
        <div class="form-row slider-row mb-4">
          <div class="form-group col-md-3">
            <label id="lblSliderLabel1">This will take</label>
          </div>
          <div class="form-group col-md-6">
            <div class="ag-slider">
              <nouislider [config]="weeksConfig" formControlName="timeEstimate" class="r-slider" id="nouThisWillTake">
              </nouislider>
            </div>
          </div>
          <div class="form-group col-md-3 mt600">
            <select id="dpWeeksSelct" class="form-control" formControlName="duration"
              [ngClass]="{'is-invalid':inValid(f.duration, isSubmit)}">
              <option value="">Select</option>
              <option [value]="i" *ngFor="let i of nOfweeks">{{i}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="inValid(f.duration, isSubmit)">
              <div *ngIf="f.duration.errors.required" id="err_Description">Please select duration
              </div>
            </div>
          </div>
        </div>
        <div class="form-row slider-row1 mb-4">
          <div class="form-group col-md-3">
            <label id="lblServiceCost">and cost around</label>
          </div>
          <div class="form-group col-md-9 service-cost">
            <h6>{{ f.costEstimate?.value?f.costEstimate?.value[0]:"" }}</h6>
            <h6>{{ f.costEstimate?.value?f.costEstimate?.value[1]:"" }}</h6>
            <div class="ag-slider">
              <nouislider id="nouCost" [config]="ServicePriceRangeConfig" formControlName="costEstimate"
                [tooltips]="[ false, false ]">
              </nouislider>
            </div>
          </div>
        </div>
      </form>
      <div class="card-preview  gc-pre">
        <app-services-card [serviceToProvide]="f.serviceName?.value" [description]="f.description?.value"
          componentId="SmeAddServiceCard" [done]="false" [thumbnail]="previewURL"
          [cost]="(f.costEstimate?.value?f.costEstimate.value[0]:'') + ' - ' + (f.costEstimate?.value?f.costEstimate.value[1]:'')"
          [durationType]="f.duration?.value"
          [duration]="formatTimeEstimatePreview(((f.timeEstimate?.value?f.timeEstimate.value[0]:'0') + ' - ' + (f.timeEstimate?.value?f.timeEstimate.value[1]:'3')) + ' '+f.duration?.value)"
          (previewImage)="preview($event)"></app-services-card>
        <div class="preview-body">
        </div>
      </div>
    </div>
    <div class="app-modal-footer text-right mt-5 sticky-btn ">
      <button id="btnSmeAddServiceCancel" type="button" class="btn btn-secondary mr-3"
        (click)="clearFields();addServiceModal = false">Cancel</button>
      <button id="btnSmeAddServiceSave" type="button" class="btn btn-confirm" [disabled]="" (click)="saveService();">
        <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span class="btn-text">Save</span>
      </button>
    </div>
  </p-dialog>

  <p-dialog header="" [(visible)]="deleteServiceModal" [modal]="true" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class=" product-card-modal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class="app-modal-body text-center mb-2">
      <h6>You are about to delete a Service</h6>
      <p>Are you sure you want to proceed ?</p>
    </div>
    <div class=" text-center">
      <button id="btnSmeAddProductCancel" type="button" class="btn btn-secondary mr-3"
        (click)="deleteServiceModal = false">Cancel</button>
      <button id="btnSmeAddProductSave" type="button" class="btn btn-confirm" (click)="deleteConfirmed();">
        <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span class="btn-text">Delete</span>
      </button>
    </div>
  </p-dialog>
  <p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>



  <p-dialog header="" [(visible)]="updateBRNPopup" [modal]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class=" product-card-modal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class=" brn-modal">
      <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
    </div>
  </p-dialog>

</div>