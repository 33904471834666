<div class="d-flex">
  <ng-content select="[labelTemplate]"></ng-content>
</div>
<label [for]="'lbl' + (field.name + componentId | translate)" [id]="'lbl' + (field.name + componentId | translate)">{{
  field.labelText | translate }}
  <span class="mandatory" *ngIf="field.mandatory">
    {{ 'CONSTANTS.MANDATORY' | translate }}
  </span>
</label>
<input #inputFeild [id]="'txt' + (field.name + componentId | translate)" class="form-control" [type]="field.type"
  [formControl]="field.control" autocomplete="off" placeholder="{{field.placeholderText | translate}}"
  [title]="field.control.value" (change)="onChange(field.propertyInModel,inputFeild.value)" />
<div *ngIf="field.control.invalid && (field.control.dirty || submitted )">
  <div [hidden]="!field.control.errors" class="error-container">
    <div class="error-msg" [id]="'lblErrorMsg' + (componentId + field.name | translate)">{{ field.errorMsg | translate
      }}</div>
    <div class="error-msg" id="fieldTextAreaMinLengthError" *ngIf="field.control.errors.minlength">Should contain
      at least {{minLength}} characters</div>
    <div class="error-msg" id="fieldTextAreaMaxLengthError" *ngIf="field.control.errors.maxlength">Should not exceed
      {{maxLength}} characters</div>
  </div>
</div>