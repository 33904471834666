import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanLoad } from '@angular/router';
import { UserAuthService } from '../services/auth-service/user-auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(public authService: UserAuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (!this.authService.isSessionExpired()) {
        return true;
      }
      sessionStorage.setItem("redirect_url", state.url);
      this.router.navigate(['/signin']);
      return false;
  }

  canLoad(route: Route) {
    if (!this.authService.isSessionExpired()) {
      return true;
    }

    this.router.navigate(['/signin']);
    return false;
  }

}
