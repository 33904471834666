import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { BookmarksService } from '../../../core/services/bookmarks-service/bookmarks.service';
import { UiModalComponent } from '../modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})
export class resultCardComponent implements OnInit {
  @Input() results: any;
  @Input() item: any;
  @Output() bookmarkAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<number>();
  @Output() editEvent = new EventEmitter<number>();

  @ViewChild("exampleModalLong") modal: UiModalComponent;
  element: any;
  resourcesUrl: any;
  isRM: boolean;
  communityManager: boolean;
  constructor(private sanitizer: DomSanitizer,
    private bookmarkService: BookmarksService,
    private gaService: GoogleAnalyticsService,
    private authService: UserAuthService
  ) { }

  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    this.communityManager = this.authService.isCommunityManager();
  }
  currentItem: any;
  sanitizedUrl: any;
  getFileTypeString(item) {
    var fts = "";
    if (item?.fileType?.toLowerCase() == "doc" ||
      item?.fileType?.toLowerCase() == "docx" ||
      item?.fileType?.toLowerCase() == "word") {
      fts = "word";
    } else if (item?.fileType?.toLowerCase() == "ppt" ||
      item?.fileType?.toLowerCase == "powerpoint") {
      fts = "powerpoint";
    } else {
      fts = item?.fileType?.toLowerCase();
    }
    return fts;
  }
  setCurrentItem(item) {
    this.currentItem = item;
    if (item.fileType?.toLowerCase() == "doc" ||
      item.fileType?.toLowerCase() == "docx" ||
      item.fileType?.toLowerCase() == "word" ||
      item.fileType?.toLowerCase() == "excel" ||
      item.fileType?.toLowerCase() == "powerpoint" ||
      item.fileType?.toLowerCase() == "ppt") {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item?.resourceUrl);
    } else {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(item.resourceUrl);
    }
    this.download(item);
  }
  download(item) {
    this.gaService.logCustomEvent("Downloaded", "Tools", item.name);
    this.element = document.createElement('a');
    this.element.setAttribute('href', item.resourceUrl);
    this.element.setAttribute('download', item.resourceUrl);
    this.element.setAttribute('target', "_blank");
    this.element.setAttribute('rel', "noopener");

    this.element.style.display = 'none';
    document.body.appendChild(this.element);
    this.element.click();
    document.body.removeChild(this.element);

  }
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";


  forceDownload(blob, filename) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Current blob size limit is around 500MB for browsers
  downloadResource(url, filename) {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
      headers: new Headers({
        'Origin': location.origin
      }),
      mode: 'cors'
    })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
  }
  bookmark(item) {
    this.bookmarkAdded.emit(item);
    this.bookmarkService.addBookmark(item.id).subscribe(
      (res: any) => {
      }
    );
  }

  deleteResource() {
    this.deleteEvent.emit(this.item.id);
  }

  EditResource() {
    this.editEvent.emit(this.item.id);
  }
}
