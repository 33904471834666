import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { ProfileService } from '../../../core/services/profile-service/profile.service';



@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
    public nextConfig: any;
    public menuClass: boolean;
    public collapseStyle: string;
    public windowWidth: number;
    public navCollapsed: boolean;
    public navCollapsedMob: boolean;
    profile = {};
    orgDetails = {};
    isSME: boolean;
    isServiceProvider: boolean;
    isInvester: boolean;
    isCommunityManager: boolean;
    companyName: string = "";
    companylogo: string = "";

    @Output() onNavCollapse = new EventEmitter();
    @Output() onNavHeaderMobCollapse = new EventEmitter();



    constructor(
        private router: Router,
        public authService: UserAuthService,
        private profileService: ProfileService,
    ) {
        this.nextConfig = NextConfig.config;
        this.menuClass = false;
        this.collapseStyle = 'none';
        this.windowWidth = window.innerWidth;
        this.profileService.updateEvent.subscribe(
            () => {
                this.companyName = this.profileService.companyName;
                this.companylogo = this.profileService.companyLogo;
            }
        );
    }

    ngOnInit() {
        this.isSME = this.authService.isSME();
        this.isServiceProvider = this.authService.isServiceProvider();
        this.isInvester = this.authService.isInvester();
        this.isCommunityManager = this.authService.isCommunityManager();
        this.getProfile();
    }


    getProfile() {
        this.profileService.getOrgDetails().subscribe(
            (data) => {
                this.profile = data ? data : {};
            },
            (error) => {
                this.profile = {};
                console.log(error);
            }
        );
    }

    goToViewPage() {
        let url = '/signin';
        if (this.authService.isServiceProvider()) {
            url = '/service-provider';
        }
        if (this.authService.isInvester()) {
            url = '/investor';
        }
        if (this.authService.isCommunityManager()) {
            url = '/feed';
        }
        if (this.authService.isSME()) {
            url = '/sme-profile';
        }
        if (this.authService.isRelationShipManager()) {
            url = '/feed';
        }
        this.router.navigate([url]);
    }

    logoclick() {
        if (this.authService.isCommunityManager()) {
            this.router.navigate(['/feed'], {
            });
        } else {
            this.router.navigate(['/feed'], {
            });
        }
    }

    toggleMobOption() {
        /* this.menuClass = !this.menuClass;
        this.collapseStyle = this.menuClass ? 'block' : 'none'; Kiran*/
        this.menuClass = !this.menuClass;
        if (this.windowWidth < 768)
            this.collapseStyle = (this.menuClass) ? 'block' : 'none';
        else
            this.collapseStyle = (this.menuClass) ? 'flex' : 'none';
    }

    navCollapse() {
        if (this.windowWidth >= 992) {
            this.onNavCollapse.emit();
        } else {
            this.onNavHeaderMobCollapse.emit();
        }
    }
}
