<app-language-selector></app-language-selector>
<div class="carousel">
    <!-- <p-carousel [value]="healthCards" styleClass="product-carousel" [numVisible]="2" [numScroll]="2" [circular]="false"
        [responsiveOptions]="responsiveOptions" id="carouselOurproducts">
        <ng-template let-service pTemplate="item">
            <div class="mr-4">
                <app-services-card [gcId]="service.id" [serviceToProvide]="service.serviceName"
                    [description]="service.description" [componentId]="'SmeServiceCard' + index"
                    [cost]="service.costEstimate" [thumbnail]="service.image?service.image:'assets/images/card.jpg'"
                    [duration]="formatTimeEstimate(service)" [isHours]="service.isHours"
                    [durationType]="getDurationType(service)" [isGrowthCard]="service.growthCard"
                    [serviceDescriptionUrl]="service.serviceDescriptionUrl" (edit)="onEditBtnClick(service)"
                    (book)="onBookBtnClick(service)" [canEdit]="false" [canBook]="!service.booked">
                </app-services-card>
            </div>
        </ng-template>
    </p-carousel> -->

    <div class="row">
        <div class="col-4" *ngFor="let service of healthCards">
            <app-services-card [gcId]="service.id" [serviceToProvide]="service.serviceName"
                    [description]="service.description" [componentId]="'SmeServiceCard' + index"
                    [cost]="service.costEstimate" [thumbnail]="service.image?service.image:'assets/images/card.jpg'"
                    [duration]="formatTimeEstimate(service)" [isHours]="service.isHours"
                    [durationType]="getDurationType(service)" [isGrowthCard]="service.growthCard"
                    [serviceDescriptionUrl]="service.serviceDescriptionUrl" (edit)="onEditBtnClick(service)"
                    (book)="onBookBtnClick(service)" [canEdit]="false" [canBook]="!service.booked">
                </app-services-card>
        </div>
    </div>
</div>
<p-toast key="app" position="top-center" [baseZIndex]="9999999" id="pAppToastMsg"></p-toast>
<p-dialog header="" [(visible)]="updateBRNPopup" [modal]="false" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="false"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal product-card-modal"
    [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
    [resizable]="true">
    <div class="app-modal-body brn-modal">
        <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
    </div>
</p-dialog>