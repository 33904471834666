import { Component } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-your-profile',
  templateUrl: './your-profile.component.html',
  styleUrls: ['./your-profile.component.scss']
})
export class YourProfileComponent {

  public statusLine: any;

  constructor(
    private commonService: CommonService
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.statusLine = res.YourPage.statusLine;
      })
  }


}
