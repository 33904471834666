import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';

@Component({
  selector: 'app-growth-item',
  templateUrl: './growth-item.component.html',
  styleUrls: ['./growth-item.component.scss']
})
export class GrowthItemComponent implements OnInit {
  @Input() growthItem: any;
  @Input() showMoreButton:boolean;
  @Input() showApplyButton:boolean;
  @Output() applyCallback = new EventEmitter();
  constructor(private authService:UserAuthService) { }
  isRM:boolean;
  ngOnInit(): void {
    this.isRM=this.authService.isRelationShipManager();
  }

}
