<div class="row">
  <div
    class="{{(type==Search.GROWTH_CARDS || type==Search.GROWTH_PARTNERS)?'col-lg-12 col-xl-12 col-md-12': 'col-lg-12 col-xl-12 col-md-12'}}">
    <div class="spinner-border text-center" role="status" *ngIf="loading">
      <span class="sr-only">Loading...</span>
    </div>

    <div infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)"
      *ngIf="(type==Search.GROWTH_CARDS || type==Search.GROWTH_PARTNERS)">
      <div class="row">
        <div class="col-md-12 growth-card">
          <div class="call-back">
            <a href="javascript:void(0)" routerLink="/growth-cards">Back</a>
          </div>
        </div>
      </div>
      <app-browse-card [BrowseGrowthCards]="type==Search.GROWTH_CARDS"
        [BrowseGrowthPartners]="type==Search.GROWTH_PARTNERS" [BrowseCards]="growthcardItems?.content" [listType]="type"
        [resultsDisplayTitle]="resultsDisplayTitle"></app-browse-card>
      <div class="growthPartnersLbl noResults" *ngIf="!totalItems && !loading">No Results</div>
    </div>

    <div *ngIf="(type!=Search.GROWTH_CARDS && type!=Search.GROWTH_PARTNERS)">
      <app-browse-card [BrowseCards]="browseproductcards" [listType]="type" *ngIf="!loading"></app-browse-card>
      <app-pagination [totalItems]="servicestotalItems" [currentPage]="servicepageNumber" [pageSize]="pageSize"
        [maxPages]="5" (changePage)="goToPage()" *ngIf="!loading && totalItems>9"></app-pagination>
    </div>

  </div>
</div>