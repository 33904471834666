import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-stories-carousel',
  templateUrl: './success-stories-carousel.component.html',
  styleUrls: ['./success-stories-carousel.component.scss']
})
export class SuccessStoriesCarouselComponent implements OnInit {
  @Input() successStoriesCarousel: any;

  constructor() { }

  ngOnInit() {
  }


}
