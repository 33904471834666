import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants/constants';
import { ClientInfoService } from '../client-info/client-info.service';
import { StorageService } from '../storage/storage.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CmService {

  jwt_token: string;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  getUsers(pageNumber, userType, sortBy, sortOrder): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', '10')
      .set('userType', userType)
      .set('pageNumber', pageNumber)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/community-manager/getUsers`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  addUtilityCategory(data): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/utility-category`, data)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  updateUtilityCategory(data): Observable<any> {
    const res: Observable<any> = this.http.put(`${this.BASE_API_URL}api/utility-category`, data)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getUtilityCategories(): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/utility-category`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  updateUtilityCategoryStatus(id, status): Observable<any> {
    const res: Observable<any> = this.http.put(`${this.BASE_API_URL}api/utility-category/updateStatus`, { utilityCategoryId: id, status: status })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  deleteUtilityCategory(id): Observable<any> {
    const params = new HttpParams()
      .set('utilityCategoryId', id);
    const res: Observable<any> = this.http.delete(`${this.BASE_API_URL}api/utility-category`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  updatePriorityOrder(priorityOrder): Observable<any> {
    const res: Observable<any> = this.http.put(`${this.BASE_API_URL}api/utility-category/updatePriorityOrder`, priorityOrder)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  exportContacts(userType: string, fileType: string, sortBy: string, sortOrder: string): Observable<any> {
    const params = new HttpParams()
      .set('userType', userType)
      .set('fileType', fileType)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/user/exportContacts`, {
      params: params,
      responseType: 'blob'
    })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getApprovalPendingGCs(pageNumber: number): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/community-manager/pendingForApprovalGCs`, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getGrowthCardsRejected(pageNumber: number): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/community-manager/rejectedGCs`, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  downloadGCPreviewPdf(gcId: number): Observable<any> {
    const params = new HttpParams()
      .set('gcId', '' + gcId);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/growth-cards/viewGCAsPDF`, {
      params: params,
      responseType: 'blob'
    })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  approveOrRejectGC(gcId: number, approveOrReject: string): Observable<any> {
    const params = new HttpParams()
      .set('gcId', '' + gcId)
      .set('approveOrReject', approveOrReject);
    const res: Observable<any> = this.http.put(`${this.BASE_API_URL}api/community-manager/approveOrRejectGC?gcId=` + gcId + `&approveOrReject=` + approveOrReject, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  deleteGrowthCard(gcId: number): Observable<any> {
    const params = new HttpParams()
      .set('gcId', '' + gcId);
    const res: Observable<any> = this.http.delete(`${this.BASE_API_URL}api/growth-cards`, { params: params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  approveOrRjectGCOnboarding(data): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/community-manager/approveOrRejectUser`, data)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

}
