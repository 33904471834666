<app-language-selector></app-language-selector>
<div class="middle-content">
    <div class="row">
        <div class="col-sm-12 col-md-2">
            <div class="comp-name">
                <span class="icon"><em class="fab fa-adn"></em></span>
                <span class="name">{{'opportunitiesPerformance.data.name' | translate}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-md-5">
            <div class="timeline-bar">
                <p class="disc">{{'opportunitiesPerformance.data.PLANStatus' | translate}}</p>
                <ol>
                    <li>
                        <span class="point far fa-check-circle done"></span>
                    </li>
                    <li>
                        <span class="point far fa-check-circle done"></span>
                    </li>
                    <li>
                        <span class="point far fa-circle"></span>
                    </li>
                    <li>
                        <span class="point far fa-circle"></span>
                    </li>
                </ol>
            </div>
        </div>
        <div class="col-sm-12 col-md-2">
            <div class="content">
                <p>{{ObjOpportunitiesPerformance.MUR}}</p>
                <p>{{'opportunitiesPerformance.data.Investment' | translate}}</p>
            </div>
        </div>
        <div class="col-sm-12 col-md-3">
            <div class="status">
                <span>{{ObjOpportunitiesPerformance.RETURN}}</span>
            </div>
        </div>
    </div>
</div>