<div class="newsEvents-section" id="newsEvents-section">
    <div class="container">
        <div class="newsEvents-content">
            <div class="row text-center">
                <div class="col-md-12">
                    <h2>{{'landing.landing.NewsEvents.title' | translate}}</h2>
                    <div class="testimonials">
                        <ngb-carousel [interval]="2000" [showNavigationArrows]="false"
                            [showNavigationIndicators]="true">
                            <ng-template ngbSlide class="bannerStyle">
                                <div class="testimonial-single">
                                    <div class="imgBlock">
                                        <img id="imgNewsEvent1" src="assets/landing/images/img2.jpg" alt="alt">
                                    </div>
                                    <div class="testimonial-text wow fadeInUp" data-wow-delay="0.2s">
                                        <p class="date">{{'landing.landing.NewsEvents.date' | translate}}</p>
                                        <p>{{'landing.landing.NewsEvents.disc' | translate}}</p>
                                        <div class="buttons-block">
                                            <button id="btnMarketExpansion1"
                                                class="btn btn-outline-primary">{{'buttons.MarketExpansion' |
                                                translate}}</button>
                                            <button id="btnReachingAfrica1"
                                                class="btn btn-outline-primary">{{'buttons.ReachingAfrica' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="testimonial-single">
                                    <div class="imgBlock">
                                        <img id="imgNewsEvent2" src="assets/landing/images/img1.jpg" alt="alt">
                                    </div>
                                    <div class="testimonial-text">
                                        <p class="date">{{'landing.landing.NewsEvents.date' | translate}}</p>
                                        <p>{{'landing.landing.NewsEvents.disc1' | translate}}</p>
                                        <div class="buttons-block">
                                            <button id="btnMarketExpansion2"
                                                class="btn btn-outline-primary">{{'buttons.MarketExpansion' |
                                                translate}}</button>
                                            <button id="btnReachingAfrica2"
                                                class="btn btn-outline-primary">{{'buttons.ReachingAfrica' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ngbSlide class="bannerStyle">
                                <div class="testimonial-single">
                                    <div class="imgBlock">
                                        <img id="imgNewsEvent3" src="assets/landing/images/img2.jpg" alt="alt">
                                    </div>
                                    <div class="testimonial-text wow fadeInUp" data-wow-delay="0.2s">
                                        <p class="date">{{'landing.landing.NewsEvents.date' | translate}}</p>
                                        <p>{{'landing.landing.NewsEvents.disc' | translate}}</p>
                                        <div class="buttons-block">
                                            <button id="btnMarketExpansion3"
                                                class="btn btn-outline-primary">{{'buttons.MarketExpansion' |
                                                translate}}</button>
                                            <button id="btnReachingAfrica3"
                                                class="btn btn-outline-primary">{{'buttons.ReachingAfrica' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="testimonial-single">
                                    <div class="imgBlock">
                                        <img id="imgNewsEvent4" src="assets/landing/images/img1.jpg" alt="alt">
                                    </div>
                                    <div class="testimonial-text">
                                        <p class="date">{{'landing.landing.NewsEvents.date' | translate}}</p>
                                        <p>{{'landing.landing.NewsEvents.disc1' | translate}}</p>
                                        <div class="buttons-block">
                                            <button id="btnMarketExpansion4"
                                                class="btn btn-outline-primary">{{'buttons.MarketExpansion' |
                                                translate}}</button>
                                            <button id="btnReachingAfrica4"
                                                class="btn btn-outline-primary">{{'buttons.ReachingAfrica' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>