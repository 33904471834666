<form [formGroup]="feedFormGroup">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <app-input-reactive-field (getData)="getData($event)" [componentId]="" [field]="titleField" [form]=""
                    *ngIf="!options.titleField.hide" [maxLength]="options.titleField.maxLength"
                    [minLength]="options.titleField.minLength" [submitted]="submitted">
                </app-input-reactive-field>
            </div>
            <div class="form-group">
                <app-input-reactive-field (getData)="getData($event)" [componentId]="" [field]="linkField" [form]=""
                    *ngIf="!options.linkField.hide" [maxLength]="options.linkField.maxLength" [submitted]="submitted">
                </app-input-reactive-field>
            </div>
            <div class="text-center" *ngIf="(uploadText != '' && uploadText !='Upload a video') || (showUploadFile)">
                <div class="saprator my-4"><span>{{"formElementsLables.or" | translate}}</span></div>
            </div>
            <div class="text-center" *ngIf="selectedFeedData?.documentUrl && deleteDownloadFile">
                <h5>
                    <span>{{selectedFeedData?.documentUrl | slice: 19:1000 }}</span>
                </h5>
            </div>
            <div class="form-group" *ngIf="uploadText != '' && uploadText !='Upload a video'">
                <file-upload class="file-upload" [control]="fileUploadControl" [animation]="animation"
                    id="btnUploadDocument" [ngModelOptions]="{standalone: true}" [(ngModel)]="uploadedFiles"
                    (change)="validateFilesUploaded(true);" [accept]="fileAccept">
                    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" let-options="options"
                        #placeholder>
                        <ng-container *ngIf="uploadedFiles.length === 0">
                            <span *ngIf="isFileDragDropAvailable">
                                <!-- <em class="far fa-file-alt" *ngIf="uploadText == 'Upload a document'"></em>
                                <em class="far fa-play-circle" *ngIf="uploadText == 'Upload a video'"></em> -->
                                {{uploadText}}
                            </span>
                            <span *ngIf="!isFileDragDropAvailable">
                                <!-- <em class="far fa-file-alt" *ngIf="uploadText == 'Upload a document'"></em>
                                <em class="far fa-play-circle" *ngIf="uploadText == 'Upload a video'"></em> -->
                                {{uploadText}}
                            </span>
                        </ng-container>
                        <span *ngIf="uploadedFiles.length>0">
                            {{uploadedFiles[uploadedFiles.length-1].name}}
                        </span>
                    </ng-template>
                </file-upload>

                <div class="error-container">
                    <p *ngIf="filesErrorMessage" class="error-msg">{{filesErrorMessage}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <input #fileInput type="file" formControlName="feedImage" (change)="onFileChanged($event)" class="hidden"
                accept=".jpeg,.jpg,.png">
            <div class="imgage-holder pointer" (click)="fileInput.click()">
                <div *ngIf="!srcData && !selectedFeedData" class="no-img">
                    <em class="fas fa-image"></em>
                    <p>Add featured image here</p>
                </div>
                <div *ngIf="srcData">
                    <img [src]="srcData" class="img-fluid" alt="feed" />
                </div>
                <div *ngIf="selectedFeedData && !srcData">
                    <img src="{{selectedFeedData.featuredImage?selectedFeedData.featuredImage:'assets/images/news/newsfeed-square.jpg'}}"
                        alt="imgEditfeaturedImage" id="imgEditfeaturedImage" class="img-fluid" />
                </div>
            </div>

            <span *ngIf="uploadText !='Upload a video'">
                <div class="error-container"
                    *ngIf="feedFormGroup.get('feedImage').invalid && (feedFormGroup.get('feedImage').dirty || feedFormGroup.get('feedImage').touched)">
                    <div class="error-msg" *ngIf="feedFormGroup.get('feedImage').errors.required">
                        Featured image is required.
                    </div>
                </div>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <app-input-textarea-reactive-field (getData)="getData($event)" [componentId]=""
                    [field]="descriptionField" *ngIf="!options.descriptionField.hide"
                    [maxLength]="options.descriptionField.maxLength" [minLength]="options.descriptionField.minLength"
                    [submitted]="submitted">
                </app-input-textarea-reactive-field>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!isCommunityManager">
        <div class="col-md-12">
            <div class="form-group">
                <label for="">Article Visible to Public</label> <br>
                <label class="toggle-switch pointer">
                    <p-inputSwitch [(ngModel)]="articleVisibleToPublic" (onChange)="onToggleChange($event)"
                        formControlName="isPublic" onLabel="On" offLabel="Off">
                    </p-inputSwitch>
                </label>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!articleVisibleToPublic">
        <div class="col-md-12">
            <div class="form-group">
                <label for="">Select groups to visible</label>
                <span class="p-fluid">
                    <p-autoComplete [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)"
                        [multiple]="true" placeholder="Select Options" [maxlength]=5 [dropdown]="true" field="value"
                        formControlName="selectedGroups">
                        <ng-template let-result pTemplate="item">
                            <div class="autocomplete-item" *ngIf="!isLoading">
                                <img [src]="result?.image" alt="Image" class="result-image">
                                <span>{{ result?.label }}</span>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </span>
            </div>
        </div>
    </div>
    <div class="text-right mt-3">
        <button type="button" class="btn btn-secondary mr-3" data-dismiss="modal" (click)="cancelForm()"
            id="btnCancel">Cancel</button>
        <button type="button" class="btn btn-confirm" (click)="submitForm()" id="btnSubmit" *ngIf="!selectedFeedData">
            <span *ngIf="loader" class="spinner-grow spinner-grow-sm" role="status"></span>
            <span *ngIf="loader" class="load-text">Loading..</span>
            <span *ngIf="!loader" class="btn-text">Share</span>
        </button>
        <button type="button" class="btn btn-confirm" (click)="updateForm()" id="btnUpdate" *ngIf="selectedFeedData">
            <span *ngIf="loader" class="spinner-grow spinner-grow-sm" role="status"></span>
            <span *ngIf="loader" class="load-text">Loading..</span>
            <span *ngIf="!loader" class="btn-text">Update</span>
        </button>
    </div>
</form>