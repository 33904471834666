import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { CmService } from 'src/app/core/services/cm/cm.service';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { CommonService } from 'src/app/services/common/common.service';
import { PRICE_NOT_AVAILABLE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss']
})
export class ServicesCardComponent implements OnInit, OnChanges {

  @Input() componentId: string;
  @Input() serviceToProvide: string;
  @Input() description: string;
  @Input() cost: string;
  @Input() durationType: string;
  @Input() duration: string;
  @Input() thumbnail: string;
  @Input() isHours: boolean = false;
  @Input() isGrowthCard: boolean = false;
  @Input() serviceDescriptionUrl: string = "";
  @Input() companyLogo: string;
  @Input() growthPatnerName: string;
  @Input() approvalStatus: string;
  @Input() gcId: any;
  @Input() done: boolean = true
  @Output() previewImage: EventEmitter<any> = new EventEmitter();

  @Input() canBook: boolean = false;
  @Input() bookingdone: boolean = false;
  @Input() canEdit: boolean = false;
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() book: EventEmitter<any> = new EventEmitter();
  @Output() itemClicked: EventEmitter<[string, any]> = new EventEmitter<[string, any]>();
  @Input() viewPageUrl: string;
  canPreview: boolean = false;
  isRM: boolean;
  showdata: boolean;

  services: any = [];
  hasItems = false;
  // userId: any;
  buttons: string[] = ["I'm Interested"];
  constructor(
    private utility: UtilityService,
    private router: Router,
    private authService: UserAuthService,
    private commonService: CommonService,
    private cmService: CmService,
    private serviceCardService: ServiceCardService
  ) { }
  filesInput = [];
  ngOnInit(): void {
    this.canPreview = this.previewImage.observers.length > 0;
    this.isRM = this.authService.isRelationShipManager();
  }


  ngOnChanges(): void {
    this.filesInput = [];
  }
  onPreviewImage(e: any, event) {
    // this.previewImage.emit(e);
    const file = event.target.files[0];
    const fileType = file.type;
    const validImageTypes = ['image/jpeg', 'image/jpg', 'images/png', 'images/gif'];

    if (!validImageTypes.includes(fileType)) {
      this.showdata = !this.showdata;
      event.target.value = '';
      setTimeout(() => {
        this.showdata = false;
      }, 3000);
    }

    else {
      this.showdata = false;
      this.previewImage.emit(e);
    }
  }
  onEdit(e: any) {
    this.edit.emit(e);
  }
  onEditGC(e: any) {
    this.edit.emit({ editDraftGC: true });
  }
  onDelete(e: any) {
    this.delete.emit(e);
  }
  doBooking(e: any) {
    this.book.emit(e);
  }


  getCostRange() {
    return this.utility.getServicePriceString(this.cost);
  }
  showCurrency() {
    return this.utility.getServicePriceString(this.cost) != PRICE_NOT_AVAILABLE;
  }
  takeToCompanyPage() {
    if (this.viewPageUrl) {
      this.router.navigate([this.viewPageUrl], {});
    }
  }
  getMoreDetails(url: any) {
    if (url && url.indexOf("http") == 0) {
      window.open(url, "_blank");
    } else {
      if (!url) {
        url = "api/growth-cards/viewGCAsPDF?gcId=" + this.gcId;
      }
      this.commonService.downloadPDF(url).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }
  }

  onItemClicked() {
    this.itemClicked.emit([this.gcId, this.serviceToProvide]);
  }

  // loadServiceList(event: any) {
  //   let q = '';
  //   let sortAsc = event.sortField && event.sortOrder == 1 ? event.sortField : '';
  //   let sortDesc = event.sortField && event.sortOrder == -1 ? event.sortField : '';
  //   this.serviceCardService.getServiceCards((event.first / event.rows), event.rows, q, sortAsc, sortDesc, this.userId).subscribe(
  //     (data) => {
  //       this.services = data.content;
  //       if (Array.isArray(this.services) && this.services.length > 0) {
  //         this.hasItems = true;
  //       }
  //     },
  //     (error) => {
  //       this.services = [];
  //       console.log(error);
  //     }
  //   );
  // }
}
