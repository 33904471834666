import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClientInfoService } from '../client-info/client-info.service';

@Injectable({
  providedIn: 'root'
})
/**
 * This service will perform all the actions related to token
 */
export class TokenService {

  private USER_TOKEN = 'USER_TOKEN';

  private USER_INFO = null;

  constructor(private storageService: StorageService, private clientInfoService: ClientInfoService) { }

  saveToken(token) {
    this.storageService.saveInLocal(this.USER_TOKEN, token);
  }

  getToken() {
    return this.storageService.getFromLocal(this.USER_TOKEN);
  }

  removeToken() {
    this.storageService.removeFromLocal(this.USER_TOKEN);
  }

  getFirstName() {
    return this.USER_INFO && this.USER_INFO.firstName;
  }

  getUserName() {
    return this.getFullName();
  }

  getUserPosition() {
    return this.USER_INFO && this.USER_INFO.position;
  }

  getLastName() {
    return this.USER_INFO && this.USER_INFO.lastName;
  }

  getImage() {
    return this.USER_INFO && this.USER_INFO.image;
  }

  getCompanyName() {
    return this.USER_INFO && this.USER_INFO.companyName;
  }

  getForcedReset() {
    return this.USER_INFO && this.USER_INFO.forcedReset;
  }

  getDecodedAccessToken(token: string): any {
    const helper = new JwtHelperService();
    try {
      return helper.decodeToken(token);
    } catch (error) {
      console.error(error); // log to console instead
      return null;
    }
  }

  getUserType(): string {
    const token = this.getDecodedAccessToken(this.getToken());
    if (token != null) {
      return token.usertype;
    } else {
      return null;
    }
  }

  getRoles(): Array<string> {
    return this.USER_INFO && Array.isArray(this.USER_INFO.roles)?this.USER_INFO.roles:[];
  }

  public getTokenExpirationDate(): Date {
    const token = this.getDecodedAccessToken(this.getToken());
    if (token.exp === undefined) { return null; }
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  }

  getUploadHeader(): any {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.getToken(),
        IP: ''
      })
    };
  }

  getMultiPartHeader(): any {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.getToken(),
        'Content-Type': 'false',
        'Accept': 'multipart/form-data'
      })
    };
  }

  getHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getToken(),
      IP: ''
    });
  }

  getHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
        IP: ''
      })
    };
  }
  getTextHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Authorization: 'Bearer ' + this.getToken(),
        IP: ''
      })
    };
  }

  getFullName(): string {
    return this.getFirstName() + ' ' + this.getLastName();
  }

  getUserInfo() {
    return this.USER_INFO?this.USER_INFO:{};
  }

  setUserInfo(info: any) {
    this.USER_INFO = info;
  }
}
