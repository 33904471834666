import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-landing-features',
  templateUrl: './landing-features.component.html',
  styleUrls: ['./landing-features.component.scss']
})
export class LandingFeaturesComponent implements OnInit {
  @Input() landingFeatures: any;
  public AccountType: any;

  constructor(private router: Router, private commonService: CommonService) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.AccountType = res.accountType.typeData;
      });
  }

  ngOnInit() {
  }
  redirectToSignup(accountType) {
    this.router.navigate(['/signup'], {
      queryParams: { accountType: accountType }
    });
  }


}
