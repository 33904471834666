import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss']
})
export class FormFooterComponent implements OnInit {
  @Input()
  componentId = '';
  @Input()
  disableBackButton = false;
  @Input()
  disableNextButton = true;
  @Input()
  nextBtnLabel = 'COMMON.NEXT';
  @Output()
  backEvent = new EventEmitter();
  @Output()
  saveEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  back() {
    this.backEvent.emit();
  }

  save() {
    this.saveEvent.emit();
  }
}
