import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

declare var gtag: any;
declare var ga: any;

@Injectable({
  providedIn: 'root'
})
/**
 *  This service can be used to log page view and events on google analytics.
 */
export class GoogleAnalyticsService {
  
  trackingIds: string[] = [];
  
  constructor() {
    if (typeof ga !== 'undefined' && ga) {
      // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/ga-object-methods-reference
      ga(() => {
        ga.getAll().forEach((tracker: any) => {
          const id = tracker.get('trackingId');
          // If set both in forRoot and HTML page, we want to avoid duplicates
          if (id !== undefined && this.trackingIds.indexOf(id) === -1) {
            this.trackingIds.push(id);
          }
        });
      });
    }
  }

  // See: https://developers.google.com/analytics/devguides/collection/gtagjs/events
  logCustomEvent(
    eventAction: string, 
    eventCategory: string, 
    eventLabel: string = null,  
    eventValue: number = null,
    nonInteraction: boolean = false
  ) {
    if (typeof gtag !== 'undefined' && gtag) {
      const properties: any = {
        event_category: eventCategory || 'interaction',
        event_label: eventLabel,
        value: eventValue,
        non_interaction: nonInteraction
      };

      gtag('event', eventAction, properties);
    }
  }

  // See: https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications#tracking_virtual_pageviews
  logPageView(url: string, path: string) {
    if (typeof gtag !== 'undefined' && gtag) {
      const params: any = {
        page_path: path,
        page_location: url
      };

      gtag('config', environment.gaTrackingId, params);
    }
  }
}
