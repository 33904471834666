import { Component, OnInit, ViewChild } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';

import { UiModalComponent } from '../../shared/components/modal/ui-modal/ui-modal.component';
import { FaqService } from '../../core/services/faq-service/faq.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  faqs: any;

  oneSection: boolean = true;
  isOpen = true;

  myForm: FormGroup;

  isSubmit: boolean;
  buttonPressed: boolean;
  loading: boolean;

  @ViewChild('askFaqQuestionModal', { static: false }) formModal: UiModalComponent;
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;

  constructor(
    private faqService: FaqService
  ) { }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      name: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(35),
      CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })]),
      email: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.email,
      Validators.maxLength(100)]),
      question: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(275)])
    });
    this.getFaqs();
  }

  getFaqs() {
    this.loading = true;
    this.faqService.getFaqs(0, 100).subscribe(
      (data) => {
        this.faqs = Array.isArray(data["content"]) ? data["content"] : [];
        this.loading = false;
      },
      (error) => {
        this.faqs = [];
        this.loading = false;
        // console.log(error);
      }
    );
  }

  saveQuestion() {
    this.isSubmit = true;

    if (this.myForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let formData = this.myForm.value;

    this.faqService.askCustomQuestion(formData).subscribe(
      data => {
        this.successMessage();
        this.buttonPressed = false;
        this.clearFields();
        this.formModal.hide();
      },
      error => {
        this.errorMessage();
        this.buttonPressed = false;
      }
    );
  }
  clearFields() {
    this.isSubmit = false;
    this.myForm.reset();
    this.myNgForm.resetForm();
  }
  successMessage() {
    Swal.fire(
      'Great!',
      'We’ve received your question. We’ll get in touch with you in the coming days.',
      'success'
    );
  }

  errorMessage() {
    Swal.fire(
      'Error!',
      'Unable to process question right now. Please try after some time.',
      'error'
    );
  }

  get f() { return this.myForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

}
