import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

import { TeamMemberService } from '../../core/services/team-member-service/team-member.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-meet-our-team',
  templateUrl: './meet-our-team.component.html',
  styleUrls: ['./meet-our-team.component.scss']
})
export class MeetOurTeamComponent implements OnInit {
  hasItems: boolean = false;
  isPublic: boolean = false;
  userId: any;
  memberForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  loading: boolean;
  members: any = [];
  imagePath: any;
  previewURL: any;
  addTeamModal: boolean;
  showdata: boolean;
  deleteTeamMemberModal: boolean;
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;
  responsiveOptions: any;
  maxAllowedSize = 1 * 1024 * 1024;
  srcData: any = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropimage: boolean = false;
  imageCroppedFlag: boolean = false;
  croppedImageBase64: string = '';
  file: any = []

  constructor(
    private teamMemberService: TeamMemberService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.memberForm = new FormGroup({
      empName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z]*$'), CustomValidators.removeSpaces]),
      empPosition: new FormControl('', [Validators.required, Validators.maxLength(150), Validators.pattern('^[a-zA-Z]*$'), CustomValidators.removeSpaces]),
      linkedin: new FormControl('', [CustomValidators.removeSpaces])
    });

    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestMemberList();
    });
  }

  addteamMember() {
    this.addTeamModal = true;
    this.srcData = "";
    this.croppedImageBase64 = "";
    this.cropimage = false;
  }

  loadMemberList(event: any) {
    this.loading = true;
    let q = '';
    let sortAsc = event.sortField && event.sortOrder == 1 ? event.sortField : '';
    let sortDesc = event.sortField && event.sortOrder == -1 ? event.sortField : '';
    this.teamMemberService.getTeamMembers((event.first / event.rows), event.rows, q, sortAsc, sortDesc).subscribe(
      (data) => {
        this.members = data;
        if (Array.isArray(this.members) && this.members.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.members = [];
        this.loading = false;
        // console.log(error);
      }
    );
  }

  loadLatestMemberList() {
    this.loading = true;
    this.teamMemberService.getLatestMemberCards(this.userId).subscribe(
      (data) => {
        this.members = data;
        if (Array.isArray(this.members) && this.members.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.members = [];
        this.loading = false;
        // console.log(error);
      }
    );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
    this.imageCroppedFlag = true;
  }

  async onButtonClicked() {
    this.srcData = await this.base64ToBlob(
      this.croppedImageBase64
    )
    this.cropimage = false;
  }

  async base64ToBlob(data) {
    const arr = data.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: 'image/jpeg' });
  }

  onFileChanged(event: any) {
    this.cropimage = true
    this.imageChangedEvent = event;

    this.file = event.target.files[0];

    if (this.file) {
      this.displaySelectedImage(this.file);
    }
  }

  displaySelectedImage(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.srcData = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }
  }


  saveMember() {
    this.isSubmit = true;
    this.buttonPressed = false;
    if (this.memberForm.invalid || this.buttonPressed) {
      return false;
    }
    if (!this.srcData) {
      this.toastMessage("Team Member", "Please add member profile image", "error");
      return false;
    }

    this.buttonPressed = true;
    let formData = this.memberForm.value;
    this.teamMemberService.saveTeamMember(this.getFormData(formData)).subscribe(
      data => {
        this.hasItems = true;
        this.addTeamModal = false;
        this.loadMemberList({ first: 0, rows: 10 });
        this.buttonPressed = false;
        this.clearFields();
        this.toastMessage("Team Member", "Team member saved successfully", "success");
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage("Team Member", "Unable to save team member! Please try after some time.", "error");
      }
    );
  }


  clearFields() {
    this.memberForm.reset();
    this.deleteTeamMemberModal = false;
    this.isSubmit = false;
    this.imagePath = null;
    this.previewURL = null;
    this.myNgForm.resetForm();
    this.currentMember = null;
    this.buttonPressed = false;
  }
  get f() { return this.memberForm.controls; }

  inValid(control: any, submitted: any,) {
    return control.invalid && (control.dirty || control.touched || submitted);
  }


  getFormData(item: any) {
    let payload = new FormData();
    payload.append('data', JSON.stringify(item));
    if (this.srcData)
      payload.append('file', this.srcData, 'image.jpg');
    return payload;
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }
  currentMember;
  // deleteTeamMemberModal=false;
  remove(member) {
    this.currentMember = member;
    this.deleteTeamMemberModal = true;
  }
  deleteConfirmed() {
    this.buttonPressed = true;
    this.teamMemberService.deleteTeamMember(this.currentMember.teamMemberId).subscribe(
      data => {
        this.members = this.members.filter(m => { return (m.teamMemberId != this.currentMember.teamMemberId) });
        this.deleteTeamMemberModal = false;
        this.toastMessage("Team Member", "Team member removed successfully", "success");
        this.clearFields();
      },
      error => {
        this.clearFields();
        this.toastMessage("Team Member", "Unable to delete member! Please try after some time.", "error");
      }
    );
  }


}
