import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-sp-meet-our-team',
  templateUrl: './sp-meet-our-team.component.html',
  styleUrls: ['./sp-meet-our-team.component.scss'],
  animations: [fadeAnimation]
})
export class SpMeetOurTeamComponent {
  serviceCard = false;
  emptyCard = true;


  getStarted() {
    this.serviceCard = false;
    this.emptyCard = true;
  }
  editCard() {
    this.serviceCard = true;
    this.emptyCard = false;
  }


}
