<div class="row">
    <div class="col-md-12 col-lg-12">
        <div class="user-details edit">
            <form [formGroup]="orgForm" *ngIf="isSME || isServiceProvider || isInvester">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblCompany" class="othresp-m-b">{{ "editProfile.Company.Company" | translate
                                }}</label>
                            <input [readonly]="orgDetails?.verifiedCompany" type="text" class="form-control"
                                id="txtCompany"
                                placeholder="Ex: Best company Ltd. This will be the name displayed on your company profile page. "
                                formControlName="companyName"
                                [ngClass]="{'is-invalid':inValid(f.companyName, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.companyName, isSubmit)">
                                <div *ngIf="f.companyName.errors.required" id="errNoCompanyData">
                                    Please enter your company name so that other users can find you.
                                </div>
                                <div *ngIf="f.companyName.errors.maxlength" id="errCompanyLengthMax">Company Name should
                                    not exceed 40
                                    characters</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblBrnnumber">{{ "editProfile.Company.BRN" | translate }}</label>
                            <input type="text" class="form-control" id="txtBrnNumber" placeholder="Ex: YYYY/XXXXXX/ZZ"
                                formControlName="brnNumber" [ngClass]="{'is-invalid':inValid(f.brnNumber, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.brnNumber, isSubmit)">
                                <div *ngIf="f.brnNumber.errors.required" id="err_BrnNumber">
                                    Please insert Company Registration Number
                                </div>
                                <div *ngIf="f.brnNumber.errors.hasValidBRN" id="errBrnFirstLetter">Please insert a valid
                                    Company Registration Number (YYYY/XXXXXX/ZZ)</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblBrnnumber">{{ "editProfile.Company.tradeLicenseNumber" | translate }}</label>
                            <input [readonly]="orgDetails?.verifiedCompany" type="number" class="form-control"
                                id="txtBrnNumber" placeholder="Ex: 12345678" formControlName="tradeLicense"
                                [ngClass]="{'is-invalid':inValid(f.tradeLicense, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.tradeLicense, isSubmit)">
                                <div *ngIf="f.tradeLicense.errors.required" id="err_BrnNumber">
                                    Please enter the Trade License Number
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblBrnnumber">{{ "editProfile.Company.companyRegion" | translate }}</label>
                            <input [readonly]="orgDetails?.verifiedCompany" type="text" class="form-control"
                                id="txtBrnNumber" placeholder="Ex: RUS" formControlName="companyRegion"
                                [ngClass]="{'is-invalid':inValid(f.companyRegion, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.companyRegion, isSubmit)">
                                <div *ngIf="f.companyRegion.errors.required" id="err_BrnNumber">
                                    Please enter the Company region
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblIndustry">{{ "editProfile.Company.Industry" | translate }}</label>
                            <div class="options" id="divIndustry">
                                <span class="p-fluid">
                                    <p-dropdown [options]="investmentOption"
                                        placeholder="Select {{ 'formElementsLables.Industry' | translate }}"
                                        [ngClass]="{'is-invalid':inValid(f.industry, isSubmit)}"
                                        formControlName="industry" id="selIndustry">
                                    </p-dropdown>
                                </span>
                                <div class="invalid-feedback" *ngIf="inValid(f.industry, isSubmit)">
                                    <div *ngIf="f.industry.errors.required" id="err_Industry">Please select your
                                        industry
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblIncoporation">{{ "editProfile.Company.Incoporation" | translate }}</label>
                            <div class="options" id="divIncorporationYear">
                                <span class="p-fluid">
                                    <p-dropdown [options]="years" formControlName="incorporationYear"
                                        placeholder="Select a year" [showClear]="false"
                                        [ngClass]="{'is-invalid':inValid(f.incorporationYear, isSubmit)}"
                                        id="selIncorporationYear" filter="true"></p-dropdown>
                                </span>
                                <div class="invalid-feedback" *ngIf="inValid(f.incorporationYear, isSubmit)">
                                    <div *ngIf="f.incorporationYear.errors.required">Please enter Incoporation</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblAbout">{{ "editProfile.Company.About" | translate }}</label>
                            <textarea class="form-control" id="txtAbout"
                                placeholder="Describe your company in a few words" formControlName="about"
                                [ngClass]="{'is-invalid':inValid(f.about, isSubmit)}"></textarea>
                            <div class="invalid-feedback" *ngIf="inValid(f.about, isSubmit)">
                                <div *ngIf="f.about.errors.required" id="errNoAbout">Please fill in the about
                                    section.</div>
                                <div *ngIf="f.about.errors.maxlength" id="errAboutMaxLength">About should
                                    not exceed 1000 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form [formGroup]="cmOrgForm" *ngIf="isCommunityManager">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblCompany">{{ "editProfile.Company.Company" | translate }}</label>
                            <input type="text" class="form-control" id="txtCompany"
                                placeholder="Ex: Best company Ltd. This will be the name displayed on your company profile page. "
                                formControlName="companyName"
                                [ngClass]="{'is-invalid':inValid(c.companyName, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(c.companyName, isSubmit)">
                                <div *ngIf="c.companyName.errors.required">Please enter your company name so other users
                                    can find you.</div>
                                <div *ngIf="c.companyName.errors.maxlength" id="errCompanyLengthMax">Company Name should
                                    not exceed 40
                                    characters</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-12 col-lg-12 d-flex justify-content-center text-align-center">
        <input #fileInput type="file" (change)="onFileChanged($event)" class="hidden" accept=".jpeg,.jpg,.png">
        <div class="profile-pic" id="imgEditProfilePic" *ngIf="!cropimage">
            <span *ngIf="!srcData" class="camera-show" (click)="fileInput.click();" id="btnUploadHere"><em
                    class="upload-text">{{
                    "editProfile.personalDetails.UploadHere" |
                    translate }}</em></span>
            <img *ngIf="croppedImageBase64 || srcData"
                [src]="croppedImageBase64 ? croppedImageBase64 : srcData || 'assets/images/profile-picture.png'"
                class="profile-img" alt="feed" (click)="fileInput.click()" id="imgProfilePic" />
        </div>
        <div [ngClass]="cropimage ? 'cropimagestyle' : 'othertyle'" *ngIf="cropimage">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
                format="png" (imageCropped)="imageCropped($event)" output="base64"></image-cropper>

            <button class="btn btn-confirm crop" *ngIf="imageCroppedFlag" (click)="onButtonClicked()">Crop
                Image</button>
        </div>
    </div>
</div>