import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscussionService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  newQuestion = new EventEmitter<any>();
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) { }

  getDiscussions(pageNumber, pageSize) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/discussion-board/getQuestions`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  getAnswers(questionId, pageNumber, pageSize) {
    const params = new HttpParams()
      .set('questionId', questionId)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/discussion-board/getAnswers`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  submitQuestion(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/discussion-board/submitQuestion`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
            this.newQuestion.emit(appRef);
          }
        )
      );
  }
  updateQuestion(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/discussion-board/updateQuestion`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
          }
        )
      );
  }

  submitAnswer(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/discussion-board/submitAnswer`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  updateAnswer(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/discussion-board/updateAnswer`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  deleteQuestion(id) {
    return this.http.delete<any>(`${this.BASE_API_URL}api/discussion-board/deleteQuestion?questionId=` + id,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  deleteAnswer(id) {
    return this.http.delete<any>(`${this.BASE_API_URL}api/discussion-board/deleteAnswer?answerId=` + id,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getTopActive(): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/discussion-board/getTopActive`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  setForumHelpfulness(forumId, helpful): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/discussion-board/setForumHelpfulness?forumId=` + forumId + '&forumHelpful=' + helpful, '')
      .pipe(tap((appRef: any) => { }))
    return res;
  }

  setForumUnHelpfulness(forumId, unHelpful): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/discussion-board/setForumUnHelpfulness?forumId=` + forumId + '&forumUnHelpful=' + unHelpful, '')
      .pipe(tap((appRef: any) => { }))
    return res;
  }
}
