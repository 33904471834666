import { FormControl } from '@angular/forms';

export enum FieldType {
  CURRENCY,
  DROPDOWN,
  MULTI_SELECT,
  TEXT,
  TEXT_AREA,
  DATE_TIME_PICKER,
  MOBILE,
  DISABLED_DEFAULT_CURRENCY,
  SEARCHABLE_DROPDOWN,
  RADIO,
  NUMBER,
  YEAR_MONTH_DATE
}

export enum FieldSourceModel {
  APPLICANT_DETAIL
}

export class ToolTipMetaData {
  content = '';
  displayPosition = '';

  constructor(content, displayPosition = '') {
    this.content = content;
    this.displayPosition = displayPosition;
  }

}

export class FieldMetaData {
  fieldType: FieldType;
  labelText = '';
  placeholderText = '';
  control: FormControl = undefined;
  name = '';
  propertyInModel = '';
  fieldSourceModel: FieldSourceModel = FieldSourceModel.APPLICANT_DETAIL;
  display = true;
  mandatory = true;
  isEditable = true;
  showOptional = false;
  metaData = '';
  toolTipMetaData: ToolTipMetaData;

  constructor(
    fieldType: FieldType,
    control: FormControl,
    name: string,
    labelText: string,
    placeholderText: string,
    propertyInModel: string,
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    isEditable = true,
    display = true,
    showOptional = false,
    metaData = ''
  ) {
    this.fieldType = fieldType;
    this.labelText = labelText;
    this.placeholderText = placeholderText;
    this.control = control;
    this.name = name;
    this.toolTipMetaData = toolTipMetaData;
    this.propertyInModel = propertyInModel;
    this.mandatory = mandatory;
    this.fieldSourceModel = fieldSourceModel;
    this.isEditable = isEditable;
    this.display = display;
    this.showOptional = showOptional;
    this.metaData = metaData;
  }

  isValuePresent() {
    return this.control.value !== null && this.control.value !== undefined && this.control.value !== '';
  }
}



export class SearchableDropdownFieldMetaData extends FieldMetaData {
  inputList: any[] = [];
  displayProperty = 'displayValue';
  valueProperty = 'value';
  countryMode = false;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    placeholderText: string,
    propertyInModel: string,
    inputList: any[] = [],
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    displayProperty = 'displayValue',
    valueProperty = 'value',
    toolTipMetaData: ToolTipMetaData = null,
    countryMode = false,
    showOptional = false
  ) {
    super(FieldType.SEARCHABLE_DROPDOWN, control, name, labelText, placeholderText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData, true, true, showOptional);
    this.inputList = inputList;
    this.displayProperty = displayProperty;
    this.valueProperty = valueProperty;
    this.countryMode = countryMode;
    this.showOptional = showOptional;
  }
}



export class TextFieldMetaData extends FieldMetaData {
  errorMsg = '';
  type = 'text';

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    placeholderText: string,
    propertyInModel: string,
    errorMsg = '',
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    isEditable = true,
    type = 'text',
    showOptional = false
  ) {
    super(FieldType.TEXT, control, name, labelText, placeholderText, propertyInModel, mandatory,
      fieldSourceModel, toolTipMetaData, isEditable, true, showOptional, '');
    this.errorMsg = errorMsg;
    this.type = type;
  }
}

export class TextAreaFieldMetaData extends FieldMetaData {
  maxLength = 500;
  errorMsg = '';
  showLabel = true;
  rows = 2;
  fullWidth = false;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    placeholderText: string,
    propertyInModel: string,
    errorMsg = '',
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    maxLength = 500,
    showLabel = true,
    rows = 2,
    fullWidth = false
  ) {
    super(FieldType.TEXT_AREA, control, name, labelText, placeholderText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.maxLength = maxLength;
    this.errorMsg = errorMsg;
    this.showLabel = showLabel;
    this.rows = rows;
    this.fullWidth = fullWidth;
  }
}


export function getControlByName(fields: FieldMetaData[], name: string): FormControl {
  return fields.filter(field => field.name === name)[0].control;
}

export function getFieldByName(fields: FieldMetaData[], name: string): FieldMetaData {
  return fields.filter(field => field.name === name)[0];
}



export function areFieldsValid(fields: FieldMetaData[]) {
  let valid = true;
  fields
    .filter(field => field.display)
    .forEach(field => {
      valid = (valid && getValueStatus(field) && (!field.control.errors))
    });
  return valid;
}

function getValueStatus(field: FieldMetaData) {
  return !field.mandatory || (field.mandatory && field.isValuePresent());
}
