import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit {

  isSME: boolean = true;
  isSP: boolean = true;
  isInvestor: boolean = true;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const type = params.type;
      this.isSME = type == 'sme';
      this.isSP = type == 'service_provider';
      this.isInvestor = type == 'investor';
    });
  }

}
