import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-my-story-form',
  templateUrl: './my-story-form.component.html',
  styleUrls: ['./my-story-form.component.scss']
})
export class MyStoryFormComponent implements OnInit {
  
  @Input() isSubmit: boolean;
  @Input() storyForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    if(!this.storyForm) {
      this.storyForm = new FormGroup({
        pastTitle: new FormControl(''),
        past:  new FormControl(''),
        nowTitle: new FormControl(''),
        now:  new FormControl(''),
        futureTitle: new FormControl(''),
        future:  new FormControl('')
      });
    }
  }

  get f() { return this.storyForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

}
