<app-language-selector></app-language-selector>
<div class=" sme-stats">
  <div class="row">
    <div class="col-lg-12 col-xl-9 col-md-12">
      <ng-container *ngIf="!showingdetails">
        <app-feed-share (newFeedAdded)="newFeedAdded($event)"></app-feed-share>
        <div class="card main-card">
          <p-tabView styleClass="subNav feed-tabs">
            <p-tabPanel>
              <div class="gridLayoutIcons">
                <span class="pointer" (click)="gridUIClass = ''" [ngClass]="{'active': gridUIClass === ''}">
                  <i class="fas fa-list mx-3"></i>
                </span>
                <span class="pointer" (click)="gridUIClass = 'gridLayout'"
                  [ngClass]="{'active': gridUIClass === 'gridLayout'}">
                  <i class="fas fa-th-large"></i>
                </span>
              </div>
              <ng-template pTemplate="header" id="tabFeed">
                <span>Feed</span>
                <span *ngIf="feedTotalRecords" class="num">({{feedTotalRecords}})</span>
              </ng-template>

              <div infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
                <app-news-feed [newsFeed]="newsFeeds" [className]="gridUIClass" [handleDeleteClick]="onDelete"
                  (showDetailEvent)="handleShowDetail($event)" id="app-news-feed"></app-news-feed>
              </div>
              <div class="spinner-border text-center" role="status" *ngIf="showLoader">
                <span class="sr-only">Loading...</span>
              </div>
            </p-tabPanel>

            <p-tabPanel>
              <ng-template pTemplate="header" id="tabMyContent" *ngIf="!isRM">
                <span>My Content</span>
                <span *ngIf="myFeedTotalRecords" class="num">({{myFeedTotalRecords}})</span>
              </ng-template>
              <div #MyContentPostDetails>
                <div class="card-body" *ngIf="totalRecords==0">
                  No content submitted yet
                </div>


                <p-dataView [value]="myFeedContent" [lazy]="true" [paginator]="totalPages>1" [rows]="10"
                  [totalRecords]="myFeedTotalRecords" [loading]="loading" [style]="{width:'100%'}"
                  (onLazyLoad)="getMyFeedContent($event)" class="ufeed-data-table feed-content" *ngIf="totalRecords>0">

                  <ng-template pTemplate="header">
                    <div class="flex flex-column md:flex-row md:justify-content-between">
                      <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                    </div>
                  </ng-template>


                  <ng-template let-rowData pTemplate="listItem">
                    <div class="align-middle  img-content pointer row" data-toggle="modal"
                      (click)="currentMyFeed=rowData; feedArticalViewModal = true">
                      <div class="col-4 content-imgsrc p-0">
                        <img
                          src="{{rowData.featuredImage ? rowData.featuredImage : 'assets/images/news/newsfeed-square.jpg'}}"
                          id="img{{ rowData.id }}" class="align-top m-r-15 d-inline-block" alt="feed">
                      </div>
                      <div class="d-inline-block break-all col-8 data-content">
                        <h6 id="ttl{{ rowData.id }}">{{rowData.title}}</h6>
                        <p class="text-muted m-b-0 word-align">{{rowData.description}}</p>
                        <span class="status-icon m-0"
                          [ngClass]="{approved: (rowData.reviewStatus == 'approved'), rejected: (rowData.reviewStatus == 'rejected'), inProgress: (rowData.reviewStatus == 'pending')}">
                          <p class="status_head">Status</p>
                          <p>
                            <em *ngIf="rowData.reviewStatus == 'pending'" class="fas fa-exclamation-circle"></em>
                            <em *ngIf="rowData.reviewStatus == 'approved'" class="fas fa-check-circle"></em>
                            <em *ngIf="rowData.reviewStatus == 'rejected'" class="fas fa-ban"></em>
                            {{ FeedStatus[rowData.reviewStatus] | translate }}
                          </p>

                        </span>
                      </div>
                    </div>
                  </ng-template>


                  <ng-template let-rowData pTemplate="gridItem">
                    <div class=" img-content-gv pointer" data-toggle="modal"
                      (click)="currentMyFeed=rowData; feedArticalViewModal = true">

                      <div class="content-imgsrc-gv p-0" style="height:270px">
                        <img
                          src="{{rowData['featuredImage']?rowData.featuredImage:'assets/images/news/newsfeed-square.jpg'}}"
                          id="img{{ rowData.id }}" class="align-top m-r-15 d-inline-block" alt="feed">
                      </div>
                      <div class="break-all data-content">
                        <h6 id="ttl{{ rowData.id }}">{{rowData.title}}</h6>
                      </div>
                      <div class="d-inline-block break-all data-content">

                        <p class="text-muted m-b-0 word-align">{{rowData.description}}</p>
                        <span class="status-icon d-flex justify-content-between m-0">
                          <p>
                            <em *ngIf="rowData.reviewStatus == 'pending'" class="fas fa-exclamation-circle"></em>
                            <em *ngIf="rowData.reviewStatus == 'approved'" class="fas fa-check-circle"></em>
                            <em *ngIf="rowData.reviewStatus == 'rejected'" class="fas fa-ban"></em>
                            {{ FeedStatus[rowData.reviewStatus] | translate }}
                          </p>

                        </span>
                      </div>
                    </div>
                  </ng-template>
                </p-dataView>
              </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="isCommunityManager">
              <ng-template pTemplate="header" id="tabPending">
                <span>Pending</span>
                <span *ngIf="totalPendings" class="num">({{totalPendings}})</span>
              </ng-template>

              <app-cm-pending-feed *ngIf="isCommunityManager"
                (pendingRecordsEmitter)="recordsEmitter($event)"></app-cm-pending-feed>
            </p-tabPanel>
            <p-tabPanel *ngIf="isCommunityManager">
              <ng-template pTemplate="header" id="tabRejected">
                <span>Rejected</span>
                <span *ngIf="totalPendings" class="num">({{totalRejects}})</span>
              </ng-template>

              <app-cm-rejected-feed *ngIf="isCommunityManager"
                (rejectedRecordsEmitter)="RejectsRecordsEmitter($event)"></app-cm-rejected-feed>
            </p-tabPanel>
          </p-tabView>
        </div>
      </ng-container>

      <ng-container *ngIf="showingdetails">
        <div class="card main-card">

          <div class="group-loaders" *ngIf="loadingDetails">
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
            </div>
          </div>

          <div class="news-card" *ngIf="!loadingDetails">
            <div class="card-body">
              <span class="pointer back-btn" (click)="handleShowDetail()">
                <p> <em class="fas fa-arrow-left"></em> All</p>
              </span>

              <ng-container *ngFor="let item of feedDetail">
                <div class="d-flex align-items-center company-details">

                  <span>
                    <img class="avatar"
                      src="{{item?.companyLogo ? item?.companyLogo : 'assets/images/news/newsfeed.jpg'}}" alt="">
                  </span>


                  <div class="ml-1">
                    <p class="name">{{item?.companyName}}</p>

                    <span>
                      <p class="role" *ngIf="item?.roleName == 'SME'">Entrepreneur</p>
                      <p class="role" *ngIf="item?.roleName == 'COMMUNITY_MANAGER'">Community manager</p>
                      <p class="role" *ngIf="item?.roleName == 'SERVICE_PROVIDER'">Service provider</p>
                      <p class="role" *ngIf="item?.roleName == 'INVESTOR'">Service provider</p>
                    </span>
                  </div>
                </div>


                <div class="card-header">
                  <h5>
                    <span>{{item?.title}}</span>
                    <span class="updatedTime">{{ getRelativeTime(item?.updatedTime) }}</span>
                  </h5>
                </div>


                <div class="card-cntent p-0">

                  <img *ngIf="item?.topic != 'video'" id=""
                    src="{{item?.featuredImage ? item?.featuredImage : 'assets/images/news/newsfeed.jpg'}}"
                    class="detailCard-img mb-3" alt="alt">

                  <!-- youtube -->
                  <span *ngIf="item?.topic == 'video' && item?.featuredImage && !item.clickedPlayButton"
                    (click)="sanitizeUrl(item.feedUrl);updatePlayButtonClicked(item.id);">
                    <img class="avatar"
                      src="{{item?.featuredImage ? item?.featuredImage : 'assets/images/news/newsfeed.jpg'}}"
                      class="detailCard-img mb-3" alt="">
                    <div class="show-video-play-icon">&nbsp;</div>
                  </span>

                  <span class="embed-responsive embed-responsive-16by9 detailCard-img"
                    *ngIf="item?.topic == 'video' && (!item.featuredImage || item.clickedPlayButton)">
                    <div *ngIf="item?.topic == 'video' && getYoutubeId(item.feedUrl)">
                      <app-custom-youtube feedTitle="{{item.title}}" youtubeId="{{getYoutubeId(item.feedUrl)}}"
                        playButtonPressed="{{item.clickedPlayButton}}"></app-custom-youtube>
                    </div>
                    <video *ngIf="item?.topic == 'video' && !getYoutubeId(item.feedUrl)" width="100%" height="500"
                      controls preload="auto" [src]="item.videoUrl">
                      Your browser does not support the video tag.
                    </video>
                  </span>
                  <!-- youtube -->

                  <div class="mb-3">
                    <p class="card-text">{{item?.description}}</p>
                    <a class="mb-3" style="color: #1971c2" href="{{item?.feedUrl}}">{{item?.feedUrl}}</a>
                  </div>

                  <div class="groups-list">
                    <ng-container *ngFor="let groupitem of item?.groupsData">
                      <div class="d-flex group-block pointer" (click)="groupredirection(groupitem?.groupId)">
                        <img class="group-img"
                          src="{{groupitem?.groupImg ? groupitem?.groupImg : 'assets/images/news/newsfeed.jpg'}}"
                          alt="group_image">
                        <div class="group-info">
                          <p class="group-name">{{groupitem?.groupName}}
                          </p>
                          <div>
                            <div></div>
                            <p>
                              <i class="fas fa-users"></i>
                              {{groupitem?.membersCount}} members
                            </p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                  <hr>

                  <div class="detail-comment">
                    <div class="company_name mb-3">
                      <span class="detail-helpFul-rating">
                        <span (click)="helpful(item.id)" id="{{item?.rating?.HelpfulId}}" class="pointer ml-2">
                          <em class="{{item.helpful?'fas':'far'}} fa-thumbs-up"></em>
                          <p>{{item.totalHelpful}}</p>
                        </span>
                        <span (click)="unHelpful(item.id)" id="{{item?.rating?.UnhelpfulId}}" class="pointer ml-3">
                          <em class="{{item.unHelpful?'fas':'far'}} fa-thumbs-down"></em>
                          <p>{{item.totalUnHelpful}}</p>
                        </span>

                        <span class="feed-cmnt ml-3">
                          <span *ngIf="item.totalComments" (click)="toggleFeedComment(item)">
                            <em class="fas fa-comment-dots pointer"></em>
                            <p>{{item.totalComments}}</p>
                          </span>
                          <span *ngIf="!item.totalComments">No comments yet</span>
                        </span>

                      </span>
                    </div>

                    <app-feed-comment [feed]="item" [showComments]="item.toggle" [companyProfile]="companyProfile">
                    </app-feed-comment>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-lg-4 col-xl-3 content-h">
      <app-own-content></app-own-content>
    </div>
  </div>
</div>

<p-dialog header="" [(visible)]="feedArticalViewModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="shareFeed_dialog" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">

  <div class="view-feed">
    <div class="text-center">
      <h5 class="modal-title" id="ttlViewMyContent">
        {{'FeedMyContent.popupContent.title' | translate }}
      </h5>
    </div>
    <div class="app-modal-body">
      <app-feed-popup-view [feed]="currentMyFeed?currentMyFeed:{}" [CMView]="false" [showPostPreviewFooter]="true"
        [showinfo]="false" [submittedby]="false"></app-feed-popup-view>
    </div>


  </div>
</p-dialog>
<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>