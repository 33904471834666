<div class="service-card user-book-service">
  <app-card [id]="componentId" [customHeader]="true" headerClass="border-0 p-0 pb-0" [options]="false"
    cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
    <div class="app-card-header position-relative" (click)="takeToCompanyPage()" [ngClass]="{ 'growth-card-badge-wait-aprv': isGrowthCard && approvalStatus == 'New',
                'growth-card-badge-draft': isGrowthCard && approvalStatus == 'Draft'}">
    </div>

    <div class="user-about-block text-center">
      <div class="row align-items-end">
        <div class="col text-right">
          <div class="card-action">
            <div class="dropdown text-right" *ngIf="canEdit ">
              <button class="cardbtn " *ngIf="!canPreview  && !isGrowthCard"><em
                  class="fas fa-ellipsis-v"></em></button>
              <div class="card-content" *ngIf="!isGrowthCard">
                <a href="javascript: void(0)" id="'btn' + componentId + 'Edit'" (click)="onEdit($event)"
                  class=" waves-effect waves-light d-flex justify-content-between">
                  <span>Edit</span>
                  <span><em class="fas fa-pencil-alt"></em></span></a>
                <a href="javascript: void(0)" class=" waves-effect waves-light d-flex justify-content-between"
                  (click)="onDelete($event)">
                  <span>Remove</span>
                  <span><em class="fas fa-trash-alt"></em></span></a>
              </div>

              <button class="cardbtn " *ngIf="isGrowthCard && approvalStatus == 'Draft' && !isRM"><em
                  class="fas fa-ellipsis-v"></em></button>
              <div class="card-content" *ngIf="isGrowthCard && approvalStatus == 'Draft' && !isRM">
                <a href="javascript: void(0)" id="'btn' + componentId + 'Edit'" (click)="onEditGC($event)"
                  class=" waves-effect waves-light d-flex justify-content-between">
                  <span>Edit</span>
                  <span><em class="fas fa-pencil-alt"></em></span></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div class="mt-2 text-danger" *ngIf="showdata">Only images in PNG, JPG, and GIF formats are permitted</div>
    </div>
    <div class="text-left">
      <div class="service-info">

        <h6 class="mb-1 title pointer" (click)="onItemClicked()">
          <span>
            {{ serviceToProvide }}
          </span>
        </h6>
      </div>
    </div>


    <div class="cover-img-block pointer text-center" (click)="canPreview?file.click():null">
      <div class="service-add-pic" *ngIf="thumbnail">
        <img [id]="'img' + componentId" [src]="thumbnail" alt="alt" class="img-fluid">
      </div>
      <div class="add-photo size-full text-center pointer" *ngIf="!thumbnail">
        <em class="fas fa-image"></em>
        <div class="mt-2">Add Featured image here</div>
      </div>
      <input [id]="'fil' + componentId" [(ngModel)]="filesInput" #file type="file" accept='image/*'
        (change)="onPreviewImage(file.files, $event)" style="display: none;" />
      <div class="company-logo" *ngIf="companyLogo">
      </div>
    </div>

    <div class="d-flex company-logo companyblock">
      <img *ngIf=" companyLogo" [id]="'img' + componentId" src="{{companyLogo}}" alt="alt" class="img-company-logo">
      <h3 class="companyhead mt-2 mx-2">
        {{growthPatnerName}}
      </h3>
    </div>

    <div class="text-center">
      <div class="d-flex card-data" *ngIf="done">
        <div [id]="'lst' + componentId + 'Cost'" class="mr-3">
          <p> <em class="fas fa-suitcase mr-1"></em>{{ getCostRange() }}
            <span class="mb-0" *ngIf="showCurrency()">{{ "Spservices.servicesCard.MUR" | translate }}</span>
          </p>
        </div>

        <div [id]="'lst'+ componentId + 'Time'">
          <p class="mb-0" *ngIf="!isHours"><em class="fas fa-calendar-alt mr-1"></em>{{ duration }} {{ durationType |
            translate }}</p>
          <p class="mb-0" *ngIf="isHours">hours</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between bottom-data mt-2" *ngIf="done">
      <div>
        <a [id]="'btn' + componentId + 'Details'" *ngIf="isGrowthCard"
          class="btn waves-effect waves-light btn-link p-0 pointer" (click)="getMoreDetails(serviceDescriptionUrl)">More
          Details</a>
      </div>

      <div class="mt-2">
        <button class="btn btn-primary intrestedbutton" type="button" id="btnBookService"
          *ngIf="canBook && !(isGrowthCard) && !isRM" (click)="doBooking($event)"><em class="fas fa-envelope mx-2"></em>
          {{buttons}}</button>
        <p *ngIf="bookingdone  && !(isGrowthCard) && !isRM"><em class="fas fa-check mx-1"></em> booked</p>

        <button class="btn btn-primary intrestedbutton" type="button" id="btnBookService" *ngIf="canBook &&
          (isGrowthCard || serviceDescriptionUrl) && !isRM " (click)="doBooking($event)"><em
            class="fas fa-envelope mx-2"></em>
          <span class="content-h">{{buttons}}</span></button>
        <p *ngIf="bookingdone  && (isGrowthCard || serviceDescriptionUrl) && !isRM"><em class="fas fa-check mx-1"></em>
          booked</p>
      </div>
    </div>

    <!-- <div class="text-center">
      <a [id]="'btn' + componentId + 'Details'" *ngIf="isGrowthCard" class="btn waves-effect waves-light btn-link p-0"
        (click)="getMoreDetails(serviceDescriptionUrl)">More Details</a>
    </div> -->
  </app-card>
</div>