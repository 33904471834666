<app-language-selector></app-language-selector>
<div id="divSmeYourProfileCard" class="card status-line">
  <div class="card-header" style="border:0">
    <h5 id="ttlSmeYourProfileCard" style="font-size: 18px;font-weight: bold;color: #000;">{{ 'YourPage.title' |
      translate }}</h5>
  </div>
  <div class="card-body">
    <h5>{{ 'YourPage.status.title' | translate }}</h5>
    <div class="progress-box">
      <ngb-progressbar type="primary" [value]="25"></ngb-progressbar>
    </div>
    <p>{{ 'YourPage.status.disc' | translate }}</p>
    <app-status-line [statusLine]="statusLine"></app-status-line>
  </div>
</div>