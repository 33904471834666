export class ChartDB {
  public static radial1 = {
    chart: {
      height: 120,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        }
      },
    },
    labels: ['$ 00 left'],
    colors: ['#0D9B6A'],
    series: [75],
  }

  public static radial2 = {
    chart: {
      height: 120,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        }
      },
    },
    labels: ['$00 left'],
    colors: ['#FF9125'],
    series: [10],
  }

  public static radial3 = {
    chart: {
      height: 120,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        }
      },
    },
    labels: ['-$00 left'],
    colors: ['#DC143D'],
    series: [100],
  }

  public static performance = {
    chart: {
      height: 273,
      type: 'bar',
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#ED365B', '#C51236'],
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    series: [
      {
        name: 'Me',
        data: [44, 55, 57, 56, 61, 58]
      }, {
        name: 'Other Investors',
        data: [76, 85, 101, 98, 87, 105]
      },
    ],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    },
    yaxis: {
      title: {
        text: 'Thousands'
      }
    },
    fill: {
      opacity: 0.9

    },
    tooltip: {
      y: {
        formatter: (val) => '$ ' + val
      }
    }
  };


  public static investmentSummary = {
    chart: {
      height: 320,
      type: 'donut',
    },
    labels: ['INVESTMENT 1', 'INVESTMENT 2', 'INVESTMENT 3'],
    series: [10, 60, 30],
    colors: ['#FF9125', '#0D9B6A', '#DC143D'],
    legend: {
      show: true,
      floating: false,
      fontSize: '12px',
      fontColor: 'red',
      position: 'bottom',
      offsetX: 0,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: (seriesName, opts) => seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex],
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };


}

