import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { ServiceCardService } from '../../../core/services/service-card-service/service-card.service';
import { CommonService } from '../../../services/common/common.service';
import { Common } from '../../../utility/common';
import { Currency } from "../../../core/Enums/currency"
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { ServiceProvidersService } from 'src/app/core/services/service-providers/service-providers.service';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-growth-partner',
  templateUrl: './growth-partner.component.html',
  styleUrls: ['./growth-partner.component.scss'],
  animations: [fadeAnimation]
})
export class GrowthPartnerComponent implements OnInit {
  public videoArticles: any;
  public sideCardContent: any;
  public getGrowthcard: any;
  @Input() type: string = "service";
  @Input() BrowseCards: any = [];
  @Input() searchItems: any = [];
  loading: boolean;
  pageNumber: number = 1;
  pageSize: number = 9;
  totalItems = 0;
  categories: string = "All";
  categoryList: Array<any>;
  allGrowthCard: any;
  responsiveOptions: any;
  hasItems = false;
  partners: any = [];
  userId: any;
  isPublic: boolean = false;
  growthPartners: any;




  description = Common.discp;
  public keyupLabelOn = false;
  public keydownLabelOn = false;
  public someRange2: number[] = [0, 250000];
  public noOfweeks: number[] = [0, 3];

  maxAllowedSize = 1 * 1024 * 1024;

  public weeksConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 4],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 5
    },
    pips: {
      mode: 'count',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  format = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: Currency.MUR,
    minimumFractionDigits: 0,
  }
  );

  public ServicePriceRangeConfig: any = {
    start: [0, 250000],
    connect: true,
    step: 1000,
    range: {
      min: 0,
      max: 1000000
    },
    pips: {
      mode: 'count',
      values: 2
    },
    format: {
      to: function (value) {
        return Currency.RS + (new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: Currency.MUR,
          minimumFractionDigits: 0,
        }).format(Math.floor(value))).replace("MUR", "");
      },
      from: function (value) {
        return Number(value.replace('Rs', '').trim().replace(/\,/g, ''));
      }
    }
  };

  businesses = Common.helpBusinesses;
  selectedBusinesses = '';
  nOfweeks = Common.nOfweeks;
  isLoggedIn: boolean;
  editMode: boolean;
  selectedService: any;
  imagePath: any;
  previewURL: any;

  constructor(
    private serviceCardService: ServiceCardService,
    private serviceproviders: ServiceProvidersService,
    private commonService: CommonService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private utility: UtilityService,
    private router: Router
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.videoArticles = res.sideContent.Growthcard.video;
        this.sideCardContent = res.sideContent.Growthcard;
        this.growthPartners = res.growthPartners.data;
      })
    this.responsiveOptions = [
      {
        breakpoint: '1600px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1366px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestPartnersList();
    });

    // this.loadLatestPartnersList();
  }

  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }

  formatTimeEstimatePreview(value) {
    return this.utility.formatTimeEstimate(value);
  }

  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }

  // loadLatestPartnersList() {
  //   this.loading = true;
  //   this.serviceproviders.getAllServiceProviders(this.pageNumber - 1, this.pageSize).subscribe(
  //     (data) => {
  //       this.partners = data.content;
  //       this.totalItems = data.totalElements
  //       if (Array.isArray(this.partners) && this.partners.length > 0) {
  //         this.hasItems = true;
  //       }
  //       this.loading = false;
  //     },
  //     (error) => {
  //       this.partners = [];
  //       this.loading = false;
  //       console.log(error);
  //     }
  //   );
  // }

  loadLatestPartnersList(): void {
    this.loading = true;
    this.serviceproviders.getAllServiceProviders(this.pageNumber - 1, this.pageSize).subscribe(
      (data) => {
        this.partners = data.content;
        this.totalItems = data.totalElements;
        this.loading = false;
      },
      (error) => {
        this.partners = [];
        this.loading = false;
        console.log(error);
      }
    );
  }


  navigateCompanyPage(partner) {
    console.log(partner, "itsnot")
    let url = "/user-browse/details/" + (partner.userRole ? partner.userRole.toLowerCase() : "service_provider") + "/" + partner.userId;
    this.router.navigate([url]);
  }
  redirectToAllCards(id) {
    this.router.navigate(['/growth-partners/all-partners'], {
      queryParams: { categories: id }
    });
  }


  // goToPage(page: number): void {
  //   if (page < 1) {
  //     page = 1;
  //   }
  //   this.pageNumber = page;
  //   this.loadLatestPartnersList();
  // }

}
