// type 0-send, 1-received
export class UserChat {
  public static chat = [
    {
      friend_id: 1,
      friend_photo: 'assets/images/avatar-3.jpg',
      messages: [
        {
          type: 1,
          msg: 'Hello, I am McBot How may I assist you today?',
          time: '8:20 a.m'
        }
      ]
    }
  ];
}
