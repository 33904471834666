import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartnersComponent {
  @Input() ourPartners: any;

  responsiveOptions;


  constructor(private commonService: CommonService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

}
