import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { PRICE_NOT_AVAILABLE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  deleteProductModal = false;
  @Input() productName: string;
  @Input() description: string;
  @Input() price: string;
  @Input() thumbnail: string;
  @Input() componentId: string;
  @Input() done: boolean = true
  @Output() previewImage: EventEmitter<any> = new EventEmitter();
  @Input() canBook: boolean = false;
  @Input() bookingdone: boolean = false;
  @Input() canEdit: boolean = false;
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() book: EventEmitter<any> = new EventEmitter();

  canPreview: boolean = false;
  isRM: boolean;
  showdata: boolean;
  button: string[] = ["I'm Interested"];

  constructor(
    private utility: UtilityService,
    private authService: UserAuthService
  ) { }

  ngOnInit(): void {
    this.canPreview = this.previewImage.observers.length > 0;
    this.isRM = this.authService.isRelationShipManager();
  }

  onPreviewImage(e: any, event) {
    // this.previewImage.emit(e);
    const file = event.target.files[0];
    const fileType = file.type;
    const validImageTypes = ['image/jpeg', 'image/jpg', 'images/png', 'images/gif'];

    if (!validImageTypes.includes(fileType)) {
      this.showdata = !this.showdata;
      event.target.value = '';
      setTimeout(() => {
        this.showdata = false;
      }, 3000);
    }

    else {
      this.showdata = false;
      this.previewImage.emit(e);
    }
  }

  onEdit(e: any) {
    this.edit.emit(e);
  }
  onDelete(e: any) {
    this.delete.emit(e);
  }

  doBooking(e: any) {
    this.book.emit(e);
  }
  getPriceString() {
    return this.utility.getPriceString(this.price);
  }
  showCurrency() {
    return this.utility.getPriceString(this.price) != PRICE_NOT_AVAILABLE;
  }
}
