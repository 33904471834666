import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../app-config';
import { Location } from '@angular/common';

import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    public nextConfig: any;
    public navCollapsed: boolean;
    public navCollapsedMob: boolean;
    public windowWidth: number;

    isServiceProvider: boolean;

    constructor(
        private zone: NgZone,
        private location: Location,
        private authService: UserAuthService
    ) {
        this.nextConfig = NextConfig.config;
        let currentURL = this.location.path();
        const baseHerf = this.location['_baseHref'];
        if (baseHerf) {
            currentURL = baseHerf + this.location.path();
        }

        this.windowWidth = window.innerWidth;

        if (
            (currentURL === baseHerf + '/layout/collapse-menu' || currentURL === baseHerf + '/layout/box') &&
            this.windowWidth >= 992 &&
            this.windowWidth <= 1024
        ) {
            this.nextConfig.collapseMenu = true;
        }

        this.navCollapsed = this.windowWidth >= 992 ? this.nextConfig.collapseMenu : false;
        this.navCollapsedMob = false;

        /* this.navCollapsed = ((this.windowWidth <= 1280 && this.windowWidth >= 992)) ? this.nextConfig.collapseMenu : false;
        this.navCollapsedMob = false; Kiran*/
    }

    ngOnInit() {
        this.isServiceProvider = this.authService.isServiceProvider();
        if (this.windowWidth < 992) {
            this.nextConfig.layout = 'vertical';
            setTimeout(() => {
                document.querySelector('.pcoded-navbar').classList.add('menupos-static');
                var el: HTMLElement;
                el = document.querySelector('#nav-ps-next');
                el.style.maxHeight = '100%'; // 100% amit
            }, 500);
        }
    }

    navMobClick() {
        if (this.windowWidth < 992) {
            if (this.navCollapsedMob && !document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
                this.navCollapsedMob = !this.navCollapsedMob;
                setTimeout(() => {
                    this.navCollapsedMob = !this.navCollapsedMob;
                }, 100);
            } else {
                this.navCollapsedMob = !this.navCollapsedMob;
            }
        }

    }
}
