import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { I18nConfService } from '../../core/services/i18n-conf/i18n-conf.service';
import { ClientInfoService } from '../../core/services/client-info/client-info.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private readonly _http: HttpClient,
    private i18nConfService: I18nConfService,
    private clientInfoService: ClientInfoService
  ) { }

  getCommonData(): Observable<any> {
    return this._http.get('assets/i18n/' + this.i18nConfService.currentLang + '.json');
  }

  downloadPDF(url: string) {
    if(url && url.indexOf("http") != 0) {
      if(url.substring(0, 1) == "/") {
        url = url.substring(1);
      }
      url = this.BASE_API_URL + url;
    }
    return this._http.get(url, {responseType: 'blob'})
    .pipe(map((res: any) => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

}
