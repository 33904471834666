<app-language-selector></app-language-selector>
<div *ngFor="let item of sideCardContent?.data; index as i">
  <app-card [hidHeader]="true" cardClass="user-card-full video-article sideCardContent {{item?.class}}"
    id="app{{item.id}}{{i+1}}" blockClass="p-0" *ngIf="!isRM">
    <div class="content">
      <h4 class="sub-title" id="ttl{{item.id}}{{i+1}}">{{ item.title | translate }}</h4>
      <p class="discp" id="p{{item.id}}{{i+1}}">
        {{ item.desp | translate }}
      </p>
      <button id="btn{{item.id}}{{i+1}}" type="button" class="btn btn-primary" *ngIf="item?.url"
        [routerLink]="[item?.url]">
        {{ item.button | translate }}
      </button>
      <a href="mailto:{{item?.mailto}}" class="btn btn-primary" *ngIf="item?.mailto" id="btn{{item.id}}{{i+1}}">{{
        item.button | translate }}</a>
    </div>
  </app-card>
</div>