import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-video-article',
  templateUrl: './landing-video-article.component.html',
  styleUrls: ['./landing-video-article.component.scss']
})
export class LandingVideoArticleComponent implements OnInit {
  @Input() landingVideoArticle: any;

  constructor() { }

  ngOnInit() {
  }


}
