import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { UiModalComponent } from '../../shared/components/modal/ui-modal/ui-modal.component';
import { ProfileService } from '../../core/services/profile-service/profile.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';
import { ActivatedRoute } from '@angular/router';
import { MOBILE_PATTERN } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {
  @ViewChild('MyContentPostDetails', { static: false }) MyContentPostDetails: ElementRef;
  commonForm: string = "commonForm";
  commonFormData: any;
  hasItems = false;
  isPublic: boolean = false;
  userId: any;
  loading: boolean;
  contact: any = [];
  myForm: FormGroup;
  buttonPressed: boolean;
  contactModal: boolean;
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.commonFormData = {
      companyField: {
        label: 'commonForm.Company.title',
        placeholderText: 'commonForm.Company.placeholder',
        type: 'text',
        required: false,
        id: 'commonForm.Company.id',
        validMsg: 'commonForm.Company.errorMsg',
        minLength: 3,
        maxLength: 10
      },
      emailField: {
        label: 'commonForm.Email.title',
        placeholderText: 'commonForm.Email.placeholder',
        type: 'text',
        required: false,
        id: 'commonForm.Email.id',
        validMsg: 'commonForm.Email.errorMsg',
        maxLength: 50
      },
      websiteField: {
        label: 'commonForm.Website.title',
        placeholderText: 'commonForm.Website.placeholder',
        type: 'text',
        required: false,
        id: 'commonForm.Website.id',
        validMsg: 'commonForm.Website.errorMsg',
        maxLength: 100
      },
      phoneField: {
        label: 'commonForm.Phone.title',
        placeholderText: 'commonForm.Phone.placeholder',
        type: 'text',
        required: false,
        id: 'commonForm.Phone.id',
        validMsg: 'commonForm.Phone.errorMsg',
        maxLength: 20
      },
      descriptionField: {
        label: 'commonForm.description.title',
        placeholderText: 'commonForm.description.placeholder',
        type: 'text',
        required: false,
        id: 'commonForm.description.id',
        validMsg: 'commonForm.description.errorMsg',
        minLength: 3,
        maxLength: 2000,
      }
    }
  }
  urlreg = '(http[s]?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  ngOnInit(): void {
    this.myForm = new FormGroup({
      address: new FormControl('', [Validators.required, Validators.maxLength(2000), CustomValidators.removeSpaces]),
      email: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]),
      contactNo: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.pattern(MOBILE_PATTERN)]),
      linkedin: new FormControl('', [Validators.pattern(this.urlreg)]),
      website: new FormControl('', [Validators.pattern(this.urlreg)])
    });
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.getContactDetails();
    });
  }
  prepareForm() {
    this.myForm.controls.address.setValue(this.contact?.address ? this.contact.address : '');
    this.myForm.controls.email.setValue(this.contact?.email ? this.contact.email : '');
    this.myForm.controls.contactNo.setValue(this.contact?.contactNo ? this.contact.contactNo : '');
    this.myForm.controls.linkedin.setValue(this.contact?.linkedIn ? this.contact.linkedIn : '');
    this.myForm.controls.website.setValue(this.contact?.website ? this.contact.website : '');
  }
  submitPressed = false
  saveFormContact() {
    this.myForm.markAllAsTouched();
    this.submitPressed = true;
    if (this.myForm.invalid || this.buttonPressed) {
      return false;
    }
    let formData = this.myForm.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(formData));
    this.profileService.saveContactDetails(payload).subscribe(
      data => {
        this.hasItems = true;
        this.contactModal = false;
        this.getContactDetails();
        this.clearFields();
        this.toastMessage("Contact Details", "Contact details saved successfully", "success");
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage("Contact Details", "Unable to save! Please try after some time.", "error");
      }
    );
  }
  clearFields() {
    this.buttonPressed = false;
    this.myForm.reset();
    this.myNgForm.resetForm();
    this.submitPressed = false;
  }
  getContactDetails() {
    this.loading = true;
    this.profileService.getContactDetails(this.userId).subscribe(
      (data) => {
        this.contact = data;
        if (this.contact.address) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.contact = {};
        this.loading = false;
        // console.log(error);
      }
    );
  }



  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
