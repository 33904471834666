import { Component, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { Observable, of, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

interface DataSourceType {
  id: number;
  name: string;
  region: string;
}



@Component({
  selector: 'app-proposed-timeline',
  templateUrl: './proposed-timeline.component.html',
  styleUrls: ['./proposed-timeline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProposedTimelineComponent {
  isFirstOpen = true;
  today = new Date();
  jstoday = '';
  public animation: boolean = false;
  public fileUploadControl = new FileUploadControl().setListVisibility(false);

  asyncSelected: string;
  dataSource: Observable<DataSourceType[]>;
  typeaheadLoading: boolean;
  statesComplex: DataSourceType[] = [
    { id: 1, name: 'Impact Digital', region: 'South' },
    { id: 2, name: 'Completed 3 similar projects', region: 'West' },
    { id: 3, name: 'Build eCommerce Website', region: 'West' },
    { id: 4, name: 'Impact', region: 'South' },
    { id: 5, name: 'Digital', region: 'West' },
    { id: 6, name: 'projects', region: 'West' },
    { id: 7, name: 'Connecticut', region: 'Northeast' },
    { id: 8, name: 'Delaware', region: 'South' },
    { id: 9, name: 'AFlorida', region: 'South' },
    { id: 10, name: 'Georgia', region: 'South' }
  ];

  constructor(
  ) {
    this.jstoday = formatDate(this.today, 'dd-MM-yy', 'en-US', '+0530');

    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((token: string) => this.getStatesAsObservable(token))
      );
  }
  getStatesAsObservable(token: string): Observable<DataSourceType[]> {
    const query = new RegExp(token, 'i');

    return of(
      this.statesComplex.filter((state: any) => {
        return query.test(state.name);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  meetOurTeamOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }



}
