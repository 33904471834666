import { Component, Input, OnInit } from '@angular/core';
import { DiscussionService } from 'src/app/core/services/discussion-service/discussion.service';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';

@Component({
  selector: 'app-video-article',
  templateUrl: './video-article.component.html',
  styleUrls: ['./video-article.component.scss']
})

export class VideoArticleComponent implements OnInit {
  @Input() videoArticle: any;
  apiLoaded = false;
  articalVideoModal: boolean;
  getdataarticles: any;
  loading: boolean;
  constructor(private discussionservice: DiscussionService, private feedService: FeedService) { }

  ngOnInit(): void {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }

    this.getTopActives();
  }

  getRelativeTime(value: any) {
    return this.feedService.getRelativeTime(value, 0);
  }

  getTopActives() {
    this.loading = true;
    this.discussionservice.getTopActive().subscribe(
      res => {
        this.getdataarticles = res.content;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    )
  }
}
