import { Component } from '@angular/core';
import { Common } from '../../utility/common';


@Component({
  selector: 'app-opportunities-performance-status',
  templateUrl: './opportunities-performance-status.component.html',
  styleUrls: ['./opportunities-performance-status.component.scss']
})
export class OpportunitiesPerformanceStatusComponent {
  ObjOpportunitiesPerformance = Common.opportunitiesPerformance;

}
