import { Component } from '@angular/core';

@Component({
  selector: 'app-build-service-provider',
  templateUrl: './build-service-provider.component.html',
  styleUrls: ['./build-service-provider.component.scss']
})
export class BuildServiceProviderComponent {

}
