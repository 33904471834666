import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { PRICE_NOT_AVAILABLE } from '../../constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { ProductService } from 'src/app/core/services/product-service/product.service';

@Component({
  selector: 'app-browse-card',
  templateUrl: './browse-card.component.html',
  styleUrls: ['./browse-card.component.scss']
})
export class BrowseCardComponent implements OnInit {
  @Input() BrowseCards: any;
  @Input() resultsDisplayTitle: any;
  @Input() listType: string = "companies";
  @Input() BrowseGrowthCards: boolean;
  @Input() BrowseGrowthPartners: boolean;
  userId: any;
  fromSearch: boolean = false;
  buttons: string[] = ["I'm Interested"];
  isPublic: boolean;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private serviceCardService: ServiceCardService,
    private productcardService: ProductService,
    private messageService: MessageService,
    private authService: UserAuthService,
    private commonService: CommonService
  ) { }

  isRM: boolean;
  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    if (this.router.url.indexOf("/user-browse") > -1 || this.router.url.indexOf("search") > -1) {
      this.fromSearch = true;
    } else {
      this.fromSearch = false;
    }
  }

  getLink(userId: any, userType: any) {
    let url = "/details/" + (userType ? userType.toLowerCase() : "cm") + "/" + userId;
    if (this.router.url.indexOf("/user-browse") > -1 ||
      this.router.url.indexOf("/growth-") > -1 ||
      this.router.url.indexOf("search-") > -1) {
      url = "/user-browse" + url;
    } else {
      url = "/browse" + url;
    }
    return [url];
  }

  getMyPage() {
    return this.router.url.split("?")[0];
  }

  getQueryParams() {
    let arr = this.router.url.split("?");
    if (arr.length > 1) {
      arr = arr[1].split("#");
      return JSON.parse('{"' + decodeURI(arr[0]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
    return {};
  }

  formatTimeEstimate(timeEstimate: any) {
    return this.utility.formatTimeEstimate(timeEstimate);
  }

  getTimeEstimate(timeEstimate: any) {
    return this.utility.getDurationType(timeEstimate);
  }

  getPrice(priceEstimate: any) {
    return this.utility.getServicePriceString(priceEstimate);
  }

  getProductPrice(price) {
    return this.utility.getPriceString(price);
  }
  showServiceCurrency(priceEstimate) {
    return this.utility.getServicePriceString(priceEstimate) != PRICE_NOT_AVAILABLE;
  }
  showProductCurrency(price) {
    return this.utility.getPriceString(price) != PRICE_NOT_AVAILABLE;
  }

  onBookBtnClick(item) {
    console.log(item, "ssoo");
    if (item.booking || item.booked) {
      return false;
    }
    item.booking = true;
    this.serviceCardService.bookService(item.growthCardId || item.serviceId || item.id).subscribe(
      data => {
        console.log(item, "done");
        item.booked = true;
        item.booking = false;
        if (item.growthCardId) {
          this.messageService.add({ key: "app", severity: "success", summary: "Book Growth Card", detail: "A request has been successfully sent to the Service Provider" });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          this.messageService.add({ key: "app", severity: "success", summary: "Book Service", detail: "Service Booked Successfully" });
        }
      },
      error => {
        item.booking = false;
        if (typeof error.error === 'string' && error.error.toLowerCase().indexOf("brn") > -1) {
          this.updateBRNPopup = true;
          this.currentBookingItem = item;
        } else {
          if (item.growthCardId) {
            this.messageService.add({ key: "app", severity: "error", summary: "Book Growth Card", detail: "Unable to book Growth Card! Please try after some time." });
          } else {
            this.messageService.add({ key: "app", severity: "error", summary: "Book Service", detail: "Unable to book Service! Please try after some time." });
          }
        }
      }
    );
  }




  onBookBtnClickproducts(item) {
    console.log(item, "onBookBtnClickproducts")
    if (item.booking || item.booked) {
      return false;
    }
    item.booking = true;
    this.productcardService.bookProduct(item.productId || item.id).subscribe(
      data => {
        if (item.productId || item.id) {
          this.messageService.add({ key: "app", severity: "success", summary: "Book Service", detail: "Product Booked Successfully" });
        }

      },
      error => {
        item.booking = false;
        if (error.error.toLowerCase().indexOf("brn") > -1) {
          this.updateBRNPopup = true;
          this.currentBookingItem = item;
        } else {
          if (item.productId) {
            this.messageService.add({ key: "app", severity: "error", summary: "Book Growth Card", detail: "Unable to book Growth Card! Please try after some time." });
          }
        }
      }
    );
  }



  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }
  getMoreDetails(url: any, gcId: any) {
    if (url && url.indexOf("http") == 0) {
      window.open(url, "_blank");
    } else {
      if (!url) {
        url = "api/growth-cards/viewGCAsPDF?gcId=" + gcId;
      }
      this.commonService.downloadPDF(url).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }
  }

  growthcardDetails(growthCardId: string, growthCardTitle: any) {
    const gcId = growthCardId;
    const serviceToProvide = growthCardTitle;
    console.log('gcId:', gcId);
    console.log('serviceToProvide:', serviceToProvide);
    this.router.navigate(['/details/' + gcId], { queryParams: { growthcard: serviceToProvide } });
  }

  isHttpLogo(logo: string): boolean {
    return /^http/.test(logo);
  }
}
