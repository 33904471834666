import { Component, OnInit } from '@angular/core';
import { ChartService } from '../../shared/chart/apex-chart/apex-chart.service';
import { ChartDB } from '../../shared/chart/chart/chart-data';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
  public chartDB: any;

  constructor(public apexEvent: ChartService) {
    this.chartDB = ChartDB;
  }

  ngOnInit(): void {
  }

}
