import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../client-info/client-info.service';
import { StorageService } from '../storage/storage.service';
import { TokenService } from '../token/token.service';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BookmarksService {
  jwt_token: string;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  addBookmark(resourceId:any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/bookmarks/addBookmark?resourceId=`+resourceId,{})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getBookmarks(): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/bookmarks/getBookmarks`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
}
