import { Component, OnInit } from '@angular/core';
import {I18nConfService} from "../../../core/services/i18n-conf/i18n-conf.service";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  i18nConf: I18nConfService;
  constructor( i18nConf: I18nConfService) {
    this.i18nConf = i18nConf;
    this.i18nConf.setUpConf();
  }

  ngOnInit() {
  }
}
