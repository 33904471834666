import { FormGroup, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static passwordMatchValidator(controlName: string, matchingControlName: string):any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
  
        if (matchingControl.errors && !matchingControl.errors.NoPassswordMatch) {
            return;
        }
  
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ NoPassswordMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  static removeSpaces(control: AbstractControl) {
    if (control && control.value && /^\s/.test(control.value)) {
      try{
      control.setValue(control.value.trim());
      }catch(err){}
    }
    return null;
  }

}
