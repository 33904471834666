import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { Common } from '../../../utility/common';

@Component({
  selector: 'app-brn-form',
  templateUrl: './brn-form.component.html',
  styleUrls: ['./brn-form.component.scss']
})
export class BRNFormComponent implements OnInit {

  constructor(public profileService: ProfileService) { }

  @Output() closeModal = new EventEmitter();
  @Output() continueBooking = new EventEmitter();
  BRNInputValue = "";
  showError = false;
  buttonPressed = false;

  ngOnInit(): void { }

  closePopup() {
    this.closeModal.emit(false);
  }
  
  updateBRN() {
    if (!this.BRNInputValue) {
      this.showError = true;
      return;
    }
    if (!Common.isValidBRN(this.BRNInputValue)) {
      this.showError = true;
      return;
    }
    this.buttonPressed = true;

    this.profileService.updateBRN({ brnNumber: this.BRNInputValue }).subscribe(
      data => {
        this.closeModal.emit(false);
        this.clearFields();
        Swal.fire({
          title: 'Great! Your profile has been updated.',
          text: "You can now proceed with requests for Products, Services, Growth cards & Growth plans.",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue'
        }).then((result) => {
          if (result.isConfirmed) {
            this.continueBooking.emit(true);
          } else {
            this.continueBooking.emit(false);
          }
        });
      },
      error => {
        this.closeModal.emit(false);
        this.clearFields();
        Swal.fire(
          'Error!',
          'Unable to update BRN Number right now. Please try after some time.',
          'error'
        );
      }
    );
  }

  clearFields() {
    this.BRNInputValue = '';
    this.showError = false;
    this.buttonPressed = false;
  }
}
