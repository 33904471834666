<app-startup-card cardTitle="Products" [subTitle]="'SmeProducts.subTitle1' | translate"
  [description]="'SmeProducts.description1' | translate" componentId="SmeProductsCard"
  iconPath="assets/images/product-image.png" btnLabel="Add Product" (btnClick)="addProductModal = true"
  *ngIf="(!hasItems || products.length==0) && !loading && !isPublic">
</app-startup-card>
<app-startup-card cardTitle="Products" [subTitle]="'SmeProducts.subTitle2' | translate"
  [description]="'SmeProducts.description2' | translate" iconPath="assets/images/product-image.png"
  *ngIf="(!hasItems || products.length==0) && !loading && isPublic"></app-startup-card>

<div class="row products-sec" *ngIf="hasItems && !loading && products.length>0">
  <div class="col-md-12">
    <div id="divSmeProductsCard" class="products sp-service">
      <h3 id="ttlSmeProductsCard" class="title mb-2">
        <span>{{ "SmeProducts.title" | translate }}
          <em class="fas fa-plus-circle" *ngIf="!isPublic" (click)="onAddBtnClick()"></em>
        </span>
        <span class="addServiceBtn" @fadeAnimation *ngIf="products.length>3">
          <a id="aSmeAddProduct" href="javascript:void(0);"
            [routerLink]="[userId ? '/all-produtcs/' + userId : '/all-produtcs']">View all</a>
        </span>
      </h3>

      <div class="row" @fadeAnimation>
        <div class="col-md-12">
          <div class="products mb-0" *ngIf="products && products.length>0">
            <div class="product-carousel">
              <div class="car-details">
                <div class="p-grid p-nogutter">
                  <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" *ngFor="let  product of products| slice:0:3">
                      <app-product-card [productName]="product.productName" [price]="[product.price]"
                        [componentId]="'SmeProductCard' + index"
                        [thumbnail]="product.featuredImage?product.featuredImage:'assets/images/product.png'"
                        [description]="product.description" (edit)="onEditBtnClick(product)"
                        (delete)="onDeleteClick(product)" (book)="onBookBtnClick(product)" [canEdit]="!isPublic"
                        [canBook]="product?.isOwnProduct ? false : userCanBook(product)"
                        [bookingdone]="product?.isOwnProduct ? false : bookingdoneuser(product)">
                      </app-product-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="add-products shareFeed_dialog">
  <p-dialog header="" [(visible)]="addProductModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="service-card-modal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
    (onHide)="clearFields()">

    <div class="app-modal">
      <h5 id="ttlSmeProductsModalAdd" class="modal-title text-center">{{editMode?'Edit':'Add'}} Product</h5>
      <form id="frmSmeAddProduct" [formGroup]="productForm" #myNgForm="ngForm">
        <div class="form-group mb-4">
          <label id="lblProductName">Product Name</label>
          <input id="txtProductName" type="text" class="form-control" formControlName="name"
            [ngClass]="{'is-invalid':inValid(f.name, isSubmit)}" placeholder="Insert Product name here">
          <div class="invalid-feedback" *ngIf="inValid(f.name, isSubmit)">
            <div *ngIf="f.name.errors.required" id="err_Name">Please enter product name</div>
            <div *ngIf="f.name.errors.minlength" id="err_minLength_name">Product Name should contain at least 3
              characters</div>
            <div *ngIf="f.name.errors.maxlength" id="err_maxLength_name">Should not exceed 100 characters
            </div>
            <div *ngIf="f.name.errors.hasNoSpecialCharacters" id="err_specialChar_name">Must not contain any
              Special Character</div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label id="lblProductDescription">Description</label>
          <textarea id="texProductDescription" class="form-control" formControlName="description"
            [ngClass]="{'is-invalid':inValid(f.description, isSubmit)}" style="resize: none;"
            placeholder="Describe how your product is unique."></textarea>
          <div class="invalid-feedback" *ngIf="inValid(f.description, isSubmit)">
            <div *ngIf="f.description.errors.required" id="err_Description">Please enter description</div>
            <div *ngIf="f.description.errors.minlength" id="err_minLength_description">Description should contain
              at
              least 3 characters</div>
            <div *ngIf="f.description.errors.maxlength" id="err_maxLength_description">Should not exceed 500
              characters
            </div>
            <div *ngIf="f.description.errors.hasNoSpecialCharacters" id="err_specialChar_description">Must not
              contain
              any
              Special Character</div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label id="lblProductPrice">Price</label>
          <input id="txtProductPrice" type="text" class="form-control" formControlName="price"
            [ngClass]="{'is-invalid':inValid(f.price, isSubmit)}" placeholder="0000">
          <div class="invalid-feedback" *ngIf="inValid(f.price, isSubmit)">
            <div *ngIf="f.price.errors.required" id="err_Price">Please enter price</div>
            <div *ngIf="f.price.errors.minlength" id="err_minLength_price">Price should contain at least a
              character
            </div>
            <div *ngIf="f.price.errors.maxlength" id="err_maxLength_price">Should not exceed 10 characters
            </div>
            <div *ngIf="f.price.errors.hasValidPrice" id="err_specialChar_price">Only numeric values allowed</div>
          </div>
        </div>
        <div class="form-group mb-4">
          <label id="lblProductCategory">Category</label>
          <select id="dpProductCategory" class="form-control" formControlName="category"
            [ngClass]="{'is-invalid':inValid(f.category, isSubmit)}">
            <option value="">Select category</option>
            <ng-container *ngFor="let item of categoriesOptions">
              <option [value]="item.value">{{ item.label }}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback" *ngIf="inValid(f.category, isSubmit)">
            <div *ngIf="f.category.errors.required" id="err_Category">Please select category</div>
          </div>
        </div>
      </form>

      <div class="card-preview  gc-pre ">
        <app-product-card [done]="false" [price]="f.price.value" componentId="SmeAddProduct"
          [description]="f.description.value" [thumbnail]="previewURL"
          (previewImage)="previewImages($event, 'featured')"></app-product-card>
      </div>
    </div>
    <div class="app-modal text-right mt-5 sticky-btn">
      <button id="btnSmeAddProductCancel" type="button" class="btn btn-secondary mr-3"
        (click)="clearFields();addProductModal = false">Cancel</button>
      <button id="btnSmeAddProductSave" type="button" class="btn btn-confirm" (click)="saveProduct();">
        <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span class="btn-text">Save</span>
      </button>
    </div>
  </p-dialog>

  <p-dialog header="" [(visible)]="deleteProductModal" [modal]="true" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class=" product-card-modal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class="app-modal-body text-center mb-2">
      <h6>You are about to delete a Product</h6>
      <p>Are you sure you want to proceed ?</p>
    </div>
    <div class=" text-center">
      <button id="btnSmeAddProductCancel" type="button" class="btn btn-secondary mr-3"
        (click)="clearFields();deleteProductModal = false">Cancel</button>
      <button id="btnSmeAddProductSave" type="button" class="btn btn-confirm" (click)="deleteConfirmed();">
        <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span class="btn-text">Delete</span>
      </button>
    </div>
  </p-dialog>
  <p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>

  <p-dialog header="" [(visible)]="updateBRNPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class=" product-card-modal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class=" brn-modal">
      <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
    </div>
  </p-dialog>
</div>