import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomValidators } from '../../helpers/custom.validators';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { SelectOptionService } from '../select/select-option.service';


interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-own-content',
  templateUrl: './own-content.component.html',
  styleUrls: ['./own-content.component.scss']
})
export class OwnContentComponent implements OnInit {

  feedContent: any;
  dtOptions: DataTables.Settings = {};
  getTopArticles: any;
  loading: boolean;
  getdataarticles: any;
  feedArticalVideoModal: boolean;
  orgForm: FormGroup;
  orgDetails: any = {};
  isSubmit: boolean;

  company_Name: string;
  trade_License: string;
  company_Region: string;
  brn_Number: string;
  industry_: string;
  incorporation_Year: string;
  about_: string;

  messageService: any;
  buttonPressed: boolean;

  uqudoData: any;
  isCommunityManager: boolean;
  isSME: boolean;
  isLoading: boolean;
  showinputs: boolean = true;
  showauthinfo: boolean;
  errormsg: boolean = false;

  cities: City[];
  plans: any = [];
  prises: any = [];
  selectedCityCode: string;
  countryRegionOptions: any;
  profile: any = {};
  subscribeModal: boolean;
  subscriptionplans: any;
  subscriptionprice: any;

  constructor(private router: Router,
    private commonService: CommonService,
    private feedService: FeedService,
    private profileService: ProfileService,
    private authService: UserAuthService,
    public selectOptionService: SelectOptionService) {

  }


  ngOnInit(): void {
    this.isCommunityManager = this.authService.isCommunityManager();
    this.isSME = this.authService.isSME();

    this.commonService.getCommonData().subscribe(
      res => {
        this.feedContent = res.smeFeed.content;
      })
    this.getCompanyProfile();
    this.getToparticles();

    this.orgForm = new FormGroup({
      companyName: new FormControl("", Validators.required),
      tradeLicense: new FormControl("", Validators.required),
      companyRegion: new FormControl("", Validators.required),
      brnNumber: new FormControl(""),
      industry: new FormControl(""),
      incorporationYear: new FormControl(""),
      about: new FormControl(""),
    });


    this.selectOptionService.getCountryRegion().subscribe((data: any) => {
      this.countryRegionOptions = data.map(v => {
        return { value: v.countryCode, label: v.countryName };
      });
    });

    this.profileService.getProfileDetails().subscribe(
      (data) => {
        this.profile = data ? data : {};
      },
      (error) => {
        this.profile = {};
        this.loading = false;
      }
    );

    // this.getplans();
  }

  getToparticles() {
    this.loading = true;
    let pageNumber = 0;
    this.feedService.getTopArticles(pageNumber).subscribe(
      res => {
        this.getdataarticles = res.feed;
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    )
  }

  startfuction() {
    this.feedArticalVideoModal = true;
  }

  getCompanyProfile() {
    this.loading = true;
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.orgDetails = data ? data : {};

        this.company_Name = this.orgDetails.companyName;
        this.trade_License = this.orgDetails.tradeLicense;
        this.company_Region = this.orgDetails.companyRegion;
        this.brn_Number = this.orgDetails.brnNumber;
        this.industry_ = this.orgDetails.industry;
        this.incorporation_Year = this.orgDetails.incorporationYear;
        this.about_ = this.orgDetails.about;

        this.orgForm.patchValue({
          companyName: this.company_Name,
          tradeLicense: this.trade_License,
          companyRegion: this.company_Region,
          brnNumber: this.brn_Number,
          industry: this.industry_,
          incorporationYear: this.incorporation_Year,
          about: this.about_,
        });

      },
      (error) => {
        this.orgDetails = {};
        console.error(error);
      }
    );
  }


  getRelativeTime(value: any) {
    return this.feedService.getRelativeTime(value, 0);
  }


  onSubmit() {
    if (this.orgForm.invalid) {
      return false;
    }

    let formData = this.orgForm.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(formData));

    this.profileService.saveOrglDetails(payload).subscribe(
      data => {
        this.getCompanyProfile();
        // this.messageService.add({ key: 'c', severity: 'success', summary: 'Company / Page details', detail: 'Successfully updated' });
        const compData = {
          companyName: this.orgForm.controls['companyName'].value,
          tradeLicense: this.orgForm.controls['tradeLicense'].value,
          companyRegion: this.orgForm.controls['companyRegion'].value
        }

        this.showinputs = false;
        this.isLoading = true;

        this.profileService.companyVerification(compData).subscribe(
          data => {
            this.uqudoData = data.content.basic;
            this.isLoading = false;
            this.showauthinfo = true;

            if (this.uqudoData?.companyStatus) {
              const verified_Company = {
                companyName: this.orgForm.controls['companyName'].value,
                companyStatus: this.uqudoData?.companyStatus
              }
              this.profileService.verifiedCompany(verified_Company).subscribe(
                data => {
                  // console.log(data, 'verifiedCompany')
                },
                error => {
                  this.isLoading = false;
                  this.errormsg = true;
                  setTimeout(() => {
                    this.feedArticalVideoModal = false;
                  }, 2000);
                }
              )
            }
          },
          error => {
            this.isLoading = false;
            this.errormsg = true;
            setTimeout(() => {
              this.feedArticalVideoModal = false;
            }, 2000);
          }
        )
        this.orgForm.reset();
      },
      error => {
        console.error(error);
        this.isLoading = false;
        this.errormsg = true;
        setTimeout(() => {
          this.feedArticalVideoModal = false;
        }, 2000);
      }
    );
  }

  resetfeedArticalVideoModal() {
    this.errormsg = false;
    this.isLoading = false;
    this.showinputs = true;
    this.showauthinfo = false;
  }

  // getplans() {
  //   this.feedService.getsubscriptionproducts().subscribe(
  //     data => {
  //       this.subscriptionplans = data;
  //       this.getprices();
  //       console.log(data, "sub plans");
  //     },
  //     error => {
  //       console.error(error)
  //     }
  //   )
  // }

  // subsDetails: any[] = []
  // getprices() {
  //   this.feedService.getsubscriptionprices().subscribe(
  //     data => {
  //       this.subscriptionprice = data;
  //       console.log(data, "sub prices");
  //       this.subsDetails = []
  //       for (let i = 0; i < this.subscriptionplans.length; i++) {
  //         for (let j = 0; j < this.subscriptionprice.length; j++) {
  //           if (this.subscriptionplans[i]['id'] == this.subscriptionprice[j]['product']) {
  //             this.subsDetails.push({
  //               plan: this.subscriptionplans[i]['name'],
  //               price: this.subscriptionprice[j]['id'],
  //               cur: this.subscriptionprice[j]['currency']
  //             })
  //             break;
  //           }
  //         }
  //       }

  //     },
  //     error => {
  //       console.error(error)
  //     }
  //   )
  // }


  // combineAndPrintValues(subscriptionplans: any[], subscriptionprice: any[]) {
  //   subscriptionplans.forEach(product => {
  //     const matchingPrice = subscriptionprice.find(price => price.id === product.id);
  //     if (matchingPrice) {
  //       console.log(`Product ID: ${product.id}, Product Name: ${product.name}, Price: ${matchingPrice.amount}`);
  //     }
  //   });
  // } 
  // combineAndPrintValues() {
  //   if (this.subscriptionplans && this.subscriptionprice) {
  //     this.subscriptionplans.forEach(product => {
  //       const matchingPrice = this.subscriptionprice.find(price => price.id === product.id);
  //       if (matchingPrice) {
  //         console.log(`Product ID: ${product.id}, Product Name: ${product.name}, Price: ${matchingPrice.amount}`);
  //       }
  //     });
  //   }
  // }

  // combineAndPrintValues() {
  //   if (this.subscriptionplans && this.subscriptionprice) {
  //     this.subscriptionplans.forEach(product => {
  //       const matchingPrice = this.subscriptionprice.find(price => price.id === product.id);
  //       if (matchingPrice) {
  //         console.log(`Product ID: ${product.id}, Product Name: ${product.name}, Price: ${matchingPrice.amount}`);
  //       }
  //     });
  //   }
  // }


  // findSimilarElements() {
  //   console.log(this.subscriptionplans)
  //   console.log(this.subscriptionprice)
  //   this.subscribeModal = true;
  // }

  // findSimilarElements() {
  //   console.log(this.subscriptionplans, this.subscriptionprice, 'subscriptionplans')
  //   // Ensure both arrays are not undefined or null
  //   if (this.subscriptionplans && this.subscriptionprice) {
  //     // Find common elements using filter and includes
  //     const commonElements = this.subscriptionplans.filter(plan =>
  //       this.subscriptionprice.includes(plan)
  //     );

  //     // Print or use the common elements as needed
  //     console.log('Common Elements:', commonElements);

  //     // You can return the common elements if needed
  //     return commonElements;
  //   } else {
  //     console.error('Arrays are undefined or null.');
  //     // Return an appropriate value or handle the error as needed
  //   }
  // }

}