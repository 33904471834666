import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MessageService } from 'primeng/api';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { Common } from 'src/app/utility/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feed-comment',
  templateUrl: './feed-comment.component.html',
  styleUrls: ['./feed-comment.component.scss']
})
export class FeedCommentComponent implements OnInit, OnChanges {

  @Input() feed: any;
  @Input() showComments: boolean;
  @Input() companyProfile: any;

  comments: any = [];
  size: number = 5;
  page: any;
  loading: boolean = false;
  hideMoreComments: boolean = false;

  updatedCommentText: any = {};
  isValidUpdateComment: any = {};
  updateMentions: any = {};

  commentText: string = "";
  isValidAddComment: boolean;
  addMentions: any = [];
  clear: boolean = false;
  answerLength = 350;

  badCommentMsg: string = "This comment seems irrelevant or inappropriate and can't be posted.";
  blankCommnetMsg: string = "Blank or empty comment can't be posted.";
  exceedCommnetMsg: string = "Comment exceeds the character limit.";
  doneee: boolean;

  constructor(
    private feedService: FeedService,
    private messageService: MessageService,
    private authService: UserAuthService,
    private router: Router
  ) { }
  isRM: boolean;
  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    this.feedCommentsLoad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('showComments' in changes) {
      if (!this.feed.noLoad) {
        this.comments = [];
        this.page = null;
        if (changes['showComments'].currentValue && this.feed) {
          this.fetchFeedcomments('?feedId=' + this.feed.id + '&size=' + this.size);
        }
      }
      this.feed.noLoad = false
    }
  }
  feedCommentsLoad() {
    this.comments = [];
    this.page = null;
    this.fetchFeedcomments('?feedId=' + this.feed.id + '&size=' + this.size);
  }

  fetchFeedcomments(query: string) {
    this.loading = true;
    this.feedService.getFeedComments(query).subscribe(
      (res: any) => {
        this.loading = false;
        if (res && Array.isArray(res.comments)) {
          this.comments = this.comments.concat(res.comments);
          this.page = res.comments.length > 0 ? res.page : null;
          this.hideMoreComments = res.noMoreComments;
        }
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  loadMoreComments() {
    if (this.page && this.page.beforeQuery) {
      this.fetchFeedcomments(this.page.beforeQuery);
    }
  }

  saveComment() {
    this.answerLength = 350;
    this.clear = false;
    if (this.feed && this.commentText && this.commentText.trim().length > 0) {
      if (!this.isValidAddComment) return;
      this.commentText == this.commentText.trim();
      this.feedService.submitFeedComment(this.feed.id, this.commentText, this.addMentions, 0).subscribe(
        (res: any) => {
          if (res.comment) {
            if (!this.showComments) {
              this.comments = [];
            }
            this.comments.splice(0, 0, res.comment);
            if (!this.feed.toggle) {
              this.page = {
                before: res.comment.submittedOn,
                beforeQuery: '?feedId=' + this.feed.id + '&before=' + res.comment.submittedOn + '&size=' + this.size
              };
              this.feed.noLoad = true;
              this.feed.toggle = true;
              // this.answerLength = 350;
            }
            this.feed.totalComments = this.feed.totalComments + 1;
          }
          this.commentText = "";
          this.isValidAddComment = true;
          this.addMentions = [];
          this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Comment posted successfully.", detail: '' });
          this.clear = true;

        },
        (error: any) => {
          if (error.status == 400) {
            this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: this.badCommentMsg, detail: '' });
          }
        }
      );
    } else {
      this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: this.blankCommnetMsg, detail: '' });
    }
  }

  updateComment(comment: any, id: string) {
    if (this.feed && this.updatedCommentText[id] && this.updatedCommentText[id].trim().length > 0) {
      if (!this.isValidUpdateComment[id]) return;
      this.feedService.updateFeedComment(comment.id, this.updatedCommentText[id], this.updateMentions[id]).subscribe(
        (res: any) => {
          comment.commentText = this.updatedCommentText[id];
          this.showHideUpdateCommentForm(comment, true, id);
          this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Comment updated successfully.", detail: '' });
        },
        (error: any) => {
          if (error.status == 400) {
            this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: this.badCommentMsg, detail: '' });
          }
        }
      );
    } else {
      this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: this.blankCommnetMsg, detail: '' });
    }
  }

  showHideUpdateCommentForm(comment: any, hide: boolean, id: string) {
    this.doneee = false;
    if (comment) {
      this.updatedCommentText[id] = hide ? "" : comment.commentText;
      if (hide) {
        this.updateMentions[id] = [];
      } else {
        this.updateMentions[id] = Common.getMentions(comment.commentText).mentionIds;
      }
      this.isValidUpdateComment[id] = true;
      comment.editMode = !hide;
    }
  }

  deleteComment(comment: any, index: number) {
    this.feedService.deleteFeedComment(comment.id).subscribe(
      (res: any) => {
        this.comments.splice(index, 1);
        this.feed.totalComments = this.feed.totalComments - 1;
        this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Comment deleted successfully.", detail: '' });
      }
    );
  }

  askDeleteComment(comment: any, index: number) {
    Swal.fire({
      title: 'Are you sure you wish to remove this comment?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result: any) => {
      if (result.isConfirmed && comment && comment.id) {
        this.deleteComment(comment, index);
      }
    });
  }

  getRelativeTime(value: any) {
    return this.feedService.getRelativeTime(value, 86400 * 2);
  }

  formatComment(comment: string) {
    comment = Common.replaceMentions(comment);
    return Common.replaceURLs(comment);
  }

  get showMoreCommentsLink() {
    return this.page && !this.loading && !this.hideMoreComments
      && this.feed && this.feed.totalComments && this.feed.totalComments > this.size;
  }

  onAddMentionChange(item: any) {
    this.commentText = item.dataComment;
    this.isValidAddComment = item.isValid;
    this.addMentions = item.mentions;
  }

  onUpdateMentionChange(item: any, id: string) {
    this.doneee = true
    this.updatedCommentText[id] = item.dataComment;
    this.isValidUpdateComment[id] = item.isValid;
    this.updateMentions[id] = item.mentions;
  }

  get isCommunityManager() {
    return this.authService.isCommunityManager();
  }

  getLink(userId: any, userRole: any) {
    let url = "/details/" + (userRole ? userRole.toLowerCase() : "cm") + "/" + userId;
    if (this.router.url.indexOf("/user-browse") > -1 ||
      this.router.url.indexOf("/growth-") > -1 ||
      this.router.url.indexOf("search-") > -1 ||
      this.router.url.indexOf("feed") > -1) {
      url = "/user-browse" + url;
    } else {
      url = "/browse" + url;
    }
    return [url];
  }

  comapanyredirection(userId: any, userRole: any) {
    this.router.navigate(this.getLink(userId, userRole))
  }

  // reply 
  // replyMessages: { [key: string]: string } = {};

  // showReplyInput(answerId: string, userId: string, commentId: string) {
  //   console.log(commentId, 'commentId1')
  //   const key = answerId + '_' + userId + '_' + commentId;
  //   this.replyMessages[key] = '';
  //   this.comments.forEach(comment => {
  //     comment.showReplyInput = (comment.answerId === answerId && comment.userId === userId && comment.id === commentId);
  //   });
  // }

  // onReplyValueChange(value: string, answerId: string, userId: string, commentId: number) {
  //   console.log(commentId, 'commentId2');
  //   const key = answerId + '_' + userId + '_' + commentId;
  //   this.replyMessages[key] = value;
  // }

  // sendReply(answerId: string, userId: string, commentId: string, replyMessage: any) {
  //   const key = answerId + '_' + userId + '_' + commentId;
  //   const reply = {
  //     answerId: answerId,
  //     userId: userId,
  //     id: commentId,
  //     message: replyMessage,
  //     timestamp: new Date()
  //   };

  //   console.log("Sending reply for userId:", reply.userId);
  //   console.log("AnswerId:", reply.answerId);
  //   console.log("Id:", reply.id);
  //   console.log("Random message:", reply.message);

  //   this.replyMessages[key] = '';

  //   this.feedService.submitFeedComment(this.feed.id, reply?.message?.textComment, this.addMentions, reply.id).subscribe(
  //     (res: any) => {
  //       console.log(res, 'res')
  //     },
  //     (error: any) => {
  //       console.log(error, 'error')
  //     }
  //   );

  //   const matchingComment = this.comments.find(comment => comment.answerId === answerId && comment.userId === userId);
  //   if (matchingComment) {
  //     matchingComment.showReplyInput = false;
  //   }
  // }

  // cancelReply(answerId: string, userId: string) {
  //   const key = answerId + '_' + userId;
  //   this.replyMessages[key] = '';
  //   this.comments.forEach(comment => {
  //     comment.showReplyInput = false;
  //   });
  // }

}
