import { Component, Input, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
@Component({
  selector: 'app-side-content-card',
  templateUrl: './side-content-card.component.html',
  styleUrls: ['./side-content-card.component.scss']
})
export class SideContentCardComponent implements OnInit {
  @Input() sideCardContent: any;
  isRM:boolean;

  constructor( private authService:UserAuthService) { }

  ngOnInit(): void {
    this.isRM=this.authService.isRelationShipManager();
  }

}
