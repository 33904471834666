import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-timeline',
  templateUrl: './landing-timeline.component.html',
  styleUrls: ['./landing-timeline.component.scss']
})
export class LandingTimelineComponent implements OnInit {
  @Input() landingTimeline: any;

  constructor() { }

  ngOnInit() {
  }


}
