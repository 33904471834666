import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-as',
  templateUrl: './signup-as.component.html',
  styleUrls: ['./signup-as.component.scss']
})
export class SignupAsComponent implements OnInit {
  @Input() signupAs: any;

  constructor() { }

  ngOnInit() {
  }


}
