import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-status-line',
  templateUrl: './status-line.component.html',
  styleUrls: ['./status-line.component.scss']
})
export class StatusLineComponent implements OnInit {
  @Input() statusLine: any;

  constructor() { }

  ngOnInit(): void {
  }

}
