export const RoutesUrl = {
  CUSTOMER_SIGN_IN_URL: '/signin',
  CUSTOMER_SIGN_IN_NIC_PASSPORT_URL: '/signin-nic-passport',
  CUSTOMER_SIGN_IN_OPTIONS_URL: '/sign-options',
  DASHBOARD_URL: '/dashboard',
  BACKEND_WORKFLOW_SIGN_IN: '/backend-workflow',
  CONTACT_CENTRE: '/contact-center',
  CUSTOMER_E_FORM: '/customer',
  THANK_YOU_URL: '/thank-you',
  EFORM_CONTACT_DETAILS: '/details',
  LANDING: 'https://smeconnect.cloudseed.io/',
  THANK_YOU_CONTACT_CENTRE_URL: '/contact-center/thank-you',
  VIEW_APPLICATION: '/backend-workflow/application',
  LOAN_REVIEW: '/backend-workflow/loan-review'
}

