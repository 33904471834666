import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { promise } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public browseDataSubject: BehaviorSubject<any>;
  public browseData: Observable<any>;
  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) {
    this.browseDataSubject = new BehaviorSubject<any>(null);
    this.browseData = this.browseDataSubject.asObservable();
  }
  public get currentBrowseValue(): any {
    return this.browseDataSubject.value;
  }

  updatecurrentBrowseValue(value: any) {
    this.browseDataSubject.next(value);
  }

  searchUsers(categories: string, pageNumber: number, pageSize: number) {
    const params = new HttpParams()
      .set('categories', categories)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/search/SearchUsers`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  searchProducts(categories: string, pageNumber: number, pageSize: number) {
    const params = new HttpParams()
      .set('categories', categories)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/search/SearchProducts`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  searchServices(categories: string, pageNumber: number, pageSize: number) {
    const params = new HttpParams()
      .set('categories', categories)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/search/SearchServices`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  searchGrowthCards(categories: string, pageNumber: number, pageSize: number) {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (categories.toLocaleLowerCase() != "all") {
      params = params.set('category_id', categories);
    }
    return this.http.get<any>(`${this.BASE_API_URL}api/growth-cards/getAllGrowthCards`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  searchGrowthPartners(categories: string, pageNumber: number, pageSize: number) {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (categories.toLowerCase() != "all") {
      params = params.set('category_ids', categories);
    }
    return this.http.get<any>(`${this.BASE_API_URL}api/utility-category/getFilteredUCGrowthPatners`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  search(text: string) {
    let params = new HttpParams()
      .set('term', text);
    return this.http.get<any>(`${this.BASE_API_URL}api/search/`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  searchTagUsers(term: string) {
    let params = new HttpParams()
      .set('companyNameWithChars', term);
    return this.http.get<any[]>(`${this.BASE_API_URL}api/user/getTaggableUsers`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    );
  }

  async searchTagUsersAsyc(term: string) {
    return new Promise(resolve => {
      this.searchTagUsers(term).subscribe({
        next: result => {
          resolve(result)
        },
        error: err => resolve(err)
      })
    })
  }
}
