import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { environment } from "src/environments/environment";

@Component({
  selector: 'app-auth-message',
  templateUrl: './auth-message.component.html',
  styleUrls: ['./auth-message.component.scss']
})
export class AuthMessageComponent implements OnInit {

  @Input() title: string;

  @Input() desc1: string;

  @Input() desc2: string;

  @Input() resendVerify: boolean;

  @Input() growthPartner: boolean;

  @Output() btnClick: EventEmitter<any> = new EventEmitter();

  smeconnectURL: any;

  ngOnInit(): void {
    this.smeconnectURL = environment.smeconnectURL
  }

  gotoSMECONNECT() {
    window.location.href = this.smeconnectURL;
  }

  onBtnClick(event: any) {
    this.btnClick.emit(event);
  }

}
