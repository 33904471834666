<p-tabView>
    <p-tabPanel>
        <ng-template pTemplate="header" id="tabMyContent">
            <span>Followers</span>
            <span class="num">({{totalRecords}})</span>
        </ng-template>
        <p-table [columns]="cols" [value]="followers" (onLazyLoad)="getFollowers($event)" [lazy]="true"
            [paginator]="totalPages>1" [rows]="10" [totalRecords]="totalRecords">
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns; index as i">
                        <div class="d-flex" *ngIf="col.field == 'companyName'">
                            <div class="companyName">
                                <img *ngIf="rowData?.userIcon" src="{{rowData?.userIcon}}" [alt]="rowData?.userIcon">
                                <div class="feed-company-logo"
                                    *ngIf="rowData?.userIcon =='' || rowData?.userIcon == null">
                                    {{rowData[col.field]?.trim().slice(0,1)}}</div>
                            </div>
                            <div style="margin-top: 12px;">
                                <span>{{rowData[col.field]}}</span>
                            </div>
                        </div>

                        <div *ngIf="col.field == 'id'">
                            <a href="javascript:void(0);"
                                [routerLink]="['/user-browse/details/'+(rowData.role?rowData.role.toLowerCase():'sme')+'/'+rowData.userId]"
                                id="btnFollowersViewPage{{i+1}}">View
                                Page</a>
                        </div>
                        <div *ngIf="col.field != 'companyName' && col.field != 'id'">
                            {{rowData[col.field]}}
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="followCount text-center mt-3">
            <h5 *ngIf="totalRecords==0">Your page has no followers yet.</h5>
        </div>

    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header" id="tabMyContent">
            <span>Following</span>
            <span class="num">({{totalRecordsfollowees}})</span>
        </ng-template>
        <p-table [columns]="cols" [value]="followees" (onLazyLoad)="getFollowees($event)" [lazy]="true"
            [paginator]="totalPagesfollowees>1" [rows]="10" [totalRecords]="totalRecordsfollowees">
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns; index as i">
                        <div class="d-flex" *ngIf="col.field == 'companyName'">

                            <div class="companyName">
                                <img *ngIf="rowData?.userIcon" src="{{rowData?.userIcon}}" [alt]="rowData?.userIcon">
                                <div class="feed-company-logo"
                                    *ngIf="rowData?.userIcon =='' || rowData?.userIcon == null">
                                    {{rowData[col.field]?.trim().slice(0,1)}}</div>
                            </div>

                            <div style="margin-top: 12px;">
                                <span>{{rowData[col.field]}}</span>
                            </div>

                        </div>
                        <div class="actions" *ngIf="col.field == 'id'">
                            <button class="btn px-1" (click)="unFollow(rowData.userId)"
                                id="btnFollowingUnfollow{{i+1}}">Unfollow</button>
                            <span>/ </span>
                            <a href="javascript:void(0);"
                                [routerLink]="['/user-browse/details/'+(rowData.role?rowData.role.toLowerCase():'sme')+'/'+rowData.userId]"
                                id="btnFollowingViewPage{{i+1}}">View
                                Page</a>
                        </div>
                        <div *ngIf="col.field != 'companyName' && col.field != 'id'">
                            {{rowData[col.field]}}
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="followCount text-center mt-3">
            <h5 *ngIf="totalRecordsfollowees==0">You are not following any company yet.</h5>
        </div>

    </p-tabPanel>
</p-tabView>