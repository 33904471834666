import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProductService } from '../../core/services/product-service/product.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { SelectOptionService } from '../../shared/components/select/select-option.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';
import { Common } from '../../utility/common';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-sme-products',
  templateUrl: './sme-products.component.html',
  styleUrls: ['./sme-products.component.scss'],
  animations: [fadeAnimation]
})
export class SmeProductsComponent implements OnInit {
  userId: any;
  isPublic: boolean = false;
  hasItems = false;
  thumbnail: string = "";
  productForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  loading: boolean;
  isLoggedIn: boolean;
  editMode: boolean;
  selectedProduct: any;
  products: any = [];
  featuredImage: any;
  previewURL: any;
  previewURLs: any = [];
  images: any = [];
  categoriesOptions: Array<any> = [];
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;
  responsiveOptions: any;
  addProductModal: boolean;
  deleteProductModal: boolean;
  maxAllowedSize = 1 * 1024 * 1024;


  constructor(
    private productService: ProductService,
    private selectOptionService: SelectOptionService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.productForm = new FormGroup({
      name: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(100)
      ]),
      description: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(500)
      ]),
      category: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      price: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(1),
      Validators.maxLength(10),
      CustomValidators.patternValidator(/^(\d)*$/, { hasValidPrice: true })])
    });

    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestProductList();
    });
    this.selectOptionService.getProductCategories().subscribe((data: any) => {
      this.categoriesOptions = data.map(v => {
        return { value: v.category, label: v.category };
      });
    });
  }

  setThumbnail() {
    this.thumbnail = "assets/images/card.jpg";
  }

  loadProductList(event: any) {
    this.loading = true;
    let q = '';
    let sortAsc = event.sortField && event.sortOrder == 1 ? event.sortField : '';
    let sortDesc = event.sortField && event.sortOrder == -1 ? event.sortField : '';
    this.productService.getProducts((event.first / event.rows), event.rows, q, sortAsc, sortDesc, this.userId).subscribe(
      (data) => {
        this.products = data.content;
        if (Array.isArray(this.products) && this.products.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.products = [];
        this.loading = false;
        console.log(error);
      }
    );
  }

  loadLatestProductList() {
    // this.loading = true; 
    // this.productService.getLatestProducts(this.userId).subscribe(
    //   (data) => {
    //     this.products = data;
    //     if (Array.isArray(this.products) && this.products.length > 0) {
    //       this.hasItems = true;
    //     }
    //     this.loading = false;
    //   },
    //   (error) => {
    //     this.products = [];
    //     this.loading = false;
    //     console.log(error);
    //   }
    // );
    this.loading = true;
    this.productService.getProducts(0, 100, "", "", "", this.userId).subscribe(
      (data) => {
        this.products = data.content;
        if (Array.isArray(this.products) && this.products.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.products = [];
        this.loading = false;
        console.log(error);
      }
    );
  }

  saveProduct() {
    let msgSummary = (this.editMode ? "Update" : "Create") + " Product";
    this.isSubmit = true;

    if (this.productForm.invalid || this.buttonPressed) {
      return false;
    }

    if (!this.editMode && (!this.featuredImage || (Array.isArray(this.featuredImage[0]) && this.featuredImage.length == 0))) {
      this.toastMessage(msgSummary, "Please add feature image", "error");
      return false;
    }

    this.buttonPressed = true;
    let formData = this.productForm.value;
    formData["productImages"] = [{ image: "" }];
    formData["visibility"] = "visibile";
    if (this.editMode) {
      formData.id = this.selectedProduct.productId;
    }

    let action = this.editMode ? "updateProduct" : "saveProduct";
    this.productService[action](this.getFormData(formData)).subscribe(
      data => {
        this.buttonPressed = false;
        if (data.error) {
          this.toastMessage(msgSummary, data.error, "error");
        } else {
          this.toastMessage(msgSummary, "Product saved successfully", "success");
          this.hasItems = true;
          this.addProductModal = false;
          this.loadLatestProductList();
          this.isSubmit = false;
          this.clearFields();
        }
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage(msgSummary, "Unable to save product! Please try after some time.", "error");
      }
    );
  }
  clearFields() {
    this.isSubmit = false;
    this.featuredImage = null;
    this.previewURL = null;
    this.images = [];
    this.previewURLs = [];
    this.productForm.reset();
    this.myNgForm.resetForm();
  }
  get f() { return this.productForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

  previewImages(files: any, type: string) {
    if (files[0].size > this.maxAllowedSize) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }
    if (files.length === 0) {
      if (type == 'featured') {
        this.featuredImage = null;
        this.previewURL = null;
      }
      return;
    }

    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      if (type == 'featured') {
        this.featuredImage = null;
        this.previewURL = null;
      }
      return;
    }

    let reader = new FileReader();
    if (type == 'featured') {
      this.featuredImage = files;
    } else {
      this.images.push(files[0])
    }
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (type == 'featured') {
        this.previewURL = reader.result;
      } else {
        this.previewURLs.push(reader.result);
      }
    }
  }

  getFormData(item: any) {
    let payload = new FormData();
    payload.append('data', JSON.stringify(item));
    if (this.featuredImage) {
      payload.append('file', this.featuredImage[0]);
    }
    if (this.images && this.images.length) {
      for (let i = 0; i < this.images.length; i++) {
        payload.append('files', this.images[i]);
      }
    }
    return payload;
  }

  onAddBtnClick() {
    this.isSubmit = false;
    this.editMode = false;
    this.selectedProduct = null;
    this.featuredImage = null;
    this.previewURL = null;
    this.images = [];
    this.previewURLs = [];
    this.productForm.reset();
    this.addProductModal = true;
    this.addProductModal = true;
    this.productForm.patchValue({
      name: "",
      description: "",
      category: "",
      price: ""
    });

  }

  onEditBtnClick(product: any) {
    this.isSubmit = false;
    this.editMode = true;
    this.featuredImage = null;
    this.images = [];
    this.previewURLs = [];
    this.previewURL = product.featuredImage;
    product.name = product.productName;
    this.selectedProduct = product;
    this.productForm.reset();
    this.productForm.patchValue(product);
    this.markFormGroupTouched(this.productForm);
    this.addProductModal = true;
  }
  currentProduct;
  onDeleteClick(product: any) {
    this.currentProduct = product;
    this.deleteProductModal = true;
  }
  deleteConfirmed() {
    if (this.currentProduct.deleting) {
      return false;
    }
    this.currentProduct.deleting = true;
    this.productService.deleteProduct(this.currentProduct.productId).subscribe(
      data => {
        this.deleteProductModal = false;
        this.currentProduct.deleting = false;
        this.products = this.products.filter(p => { return this.currentProduct.productId != p.productId; });
        this.toastMessage("Delete Product", "Your Product Card has been removed", "success");
      },
      error => {
        this.deleteProductModal = false;
        this.currentProduct.deleting = false;
        this.toastMessage("Delete Product", "Unable to delete product! Please try after some time.", "error");
      }
    );
  }
  userCanBook(product: any) {
    return this.isPublic && this.isLoggedIn && !product.booked;
  }

  bookingdoneuser(product: any) {
    return this.isPublic && this.isLoggedIn && product.booked;
  }

  onBookBtnClick(product: any) {
    if (product.booking || product.booked) {
      return false;
    }
    product.booking = true;
    this.productService.bookProduct(product.productId).subscribe(
      data => {
        product.booking = false;
        product.booked = true;
        this.toastMessage("Book Product", "Product booked successfully", "success");
      },
      error => {
        product.booking = false;
        if (Common.isBRNErrors(error)) {
          this.updateBRNPopup = true;
          this.currentBookingItem = product;
        } else {
          this.toastMessage("Book Product", "Unable to book product! Please try after some time.", "error");
        }
      }
    );
  }
  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.value)
        control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
