// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableBackendOffice: true,
  baseApiUrl: '/sme/',
  externalUrl: 'http://52.172.93.14',
  allowCaptcha: true,
  allowValidateButton: false,
  key: 'NGQ2YWM0NmItNDdkZS00MWU2LWJjOTktODE0NTBiNmRhN2Y2',
  gaTrackingId: 'G-N',
  smeconnectURL: '',
  aboutURL: 'about-us',
  faqURL: 'faq',
  termsURL: 'terms-and-conditions',
  privacyURL: 'privacy-statement',
  cookieURL: 'cookie-policy'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
