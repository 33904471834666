<app-language-selector></app-language-selector>
<div class="modal" [ngClass]="errorModel.hasError ? 'show-modal' : null" style="z-index: 9999999999;">
    <div class="modal-content container sm-size">
        <span class="close-button" (click)="hideModal()"></span>

        <div class="row my-2 justify-content-center ">
            <div class="col-8">
                <div class="row text-align-center justify-content-center">
                    <div class="col-12">
                        <img id="imgErrorModel" src="assets/icons/{{errorModel.items.icon}}" alt="alt" />
                    </div>
                    <div class="col-12 display-lg">
                        {{ errorMessage | translate}}
                    </div>
                    <div class="col-10 mb-1">
                        <p>{{errorModel.items.subtitle}}</p>
                    </div>
                    <div class="col-12">
                        <button id="btnHttperror" type="button" class="main-button-red sm"
                            (click)="handleActionButton(errorModel.items.clickType)">
                            {{errorModel.items.buttonText}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>