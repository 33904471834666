import { Component } from '@angular/core';
import { ChartService } from '../../shared/chart/apex-chart/apex-chart.service';
import { ChartDB } from '../../shared/chart/chart/chart-data';

@Component({
  selector: 'app-investment-summary',
  templateUrl: './investment-summary.component.html',
  styleUrls: ['./investment-summary.component.scss']
})
export class InvestmentSummaryComponent {
  public chartDB: any;

  constructor(public apexEvent: ChartService) {
    this.chartDB = ChartDB;
  }

}
