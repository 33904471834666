import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-browse-benner',
  templateUrl: './browse-benner.component.html',
  styleUrls: ['./browse-benner.component.scss']
})
export class BrowseBennerComponent implements OnInit {
  @Input() lists: any;
  @Input() benner: any;

  constructor() { }

  ngOnInit(): void {
  }

}
