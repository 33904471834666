<app-language-selector></app-language-selector>
<div class="row sme-growth-item mt-2" *ngIf="growthItem">
  <div class="col-xl-12 col-md-12">
    <app-card id="crdGrowthItem" [hidHeader]="true" cardClass="user-card-full" blockClass="p-0">
      <div class="row m-l-0 m-r-0 p-4">
        <div class="col-sm-3 user-profile-side">
          <div class="p-20 text-center text-white">
            <div class="m-b-25">
              <img id="imgGrowthItemMap" src="{{growthItem.imageUrl?growthItem.imageUrl:'assets/images/map.svg'}}"
                alt="{{growthItem.name}}" class="img-fluid">
            </div>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-9">
              <h6 class="title" id="ttl{{growthItem.name}}">{{ growthItem.name | translate }}</h6>
              <p class="dsc">{{ growthItem.description | translate }}</p>
            </div>

          </div>
          <div class="row">
            <div class="col-xl-4 col-sm-12">
              <div class="details time d-flex">
                <em class="fas fa-clock"></em>
                <div>
                  <h6 class="text-muted f-w-400">
                    {{ 'DURATION' | translate }}
                  </h6>
                  <div class="ng-container">
                    <p>{{ growthItem.duration | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-sm-12">
              <div class="details doller d-flex">
                <em class="fas fa-coins"></em>
                <div>
                  <h6 class="text-muted f-w-400">{{ 'FINANCING/BUDGET' | translate }}</h6>
                  <div class="ng-container">
                    <p>{{ growthItem.financingBudget | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 xol-sm-12">
              <div class="details team d-flex">
                <em class="fas fa-handshake"></em>
                <div>
                  <h6 class="text-muted f-w-400">{{ 'RESOURCES' | translate }}</h6>
                  <div class="ng-container">
                    <p>{{ growthItem.resources | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-sm-12">
              <div class=" text-center topPadding">
                <button id="btnGrowthItemStartPlan" type="button" *ngIf="showMoreButton"
                  [routerLink]="['/my-growth/growth-plans/'+growthItem.id]" class="btn btn-primary applyButton mb-2">{{
                  'Show more' |
                  translate }}</button>

                <button id="btnGrowthDetailsInterested" *ngIf="showApplyButton" class="btn btn-confirm applyButton"
                  data-toggle="modal" (click)="applyCallback.emit();"
                  [attr.disabled]="(growthItem.requestedOn!=null || isRM)?true:null">{{ "Apply" |
                  translate }}</button>
                <span *ngIf="growthItem.requestedOn">Request sent {{growthItem.requestedOn}}</span>
              </div>
            </div>
          </div>

          <div class="partners">
            <h6 id="ttlPartners">{{ growthItem.partnersTitle | translate }}</h6>
            <ul class="partners-txt">

            </ul>
            <ul class="partners-logos">
              <li>
                <span class="d-flex" *ngFor="let partner of growthItem.partners; index as pi">
                  <img *ngIf="partner?.type?.toLowerCase()!='investment'" src="{{partner.logo}}"
                    id="imgPartnersLogo{{pi+1}}" alt="{{partner.name}}">

                  <p>{{partner.name}}</p>
                </span>
              </li>
            </ul>
          </div>
          <ul class="btns">

          </ul>
        </div>
      </div>
    </app-card>
  </div>
</div>