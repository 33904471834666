<div class="d-flex align-items-center feed-comment-form" *ngIf="companyProfile && !isRM">
  <div class="input-group-append">
    <div class="users-img" *ngIf="companyProfile.logo">
      <img src="{{companyProfile.logo}}" class="img-fluid" id="imgProfilePic">
    </div>
    <div class="company-logo-txt" *ngIf="!companyProfile.logo">
      {{companyProfile?.companyName | slice:0:1}}
    </div>
  </div>
  <div class="form-control">
    <app-comment-box [value]="commentText" [clear]="clear" placeholder="Write a comment" [disableMention]="false"
      (valueChange)="onAddMentionChange($event)" [idPrefix]="'A_'+feed.id" [maxlength]="answerLength"></app-comment-box>
  </div>
  <div *ngIf="commentText && commentText.length && isValidAddComment" class="input-group-append submit">
    <button class="btn" type="button" (click)="saveComment()"><em class="fas fa-arrow-circle-right"></em></button>
  </div>
</div>

<div class="feed-comments">
  <ul class="media-list p-0">
    <ng-container *ngFor="let comment of comments; let index = index">
      <li class="media replytoreply mt-2"
        [ngClass]="{'community_manager': comment && comment.userRole == 'community_manager'}">
        <div class="media-left mr-3 pointer" *ngIf="comment.userCompanyLogo">
          <img class="media-object comment-img" [src]="comment.userCompanyLogo" alt="User Company Logo"
            (click)="comapanyredirection(comment.userId , comment.userRole)">
        </div>
        <div class="company-logo-txt" *ngIf="!comment.userCompanyLogo">
          {{comment.userCompanyName[0]}}
        </div>
        <div class="media-body">
          <p class="companyName pointer" (click)="comapanyredirection(comment.userId , comment.userRole)">
            {{comment?.userCompanyName}}
            <img class="company-verification" *ngIf="comment?.verifiedCompany == true"
              src="../../../../assets/images/auth-company-badge.svg" alt="">
          </p>
          <p style="white-space: pre-line" class="comment" [innerHTML]="formatComment(comment.commentText)"
            *ngIf="!comment.editMode">
          </p>
          <div class="d-flex justify-content-end" *ngIf="!comment.editMode">
            <!-- <div class="pointer commnent-Status mx-1" *ngIf="!comment.editMode && comment?.parentCommentId==0"
              (click)="showReplyInput(comment.answerId, comment.userId, comment.id)"><em class="fas fa-reply"></em>
              Reply <span>{{comment?.id}}</span>
            </div> -->
            <div class="commnent-Status" *ngIf="!comment.editMode">{{getRelativeTime(comment.submittedOn)}}</div>
            <div class="actions text-right" *ngIf="comment.editable">
              <div class="btn-group d-inline-block" ngbDropdown [placement]="'bottom-left'">
                <button class="btn drp-icon" ngbDropdownToggle type="button" *ngIf="!comment.editMode"><em
                    class="fas fa-ellipsis-v"></em></button>
                <div ngbDropdownMenu>
                  <a *ngIf="comment.editableOwner" class="dropdown-item" href="javascript:void(0);"
                    (click)="showHideUpdateCommentForm(comment, false, 'FC_'+feed.id+'_'+comment.id)">Edit</a>
                  <a class="dropdown-item" href="javascript:void(0);"
                    (click)="askDeleteComment(comment, index);">Remove</a>
                </div>
              </div>
            </div>
          </div>

          <div class="input-group feed-comment-form" *ngIf="comment.editMode">
            <div class="form-control">
              <app-comment-box [clear]="clear" [value]="updatedCommentText['FC_'+feed.id+'_'+comment.id]"
                placeholder="Write a comment" [disableMention]="false" [idPrefix]="'U'+feed.id+'_'+comment.id"
                [maxlength]="answerLength"
                (valueChange)="onUpdateMentionChange($event, 'FC_'+feed.id+'_'+comment.id)"></app-comment-box>
            </div>
            <div class="submit mx-2">
              <button class="btn p-0 mr-2" type="button" [disabled]="!doneee"
                *ngIf="updatedCommentText['FC_'+feed.id+'_'+comment.id].length && isValidUpdateComment['FC_'+feed.id+'_'+comment.id]"
                (click)="updateComment(comment, 'FC_'+feed.id+'_'+comment.id)"><em
                  class="fas fa-check-circle"></em></button>
              <button class="btn p-0" type="button"
                (click)="showHideUpdateCommentForm(comment, true, 'FC_'+feed.id+'_'+comment.id)"><em
                  class="fas fa-times-circle"></em></button>
            </div>
          </div>
        </div>
      </li>

      <!-- <div class="input-group feed-comment-form" *ngIf="comment.showReplyInput" style="padding: 0px 0px 0px 105px;">
        <div class="form-control">
          <app-comment-box [clear]="clear" [value]="replyMessages[comment.answerId + '_' + comment.userId]"
            placeholder="Write a response" [disableMention]="false"
            [idPrefix]="'Reply_'+comment.answerId+'_'+comment.userId" [maxlength]="answerLength"
            (valueChange)="onReplyValueChange($event, comment.answerId, comment.userId, comment.id)"></app-comment-box>
        </div>
        <div class="submit mx-2">
          <button class="btn p-0 mr-2" type="button" style="font-size: 35px;"
            (click)="sendReply(comment.answerId, comment.userId,comment.id, replyMessages[comment.answerId + '_' + comment.userId + '_' + comment.id])">
            <em class="fas fa-check-circle"></em>
          </button>

          <button class="btn p-0" type="button" (click)="cancelReply(comment.answerId, comment.userId)"
            style="font-size: 35px;">
            <em class="fas fa-times-circle"></em>
          </button>
        </div>
      </div> -->

    </ng-container>
  </ul>
  <div class="spinner-border text-center" role="status" *ngIf="loading">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="text-center mt-3" *ngIf="showMoreCommentsLink">
    <button class="btn btn-link" (click)="loadMoreComments()">See more comments <em
        class="fas fa-chevron-down"></em></button>
  </div>
</div>
<p-toast position="top-center" key="commentMsg" [baseZIndex]="9999999" id="pToastMsg"></p-toast>