import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import { HttpErrorCategorizeService } from '../http-error-categorize/http-error-categorize.service';
import { BroadcastService } from '../broadcast/broadcast.service';
import { Constants } from '../../../shared/constants/constants';
import { TokenService } from '../token/token.service';
import { ClientInfoService } from "../client-info/client-info.service";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  clearErrorInterval = null;
  REMOVE_ERROR_TIMER = 5000;
  docUploadURL = ['/documentMetadatas/', '/fileService/fileUpload'];
  urlsExcludedForError = [Constants.IP_API_URL];
  urlsExcludedForLoader: string[] = ['' + Constants.IP_API_URL];
  activeRequestCounter = 0;
 
  constructor(
    private loc: Location,
    public broadcastService: BroadcastService,
    public httpErrorCategoriseService: HttpErrorCategorizeService,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) {}
  
  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  readonly KEY: string = this.clientInfoService.getKey();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let excluded = false;
    this.urlsExcludedForLoader.forEach(url => {
      if (request.url.indexOf(url) !== -1) {
        excluded = true;
      }
    });
    if (!excluded) {
      //this.setLoader(request.url);
      this.activeRequestCounter++;
    }
    if (this.tokenService.getToken()) {
      request = this.addToken(request, this.tokenService.getToken());
    }
    request = this.addSecurityHash(request, this.tokenService.getToken());
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.emitError(error);
          }
          this.activeRequestCounter = 0;
          this.broadcastService.httpLoader.next(false);
        },
        () => {
          if (!excluded) {
            this.activeRequestCounter--;
          }
          if (this.activeRequestCounter <= 0) {
            this.broadcastService.httpLoader.next(false);
          }
        }
      )
    );
  }

  setLoader(url: string) {
    let isDocUpload = false;
    this.docUploadURL.forEach(docURL => {
      isDocUpload = isDocUpload || url.indexOf(docURL) !== -1;
    });
    if (!isDocUpload) {
      this.broadcastService.httpLoader.next(true);
    }
  }

  emitError(error: any) {
    let excluded = false;
    this.urlsExcludedForError.forEach(url => {
      if (error.url !== undefined && error.url !== null && error.url.indexOf(url) !== -1) {
        excluded = true;
      }
    });
    if (!excluded) {
      const categorizedError = this.httpErrorCategoriseService.categorize(error.status, error.url);
      this.broadcastService.httpError.next(categorizedError);
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private addSecurityHash(request: HttpRequest<any>, token: string) {

    let currentTime = new Date().getTime().toString();
    let message = this.createMessage(request, token, currentTime);
    let hash = CryptoJS.HmacSHA256(message, this.KEY);
    let base64Hash =  CryptoJS.enc.Base64.stringify(hash);
    return request.clone({
      setHeaders: {
        'x-base-href': this.getBaseHref(),
        'x-signature': base64Hash,
        'x-timestamp': currentTime
      }
    });
  }

  private createMessage(request: HttpRequest<any>, token: string, timestamp: string) {
    let message = [];
    message.push(request.method);
    message.push(this.resolvePath(request.urlWithParams));
    message.push(token);
    //FIX: body empty for file upload with data
    message.push(this.formatBody("")); 
    message.push(timestamp);
    return message.join(":");
  }

  private resolvePath(url: string) {
    let parser = document.createElement("a");
    parser.href = url;
    let path = url.replace(parser.origin, "");
    if(path.substr(0, 1) != "/") {
      path = "/" + path;
    }
    path = decodeURI(path);
    path = path.replace(/%26/g, "&");
    return path;
  }

  private formatBody(body: string) {
    let jsonStr = body?JSON.stringify(body):"";
    jsonStr = this.canocalized(jsonStr);
    return CryptoJS.SHA256(jsonStr).toString(CryptoJS.enc.Hex);
  }

  private canocalized(json: string) {
    return json.replace(/ /g, "")
            .replace(/\\r/g, '')
            .replace(/\\n/g, '')
            .replace(/\\t/g, '');
  }

  private getBaseHref() {
    const angularRoute = this.loc.path();
    const url = window.location.href;
    return url.replace(angularRoute, '');
  }

}
