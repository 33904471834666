<ng-container *ngIf="item && !item.hidden">
    <li *ngIf="themeLayout === 'horizontal'" (mouseenter)="navCollapse($event)" class="nav-item pcoded-hasmenu"
        [routerLinkActive]="['active']">
        <a id="aHorizontalNavLink" [routerLinkActive]="['active']" href="javascript:void(0);" class="nav-link">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
        <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
    <li *ngIf="themeLayout === 'vertical'" class="nav-item pcoded-hasmenu" [routerLinkActive]="['active']">
        <a id="aVerticalNavLink" [routerLinkActive]="['active']" href="javascript:void(0);" class="nav-link"
            (click)="navCollapse($event)">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
        <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
    <ng-template #itemContent>
        <span class="pcoded-micon" *ngIf="item.icon">
            <span class="{{item.icon}}">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
            </span>
        </span>
        <span class="pcoded-mtext">{{item.title}}
            <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type">
                {{item.badge.title}}
            </span>
        </span>
        <span *ngIf="item.badge && themeLayout === 'vertical'" class="pcoded-badge badge" [ngClass]="item.badge.type">
            {{item.badge.title}}
        </span>
    </ng-template>
    <ng-template #subMenuContent>
        <ul class="pcoded-submenu" [routerLinkActive]="['active']">
            <ng-container *ngFor="let item of item.children">
                <app-nav-group id="app-nav-group" class="app-nav-group" *ngIf="item.type=='group'"
                    [item]="item"></app-nav-group>
                <app-nav-collapse id="app-nav-collapse" *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item id="app-nav-item" *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </ng-template>
</ng-container>