import { Component, OnInit, Input } from '@angular/core';
import { FieldMetaData, FieldSourceModel, TextAreaFieldMetaData } from '../../core/models/field-meta-data';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-about-form',
  templateUrl: './about-form.component.html',
  styleUrls: ['./about-form.component.scss']
})
export class AboutFormComponent implements OnInit {
  @Input() options: any = {
    descriptionField: {}
  };
  @Input() aboutForm: FormGroup;
  formFields: FieldMetaData[] = [];
  descriptionField: any;
  public animation: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(!this.aboutForm) {
      this.aboutForm = new FormGroup({
        about: new FormControl('')
      });
    }
    this.buildFields();
  }

  buildFields() {
    this.descriptionField = new TextAreaFieldMetaData(
      this.aboutForm.controls["about"] as FormControl,
      this.options.descriptionField.id,
      this.options.descriptionField.label,
      this.options.descriptionField.placeholderText,
      'description',
      this.options.descriptionField.validMsg,
      this.options.descriptionField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      this.options.descriptionField.type
    );
  }

}
