<div class="row">
  <div class="col-md-12">
    <div class="dscp">
      <p>We noticed you did not provide a BRN for {{profileService?.orgDetails?.companyName}}. Kindly enter a valid BRN
        so
        that you can proceed. We will update your profile & company page and make it visible to other users.</p>
    </div>
  </div>
  <div class="col-md-12">
    <div class="inserting-block">
      <p>Inserting a valid BRN will help other users build trust and let them know you are serious.</p>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group mb-3">
      <label id="lblBRN">BRN</label>
      <input #BRNInput id="txtBRN" type="text" class="form-control" placeholder="e.g C12345678" autocomplete="off"
        [(ngModel)]="BRNInputValue" maxlength="9" (keyup)="showError=BRNInput.value.length==0;" />
      <span *ngIf="showError" class="error">Please insert a valid BRN</span>
    </div>
  </div>
</div>
<div class="text-right">
  <button id="btnUpdateBRNPopupCancel" type="button" class="btn btn-secondary mr-3"
    (click)="clearFields();closePopup();">Cancel</button>
  <button id="btnupdateBRNPopupContinue" type="button" class="btn btn-confirm" (click)="updateBRN();">
    <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
    <span class="btn-text">Continue</span>
  </button>
</div>