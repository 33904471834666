<app-language-selector></app-language-selector>
<div class="row mx-0 justify-content-md-center">
  <div class="col-lg-12 col-xl-10">
    <div class="browse-categories" id="div{{ 'Browse.id' | translate }}">
      <h3 id="ttl{{ 'Browse.id' | translate }}">{{ 'Browse.title' | translate }}</h3>
      <div class="row">
        <!-- <div class="col-md-6 mb-4" *ngFor="let item of categories?.data; index as i">
          <div class="categories-box" id="div{{item.id}}{{i+1}}">
            <h6 id="ttl{{item.id}}{{i+1}}">
              <span>{{ item.title }}</span>
              <a href="javascript:void(0);" id="anc{{item.id}}{{i+1}}" *ngIf="item?.link">{{ item?.link }}</a>
            </h6>
            <p id="p{{item.id}}{{i+1}}">{{ item.disc }}</p>
            <button type="button" class="btn btn-primary" id="btn{{item.id}}"
              [routerLink]="[item.url]">{{item.button}}</button>
          </div>
        </div> -->

        <!-- <p-dropdown [options]="browse" [(ngModel)]="selectedCity" optionLabel="name" (onChange)="browseEnterprenuers($event)"></p-dropdown> -->
        
      </div>
    </div>
  </div>
</div>