export class NextConfig {
    public static config = {
        layout: 'vertical',
        subLayout: '',
        collapseMenu: true,
        layoutType: 'menu-light',
        headerBackColor: 'background-blue',
        rtlLayout: false,
        navFixedLayout: true,
        headerFixedLayout: false,
        boxLayout: false,
    };
}
