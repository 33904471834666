<app-language-selector></app-language-selector>
<div class="group-loaders" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
  </div>
</div>
<ng-container *ngIf="!loading">
  <ng-container *ngFor="let item of discussions; index as i">
    <app-card [hidHeader]="true" cardClass="forum" blockClass="p-0" id="acc{{item.questionId}}{{i+1}}">
      <!--View Question Start-->
      <img *ngIf="item.companyLogo" src="{{item.companyLogo}}" class="user main-logo pointer"
        id="img{{item.questionId}}{{i+1}}" alt="alt" (click)="comapanyredirection(item.userId , item.userRole)">
      <div class="company-logo-txt" *ngIf="!item.companyLogo && item.userName">
        {{ item.companyName[0]}}
      </div>
      <div class="answers" id="div{{item.questionId}}{{i+1}}">
        <div class="d-flex justify-content-between mb-2" *ngIf="!item.editClicked">
          <div>
            <h6 class="qtitle">
              <span id="ttlUser{{item.questionId}}{{i+1}}" (click)="comapanyredirection(item.userId , item.userRole)">{{
                item.companyName | slice:0:22 }}
                <span *ngIf="item.companyName.length > 22">...</span>
                ({{item.userName }})
              </span>
              <span class="user-badge" *ngIf="item?.verifiedProfile == true">
                <img src="../../../../../assets/images/user-auth-badge.svg" alt="">
              </span>
            </h6>
            <span class="userRole" id="ttlUser{{item.questionId}}{{i+1}}" *ngIf="item?.userRole">
              <ng-container *ngIf="item.userRole == 'sme'">Entrepreneur</ng-container>
              <ng-container *ngIf="item.userRole == 'service_provider'">Service Provider</ng-container>
              <ng-container *ngIf="item.userRole == 'investor'">Investor</ng-container>
            </span>
          </div>

          <div class="question-block d-flex">
            <span>{{ item.capturedDate | dateAgo }}</span>
            <span *ngIf="item.editable || item.removable">

              <div ngbDropdown class="d-inline-block ng-drop ml-2">
                <i class="fas fa-ellipsis-v pointer menu" ngbDropdownToggle></i>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <div class="d-flex justify-content-between pointer" ngbDropdownItem
                    (click)="makeQuestionEditable(item.questionId)">
                    <span>Edit</span>
                    <span><em class="fas fa-pen"></em></span>
                  </div>

                  <div class="d-flex justify-content-between pointer" ngbDropdownItem
                    (click)="deleteQuestion(item.questionId)">
                    <span>Delete</span>
                    <span><em class="fas fa-trash"></em></span>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div *ngIf="!item.editClicked" class="head-title" id="btn{{item.questionId}}{{i+1}}">
          <h6 id="ttl{{item.questionId}}{{i+1}}" [innerHTML]="formatText(item.question)"></h6>
          <div [ngClass]="{'grouplist': item.groupsData.length > 0}">
            <li *ngFor="let imageUrl of item.groupsData">
              <img class="pointer" [src]="imageUrl?.groupImg" alt="Image" pTooltip="{{imageUrl?.groupName}}"
                tooltipPosition="top" (click)="groupredirection(imageUrl?.groupId)">
            </li>
          </div>
        </div>
        <!--View Question End-->
        <!--Edit Question start-->
        <div class="contribute"
          [ngClass]="{'border-0 mt-0 p-0': (!item.question || !item.question.content) || (item.question && item.question.content && item.question.content.length < 1)}">
          <div class="contribute-block">
            <div *ngIf="item.editClicked" class="form-group">
              <span class="form-control">

                <textarea class="form-control1" placeholder="Write a response"
                  id="txtContribute{{item.questionId}}{{i+1}}" maxlength="{{questionLength}}"
                  [(ngModel)]="editQuestion[i]"
                  [ngClass]="{'is-invalid':(!editQuestion[i] && editQuestionButtonPressedForError[i])}"
                  rows="{{editQuestion[i].split('\n').length}}"
                  (input)="isQuestionEdited = true">{{item.question}}</textarea>

                <div class="char-count">
                  <ng-container *ngIf="editQuestion[i] && editQuestion[i].length < questionLength">{{questionLength -
                    editQuestion[i].length}} characters left</ng-container>
                  <ng-container *ngIf="editQuestion[i].length==questionLength">You have reached the character limit
                  </ng-container>
                </div>
              </span>
              <button type="submit" class="btn" [disabled]="!isQuestionEdited" (click)="updateQuestion(i)"
                id="btnContribute{{item.questionId}}{{i+1}}"><em class="fas fa-check-circle"></em></button>
              <button class="btn ml-2" type="button" (click)="cancelEditQuestion(i)"><em
                  class="fas fa-times-circle"></em></button>
              <div class="invalid-feedback pl-5" *ngIf="(!editQuestion[i] && editQuestionButtonPressedForError[i])">
                <div *ngIf="(!editQuestion[i] && editQuestionButtonPressedForError[i])" id="errAnswerRequired">Please
                  enter
                  your question</div>
              </div>
            </div>
          </div>
        </div>
        <!-- reply -->

        <!--Edit Question end-->

        <ng-container *ngIf="item.answerList && item.answerList.content && item.answerList.content.length > 0">
          <ng-container *ngFor="let subItem of item.answerList.content; index as si">
            <!--Edit Answer start-->

            <div *ngIf="subItem.editClicked" class="contribute edit-contribute"
              id="divContribute{{item.questionId}}{{subItem.answerId}}{{i+1}}">
              <div class="contribute-block">
                <div class="form-group">
                  <div class="" *ngIf="image">
                    <img src="{{image}}" class="user main-logo mt-2"
                      id="imgContribute{{item.questionId}}{{subItem.answerId}}{{i+1}}" alt="alt">
                  </div>
                  <div class="company-logo-txt" *ngIf="!image && userName">
                    {{ userName[0]}}
                  </div>
                  <span class="form-control">
                    <app-comment-box [value]="editAnswer[i][si]" placeholder="Write a response" [disableMention]="false"
                      (valueChange)="onEditAnswerMentionChange($event, i, si)"
                      [idPrefix]="'EditAns_'+item.questionId+'_'+subItem.answerId+'_'+(i+1)"
                      [maxlength]="answerLength"></app-comment-box>
                  </span>

                  <button type="submit" class="btn" [disabled]="!isInputValid" (click)="submitEditAnswer(i,si)"
                    id="btnContribute{{item.questionId}}{{i+1}}"><em class="fas fa-check-circle"></em></button>
                  <button class="btn  ml-2" type="button" (click)="cancelEditAnswer(i,si)"><em
                      class="fas fa-times-circle"></em></button>

                  <div class="invalid-feedback pl-5"
                    *ngIf="(!editAnswer[i][si] && editAnswerButtonPressedForError[i][si])">
                    <div *ngIf="(!editAnswer[i][si] && editAnswerButtonPressedForError[i][si])" id="errAnswerRequired">
                      Please enter your answer</div>
                  </div>
                </div>
              </div>
            </div>

            <!--Edit Answer end-->
            <!--Answer View part start-->
            <div *ngIf="!subItem.editClicked" class="answer"
              [ngClass]="subItem.userRole == 'community_manager' ? 'cm-answer' : ''">
              <img *ngIf="subItem.companyLogo" src="{{subItem.companyLogo}}" class="user pointer"
                id="img{{item.questionId}}{{i+1}}" alt="alt"
                (click)="comapanyredirection(subItem.userId , subItem.userRole)">
              <div class="company-logo-txt" *ngIf="!subItem.companyLogo && subItem.userName">
                {{ subItem.companyName[0]}}
              </div>
              <div class="answer-block">
                <div class="tags d-flex justify-content-between mb-2">
                  <div>
                    <h6 class="qtitle pointer">
                      <span id="ttlUser{{item.questionId}}{{i+1}}"
                        (click)="comapanyredirection(subItem.userId , subItem.userRole)">
                        {{ subItem.companyName | slice:0:22}}
                        <span *ngIf="subItem?.companyName?.length > 22">...</span>
                        ({{subItem.userName }})</span>

                      <span class="user-badge" *ngIf="subItem?.verifiedProfile == true">
                        <img src="../../../../../assets/images/user-auth-badge.svg" alt="">
                      </span>
                    </h6>

                    <span class="userRole" id="ttlUser{{item.questionId}}{{i+1}}" *ngIf="subItem?.userRole">
                      <ng-container *ngIf="subItem.userRole == 'sme'">Entrepreneur</ng-container>
                      <ng-container *ngIf="subItem.userRole == 'service_provider'">Service Provider</ng-container>
                      <ng-container *ngIf="subItem.userRole == 'investor'">Investor</ng-container>
                    </span>
                  </div>

                  <div class="right-block">
                    <span class="dateago">{{ subItem.capturedDate | dateAgo }}</span>
                    <span *ngIf="subItem.editable || subItem.removable">
                      <div ngbDropdown class="d-inline-block ng-drop ml-2">
                        <i class="fas fa-ellipsis-v pointer menu" ngbDropdownToggle></i>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <div class="d-flex justify-content-between pointer" ngbDropdownItem
                            (click)="setEditableAnswer(item.questionId,subItem.answerId)">
                            <span>Edit</span>
                            <span><em class="fas fa-pen"></em></span>
                          </div>

                          <div class="d-flex justify-content-between pointer" ngbDropdownItem
                            (click)="deleteAnswer(item.questionId,subItem.answerId)">
                            <span>Delete</span>
                            <span><em class="fas fa-trash"></em></span>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <p id="p{{item.questionId}}Answer{{subItem.id}}" style="white-space: pre-line;"
                  [innerHTML]="formatText(subItem.answer)"></p>

                <div class="helpfulness">
                  <span (click)="helpful(subItem.answerId)" id="{{subItem?.rating?.HelpfulId}}" class="pointer ml-1">
                    <em class="{{subItem.helpful?'fas':'far'}} fa-thumbs-up"></em>
                    <span>{{subItem.totalHelpful}}</span>
                  </span>
                  <span (click)="unHelpful(subItem.answerId)" id="{{subItem?.rating?.UnhelpfulId}}"
                    class="pointer ml-2">
                    <em class="{{subItem.unHelpful?'fas':'far'}} fa-thumbs-down"></em>
                    <span>{{subItem.totalUnHelpful}}</span>
                  </span>
                </div>
              </div>
            </div>
            <!--Answer View part end-->
          </ng-container>
        </ng-container>
        <div
          *ngIf="item?.answerList?.totalElements>5 && item?.answerList?.pageable?.pageNumber+1<item?.answerList?.totalPages"
          class="loadMore" (click)="loadAnswers(item.questionId)">
          See more
        </div>
      </div>
      <!--New Answer start-->
      <div class="contribute" id="divContribute{{item.questionId}}{{i+1}}" *ngIf="!isRM">
        <div class="contribute-block">
          <div class="form-group">
            <div class="cmpany-logo" *ngIf="image">
              <img src="{{image}}" class="user" id="imgContribute{{item.questionId}}{{i+1}}" alt="alt">
            </div>
            <div class="company-logo-txt" *ngIf="!image && userName">
              {{ userName[0]}}
            </div>
            <span class="form-control">
              <app-comment-box [value]="newAnswer[i]" placeholder="Write a response" [disableMention]="false"
                (valueChange)="onNewAnswerMentionChange($event, i)" [idPrefix]="'NewAns_'+item.questionId+'_'+(i+1)"
                [clear]="clear" [maxlength]="answerLength"></app-comment-box>
            </span>
            <div *ngIf="newAnswer[i] && newAnswer[i].length" class="input-group-append submit">
              <button type="submit" class="btn" (click)="submitAnswer(i)"
                id="btnContribute{{item.questionId}}{{i+1}}"><em class="fas fa-arrow-circle-right"></em></button>
            </div>
            <div class="invalid-feedback pl-5" *ngIf="(!newAnswer[i] && buttonPressedForError[i])">
              <div *ngIf="(!newAnswer[i] && buttonPressedForError[i])" id="errAnswerRequired">Please enter your answer
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--New Answer end-->
    </app-card>
  </ng-container>
</ng-container>
<app-pagination [totalItems]="totalItems" [currentPage]="pageNumber" [pageSize]="pageSize" [maxPages]="5"
  (changePage)="goToPage($event)" *ngIf="!loading && totalItems>10"></app-pagination>

<p-toast position="top-center" key="commentMsg" [baseZIndex]="9999999" id="pToastMsg"></p-toast>