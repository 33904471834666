<div class="messenger">
    <div class="messenger-btn" (click)="messenger()">
        <i class="fas fa-comment"></i>
    </div>

    <!-- <div class="badge">
        22
    </div> -->

    <div class="messenger-info" *ngIf="togglemessage">
        <app-messenger></app-messenger>
    </div>
</div>

<div class="d-flex">
    <div>
        <ul class="navbar-nav navbar-right">
            <li class="user-block">
                <div class="dropdown drp-user" ngbDropdown placement="auto">
                    <a id="aProfileDropDown2" href="javascript:void(0);" ngbDropdownToggle class="fas fa-chevron-down">
                        <span class="user-img" *ngIf="!image">
                            {{ userName?.trim().slice(0,1) }}
                        </span>
                        <span class="users-img" *ngIf="image">
                            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status"></span>
                            <img *ngIf="!loading" src="{{image}}" class="img-fluid" id="imgProfilePic" alt="alt">
                        </span>
                        <span class="user-name">
                            <span class="title">
                                {{ userName?.trim().slice(0,13)}}
                                <span *ngIf="userName?.length>13">...</span>
                                <span class="user-badge">
                                    <img *ngIf="profile?.verifiedProfile == true"
                                        src="../../../../../assets/images/user-auth-badge.svg" alt="">
                                </span>
                            </span>
                            <span class="details">
                                {{ userPosition }}
                            </span>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                        <ul class="pro-body">
                            <li>
                                <a id="aMenuProfile" href="javascript:void(0);"
                                    class="dropdown-item d-flex justify-content-between" [routerLink]="['/my-profile']">
                                    <span>My Profile</span>
                                    <span><em class="fas fa-user-circle"></em></span>
                                </a>
                            </li>
                            <li>
                                <a id="aMenuLogout" href="javascript:void(0);"
                                    class="dropdown-item d-flex justify-content-between" (click)="logout()">
                                    <span>Logout</span>
                                    <span><em class="fas fa-power-off"></em></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>