<div class="features parallax" id="features">
  <div class="container">
    <div class="features-content entrepreneur">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="entrepreneur-content entrepreneur-bg">
            <h1 class="wow fadeInUp" data-wow-delay="0s" id="ttl{{landingFeatures?.id | translate}}1">
              <span class="subtitle">{{landingFeatures?.title | translate}}</span>
              <span>{{landingFeatures?.title3 | translate}}</span>
            </h1>
          </div>
        </div>
        <div class="col-lg-12 col-xl-12">
          <div class="services">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 wow" data-wow-delay="0.2s"
                *ngFor="let item of landingFeatures?.features; index as i"
                [ngClass]="{'fadeInLeft':item.title == 'Content & Tools', 'fadeInRight':item.title == 'GROWTH PLANS', 'fadeInUp':item.title == 'BUSINESS HEALTH CHECKS', 'fadeInDown':item.title == 'GROWTH CARDS'}">
                <div class="icons-box-block" id="divServices{{landingFeatures?.id | translate}}{{i+1}}">
                  <div class="services-icon">
                    <em class="{{item.icon}}"></em>
                  </div>
                  <div class="services-description">
                    <h1 id="ttlServices{{landingFeatures?.id | translate}}{{i+1}}">{{item.title | translate}}</h1>
                    <p id="pServices{{landingFeatures?.id | translate}}{{i+1}}">{{item.disc | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>