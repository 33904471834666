<div class="our-story-banner">
  <div class="overlay"></div>
  <video
    playsinline="playsinline"
    autoplay="autoplay"
    muted="muted"
    loop="loop"
  >
    <source
      src="https://static.videezy.com/system/resources/previews/000/035/053/original/business5.mp4"
      type="video/mp4"
    />
  </video>
  <div class="header-container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <h2 class="title">
            Lisa talks about how she launched her business & how she forsees the
            future.
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
