<app-language-selector></app-language-selector>
<nav class="landing-navbar navbar navbar-expand-md navbar-light navbar-area">
    <div class="container">
        <a class="navbar-brand" [href]="smeconnectURL">
            <img src="../../../../assets/images/SME-Linker.png" alt="logo" class="main-logo">
            <img src="./../../../assets/images/SME-Linker.png" alt="logo" class="logo-w">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>


        <div>
            <a class="navbar-brand collapse navbar-collapse landing-top-right" id="navbarSupportedContent">
                <button class="main-logo btn btn-confirm btn-stable" [routerLink]="['/signin']" id="btnLoginSignIn">Sign
                    In</button>
                <button class="logo-w  btn btn-confirm btn-scroll" [routerLink]="['/signin']" id="btnLoginSignIn">Sign
                    In</button>
            </a>
        </div>
    </div>
</nav>