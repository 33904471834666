import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ProfileService } from '../../core/services/profile-service/profile.service';
import { TokenService } from '../../core/services/token/token.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  aboutForm: string = "aboutForm";
  aboutFormData: any;
  hasItems = false;

  loading: boolean;

  about: string = "";
  myForm: FormGroup;
  buttonPressed: boolean;

  companyName: string = "";
  aboutModal: boolean;
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;

  constructor(
    private profileService: ProfileService,
    private tokenService: TokenService,
    private messageService: MessageService
  ) {
    this.aboutFormData = {
      descriptionField: {
        label: 'aboutForm.description.title',
        placeholderText: 'aboutForm.description.placeholder',
        type: 'text',
        required: false,
        id: 'aboutForm.description.id',
        validMsg: 'aboutForm.description.errorMsg'
      }
    }
  }

  clearAboutForm() {
    this.myForm.reset();
    this.myNgForm.resetForm();
  }
  ngOnInit(): void {
    this.companyName = this.tokenService.getCompanyName();
    this.getAbout();
    this.myForm = new FormGroup({
      about: new FormControl("", [Validators.required, CustomValidators.removeSpaces, Validators.maxLength(350)])
    });
  }

  prepareForm() {
    this.myForm.controls.about.setValue(this.about ? this.about : "");
  }

  saveFormAbout() {
    this.myForm.markAllAsTouched();
    if (this.myForm.invalid || this.buttonPressed) {
      return false;
    }
    this.buttonPressed = true;
    let formData = this.myForm.value;

    this.profileService.saveAbout(this.getFormData(formData)).subscribe(
      data => {
        this.hasItems = true;
        this.aboutModal = false;
        this.getAbout();
        this.myForm.reset();
        this.buttonPressed = false;
        this.myNgForm.resetForm();
        this.toastMessage("About", "About saved successfully", "success");
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage("About", "Unable to save about! Please try after some time.", "error");
      }
    );
  }

  getFormData(item: any) {
    let payload = new FormData();
    payload.append('about', item.about);
    return payload;
  }

  getAbout() {
    this.loading = true;
    this.profileService.getAbout(null).subscribe(
      (data) => {
        this.about = data.about;
        if (this.about) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.about = "";
        this.loading = false;
        // console.log(error);
      }
    );
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
