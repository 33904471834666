<app-language-selector></app-language-selector>
<app-startup-card cardTitle="Get in Touch" subTitle="Let your audience know how to reach you"
  description="Insert your contact details, website URL and/or links to your social media pages."
  componentId="GetInTouchCard" iconPath="assets/images/get-in-touch.png" btnLabel="Add Contact Details"
  (btnClick)="clearFields();contactModal = true" *ngIf="!hasItems && !loading && !isPublic"></app-startup-card>

<app-startup-card cardTitle="Get in Touch" subTitle="Contact details"
  description="The user has not provided his contact details yet." componentId="GetInTouchCard"
  iconPath="assets/images/get-in-touch.png" *ngIf="!hasItems && !loading && isPublic">
</app-startup-card>

<div class="row" *ngIf="hasItems && !loading">
  <div class="col-md-12">
    <div id="divGetInTouchCard" class="getInTouch">
      <div class="row">
        <div class="col-md-12">
          <h3 class="title mb-4 roducts sp-service" id="aSmeGetInTouchCardEdit"> {{ "getInTouch.title" | translate }}
            <em class="fas fa-pen" *ngIf="!isPublic" (click)="clearFields();prepareForm();contactModal = true"></em>
            <!-- <span class="addServiceBtn" *ngIf="!isPublic">
              <a id="aSmeGetInTouchCardEdit" (click)="clearFields();prepareForm();contactModal = true"
                href="javascript:void(0);">{{ "getInTouch.edit" | translate }}</a>
            </span> -->
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <ul>
            <li class="fas fa-map-marker-alt" id="lstGetInTouchLocation">
              <a id="aGetInTouchLocation">{{ contact.address }}</a>
            </li>
            <li class="far fa-envelope-open" id="lstGetInTouchMail">
              <a id="aGetInTouchMail" [href]="'mailto:' + contact.email">{{ contact.email }}</a>
            </li>

            <li class="fas fa-comment-alt" id="lstGetInTouchLinkedIn">
              <a id="aGetInTouchLinkedIn" target="_blank" [href]="contact.linkedIn" rel="noopener">{{ contact.linkedIn
                }}</a>
            </li>

            <li class="fas fa-globe" d="lstGetInTouchWebsite">
              <a id="aGetInTouchWebsite" target="_blank" [href]="contact.website" rel="noopener">{{ contact.website
                }}</a>
            </li>

            <li class="fas fa-phone-alt" id="lstGetInTouchTel">
              <a id="aGetInTouchTel" [href]="'tel:' + contact.contactNo">{{ contact.contactNo }}</a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="add-products shareFeed_dialog">
  <p-dialog header="" [(visible)]="contactModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="" [maximizable]="false" [draggable]="false"
    [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
    (onHide)="clearFields();contactModal = false">
    <div class="app-modal-body">
      <h5 class="modal-title text-center" id="ttlContactDetails">{{ "commonForm.mainTitle" | translate }}</h5>
      <form id="frmContactDetail" [formGroup]="myForm" #myNgForm="ngForm">
        <app-contact-form [options]="commonFormData" [(contactForm)]="myForm" [submitted]="submitPressed">
        </app-contact-form>
      </form>
    </div>
    <div class="app-modal-footer text-right mt-5 sticky-btn ">
      <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal"
        (click)="clearFields();contactModal = false" id="btnContactCancel">Cancel</button>
      <button type="button" class="btn btn-confirm" id="btnContactSave" (click)="saveFormContact()">
        <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span class="btn-text">Save</span>
      </button>
    </div>
  </p-dialog>
</div>