<app-language-selector></app-language-selector>
<div class="landing-sign-up" id="divSignUp">
  <div class="container">
    <div class="row signup-cards">
      <ng-container *ngFor="let item of landingSignUp?.data; index as i">
        <div class="col-xl-12 col-lg-12 signup-card-col">
          <div class="signup-card-block">
            <div class="signup-card-content animate__fadeInLeft wow" data-wow-delay="0s">
              <ul class="signup-card">
                <li>
                  <div class="card" id="div{{item.id | translate}}">
                    <div class="headerCard">
                      <h3 id="ttl{{item.id | translate}}">{{item.title | translate}}</h3>
                      <p id="p{{item.id | translate}}">{{item.decp | translate}}</p>
                    </div>
                    <div class="bodyCard" id="list{{item.id | translate}}">
                      <ul class="m-he-414">
                        <li *ngFor="let items of item?.list; index as i">{{items.data | translate}}</li>
                      </ul>
                      <div class="text-left btn-blcok">
                        <button type="button" id="btn{{item.id | translate}}" class="btn btn-confirm"
                          (click)="redirectToSignup(item.type)">{{item.button |
                          translate}}</button>
                      </div>
                      <span class="hover"></span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="signup-card-content">
              <div class="img-block animate__bounceInRight wow" data-wow-delay="0s">
                <img src="assets/images/landing/{{item.img}}" />
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>