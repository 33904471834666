<app-language-selector></app-language-selector>

<app-card id="crdOpportunityItem" [hidHeader]="true" cardClass="user-card-full details-card" blockClass="p-0">
    <div class="row">
        <div class="col-sm-12">
            <div class="company-name">
                <ul>
                    <li><img id="imgOpportunityCompanyName"
                            src="{{SME.logo?SME.logo:'../../../../../assets/images/default.jpg'}}" class="img-fluid img-radius"
                            alt="company"></li>
                    <li>
                        <span>{{ SME.name | translate }}</span>
                        <span>{{ SME.enterpriseType| translate }}</span>
                    </li>
                </ul>
            </div>
            <div>
                <!-- <span>{{ SME.about | translate}}</span> -->
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="btns">
                        <button id="btnIWantInvest" type="button" class="btn btn-outline-secondary"
                            [routerLink]="['/user-browse/details/sme/'+SME.userid]">{{'buttons.IwantInvest' |
                            translate}}</button>
                    </div> -->
                </div>
                <div class="col-md-12">
                    <div class="d-flex justify-content-between key-boxs">
                        <div class="key-box loans">
                            <p>
                            <span> <em class="fas fa-coins mx-2"></em> {{'ESTABLISHED' | translate}}</span>
                            <span>{{ SME.established | translate}}</span></p>
                        </div>
                        <div class="key-box firm">
                            <p>
                            <span><em class="fas fa-coins mx-2"></em> {{'ANNUAL TURN OVER' | translate}}</span>
                            <span>{{ SME.turnover | translate}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="btns">
                            <button id="btnIWantInvest" type="button" class="btn p-0"
                                [routerLink]="['/user-browse/details/sme/'+SME.userid]">{{'buttons.IwantInvest' |
                                translate}} <em class="fas fa-chevron-right mx-2"></em></button>
                        </div> 
                    </div>
                    <!-- <ul class="key-boxs"> -->
                        <!-- <li>
                            <div class="key-box loans">
                                <span> <em class="fas fa-coins"></em> {{'ESTABLISHED' | translate}}</span>
                                <span>{{ SME.established | translate}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="key-box firm">
                                <span><em class="fas fa-coins"></em> {{'ANNUAL TURN OVER' | translate}}</span>
                                <span>{{ SME.turnover | translate}}</span>
                            </div>
                        </li> -->
                        <!-- <li>
                            <div class="btns">
                                <button id="btnIWantInvest" type="button" class="btn"
                                    [routerLink]="['/user-browse/details/sme/'+SME.userid]">{{'buttons.IwantInvest' |
                                    translate}} <em class="fas fa-chevron-right mx-2"></em></button>
                            </div>
                        </li> -->
                    <!-- </ul> -->
                </div>
            </div>
        </div>
    </div>
</app-card>