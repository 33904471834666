import { Component } from '@angular/core';

@Component({
  selector: 'app-opportunities-performance',
  templateUrl: './opportunities-performance.component.html',
  styleUrls: ['./opportunities-performance.component.scss']
})
export class OpportunitiesPerformanceComponent {

}
