<div class="background-img-signin">
  <div class="mcb-container background-style">
    <!-- <app-language-selector></app-language-selector> -->
    <div class="row">
      <div class="col-lg-12">
        <div class='welcome-title'></div>
        <h1>{{'THANK_YOU.HEADER' }}
          <span>{{'THANK_YOU.MSG_1' }}</span>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7 mt-1 mt-md-2">
        <p>{{'THANK_YOU.MSG_2' }}</p>
        <p>
          <span>{{ 'THANK_YOU.REF_1' }}</span>
          &nbsp;<span class="bold" id="refNum">{{ refNumber }}</span>&nbsp;
          <span>{{ 'THANK_YOU.REF_2' }}</span><br>
          <span>{{ 'THANK_YOU.REF_3' }}</span>
        </p>
      </div>
    </div>
    <div *ngIf="!isContactCentre">
      <section class="row align-text-bottom my-1 my-sm-0">
        <div class="col-sm-12 col-md-12 mt-2 mb-2 col-lg-7 col-xl-7 text-left">
          <a id="aThankYou" href="landing">
            <button id="btnThankYouDone" type="button" class="main-button-red no-text-transform col-md-4">{{
              'THANK_YOU.DONE' |
              translate }}
            </button></a>
        </div>
      </section>
    </div>
    <div *ngIf="isContactCentre">
      <section class="row align-text-bottom my-1 my-sm-0">
        <div class="col-sm-12 col-md-12 mt-2 mb-2 col-lg-7 col-xl-7 text-left">
          <button id="btnThankYouDone" type="button" class="main-button-red no-text-transform col-md-4"
            (click)="navToContactCenter()">{{ 'THANK_YOU.BACK' |
            translate }}
          </button>
        </div>
      </section>
    </div>
  </div>
</div>