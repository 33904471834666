import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationItem } from '../navigation';
import { NextConfig } from '../../../../app-config';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { TokenService } from '../../../../core/services/token/token.service';
import { UserAuthService } from '../../../../core/services/auth-service/user-auth.service';
import { ProfileService } from '../../../../core/services/profile-service/profile.service';


@Component({
    selector: 'app-nav-content',
    templateUrl: './nav-content.component.html',
    styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
    public nextConfig: any;
    public navigation: any;
    public prevDisabled: string;
    public nextDisabled: string;
    public contentWidth: number;
    public wrapperWidth: any;
    public scrollWidth: any;
    public windowWidth: number;
    public isNavProfile: boolean;

    @Output() onNavMobCollapse = new EventEmitter();

    @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
    @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

    companyName: string;
    isSME: boolean;
    isServiceProvider: boolean;
    isInvester: boolean;
    isCommunityManager: boolean;

    constructor(
        public nav: NavigationItem,
        private zone: NgZone,
        private location: Location,
        private router: Router,
        private tokenService: TokenService,
        private authService: UserAuthService,
        private profileService: ProfileService
    ) {
        this.nextConfig = NextConfig.config;
        this.windowWidth = window.innerWidth;

        this.navigation = this.nav.get();
        this.prevDisabled = 'disabled';
        this.nextDisabled = '';
        this.scrollWidth = 0;
        this.contentWidth = 0;

        this.isNavProfile = false;
    }

    ngOnInit() {
        this.isSME = this.authService.isSME();
        this.isServiceProvider = this.authService.isServiceProvider();
        this.isInvester = this.authService.isInvester();
        this.isCommunityManager = this.authService.isCommunityManager();

        if (this.windowWidth < 992) {
            this.nextConfig['layout'] = 'vertical';
            setTimeout(() => {
                document.querySelector('.pcoded-navbar').classList.add('menupos-static');
                var el: HTMLElement = document.querySelector('#nav-ps-next');
                el.style.maxHeight = '100%';
            }, 500);
        }

        this.companyName = this.tokenService.getCompanyName();

        const myNavItems = this.nav.get();
        this.navigation = this.parseNavigation(this.tokenService.getRoles(), [...[], ...myNavItems]);
        this.getProfile();
    }

    logoclick() {
        if (this.authService.isCommunityManager()) {
            this.router.navigate(['/feed'], {
            });
        } else {
            this.router.navigate(['/feed'], {
            });
        }
    }

    profile = {};
    getProfile() {
        this.profileService.getOrgDetails().subscribe(
            (data) => {
                this.profile = data ? data : {};
            },
            (error) => {
                this.profile = {};
                // console.log(error);
            }
        );
    }

    ngAfterViewInit() {
        if (this.nextConfig['layout'] === 'horizontal') {
            this.contentWidth = this.navbarContent.nativeElement.clientWidth;
            this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
        }
    }

    scrollPlus() {
        this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
        if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
            this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
            this.nextDisabled = 'disabled';
        }
        this.prevDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }
    }

    scrollMinus() {
        this.scrollWidth = this.scrollWidth - this.wrapperWidth;
        if (this.scrollWidth < 0) {
            this.scrollWidth = 0;
            this.prevDisabled = 'disabled';
        }
        this.nextDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }
    }

    fireLeave() {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('active');
            sections[i].classList.remove('pcoded-trigger');
        }

        let current_url = this.location.path();
        if (this.location['_baseHref']) {
            current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            const up_parent = parent.parentElement.parentElement;
            const last_parent = up_parent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                parent.classList.add('active');
            } else if (up_parent.classList.contains('pcoded-hasmenu')) {
                up_parent.classList.add('active');
            } else if (last_parent.classList.contains('pcoded-hasmenu')) {
                last_parent.classList.add('active');
            }
        }


    }

    navMob() {
        if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
            this.onNavMobCollapse.emit();
        }
    }

    fireOutClick() {
        let current_url = this.location.path();
        if (this.location['_baseHref']) {
            current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            const up_parent = parent.parentElement.parentElement;
            const last_parent = up_parent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig['layout'] === 'vertical') {
                    parent.classList.add('pcoded-trigger');
                }
                parent.classList.add('active');
            } else if (up_parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig['layout'] === 'vertical') {
                    up_parent.classList.add('pcoded-trigger');
                }
                up_parent.classList.add('active');
            } else if (last_parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig['layout'] === 'vertical') {
                    last_parent.classList.add('pcoded-trigger');
                }
                last_parent.classList.add('active');
            }
        }


    }

    parseNavigation(roles: any, navItems: any) {
        let nav = [];
        for (var i in navItems) {
            let navItem = { ...{}, ...navItems[i] };
            const flag = this.checkRole(roles, navItem);
            if (flag) {
                if (navItem.children) {
                    navItem.children = this.parseNavigation(roles, navItem.children);
                }
                nav.push(navItem);
            }
        }
        return nav;
    }

    checkRole(roles: any, navItem: any) {
        let allowed = false;
        for (var i in roles) {
            if (navItem && navItem.allowed_roles && (navItem.allowed_roles.indexOf('*') > -1 || navItem.allowed_roles.indexOf(roles[i]) > -1)) {
                allowed = true;
                break;
            }
        }
        return allowed;
    }

    goToViewPage() {
        let url = '/signin';
        if (this.authService.isServiceProvider()) {
            url = '/service-provider';
        }
        if (this.authService.isInvester()) {
            url = '/investor';
        }
        if (this.authService.isCommunityManager()) {
            url = '/feed';
        }
        if (this.authService.isSME()) {
            url = '/sme-profile';
        }
        if (this.authService.isRelationShipManager()) {
            url = '/feed';
        }
        this.router.navigate([url]);
    }
}
