<p-dialog header="" [(visible)]="firstTimeLoginModal" [modal]="true" [style]="{width: '520px'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="onModelHide($event)"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appFirstTimeLogenModal" [blockScroll]="true" [resizable]="true">
  <p-header class="p-header">
    <div class="app-modal-header">&nbsp;</div>
  </p-header>
  <div class="app-modal-body">
    <div class="text-center px-4 pb-4">
      <div style="width: 240px; min-height: 180px;margin: auto;">
        <img id="imgFirstLogin" src="assets/images/onboarding.png" class="img-fluid banner-img" alt="Welcome" />
      </div>
      <h5 class="pt-3" id="lblBeingCreated">
        <span>Hi {{ userName }}! Welcome to SME LINKER.</span>
      </h5>
      <p class="pb-4 pt-2">
        Before you start enjoying all the benefits of our community, it is important you package your services into
        <strong>Growth Cards</strong>.
        Ready? Let's go to your page and create your first <strong>Growth Card</strong>
      </p>
      <button id="btnFirstLogin" class="btn btn-confirm mb-0" type="button" (click)="viewMyPage()">
        Update my page
      </button>
    </div>
  </div>
</p-dialog>