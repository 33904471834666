import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-search-companies',
  templateUrl: './search-companies.component.html',
  styleUrls: [
    './search-companies.component.scss',
    '../../../assets/landing/css/style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class SearchCompaniesComponent implements OnInit {
  searchItems: any;
  pageSize: number = 9;
  categories: string = "All";
  industryTypes: Array<any> = [];
  selectedFilters: any[] = [];
  @Input() companypageNumber: any;
  @Input() browsecompanycards: any;
  @Input() loading: boolean;
  @Input() totalItems: any;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }

  goToPage(page: number) {
    if (page < 1) {
      page = 1;
    }
    this.changePage.emit(page);
  }
}
