import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-sign-up',
  templateUrl: './landing-sign-up.component.html',
  styleUrls: ['./landing-sign-up.component.scss']
})
export class LandingSignUpComponent implements OnInit {
  @Input() landingSignUp: any;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }
  redirectToSignup(accountType) {
    this.router.navigate(['/account-type'], {
      queryParams: { accountType: accountType }
    });
  }

}
