<div class="row ">
    <div class="col-md-12 col-lg-12">
        <div class="user-details edit">
            <form id="frmMyStory" [formGroup]="personalDetailsForm">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblFirstName">{{ "editProfile.personalDetails.FirstName" | translate }}</label>
                            <input type="text" class="form-control" id="txtFirstName" placeholder="Ex: John"
                                formControlName="firstName" [ngClass]="{'is-invalid':inValid(f.firstName, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.firstName, isSubmit)">
                                <div *ngIf="f.firstName.errors.required" id="err_FirstName">Please enter your first name
                                </div>
                                <div *ngIf="f.firstName.errors.minlength" id="err_minLength_firstName">First Name should
                                    contain at
                                    least 3 characters</div>
                                <div *ngIf="f.firstName.errors.maxlength" id="err_maxLength_firstName">First Name should
                                    not exceed 25
                                    characters</div>
                                <div *ngIf="f.firstName.errors.hasNoSpecialCharacters" id="err_specialChar_firstName">
                                    First Name should
                                    contain alphabetical characters only</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblLastName">{{ "editProfile.personalDetails.LastName" | translate }}</label>
                            <input type="text" class="form-control" id="txtLastName" placeholder="Ex: Doe"
                                formControlName="lastName" [ngClass]="{'is-invalid':inValid(f.lastName, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.lastName, isSubmit)">
                                <div *ngIf="f.lastName.errors.required" id="err_LastName">Please enter your last name
                                </div>
                                <div *ngIf="f.lastName.errors.maxlength" id="err_maxLength_lastName">Last Name should
                                    not exceed 35
                                    characters</div>
                                <div *ngIf="f.lastName.errors.hasNoSpecialCharacters" id="err_specialChar_lastName">Last
                                    Name should
                                    contain alphabetical characters only</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblTitle">{{ "editProfile.personalDetails.Title" | translate }}</label>
                            <input type="text" class="form-control" id="txtTitle" placeholder="Ex: Managing Director"
                                formControlName="title" [ngClass]="{'is-invalid':inValid(f.title, isSubmit)}">
                            <div class="invalid-feedback" *ngIf="inValid(f.title, isSubmit)">
                                <div *ngIf="f.title.errors.required" id="errNoTitle">Please enter your title at the
                                    company.</div>
                                <div *ngIf="f.title.errors.maxlength" id="err_maxLength_title">Title should not
                                    exceed 40 characters</div>
                                <div *ngIf="f.title.errors.hasNoSpecialCharacters" id="err_specialChar_title">Title
                                    should contain alphabetical characters only</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label id="lblEmail">{{ "editProfile.personalDetails.Email" | translate }}</label>
                            <input type="text" class="form-control" id="txtEmail" placeholder="Email"
                                formControlName="email" disabled>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-12 col-lg-12 d-flex justify-content-center text-align-center">
        <input #fileInput type="file" (change)="onFileChanged($event)" class="hidden" accept=".jpeg,.jpg,.png">
        <div class="profile-pic" id="imgEditProfilePic" *ngIf="!cropimage">
            <span *ngIf="!srcData" class="camera-show" (click)="fileInput.click();" id="btnUploadHere"><em
                    class="upload-text">{{
                    "editProfile.personalDetails.UploadHere" |
                    translate }}</em></span>
            <img *ngIf="croppedImageBase64 || srcData"
                [src]="croppedImageBase64 ? croppedImageBase64 : srcData || 'assets/images/profile-picture.png'"
                class="profile-img" alt="feed" (click)="fileInput.click()" id="imgProfilePic" />
        </div>
        <div class='cropimagestyle' *ngIf="cropimage">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
                format="png" (imageCropped)="imageCropped($event)" output="base64"></image-cropper>

            <button class="btn btn-confirm crop" *ngIf="imageCroppedFlag" (click)="onButtonClicked()">Crop
                Image</button>
        </div>
    </div>
</div>