import { Component } from '@angular/core';

@Component({
  selector: 'app-our-story-banner',
  templateUrl: './our-story-banner.component.html',
  styleUrls: ['./our-story-banner.component.scss']
})
export class OurStoryBannerComponent {


}
