<ngb-carousel class="header-carousel">
  <ng-template ngbSlide *ngFor="let item of HeaderCarousel?.landing">
    <div id="home" class="main-banner main-banner-two" style="background-color: #f2f3f5;;">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="row banner-data">
            <div class="col-md-5 col-sm-12 banner-info">
              <div class="center-content">
                <h1 class="banner-header">Click • Connect • Grow</h1>
                <p class="info">Because we care for our local businesses, we've created SME LINKER, a friendly
                  community for entrepreneurs, where you can make meaningful connections and find the inspiration,
                  tools, expertise and financing you need to grow your business.</p>


                <div class="btn-join">
                  <a routerLink="/account-type">Join the Community</a>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12 text-right banner-image mobile-hide">
              <img src="../../../../assets/images/banner-SA.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>