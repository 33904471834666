<app-language-selector></app-language-selector>
<app-card [options]="false" [hidHeader]="true" class="view-details">
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="user-details">
        <div class="row">
          <div class="col-md-12 col-lg-12 p-0">
            <button type="button" class="btn p-0 change-pw" (click)="editDetails();" id="btnChangePassword">
              {{"editProfile.Password.ChangePassword" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card>

<div class="shareFeed_dialog">
  <p-dialog header="" [(visible)]="showpasswordDialog" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
    class="feedFormModal" [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal"
    [blockScroll]="true" [resizable]="true" [closable]="true" (onHide)="editCancel()">

    <div>
      <div class="app-modal-header text-center">
        <h5 class="modal-title" id="ttlShareArticle">
          <span>Update Password</span>
        </h5>
      </div>

      <div class="content-middle">
        <form id="frmNewPassword" autocomplete="on" [formGroup]="passwordForm">
          <label id="lblCurrentPassword" class="pwd-lable">{{ "editProfile.Password.CurrentPassword" | translate
            }}</label>
          <div class="input-group mb-3 pwd-show">
            <input id="txtCurrentPassword" [type]="isTextFieldType ? 'text' : 'password'" class="form-control"
              placeholder="Enter current password" autocomplete="off"
              [ngClass]="{'is-invalid':inValid(f.oldPassword, isSubmit)}" formControlName="oldPassword" />
            <div class="input-group-append">
              <span class="input-group-text" (click)="oldPwrdType()" id="oldPwrdType">
                <em [ngClass]="isTextFieldType ? 'fas fa-eye-slash' : 'fas fa-eye'"></em>
              </span>
            </div>
            <div class="invalid-feedback" *ngIf="inValid(f.oldPassword, isSubmit)">
              <div *ngIf="f.oldPassword.errors.required" id="errNoCurrentPwdEntered">Please enter the current
                password.</div>
            </div>
          </div>
          <label id="lblPassword" class="pwd-lable">{{ "editProfile.Password.NewPassword" | translate }}</label>
          <div class="input-group mb-3 pwd-show">
            <input id="txtPassword" [type]="isReTextFieldType ? 'text' : 'password'" class="form-control"
              placeholder="Enter new password" autocomplete="off"
              [ngClass]="{'is-invalid':inValid(f.newPassword, isSubmit)}" formControlName="newPassword" />
            <div class="input-group-append">
              <span class="input-group-text" (click)="newPwrdType()" id="newPwrdType">
                <em [ngClass]="isReTextFieldType ? 'fas fa-eye-slash' : 'fas fa-eye'"></em>
              </span>
            </div>
            <div class="invalid-feedback" *ngIf="inValid(f.newPassword, isSubmit)">
              <div *ngIf="f.newPassword.errors.required" id="errNoNewPwdEntered">Please enter your new password.</div>
              <div *ngIf="f.newPassword.errors.minlength ||
                f.newPassword.errors.hasNumber ||
                f.newPassword.errors.hasCapitalCase ||
                f.newPassword.errors.hasSmallCase ||
                f.newPassword.errors.hasSpecialCharacters" id="err_Password_criteria_mismatch">Your password must
                contain
                at least:</div>
              <ul>
                <li *ngIf="f.newPassword.errors.minlength" id="err_ValidPasswordMinLimit">8 characters</li>
                <li *ngIf="f.newPassword.errors.hasNumber" id="err_ValidPasswordNumber">1 number</li>
                <li *ngIf="f.newPassword.errors.hasCapitalCase" id="err_ValidPasswordCapital">1 capital letter</li>
                <li *ngIf="f.newPassword.errors.hasSmallCase" id="err_ValidPasswordSmall">1 small letter</li>
                <li *ngIf="f.newPassword.errors.hasSpecialCharacters" id="err_ValidPasswordSpecial">1 special
                  character
                </li>
              </ul>
              <div *ngIf="f.newPassword.errors.maxlength" id="err_ValidPasswordMaxLimit">Should not exceed 25
                characters
              </div>
            </div>
          </div>
          <label id="lblConfirmPassword" class="pwd-lable">{{ "editProfile.Password.ConfirmNewPassword" | translate
            }}</label>
          <div class="input-group mb-3 pwd-show">
            <input id="txtConfirmPassword" [type]="isReTextCnfmType ? 'text' : 'password'" class="form-control"
              placeholder="Confirm new password" autocomplete="off"
              [ngClass]="{'is-invalid':inValid(f.confirmPassword, isSubmit)}" formControlName="confirmPassword" />
            <div class="input-group-append">
              <span class="input-group-text" (click)="newPwrdConfmType()" id="newPwrdConfmType">
                <em [ngClass]="isReTextCnfmType ? 'fas fa-eye-slash' : 'fas fa-eye'"></em>
              </span>
            </div>
            <div class="invalid-feedback" *ngIf="inValid(f.confirmPassword, isSubmit)">
              <div *ngIf="f.confirmPassword.errors.required" id="err_CofirmPassword">Please re-enter your new password
                for confirmation.
              </div>
              <div *ngIf="f.confirmPassword.errors.NoPassswordMatch" id="err_PasswordMatch">Password does not match.
              </div>
            </div>
          </div>
          <div class="text-right mt-4">
            <button type="button" class="btn btn-secondary mr-2" (click)="editCancel();" id="btnCancelNewPassword">{{
              "editProfile.personalDetails.cancel" | translate
              }}</button>
            <button id="btnSavePassword" class="btn btn-confirm mb-0" (click)="savePassword()">
              <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
              <span *ngIf="buttonPressed" class="load-text">{{ "editProfile.personalDetails.loading" | translate
                }}</span>
              <span *ngIf="!buttonPressed" class="btn-text">{{ "editProfile.personalDetails.submit" | translate
                }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </p-dialog>
</div>

<p-toast position="top-center" key="c" [baseZIndex]="5000" id="pToastMsg"></p-toast>