import { Component } from '@angular/core';
import { StorageService } from "../../core/services/storage/storage.service";
import { Router } from "@angular/router";
import { ResetService } from "../../core/services/reset/reset.service";
import { RoutesUrl } from "../../shared/constants/routes-url";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  faqFileName = 'FAQ-MCB COVID19 Emergency Support.pdf';

  constructor(private resetService: ResetService, private storageService: StorageService,
    private router: Router) { }

  reset() {
    this.resetService.reset().subscribe(
      suc => {
        this.storageService.removeFromLocal('CURRENT_LOAN_APPLICATION_DATA_ID');
        this.router.navigate([RoutesUrl.DASHBOARD_URL]);
        window.location.reload();
      },
      err => {
        // console.log(err);
      }
    );
  }

  isBackendWorkflowLoginPage() {
    return this.router.url.endsWith(RoutesUrl.BACKEND_WORKFLOW_SIGN_IN);
  }

}
