<div class="features signUp-block" id="signUp">
  <div class="container">
    <div class="features-content">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 txtm">
          <h1 class="wow fadeInUp" data-wow-delay="2s" id="ttlBoost">
            <span class="sub-txt">{{"accountTypeLanding.title" | translate}}</span>
            <span>{{"accountTypeLanding.title1" | translate}}</span>
            <span class="sub-txt">{{"accountTypeLanding.title2" | translate}}</span>
          </h1>
          <h1 class="wow fadeInUp" data-wow-delay="3s" id="ttlBoostYourBusiness">{{"accountTypeLanding.title3" |
            translate}}</h1>
          <p id="pBoostYourBusiness">{{"accountTypeLanding.desp" | translate}}</p>
          <div class="text-center">
            <button type="button" class="btn btn-confirm" id="btnSignUp"
              routerLink="/account-type">{{"accountTypeLanding.button" |
              translate}}</button>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="signup-box">
            <div class="signup-block text-center">
              <img src="assets/images/landing/lap.png" class="img-block animate__bounceInRight wow" data-wow-delay="0s">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>