import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientInfoService } from 'src/app/core/services/client-info/client-info.service';
import { Observable } from 'rxjs/Observable';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(private clientInfoService: ClientInfoService,
    private http: HttpClient,) { }

  postgroupdetails(groupDetails, groupImage: any) {
    let payload = new FormData();
    groupDetails["ownerId"] = 0;
    payload.append('data', JSON.stringify(groupDetails));
    payload.append('groupImage', groupImage, 'groupImg.png');
    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/addGroups`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getgroupdata() {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getGroups`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getmygroupdata() {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getMyGroups`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  joingroups(groupdetails) {
    let payload = new FormData();
    groupdetails["joinStatus"] = 'join';
    payload.append('data', JSON.stringify(groupdetails));
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/joinGroups`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  leavegroup(groupdetails) {
    let payload = new FormData();
    payload.append('data', JSON.stringify(groupdetails));
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/leaveGroup`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  editgroup(groupId, groupDetails, groupImage) {
    let payload = new FormData();
    payload.append('data', JSON.stringify(groupDetails));
    payload.append('groupImage', groupImage);
    const res: Observable<any> = this.http.put(`${this.BASE_API_URL}api/editGroups/${groupId}`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  deletegroup(groupId) {
    const res: Observable<any> = this.http.delete(`${this.BASE_API_URL}api/deleteGroups/${groupId}`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  requestReceived() {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getReqJoinedGroups`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  requestMade() {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getAllJoinedGroups`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  approverequest(groupdetails) {
    let payload = new FormData();
    groupdetails["approveStatus"] = 'approved';
    payload.append('data', JSON.stringify(groupdetails));
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/approveGroups`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  deleterequest(groupdetails) {
    let payload = new FormData();
    groupdetails["approveStatus"] = 'rejected';
    payload.append('data', JSON.stringify(groupdetails));
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/approveGroups`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getgroupDetails(groupId) {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getGroupDetails/${groupId}`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getgroupbanner(groupId) {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/getGroupBanner/${groupId}`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  updategroupbanner(groupId, payload) {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/updateGroupBanner?groupId=${groupId}`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  deleteuser(groupId, userId) {
    const res: Observable<any> = this.http.delete(`${this.BASE_API_URL}api/deleteGroupMember?groupId=${groupId}&userId=${userId}`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
}
