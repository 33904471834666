<app-language-selector></app-language-selector>
<div class="service-card user-book-service">
  <app-card [id]="componentId" [customHeader]="true" headerClass="border-0 p-0 pb-0" [options]="false"
    cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
    <div class="body-right sme-growth-card">
      <div class="content">
        <div class="contents">
          <h6 class="mb-1 title pointer" *ngIf="serviceToProvide" (click)="onItemClicked()">
            <span>
              {{ serviceToProvide }}
            </span>
          </h6>
          <div class="cover-img-block pointer" (click)="onItemClicked()">
            <img [id]="'img' + componentId" [src]="thumbnail" alt="logo" class="img-fluid" *ngIf="thumbnail">
          </div>


          <div class="d-flex align-items-center company-logo companyblock">
            <img [id]="'imgcompanylogo' + componentId" [src]="logo" alt="logo" class="img-company-logo" *ngIf="logo">
            <h3 class="companyhead m-0 mx-2" *ngIf="company">
              {{company}}
            </h3>
          </div>

          <div class="d-flex card-data mx-2" *ngIf="duration && price">
            <div class="mr-2">
              <p>
                <em class="fas fa-suitcase mr-1"></em>
                <span>{{ getCostRange() }}</span>
                <span *ngIf="showCurrency()"> ZAR</span>
              </p>
            </div>
            <div>
              <p>
                <em class="fas fa-calendar-alt mr-1"></em>
                <span>{{duration}}</span>
                <span> {{durationType}}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="grouplist">
          <li *ngFor="let imageUrl of imageUrls">
            <img class="pointer" [src]="imageUrl?.groupImg" alt="Image" pTooltip="{{imageUrl?.groupName}}"
              tooltipPosition="top" (click)="groupredirection(imageUrl?.groupId)">
          </li>
        </div>

        <div class="d-flex justify-content-between align-items-center bottom-data" *ngIf="!isCommunityManager">
          <div class="company-logo">
            <!-- <a [id]="'btn' + componentId + 'Details'" class="waves-effect waves-light pointer"
              (click)="getMoreDetails(serviceDescriptionUrl)">Download <em class="pi pi-download"></em></a> -->
          </div>
          <div class="pointer" *ngIf="isOwnGrowthCard !== true">
            <!-- <button class="btn btn-primary intrestedbutton" type="button" id="btnBookService" *ngIf="canBook && !isRM"
              (click)="doBooking($event)">
              <em class="fas fa-envelope mx-2"></em>
              <span class="content-h">{{buttons}}</span></button> -->

            <a *ngIf="canBook && !isRM" class="intrested pointer" (click)="doBooking($event)"><em
                class="fas fa-envelope mr-1"></em>{{buttons}}</a>

            <p *ngIf="!canBook && !isRM" class="mt-1 booked"><em class="fas fa-check mr-1"></em>booked</p>
          </div>
        </div>

      </div>
    </div>
  </app-card>
</div>