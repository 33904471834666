<app-language-selector></app-language-selector>
<div class="row">
    <div class="col-md-12">
        <div class="skills">
            <h3 class="title">{{ "skills.title" | translate }}</h3>
            <ul>
                <li>
                    <span class="badge badge-pill badge-light">Primary</span>
                </li>
                <li>
                    <span class="badge badge-pill badge-light">Secondary</span>
                </li>
                <li>
                    <span class="badge badge-pill badge-light">Success</span>
                </li>
            </ul>
        </div>
    </div>
</div>