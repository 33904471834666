import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-browse-filter',
  templateUrl: './browse-filter.component.html',
  styleUrls: ['./browse-filter.component.scss']
})
export class BrowseFilterComponent implements OnInit, OnChanges {
  @Input() filters: any;
  @Input() filterslbl: any;
  @Input() gCardsfiltersLbl: any;
  @Input() preselected: string;
  @Output() changeFilters: EventEmitter<any> = new EventEmitter();

  newFilters: any = [];
  emittedData: any;

  constructor() { }

  ngOnInit(): void {
    this.parseFilters();
  }
  ngOnChanges(): void {
    this.parseFilters();
  }

  parseFilters() {
    if (this.preselected && this.preselected == "All") {
      this.newFilters = [{ label: "All", value: "All", checked: true }];
      if (Array.isArray(this.filters)) {
        for (let filter of this.filters) {
          this.newFilters.push({ label: filter.label, value: filter.value, checked: true })
        }
      }
    } else {
      let selectedCats = Array.isArray(this.emittedData)?this.emittedData:[];
      if(selectedCats.length == 0) {
        selectedCats = this.getSelectedFromPreSelected();
      }
      this.newFilters = [{ label: "All", value: "All", checked: this.preselected ? false : true }];
      if (Array.isArray(this.filters)) {
        for (let filter of this.filters) {
          this.newFilters.push({ label: filter.label, value: filter.value, checked: (this.preselected ? (selectedCats.indexOf(filter.value+"") > -1) : true) })
        }
      }
    }
  }

  getSelectedFromPreSelected() {
    let items = this.preselected?this.preselected.split(","):[];
    let selected = [];
    let chk = "";
    for(let item of items) {
      chk += item;
      const isPresent = this.filters.some((filter) => {
        return filter.value == chk;
      });
      if(isPresent) {
        selected.push(decodeURIComponent(chk));
        chk = "";
      } else {
        chk += ","
      }
    }
    return selected;
  }

  onChkChange(index: any, e: any) {
    let f = this.newFilters[index];
    let emitData = [];
    f.checked = !f.checked;
    if (f.value == "All") {
      for (let filter of this.newFilters) {
        filter.checked = f.checked;
      }
      emitData.push("All");
    } else {
      let chk = true;
      for (let filter of this.newFilters) {
        if (filter.value != "All") {
          if (chk && !filter.checked) {
            chk = false;
          }
          if (filter.checked) {
            emitData.push(filter.value);
          }
        }
      }
      this.newFilters[0].checked = chk;
      if (chk) {
        emitData = ["All"];
      }
    }
    this.emittedData = emitData;
    this.changeFilters.emit(emitData);
  }

}
