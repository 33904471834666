import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Common } from '../../utility/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ServiceCardService } from '../../core/services/service-card-service/service-card.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { CommonService } from '../../services/common/common.service';
import { CmService } from 'src/app/core/services/cm/cm.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { GroupsService } from 'src/app/pages/sme-groups/groups.service';

interface Language {
  name: string;
  code: string
}

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-growthcard-form',
  templateUrl: './growthcard-form.component.html',
  styleUrls: ['./growthcard-form.component.scss'],
  animations: [fadeAnimation]
})
export class GrowthcardFormComponent implements OnInit {
  userId: any;
  isPublic: boolean = false;
  public approveModal: boolean;
  description = Common.discp;
  public keyupLabelOn = false;
  public keydownLabelOn: boolean = false;

  maxAllowedSize = 1 * 1024 * 1024;
  @Input() action: string = "Add";
  @Input() currentGcId: number;
  @Input() gcFormTitle: string;
  @Input() openGCForm: boolean;
  @Output() openGCFormChange = new EventEmitter<boolean>();
  @Output() onGCUPdate: EventEmitter<any> = new EventEmitter<any>();
  serviceForm: FormGroup;
  gcForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  dButtonPressed: boolean;
  loading: boolean;
  isLoggedIn: boolean;
  editMode: boolean;
  isDisable: boolean = false;
  language: Language[] = [];
  imagePath: any;
  previewURL: any;
  approvalStatus: string;
  filteredCountries: any[] = [];
  results: any[] = [];
  selectedGroups: any[] = []
  vitscroll: boolean;
  articleVisibleToPublic: boolean = true;
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;
  @ViewChild('myGCNgForm', { static: true }) myGCNgForm: NgForm;
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
  addServiceModal: boolean;
  deleteServiceModal: boolean;
  @Output("loadLatestServiceList") loadLatestServiceList: EventEmitter<any> = new EventEmitter();

  //gcDescrption:string;
  constructor(
    private serviceCardService: ServiceCardService,
    private authService: UserAuthService,
    private cmService: CmService,
    private messageService: MessageService,
    private utility: UtilityService,
    private commonService: CommonService, private groupService: GroupsService
  ) {
    this.language = [
      { name: 'English', code: 'English' }
    ]

    this.filteredCountries = [];
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.serviceForm = new FormGroup({
      serviceName: new FormControl("", [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(100)]),
      description: new FormControl(this.description, [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(500)
      ]),
      languages: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      costEstimated: new FormGroup({
        minCost: new FormControl('', [Validators.required, Validators.maxLength(8), CustomValidators.patternValidator(Common.costRegEx, { hasOnlyDigits: true })]),
        maxCost: new FormControl('', [Validators.required, Validators.maxLength(8), CustomValidators.patternValidator(Common.costRegEx, { hasOnlyDigits: true })])
      }),
      timeEstimated: new FormGroup({
        minDays: new FormControl('', [Validators.required, Validators.maxLength(2), CustomValidators.patternValidator(Common.timeRegEx, { hasOnlyDigits: true })]),
        maxDays: new FormControl('', [Validators.required, Validators.maxLength(2), CustomValidators.patternValidator(Common.timeRegEx, { hasOnlyDigits: true })]),
        duration: new FormControl('', [Validators.required])
      })
    });
    this.gcForm = new FormGroup({
      serviceContext: new FormControl('', [Validators.required, Validators.maxLength(400)]),
      outcomes: new FormControl('', [Validators.required, Validators.maxLength(400)]),
      workScope: new FormControl('', [Validators.required, Validators.maxLength(400)]),
      exclusions: new FormControl('', [Validators.required, Validators.maxLength(400)]),
      isPublic: new FormControl(''),
      selectedGroups: new FormControl([])
    });



    this.groupService.getmygroupdata().subscribe((data: any) => {
      this.results = data.map(v => {
        return { value: v.name, label: v.name, image: v.image, groupId: v.id };
      });
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('openGCForm' in changes && this.openGCForm) {
      setTimeout(() => {
        this.perfectScroll.directiveRef.update();
        this.perfectScroll.directiveRef.scrollToTop();
      }, 300);
    }
    if ('action' in changes) {
      this.editMode = this.action == "Edit";
    }
    if ('currentGcId' in changes || 'openGCForm' in changes) {
      if (this.currentGcId > 0 && this.openGCForm) {
        this.setGCForEdit(this.currentGcId);
      }
    }
  }
  setGCForEdit(gcId: number) {
    this.serviceCardService.getGCById(gcId).subscribe(
      data => {
        let formValue = {};
        formValue["serviceName"] = data.growthCardTitle;
        formValue["description"] = data.description;
        formValue["languages"] = this.languaguesToDisplay(data.languages);
        let costEstd = data.costEstimate.split("-");
        let timeEstd = data.timeEstimate.split(" ");
        let duration = timeEstd[1];
        timeEstd = timeEstd[0].split("-");
        formValue["costEstimated"] = { "minCost": costEstd[0], "maxCost": costEstd[1] };
        formValue["timeEstimated"] = { "duration": duration, "minDays": timeEstd[0], "maxDays": timeEstd[1] };
        let form2Value = {};
        form2Value["serviceContext"] = data.serviceContext;
        form2Value["outcomes"] = data.outcomes;
        form2Value["workScope"] = data.workScope;
        form2Value["exclusions"] = data.exclusions;
        this.previewURL = data.image;
        this.approvalStatus = data.approvalStatus;
        this.serviceForm.setValue(formValue);
        this.gcForm.setValue(form2Value);
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage("", "Unable to load growth card! Please try after some time.", "error");
      }
    );
  }

  blinkKeyupLabel() {
    this.keyupLabelOn = true;
    setTimeout(() => {
      this.keyupLabelOn = false;
    }, 450);
  }

  blinkKeydownLabel() {
    this.keydownLabelOn = true;
    setTimeout(() => {
      this.keydownLabelOn = false;
    }, 450);
  }

  getTimeEstimate(timeEstimated: any) {
    return timeEstimated.minDays + '-' + timeEstimated.maxDays + ' ' + timeEstimated.duration;
  }
  getCostEstimate(costEstimated: any) {
    return costEstimated.minCost + '-' + costEstimated.maxCost;
  }
  updateGrowthCard(type: any) {
    this.editMode = true;
    this.messageService.add({ key: "c", severity: "success", summary: "Successfully updated the Growthcard" });
    this.saveGrowthCard(type);
  }
  saveGrowthCard(type: any) {
    let msgSummary = (this.editMode ? "Update" : "Create") + " Growth Card";
    this.isSubmit = true;

    if (this.serviceForm.invalid || this.gcForm.invalid || this.buttonPressed) {
      return false;
    }

    if (!this.editMode && (!this.imagePath || (Array.isArray(this.imagePath[0]) && this.imagePath.length == 0))) {
      this.toastMessage(msgSummary, "Please add feature image", "error");
      return false;
    }

    let formData = this.serviceForm.value;
    let gcFormData = this.gcForm.value;
    if (this.validateMaxCostEntered(formData.costEstimated)) {
      this.toastMessage(msgSummary, "Please enter valid cost", "error");
      return false;
    }
    if (this.validateMaxTimeEntered(formData.timeEstimated)) {
      this.toastMessage(msgSummary, "Please enter valid time", "error");
      return false;
    }
    if (type && type == 'for_draft') {
      this.dButtonPressed = true;
    } else {
      this.buttonPressed = true;
    }
    formData.timeEstimate = this.getTimeEstimate(formData.timeEstimated);
    formData.costEstimate = this.getCostEstimate(formData.costEstimated);
    formData.languages = this.languagesToStore(formData.languages);
    formData = { ...formData, ...gcFormData };
    if (formData.duration) delete formData.duration;
    if (formData.timeEstimated) delete formData.timeEstimated;
    if (formData.costEstimated) delete formData.costEstimated;
    if (this.editMode) {
      formData.id = this.currentGcId;
    }
    let action = this.editMode ? "updateGrowthCard" : "saveGrowthCard";
    this.serviceCardService[action](this.getFormData(formData, type)).subscribe(
      data => {
        let successMsg = "Growth card updated successfully";
        if (type && type == 'for_draft') {
          successMsg = "The growth card was successfully saved as draft.";
          this.dButtonPressed = false;
        } else {
          this.buttonPressed = false;
        }
        if (type && type == 'for_approval') {
          successMsg = "The growth card was successfully saved and sent to the CM for approval.";
        }
        if (data.error) {
          this.toastMessage(msgSummary, data.error, "error");
        } else {
          this.toastMessage(msgSummary, successMsg, "success");
          let emitInfo = new Object();
          emitInfo["gcId"] = this.currentGcId;
          emitInfo["action"] = this.editMode ? "update" : "create";
          emitInfo["updatedGC"] = data;
          this.onGCUPdate.emit(emitInfo);
          this.clearFields();
          this.approveModal = false;
          this.loadLatestServiceList.emit();
        }
      },
      error => {
        if (type && type == 'for_draft') {
          this.dButtonPressed = false;
        } else {
          this.buttonPressed = false;
        }
        this.toastMessage(msgSummary, "Unable to save Growth Card! Please try after some time.", "error");
      }
    );
  }

  clearFields() {
    this.imagePath = null;
    this.previewURL = null;
    this.isSubmit = false;
    this.myNgForm.resetForm();
    this.myGCNgForm.resetForm();
    this.openGCForm = false;
    this.openGCFormChange.emit(this.openGCForm);
  }

  get f() { return this.serviceForm.controls; }
  get f2() { return this.gcForm.controls; }

  inValid(control: any, submitted: any) {
    if (!control) {
      return false;
    }
    return control.invalid && (control.dirty || submitted);
  }
  inValidsubForm(subform: any, field: any, submitted: any) {
    if (!subform) { return false; }
    let control = subform.controls ? subform.controls[field] : undefined;
    if (!control) { return false; }
    return control.invalid && (control.dirty || submitted);
  }
  preview(files: any) {
    if (files.length === 0) {
      this.imagePath = null;
      this.previewURL = null;
      return;
    }
    if (files[0].size > this.maxAllowedSize) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }

    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.imagePath = null;
      this.previewURL = null;
      return;
    }

    let reader = new FileReader();
    this.imagePath = [...files];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.previewURL = reader.result;
    }
  }

  getFormData(item: any, type: any) {
    let selArr: any = [];
    const selectGrpArr: any = this.gcForm.controls['selectedGroups'].value;
    selectGrpArr.forEach(id => {
      selArr.push(id.groupId);
    });
    console.log(selArr);
    delete item.selectedGroups;
    item['isPublic'] = this.gcForm.controls['isPublic'].value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(item));
    payload.append('selectedGroups', selArr);
    if (type) {
      payload.append('type', type);
    }
    if (this.imagePath) {
      payload.append('file', this.imagePath[0]);
    }
    return payload;
  }

  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }
  prepareValueToFormat(minTime, maxTime, duration) {
    let value: any;
    let minTime1 = minTime ? minTime : '';
    let maxTime1 = maxTime ? maxTime : '';
    let duration1 = duration ? duration : '';
    if (minTime1 === maxTime1)
      return maxTime1 + ' ' + duration1;
    if (minTime1 != "")
      value = minTime1 + '-' + maxTime1 + ' ' + duration1;
    return value;
  }
  formatTimeEstimatePreview(value) {
    return this.utility.formatTimeEstimate(value);
  }
  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.value)
        control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

  exitGCForm() {
    this.approveModal = false;
    this.openGCForm = false;
    this.clearFields();
  }

  approveOrRejectGC(approveOrReject: string) {
    let detail = "Growth card approved successfully.";
    if ("Rejected" == approveOrReject)
      detail = "Growth card rejected successfully.";
    this.cmService.approveOrRejectGC(this.currentGcId, approveOrReject).subscribe(
      res => {
        this.clearFields();
        let emitInfo = new Object();
        emitInfo["gcId"] = this.currentGcId;
        emitInfo["action"] = approveOrReject;
        this.onGCUPdate.emit(emitInfo);
        this.messageService.add({ key: "c", severity: "success", summary: "Growth Card", detail: detail });
        window.scrollTo(0, 0);
      });
  }
  validateMaxTimeEntered(timeEstimateValue: any) {
    if (timeEstimateValue?.minDays && timeEstimateValue?.maxDays) {
      if (Number(timeEstimateValue.minDays) > Number(timeEstimateValue.maxDays)) {
        this.isDisable = true;
        return true;
      }
      else {
        this.isDisable = false;
      }
    }
    return false;
  }
  validateMaxCostEntered(costEstimateValue: any) {
    if (costEstimateValue?.minCost && costEstimateValue?.maxCost) {
      if (Number(costEstimateValue.minCost) > Number(costEstimateValue.maxCost)) {
        this.isDisable = true;
        return true;
      }
      else {
        this.isDisable = false;
      }
    }
    return false;
  }

  languaguesToDisplay(strLanguages: string) {
    let languages = new Array();
    let langsArr = strLanguages.split(",");
    for (let indx in langsArr) {
      let langObj = new Object();
      langObj["name"] = langsArr[indx];
      langObj["code"] = langsArr[indx];
      languages.push(langObj);
    }
    return languages;
  }

  languagesToStore(languages: any) {
    let strLanguages = "";
    for (let indx in languages) {
      strLanguages += languages[indx]["name"] + ",";
    }
    strLanguages = strLanguages.substring(0, strLanguages.length - 1);
    return strLanguages;
  }

  cancledialog() {
    this.approveModal = true;
  }


  onToggleChange(event: any) {
    const switchValue = event.checked;
    console.log('Switch value changed:', switchValue);
  }

  filterCountry(event: any) {
    const query = event.query.toLowerCase();
    this.filteredCountries = this.results.filter(option =>
      option.value.toLowerCase().includes(query)
    );
    this.vitscroll = true;
  }

}
