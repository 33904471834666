import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FieldMetaData, FieldSourceModel, SearchableDropdownFieldMetaData } from '../../../core/models/field-meta-data';
import { FormControl } from '@angular/forms';
import { PreferredBU } from '../../../core/models/preferred-bu-model';

import { SelectOptionService } from '../select/select-option.service';
import { IOption } from 'ng-select';
import { trigger, transition, style, animate } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);


@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  animations: [fadeAnimation]
})
export class SearchFilterComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  show = true;
  editfilters = false;
  filterkeys = true;
  formFields: FieldMetaData[] = [];
  turnOverOptions: Array<IOption> = this.selectOptionService.getTurnOverOptions();
  industryTypes: Array<any> = [];
  incorporatedOptions: Array<IOption> = this.selectOptionService.getIncorporatedOptions();
  cities: any[];

  turnover: any;
  industry: any;
  incorporateDate: any;

  autocompleteItemsAsObjects = [
    { value: 'Alabama', id: 0 },
    { value: 'Wyoming', id: 1 },
    { value: 'Coming', id: 2 },
    { value: 'Josephin Doe', id: 3 },
    { value: 'Henry Die', id: 4 },
    { value: 'Lary Doe', id: 5 },
    { value: 'Alice', id: 6 },
    { value: 'Alia', id: 7 },
    { value: 'Suzen', id: 8 },
    { value: 'Michael Scofield', id: 9 },
    { value: 'Irina Shayk', id: 10 },
    { value: 'Sara Tancredi', id: 11 },
    { value: 'Daizy Mendize', id: 12 },
    { value: 'Loren Scofield', id: 13 },
    { value: 'Shayk', id: 14 },
    { value: 'Sara', id: 15 },
    { value: 'Doe', id: 16 },
    { value: 'Lary', id: 17 },
    { value: 'Roni Sommerfield', id: 18 },
    { value: 'Mickey Amavisca', id: 19 },
    { value: 'Dorethea Hennigan', id: 20 },
    { value: 'Marisha Haughey', id: 21 },
    { value: 'Justin Czajkowski', id: 22 },
    { value: 'Reyes Hodges', id: 23 },
    { value: 'Vicky Hadley', id: 24 },
    { value: 'Lezlie Baumert', id: 25 },
    { value: 'Otha Vanorden', id: 26 },
    { value: 'Glayds Inabinet', id: 27 },
    { value: 'Hang Owsley', id: 28 },
    { value: 'Carlotta Buttner', id: 29 },
    { value: 'Randa Vanloan', id: 30 },
    { value: 'Elana Fulk', id: 31 },
    { value: 'Amos Spearman', id: 32 },
    { value: 'Samon', id: 33 },
    { value: 'John Doe', id: 34 }
  ];

  constructor(public selectOptionService: SelectOptionService) {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }

  ngOnInit(): void {
    this.selectOptionService.getIndustryTypes().subscribe((data:any)=>{
      this.industryTypes = data.map(v=>{
        return {value:v.industryName,label:v.industryName};
      });
    });
    this.buildFields();
  }
  buildFields() {
    var items = [];
    for (var i = 0; i < 5; i++) {
      var bu = new PreferredBU();
      bu.buName = "Name " + i;
      bu.buCode = i.toString();
      bu.buBranch = "Branch" + i;
      items.push(bu);
    }

    var searchList = new SearchableDropdownFieldMetaData(
      new FormControl(''),
      'PreferredBranch',
      'IDENTIFICATION.PREFERRED_BRANCH',
      'preferredBranch',
      items,
      false,
      FieldSourceModel.APPLICATION_DETAIL,
      'buName',
      'buCode'
    );

    this.formFields.push(searchList);

  }
  filterKeys() {
    this.editfilters = false;
    this.filterkeys = true;
  }
  editFilters() {
    this.editfilters = true;
    this.filterkeys = false;
  }

  // turnover = "";
  //industry = "";
  //incorporateDate = "";
  currentFilters = { turnover: "", industry: "", incorporateDate: "" };
  selectOptionChanged() {
    var newfilters = { turnover: this.turnover, industry: this.industry, incorporateDate: this.incorporateDate };
    var changeFlag = false;
    for (var key in this.currentFilters) {
      if (this.currentFilters[key] != newfilters[key]) {
        changeFlag = true;
      }
    }
    if (changeFlag) {
      this.currentFilters = newfilters;
      this.change.emit(this.currentFilters);
    }
  }

}
