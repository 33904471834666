<div class="shareFeed_dialog">
    <p-dialog header="" [(visible)]="openGCForm" [modal]="true" [baseZIndex]="10000" [draggable]="false"
        [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
        class="feedFormModal" [maximizable]="false" [draggable]="false" [resizable]="false"
        id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true" [closable]="true"
        (onHide)="cancledialog()">

        <div class="content-middle app-modal-header">
            <h5 class="modal-title text-center">{{gcFormTitle}}</h5>
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <form id="frmSmeAddService" [formGroup]="serviceForm" #myNgForm="ngForm">
                        <div class="form-group">
                            <label id="lblBusinessSelect">Growth Card Title</label>
                            <input id="dpBusinessselect" class="form-control" formControlName="serviceName"
                                [ngClass]="{'is-invalid':inValid(f.serviceName, isSubmit)}"
                                placeholder="Ex: “Bring your business Online”" />
                            <div class="invalid-feedback" *ngIf="inValid(f.serviceName, isSubmit)">
                                <div *ngIf="f.serviceName.errors.required" id="err_Name">Please enter the growth card
                                    title
                                </div>
                                <div *ngIf="f.serviceName.errors.minlength" id="err_minLength_serviceName">Should be
                                    minimum
                                    3 characters</div>
                                <div *ngIf="f.serviceName.errors.maxlength" id="err_maxLength_serviceName">Should not
                                    exceed
                                    100 characters</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label id="lblDescription">Short Description</label>
                            <textarea id="texDescription" class="form-control" formControlName="description"
                                placeholder="Ex: “Increase your online visibility & sales by showcasing your products with a modern and mobile friendly website”"
                                [ngClass]="{'is-invalid':inValid(f.description, isSubmit)}" rows="3"
                                name="description"></textarea>
                            <div class="invalid-feedback" *ngIf="inValid(f.description, isSubmit)">
                                <div *ngIf="f.description.errors.required" id="err_Description">Please enter description
                                </div>
                                <div *ngIf="f.description.errors.minlength" id="err_minLength_description">Should be
                                    minimum
                                    3 characters
                                </div>
                                <div *ngIf="f.description.errors.maxlength" id="err_maxLength_description">Should not
                                    exceed
                                    500 characters
                                </div>
                                <div *ngIf="f.description.errors.hasNoSpecialCharacters"
                                    id="err_specialChar_description">
                                    Must not contain any
                                    Special Character</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <label id="lblCategory">Language of provision of the Service</label>
                                </div>
                                <div class="col-md-12">
                                    <span class="p-fluid">
                                        <p-multiSelect class="" [options]="language" formControlName="languages"
                                            defaultLabel="Select a Language" optionLabel="name" display="chip"
                                            [ngClass]="{'is-invalid':inValid(f.languages, isSubmit)}"></p-multiSelect>
                                    </span>
                                    <div class="invalid-feedback display-block" *ngIf="inValid(f.languages, isSubmit)">
                                        <div *ngIf="f.languages.errors.required" id="err_Category">Please select
                                            Language</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" formGroupName="timeEstimated">
                            <label id="lblSliderLabel1" class="mb-3">Duration:</label>
                            <div class="mb-3">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="radioDays" formControlName="duration" name="duration"
                                        value="Days" class="custom-control-input"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.timeEstimated,'duration', isSubmit)}">
                                    <label class="custom-control-label infoicon" for="radioDays">Days</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="radioWeeks" formControlName="duration" name="duration"
                                        value="Weeks" class="custom-control-input"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.timeEstimated,'duration', isSubmit)}">
                                    <label class="custom-control-label infoicon" for="radioWeeks">Weeks</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="radioMonths" formControlName="duration" name="duration"
                                        value="Months" class="custom-control-input"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.timeEstimated,'duration', isSubmit)}">
                                    <label class="custom-control-label infoicon" for="radioMonths">Months</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-3">
                                    <input id="minDays" class="form-control" formControlName="minDays"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.timeEstimated,'minDays', isSubmit)}"
                                        placeholder="Minimum" maxlength="2" />
                                </div>
                                <div class="col-lg-6 col-md-3">
                                    <input id="maxDays" class="form-control" formControlName="maxDays"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.timeEstimated,'maxDays', isSubmit)}"
                                        placeholder="Maximum" maxlength="2" />
                                    <div class="invalid-feedback display-block">
                                        <div *ngIf="validateMaxTimeEntered(f.timeEstimated.value)" id="err_Category">
                                            Can't be less than minimum time</div>
                                    </div>
                                </div>
                                <div class="col-lg-12"
                                    *ngIf="inValidsubForm(f.timeEstimated,'minDays', isSubmit) || inValidsubForm(f.timeEstimated,'maxDays', isSubmit)">
                                    <div class="invalid-feedback display-block">
                                        <div id="err_timeEstimate">Please enter duration, only 2 digits allowed.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" formGroupName="costEstimated">
                            <label id="lblSliderLabel1" class="mb-3">Cost in ZAR:</label>
                            <div class="row">
                                <div class="col-lg-6 col-md-3">
                                    <input id="minCost" class="form-control" formControlName="minCost"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.costEstimated,'minCost', isSubmit)}"
                                        placeholder="Minimum" maxlength="8" />
                                </div>
                                <div class="col-lg-6 col-md-3">
                                    <input id="maxCost" class="form-control" formControlName="maxCost"
                                        [ngClass]="{'is-invalid':inValidsubForm(f.costEstimated,'maxCost', isSubmit)}"
                                        placeholder="Maximum" maxlength="8" />
                                    <div class="invalid-feedback display-block">
                                        <div *ngIf="validateMaxCostEntered(f.costEstimated.value)" id="err_Category">
                                            Can't be less than minimum cost</div>
                                    </div>
                                </div>
                                <div class="col-lg-12"
                                    *ngIf="inValidsubForm(f.costEstimated,'minCost', isSubmit) || inValidsubForm(f.costEstimated,'maxCost', isSubmit)">
                                    <div class="invalid-feedback display-block">
                                        <div id="err_costEstimated">Please enter cost, only 8 digits allowed.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-12 col-lg-12">
                    <div class="card-preview gc-pre">
                        <app-services-card [serviceToProvide]="f.serviceName?.value"
                            [description]="f.description?.value" componentId="SmeAddServiceCard"
                            [thumbnail]="previewURL"
                            [cost]="f.costEstimated?.value?.minCost+ ' - ' + f.costEstimated?.value?.maxCost"
                            [durationType]="f.duration?.value"
                            [duration]="prepareValueToFormat(f.timeEstimated?.value?.minDays,f.timeEstimated?.value?.maxDays,f.timeEstimated?.value?.duration)"
                            (previewImage)="preview($event)"></app-services-card>
                        <div class="preview-body">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <form id="frmgcFormId" [formGroup]="gcForm" #myGCNgForm="ngForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Context of the Service</label>
                                    <textarea class="form-control" formControlName="serviceContext"
                                        placeholder="Explain in a few words, the context in wchich your Growth Card will be relevant in helping an Entrepreneur grow."
                                        [ngClass]="{'is-invalid':inValid(f2.serviceContext, isSubmit)}" rows="3"
                                        name="serviceContext">
                            </textarea>
                                    <div class="invalid-feedback" *ngIf="inValid(f2.serviceContext, isSubmit)">
                                        <div *ngIf="f2.serviceContext.errors.required" id="err_serviceContext">Please
                                            enter Context of the service
                                        </div>
                                        <div *ngIf="f2.serviceContext.errors.maxlength"
                                            id="err_maxLength_serviceContext">
                                            Should not
                                            exceed
                                            400 characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Outcomes</label>
                                    <textarea class="form-control" formControlName="outcomes"
                                        placeholder="Explain in a few words the outcomes than an Entrepreneur can expect from this Growth Card (what value she will get)"
                                        [ngClass]="{'is-invalid':inValid(f2.outcomes, isSubmit)}" rows="3"
                                        name="outcomes">
                            </textarea>
                                    <div class="invalid-feedback" *ngIf="inValid(f2.outcomes, isSubmit)">
                                        <div *ngIf="f2.outcomes.errors.required" id="err_outcomes">Please
                                            enter Outcomes
                                        </div>
                                        <div *ngIf="f2.outcomes.errors.maxlength" id="err_maxLength_outcomes">Should not
                                            exceed
                                            400 characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Scope of Work</label>
                                    <textarea class="form-control" formControlName="workScope"
                                        placeholder="Provide details of what services will be included in the Growth Card"
                                        [ngClass]="{'is-invalid':inValid(f2.workScope, isSubmit)}" rows="3"
                                        name="workScope">
                                </textarea>
                                    <div class="invalid-feedback" *ngIf="inValid(f2.workScope, isSubmit)">
                                        <div *ngIf="f2.workScope.errors.required" id="err_workScope">Please
                                            enter Scope of work
                                        </div>
                                        <div *ngIf="f2.workScope.errors.maxlength" id="err_maxLength_workScope">Should
                                            not
                                            exceed
                                            400 characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Exclusions</label>
                                    <textarea class="form-control" formControlName="exclusions"
                                        placeholder="Provide details of what services will NOT Be included in the Growth Card"
                                        [ngClass]="{'is-invalid':inValid(f2.exclusions, isSubmit)}" rows="3"
                                        name="exclusions">
                                </textarea>
                                    <div class="invalid-feedback" *ngIf="inValid(f2.exclusions, isSubmit)">
                                        <div *ngIf="f2.exclusions.errors.required" id="err_exclusions">Please
                                            enter Exclusions
                                        </div>
                                        <div *ngIf="f2.exclusions.errors.maxlength" id="err_maxLength_exclusions">Should
                                            not
                                            exceed
                                            400 characters
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Article Visible to Public</label> <br>
                                    <label class="toggle-switch pointer">
                                        <p-inputSwitch [(ngModel)]="articleVisibleToPublic"
                                            (onChange)="onToggleChange($event)" formControlName="isPublic" onLabel="On"
                                            offLabel="Off">
                                        </p-inputSwitch>
                                    </label>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="!articleVisibleToPublic">
                                <div class="form-group">
                                    <label for="">Select groups to visible</label>
                                    <span class="p-fluid">
                                        <p-autoComplete [suggestions]="filteredCountries"
                                            (completeMethod)="filterCountry($event)" [multiple]="true"
                                            placeholder="Select Options" [maxlength]=5 [dropdown]="true" field="value"
                                            formControlName="selectedGroups">
                                            <ng-template let-result pTemplate="item">
                                                <div class="autocomplete-item" *ngIf="!isLoading">
                                                    <img [src]="result?.image" alt="Image" class="result-image">
                                                    <span>{{ result?.label }}</span>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="app-modal-footer text-right">
            <button id="btnSaveGCasDraft" type="button" class="btn btn-secondary mr-3" [disabled]="isDisable"
                (click)="saveGrowthCard('for_draft');"
                *ngIf="!approvalStatus || (approvalStatus && approvalStatus == 'Draft')">
                <span *ngIf="dButtonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
                <span class="btn-text">Save</span>
            </button>
            <button id="btnSmeAddServiceSave" type="button" class="btn btn-confirm" [disabled]="isDisable"
                (click)="saveGrowthCard('for_approval');"
                *ngIf="!approvalStatus || (approvalStatus && approvalStatus == 'Draft')">
                <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
                <span class="btn-text">Submit for Approval</span>
            </button>
            <button type="button" class="btn btn-secondary mr-3" (click)="updateGrowthCard('')"
                *ngIf="editMode && approvalStatus && ( approvalStatus == 'Approved' || approvalStatus == 'New' || approvalStatus =='Rejected')">Update</button>
        </div>
    </p-dialog>
</div>

<p-dialog header="" [(visible)]="approveModal" [modal]="true" [baseZIndex]="10099" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
    class="feedFormModal product-card-modal" [maximizable]="false" [draggable]="false" [resizable]="false"
    id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true" [focusOnShow]="false"
    [style]="{width: '30vw'}">
    <div class="app-modal-body">
        <h6>Would you like to save changes before exiting?</h6>
        <p>&nbsp;</p>


        <div class="text-right pb-2">
            <button id="btnGCFormExitNo" type="button" class="btn btn-secondary mr-3"
                (click)="exitGCForm();">No</button>
            <button id="btnGCFormExitYes" type="button" class="btn btn-confirm"
                (click)="approveModal = false;saveGrowthCard('for_draft');">Yes</button>
        </div>
    </div>
</p-dialog>