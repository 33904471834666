<app-startup-card cardTitle="Mission & Vision" [subTitle]="'What is ' + companyName + ' Mission & Vision?'"
  description="In a few lines, tell us what makes your company unique, and how you can help your audience."
  componentId="SmeAboutCard" iconPath="assets/images/icon@2x.png" btnLabel="Describe your company"
  (btnClick)="aboutModal = true" *ngIf="!hasItems && !loading"></app-startup-card>

<div class="row" *ngIf="hasItems && !loading">
  <div class="col-md-12">
    <div id="divSmeAboutCard" class="about">
      <h3 id="ttlSmeAboutCard" class="title mb-4 roducts sp-service">{{ "about.title" | translate }}
        <span class="addServiceBtn">
          <a id="aSmeAboutEdit" (click)="prepareForm();aboutModal = true" href="javascript:void(0);">Edit</a>
        </span>
      </h3>
      <p class="discp" [innerHTML]="about">
      </p>
    </div>
  </div>
</div>


<p-dialog header="" [(visible)]="aboutModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
  <p-header class="p-header">
    <div class="app-modal-header">
      <h5 class="modal-title" id="ttlAbout">{{ "aboutForm.mainTitle" | translate }}</h5>
    </div>
  </p-header>
  <div class="app-modal-body">
    <form id="frmAbout" [formGroup]="myForm" #myNgForm="ngForm">
      <app-about-form [options]="aboutFormData" [(aboutForm)]="myForm"></app-about-form>
    </form>
  </div>
  <div class="app-modal-footer text-right">
    <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal"
      (click)="clearAboutForm();aboutModal = false" id="btnAboutCancel">Cancel</button>
    <button type="button" class="btn btn-confirm" id="btnAboutSave" (click)="saveFormAbout()">
      <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
      <span class="btn-text">Save</span>
    </button>
  </div>
</p-dialog>