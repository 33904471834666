import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { Observable } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  updateEvent = new EventEmitter<any>();
  updateConatact = new EventEmitter<any>();
  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  profilePic: any;
  companyLogo: any;
  userPosition: any;
  userName: any;
  companyName: any;
  address: any;
  contactNo: any;
  linkedIn: any;
  website: any;
  email: any;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) { }

  getProfile(userId?: any) {
    let url = `${this.BASE_API_URL}api/user/getHeaderBanner`;
    if (userId) {
      url += '/' + userId;
    }
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
            this.address = appRef.address;
            this.contactNo = appRef.contactNo;
            this.email = appRef.email;
            this.linkedIn = appRef.linkedIn;
            this.website = appRef.website;
            this.updateConatact.emit();
          }
        )
      );
  }

  getProfileDetails(userId?: any) {
    let url = `${this.BASE_API_URL}api/user/getProfileDetails`;
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
            this.profilePic = appRef.image;
            this.userPosition = appRef.title;
            this.userName = appRef.firstName + ' ' + appRef.lastName;
            this.updateEvent.emit();
          }
        )
      );
  }
  orgDetails: any;
  getOrgDetails(userId?: any) {
    let url = `${this.BASE_API_URL}api/user/getOrgDetails`;
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
            this.orgDetails = appRef;
            this.companyName = appRef.companyName;
            this.companyLogo = appRef.logo;
            this.updateEvent.emit();
          }
        )
      );
  }
  getPasswordLastUpdated(userId?: any) {
    let url = `${this.BASE_API_URL}api/user/getPasswordLastUpdated`;
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getOrgAbout(user_id) {
    return this.http.get<any>(`${this.BASE_API_URL}api/user/getOrgDetails` + (user_id ? ('?userId=' + user_id) : ''),
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  getAbout(user_id) {
    return this.http.get<any>(`${this.BASE_API_URL}api/user/getAbout` + (user_id ? ('?userId=' + user_id) : ''),
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  saveAbout(about: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/user/addAbout`, about,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  addFollow(follow: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/user/addFollower`, follow,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  isFollowing(userId: any) {
    return this.http.get<any>(`${this.BASE_API_URL}api/user/isFollowing?userId=` + userId,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getFollowers(pageSize, pageNumber, sortColumn, sortOrder): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber)
      .set('sortBy', sortColumn)
      .set('sortOrder', sortOrder);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/user/getFollowers`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getFollowees(pageSize, pageNumber, sortColumn, sortOrder): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber)
      .set('sortBy', sortColumn)
      .set('sortOrder', sortOrder);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/user/getFollowees`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  unFollow(followeeId): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/user/unFollow?followeeId=` + followeeId)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getBannerUserId(userId): Observable<any> {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/user/getBanner/` + userId)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  updateBanner(userId, payload): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/user/updateBanner?userId=${userId}`, payload)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getUserStory(userId?: any) {
    let url = `${this.BASE_API_URL}api/user/getUserStory`;
    if (userId) {
      url += '/' + userId;
    }
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  saveUserStory(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/user/addStory`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getSmeStats(filter: string) {
    const params = new HttpParams().set('filter', filter);
    return this.http.get<any>(`${this.BASE_API_URL}api/sme/getSmeStats`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getContactDetails(user_id) {
    return this.http.get<any>(`${this.BASE_API_URL}api/contact-detail/getContactDetails` + (user_id ? ('?userId=' + user_id) : ''),
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => {
            this.address = appRef.address;
            this.contactNo = appRef.contactNo;
            this.email = appRef.email;
            this.linkedIn = appRef.linkedIn;
            this.website = appRef.website;
            this.updateConatact.emit();
          }
        )
      );
  }

  saveContactDetails(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/contact-detail`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }

  savePersonalDetails(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/user/updateProfile`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  saveOrglDetails(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/user/updateOrgDetails`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  updateBRN(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/user/updateBRN`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  isBRNExist() {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/user/isBRNExist`)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }

  // updateBanner(data: any) {
  //   const res: Observable<any> = this.http
  //     .post(`${this.BASE_API_URL}api/ekyb/verify-company`, data)
  //     .pipe(tap((appRef: any) => {

  //     }))
  //   return res;
  // }

  //eKYB
  companyVerification(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/ekyb/verify-company`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }

  verifiedCompany(data: any) {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/user/verifiedCompanyStatus`, data)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }

}
