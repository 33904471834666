import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { tap} from "rxjs/operators";

import {TokenService} from "../token/token.service";
import {ClientInfoService} from "../client-info/client-info.service";


@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient, 
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) {}

  getTeamMembers(page: number, size: number, searchText: string, sortAsc: string, sortDesc: string) {
    const params = new HttpParams()
                  .set('searchText', searchText)
                  .set('sortAsc', sortAsc)
                  .set('sortDesc', sortDesc)
                  .set('page', page.toString())
                  .set('size', size.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/team-member`, 
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
    .pipe(
      tap(
        (appRef: any) => { }
      )
    );
  }
  getLatestMemberCards(user_id) {

    return this.http.get<any>(`${this.BASE_API_URL}api/team-member/getTeamMemberList` + (user_id ? ('?userId=' + user_id) : ''),
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );

  }


  saveTeamMember(teamMember: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/team-member`, teamMember, {
      headers: this.tokenService.getMultiPartHeader()
    })
    .pipe(
      tap(
        (appRef: any) => { }
      )
    );
  }
  
  deleteTeamMember(teamMemberId: any) {
    return this.http.delete<any>(`${this.BASE_API_URL}api/team-member?teamMemberId=`+teamMemberId, {
      headers: this.tokenService.getMultiPartHeader()
    })
    .pipe(
      tap(
        (appRef: any) => { }
      )
    );
  }
}
