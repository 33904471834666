import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ErrorModel} from "../../core/models/error";
import {BroadcastService} from "../../core/services/broadcast/broadcast.service";
import {TokenService} from "../../core/services/token/token.service";
import {HttpErrorCategorizeService} from "../../core/services/http-error-categorize/http-error-categorize.service";


@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss']
})
export class HttpErrorComponent implements OnInit {
  ADMIN_LOGIN_ROUTE = '/backend-workflow';
  EFORM_ROUTE = '/';
  CUSTOMER_LOGIN_ROUTE = '/signin';
  errorModel: ErrorModel;
  MESSAGE_SELECTOR = 'HTTP_ERROR_MESSAGES'; // text selector from i18 .json
  errorMessage: string = null;
  constructor(
    public broadcastService: BroadcastService,
    private tokenService: TokenService,
    private router: Router,
    private httpErrorCategorise: HttpErrorCategorizeService
  ) {
    this.errorModel = new ErrorModel();
  }

  ngOnInit() {
    this.broadcastService.httpError.asObservable().subscribe(values => {
      this.errorModel = values;
      this.errorMessage = `${this.MESSAGE_SELECTOR}.${this.errorModel.items.selector}`;
    });
  }

  public checkAndRedirectUer() {
    const userType = this.tokenService.getUserType();
    this.router.navigate([this.getLoginRoute(userType)]);
  }

  private getLoginRoute(userType: string) {
    if (userType === 'admin') {
      return this.ADMIN_LOGIN_ROUTE;
    } else if(userType === 'anonymous') {
      return this.EFORM_ROUTE;
    } else {
      return this.CUSTOMER_LOGIN_ROUTE;
    }
  }

  hideModal() {
    this.errorModel.hasError = false;
  }
  handleActionButton(type: string) {
    switch (type) {
      case 'navigate':
        this.hideModal();
        this.checkAndRedirectUer();
        break;
      case 'close':
      default:
        this.hideModal();
        break;
    }
  }
}
