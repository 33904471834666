<app-language-selector></app-language-selector>
<div id="divSmeHeaderDetails" class="sp-header-banner sme-header-banner">

  <div class="company-banner">
    <div class="banner-img">
      <img *ngIf="getbannerimg"
        src="{{getbannerimg?.success ? getbannerimg?.success : './assets/images/sme-banner.jpg'}}" alt="">
      <span class="upload-img">
        <em *ngIf="!isPublic" class="fas fa-camera pointer" (click)="bannerupdating = true"></em>
      </span>
    </div>
  </div>

  <div class="header-container">
    <div class="d-flex">
      <div class="text-align-center">
        <div class="company-page-logo">
          <img *ngIf="profile.logo" src="{{profile.logo}}" class="img-fluid company-logo-img" alt="alt" />
          <div class="company-logo" *ngIf="!profile.logo">
            <span class="logo-txt">{{ profile?.name?.trim().slice(0,1)}}</span>
          </div>
        </div>
      </div>
      <div class="">
        <div class="content follow-clount">
          <h2 class="title ml-2">{{ profile.name?profile.name:"" }}
            <img *ngIf="profile?.verifiedCompany == true" src="../../../assets/images/auth-company-badge.svg" alt="">
          </h2>
          <div class="d-flex viewdetails pointer ml-2">
            <p class="follow-info" *ngIf="!isPublic" (click)="followersModal = true">
              <i class="fas fa-users"></i>
              {{ profile?.followers === 0 ? 'No Members following' : (profile?.followers
              === 1 ? profile?.followers + ' Member following' : profile?.followers + '
              Members following') }}
            </p>
            <p id="aFollow" (click)="followMe()" *ngIf="!isUserFollowing && canFollow && isPublic">Follow
              <span>+</span>
            </p>
            <p id="aUnFollow" (click)="unFollowMe()" *ngIf="isUserFollowing && canFollow && isPublic">Unfollow</p>
          </div>

          <ng-template #popContent>
            <ul *ngIf="followersList.length==0">
              <li *ngIf="followersList == 0">0 Followers</li>
            </ul>
            <ul *ngIf="followersList.length>0">
              <li *ngFor="let item of followersList">{{item.companyName}}</li>
              <li *ngIf="followersList.length<totalFollowers">+{{totalFollowers-followersList.length}} more</li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>


    <div class="aboutdata">
      <div *ngIf="viewproduct">
        <p class="discp">
          {{ about }}
        </p>
      </div>
      <br>
      <div *ngIf="viewproduct && profile?.healthChecks != ''">
        <h6>{{ "dashboardBaner.smeBaner.badges" | translate }}</h6>
        <p class="discp">
          <span pTooltip="Business Reslience Health Check" tooltipPosition="bottom"
            *ngIf="healthCheckStatus[0]?.enabled"><img src="../../../assets/images/businessreslience.svg" alt=""></span>
          <span pTooltip="Investment Readiness Health Check" tooltipPosition="bottom"
            *ngIf="healthCheckStatus[1]?.enabled"><img src="../../../assets/images/investmentreadiness.svg"
              alt=""></span>
          <span pTooltip="Lokal Beautiful Health Check" tooltipPosition="bottom"
            *ngIf="healthCheckStatus[2]?.enabled"><img src="../../../assets/images/lokalbeautiful.svg" alt=""></span>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="shareFeed_dialog">
  <p-dialog header="" [(visible)]="followersModal" [modal]="true" [style]="{ 'width': isPublic ? '60vw' : '40vw' }"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
    styleClass="followersModal">
    <div class="app-modal-body">
      <app-follow-content *ngIf="!isPublic"></app-follow-content>
    </div>
  </p-dialog>
</div>


<p-dialog header="" [(visible)]="bannerupdating" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
  styleClass="followersModal">
  <div class="app-modal-body banner-upload">

    <input type="file" #fileInput (change)="handleFileInput($event)">

    <div class="pointer text-center upload-area" (click)="fileInput.click()">
      <span *ngIf="!uploadedImage">
        <em class="fas fa-arrow-circle-up"></em>
        <p>Upload your banner image</p>
      </span>
      <img *ngIf="uploadedImage" [src]="uploadedImage" alt="Uploaded Image">
    </div>


    <div class="text-center mt-3">
      <button type="button" class="btn btn-primary comp-btn" (click)="submit(fileInput)">upload</button>
    </div>

  </div>
</p-dialog>

<p-toast key="app" position="top-center" [baseZIndex]="9999999" id="pAppToastMsg"></p-toast>