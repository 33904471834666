<aw-wizard #wizard id="aw-wizard" class="arc-wizard" navBarLocation="left">
    <aw-wizard-step stepTitle="Basic Info">
        <app-about-your-business></app-about-your-business>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Area of expertise">
        <app-your-area-expertise></app-your-area-expertise>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Skills / services">
        <app-your-specialisation></app-your-specialisation>
    </aw-wizard-step>
</aw-wizard>