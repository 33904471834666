<app-language-selector></app-language-selector>
<div class="spinner-border text-center mt-5" role="status" *ngIf="loading">
  <span class="sr-only">Loading...</span>
</div>
<div class="timeline-container" *ngIf="!loading">
  <div class="edit-story text-right" *ngIf="!isPublic">
    <a href="javascript:void(0);" id="btnEditStory" (click)="onEdiMyStoryClick()">Edit</a>
  </div>
  <ul class="tl">
    <li class="tl-item">
      <div class="timestamp" id="lblThen">Then</div>
      <div class="item-title">{{ story.pastTitle }}</div>
      <div class="item-detail">
        {{ story.past }}
      </div>
    </li>
    <li class="tl-item">
      <div class="timestamp" id="lblNow">Now</div>
      <div class="item-title">{{ story.nowTitle }}</div>
      <div class="item-detail">
        {{ story.now }}
      </div>
    </li>
    <li class="tl-item">
      <div class="timestamp" id="lblFuture">Future</div>
      <div class="item-title">{{ story.futureTitle }}</div>
      <div class="item-detail">
        {{ story.future }}
      </div>
    </li>
  </ul>
</div>

<p-dialog header="" [(visible)]="editMyStoryModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
  <div class="app-modal-body">
    <!-- <perfect-scrollbar class="perfect-scrollbar" [style.max-height]="'400px'"> -->
    <div class="" id="div{{'editYourStory.id' | translate}}">
      <div class="text-center">
        <h5 class="modal-title" id="ttl{{'editYourStory.id' | translate}}">{{ 'editYourStory.title' | translate }}</h5>
      </div>
      <!-- <p class="mb-4" id="p{{'editYourStory.id' | translate}}">{{ 'editYourStory.disc' | translate }}</p> -->
      <app-my-story-form [storyForm]="storyForm" [(isSubmit)]="isSubmit"></app-my-story-form>
    </div>
    <!-- </perfect-scrollbar> -->
    <div class="app-modal-footer text-right">
      <button type="button" class="btn btn-secondary mr-3" data-dismiss="modal" (click)="editMyStoryModal = false"
        id="btn{{'editYourStory.Cancel' | translate}}">{{'editYourStory.Cancel' | translate}}</button>
      <button type="button" class="btn btn-confirm" (click)="saveStory()"
        id="btn{{'editYourStory.Save' | translate}}">{{'editYourStory.Save' | translate}}</button>
    </div>
  </div>
</p-dialog>