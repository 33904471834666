<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12">
    <app-header-banner *ngIf="isSME"></app-header-banner>
    <app-sp-header-banner *ngIf="isSP"></app-sp-header-banner>
    <app-investor-banner *ngIf="isInvestor"></app-investor-banner>
    <div class="sme-profile sprole">
      <div class="content-block">
        <p-tabView>
          <p-tabPanel header="Home">
            <div class="content-body">
              <div class="row">
                <div class="col-md-12">
                  <app-sme-products *ngIf="isSME"></app-sme-products>
                  <app-sme-services *ngIf="isSME || isSP"></app-sme-services>
                  <app-get-in-touch *ngIf="isSME || isSP"></app-get-in-touch>
                  <app-meet-our-team *ngIf="isSME || isSP || isInvestor"></app-meet-our-team>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="My Story" *ngIf="isSME">
            <div class="content-body">
              <div class="row">
                <div class="col-md-12">
                  <app-timeline></app-timeline>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

  </div>
</div>