<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12">
    <div id="divSmeYourStoryCard" class="your-story">
      <h3 id="ttlSmeYourStoryCard" class="title">{{ "yourStory.title" | translate }}</h3>
      <div class="icon-img">
        <img id="imgYourStory" src="assets/images/icon@2x.png" alt="alt" />
      </div>
      <h4 class="sub-title">{{ "yourStory.subTitle" | translate }}</h4>
      <p class="discp">
        {{ "yourStory.description" | translate }}
      </p>
      <button id="btnYourStoryStart" type="button" class="btn btn-primary">
        {{ "buttons.getStarted" | translate }}
      </button>
    </div>
  </div>
</div>