<div class="auth-center text-center thankyou">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="auth-side-form">
        <div class="auth-content">
          <app-language-selector></app-language-selector>
          <h1 class="auth-title" id="lblBeingCreated">
            <span>{{ title | translate }}</span>
          </h1>
          <p>
            {{ desc1 | translate }}
          </p>
          <p *ngIf="desc2">
            {{ desc2 | translate }}
          </p>
          <button id="btnSignIn" class="btn btn-confirm mb-0" type="button" [routerLink]="['/signin']"
            *ngIf="!resendVerify && !growthPartner">
            {{ "buttons.takeMeToLogin" | translate }}
          </button>
          <button id="btnSignIn" class="btn btn-confirm mb-0" type="button" (click)="gotoSMECONNECT()"
            *ngIf="growthPartner">
            Back to home
          </button>
          <ng-container *ngIf="resendVerify && !growthPartner">
            <p class="mb-2">cannot find the mail?</p>
            <button id="btnResendVerification" class="btn btn-secondary mb-0" type="button"
              (click)="onBtnClick($event)">
              Resend Verification Link
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>