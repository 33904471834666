import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ClientInfoService } from '../client-info/client-info.service';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  constructor(private http: HttpClient,
    private clientInfoService: ClientInfoService) { }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  getMessages(receiverId, senderId) {
    return this.http.get(`${this.BASE_API_URL}api/messages/getMessages?receiverId=${receiverId}&senderId=${senderId}`);
  }

  postMessages(messageBody) {
    return this.http.post(`${this.BASE_API_URL}api/messages/saveMessage`, messageBody);
  }

  deletemessage(messageId) {
    return this.http.delete(`${this.BASE_API_URL}api/messages/deleteMessage/${messageId}`);
  }

  getmessagingUsers() {
    return this.http.get(`${this.BASE_API_URL}api/messages/getMessagingUsers`);
  }

  getsearchUsers(searchuser: string, companyName: string) {
    return this.http.get(`${this.BASE_API_URL}api/messages/searchUsers?companyName=${companyName}&user=${searchuser}`);
  }
}