import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NewsFeedComponent implements OnInit {
  @Input() newsFeed: any;
  @Input() groupfeed: any;
  @Input() className: any;
  @Input() handleDeleteClick: () => void;
  @Output() showDetailEvent = new EventEmitter<number>();


  currentItem: any;
  youtubeapiLoaded = false;
  sanitizedUrl: any;
  youtubeId: any;
  urlSafe: SafeResourceUrl;
  apiLoaded = false;
  companyProfile: any;
  itsme: any;
  isCommunityManager: boolean;
  messageService: any;
  currentMyFeed: any;
  clear: boolean = false;

  constructor(private sanitizer: DomSanitizer,
    private feedService: FeedService,
    private profileService: ProfileService,
    public datepipe: DatePipe,
    private gaService: GoogleAnalyticsService,
    private authService: UserAuthService,
    private router: Router
  ) { }
  isRM: boolean;


  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
    this.companyProfile = this.profileService.orgDetails;
    if (!this.companyProfile) {
      this.getCompanyProfile();
    }

    this.isCommunityManager = this.authService.isCommunityManager();
  }

  helpful(id) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.newsFeed = this.newsFeed.map((feed) => {
      if (isNaN(feed.totalHelpful)) {
        feed.totalHelpful = 0;
      }
      if (isNaN(feed.totalUnHelpful)) {
        feed.totalUnHelpful = 0;
      }
      if (feed.id == id) {
        if (feed.unHelpful || (!feed.helpful && !feed.unHelpful)) {
          if (feed.totalUnHelpful > 0 && feed.unHelpful) {
            feed.totalUnHelpful = feed.totalUnHelpful - 1;
          }
          feed.totalHelpful = feed.totalHelpful + 1;
          that.feedService.setHelpfulness(id, true).subscribe(res => { });
          feed.unHelpful = false;
          feed.helpful = true;
        } else if (feed.helpful) {
          feed.helpful = false;
          feed.totalHelpful = feed.totalHelpful - 1;
          that.feedService.setHelpfulness(id, false).subscribe(res => { });
        }
      }
      return feed;
    });
  }
  unHelpful(id) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.newsFeed = this.newsFeed.map((feed) => {
      if (isNaN(feed.totalHelpful)) {
        feed.totalHelpful = 0;
      }
      if (isNaN(feed.totalUnHelpful)) {
        feed.totalUnHelpful = 0;
      }
      if (feed.id == id) {
        if (feed.helpful || (!feed.helpful && !feed.unHelpful)) {
          feed.totalUnHelpful = feed.totalUnHelpful + 1;
          if (feed.totalHelpful > 0 && feed.helpful) {
            feed.totalHelpful = feed.totalHelpful - 1;
          }
          that.feedService.setUnHelpfulness(id, true).subscribe(res => { });
          feed.unHelpful = true;
          feed.helpful = false;
        } else if (feed.unHelpful) {
          feed.unHelpful = false;
          feed.totalUnHelpful = feed.totalUnHelpful - 1;
          that.feedService.setUnHelpfulness(id, false).subscribe(res => { });
        }
      }
      return feed;
    });
  }
  public sanitizeImageSrc(base64data): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + base64data);
  }
  youtubeRegex = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  public sanitizeUrl(url): any {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getYoutubeId(url) {
    if (!this.youtubeRegex.test(url)) {
      return false;
    }
    this.youtubeId = url.match(this.youtubeRegex)[1];
    return this.youtubeId;
  }
  updatePlayButtonClicked(id) {
    var that = this;
    this.newsFeed = this.newsFeed.map((feed) => {
      if (feed.id == id) {
        this.logViewEvent(feed.title);
        feed.clickedPlayButton = true;
      }
      return feed;
    });
  }

  logViewEvent(title) {
    this.gaService.logCustomEvent("Viewed Newsfeed item", "Newsfeed", title);
  }

  getRelativeTime(value: any) {
    return this.feedService.getRelativeTime(value, 0);
  }

  toggleFeedComment(feed: any) {
    feed.toggle = !feed.toggle;
  }

  getCompanyProfile() {
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.companyProfile = data;
      },
      (error) => {
        this.companyProfile = null;
      }
    );
  }

  getLink(companyId: any, roleName: any) {
    let url = "/details/" + (roleName ? roleName.toLowerCase() : "cm") + "/" + companyId;
    console.log(this.router.url, "urlmai")
    if (this.router.url.indexOf("/user-browse") > -1 ||
      this.router.url.indexOf("/growth-") > -1 ||
      this.router.url.indexOf("search-") > -1 ||
      this.router.url.indexOf("feed") > -1) {
      url = "/user-browse" + url;
    } else {
      url = "/browse" + url;
    }
    return [url];
  }

  comapanyredirection(companyId: any, roleName: any) {
    if (!this.isCommunityManager) {
      this.router.navigate(this.getLink(companyId, roleName))
    }
  }

  showDetail(itemId: number) {
    this.showDetailEvent.emit(itemId);
  }

  groupredirection(groupId) {
    this.router.navigate(['/details/' + groupId]);
  }
}
