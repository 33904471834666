<div class="service-card user-book-service">
  <app-card [id]="'crd' + componentId" [customHeader]="true" headerClass="border-0 p-0 pb-0" [options]="false"
    cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
    <div class="app-card-header position-relative" style="padding:15px">

      <div class="user-about-block text-center">
        <div class="row align-items-end">
          <div class="col text-right">
            <div class="card-action">
              <div class="dropdown text-right" *ngIf="canEdit">
                <button class="cardbtn " *ngIf="!canPreview"><em class="fas fa-ellipsis-v"></em></button>
                <div class="card-content">
                  <a href="javascript: void(0)" id="'btn' + componentId + 'Edit'" (click)="onEdit($event)"
                    class=" waves-effect waves-light d-flex justify-content-between">
                    <span>Edit</span>
                    <span><em class="fas fa-pencil-alt"></em></span>
                  </a>
                  <a href="javascript: void(0)" class=" waves-effect waves-light d-flex justify-content-between"
                    (click)="onDelete($event)">
                    <span>Remove</span>
                    <span><em class="fas fa-trash-alt"></em></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="mt-2 text-danger" *ngIf="showdata">Only images in PNG, JPG, and GIF formats are permitted</div>
      </div>
      <div class="text-left">
        <div class="service-info">
          <h6 class="mb-1 title cursor-pointer" *ngIf="done">
            <span>
              {{productName ? (productName) : "Product Name" }}
            </span>
          </h6>
        </div>
      </div>
      <div class="cover-img-block text-center" (click)="canPreview?file.click():null">
        <div class="service-add-pic" *ngIf="thumbnail">
          <img [id]="'img' + componentId" [src]="thumbnail" alt="alt" class="img-fluid">
        </div>
        <div class="add-photo size-full text-center pointer" *ngIf="!thumbnail">
          <em class="fas fa-image"></em>
          <div class="mt-2">Add Featured image here</div>
        </div>
        <input [id]="'fil' + componentId" #file type="file" accept='image/*'
          (change)="onPreviewImage(file.files,$event)" style="display: none;" />
      </div>
    </div>

    <div class="d-flex justify-content-between card-data" *ngIf="done">
      <div>
        <p>
          <em class="fas fa-suitcase"></em>
          {{price ? price : "price"}} ZAR
        </p>
      </div>

      <div>
        <button class="btn btn-primary intrestedbutton" type="button" id="btnBookService"
          *ngIf="canBook && !(isGrowthCard) && !isRM" (click)="doBooking($event)"><em class="fas fa-envelope mx-2"></em>
          {{button}}</button>
        <p *ngIf="bookingdone  && !(isGrowthCard) && !isRM"><em class="fas fa-check mx-1"></em> booked</p>
      </div>
    </div>
  </app-card>
</div>