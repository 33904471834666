import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent implements OnInit {

  smeconnectURL: any;
  faqURL: any;
  termsURL: any;
  privacyURL: any;
  cookieURL: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.smeconnectURL = environment.smeconnectURL;
    this.faqURL = environment.faqURL;
    this.termsURL = environment.termsURL;
    this.privacyURL = environment.privacyURL;
    this.cookieURL = environment.cookieURL;
  }

  redirectToSignup(accountType) {
    this.router.navigate(['/account-type'], {
      queryParams: { accountType: accountType }
    });
  }

}
