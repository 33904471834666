import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.scss']
})
export class HealthCardComponent implements OnInit {
  @Input() HealthCheck: any;
  @Input() viewScoreClick: any;
  @Output() getStarted: EventEmitter<boolean> = new EventEmitter();
  @Output() viewReport: EventEmitter<boolean> = new EventEmitter();
  @Input() viewScoreClickShow: any;
  constructor(private gaService:GoogleAnalyticsService) { }

  ngOnInit(): void {
  }
  ngOnChanges() {
  }

  onHealthCheckGetStarted(currentItem) {
    this.gaService.logCustomEvent( "Opened", "Healthcheck", currentItem.name );
    this.getStarted.emit(currentItem);
  }
  onHealthCheckViewScore(currentItem) {
    this.viewReport.emit(currentItem);
  }

}
