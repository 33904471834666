<div id="frame">
    <div id="sidepanel">
        <div id="profile" [class.expanded]="expanded">
            <div class="wrap">
                <img *ngIf="activeUser?.image && ableSearch" id="profile-img" (click)="expandStatuses = !expandStatuses"
                    [class]="activeUser.status" [src]="activeUser?.image" alt="" />

                <p class="active-user" *ngIf="activeUser?.image === '' || activeUser?.image === null && ableSearch">
                    {{activeUser?.name | slice:0:1}}</p>

                <i class="fa fa-chevron-down expand-button" (click)="expanded = !expanded" aria-hidden="true"></i>
                <div id="status-options" [class.active]="expandStatuses">
                    <ul>
                        <li *ngFor="let item of (statuses | keyvalue)" [attr.id]="'status-'+item.value"
                            (click)="activeUser.status = item.value; expandStatuses=false"
                            [class.active]="item.value === activeUser.status" [class]="item.value">
                            <span class="status-circle"></span>
                            <p style="text-transform: capitalize;">{{item.value}}</p>
                        </li>
                    </ul>
                </div>
                <div id="expanded">
                    <ng-container *ngFor="let item of (activeUser?.social | json | keyvalue)">
                        <label for="twitter"><i class="fa fa-fw" [class]="'fa-'+item.key"
                                aria-hidden="true"></i></label>
                        <input [name]="item.key" type="text" [value]="item.value" />
                    </ng-container>
                </div>
            </div>
        </div>
        <div id="contacts" [class.expanded]="expanded">
            <ul>
                <li class="contact" *ngFor="let user of users" (click)="setUserActive(user)"
                    [class.active]="activeUser.id === user?.userId">
                    <div class="wrap user-logo">
                        <p *ngIf="!user?.image">
                            {{user?.name | slice:0:1}}</p>
                        <img *ngIf="user?.image" [src]="user?.image" alt="" />
                    </div>
                </li>
            </ul>
        </div>
        <div id="bottom-bar">
            <i class="fa fa-search" aria-hidden="true" (click)="showsearch()"></i>
        </div>
    </div>

    <div class="content" *ngIf="showmessagedUsers">
        <div class="contact-profile">
            <p class="user-name" *ngIf="activeUser?.image === '' || activeUser?.image === null && ableSearch">
                {{activeUser?.name | slice:0:1}}</p>
            <img *ngIf="activeUser?.image && ableSearch" [src]="activeUser?.image" alt="" />
            <p *ngIf="ableSearch && activeUser?.name">{{activeUser?.name}} ({{activeUser?.companyName}})</p>
        </div>

        <div class="messages">
            <ul *ngIf="ableSearch">
                <ng-container *ngFor="let msg of activeUser?.messages; let i = index">
                    <li *ngIf="msg.senderId != activeUser?.userId" class="replies">
                        <p>{{ msg?.content || '' }}</p>
                    </li>
                    <li *ngIf="msg.senderId == activeUser?.userId" class="sent">
                        <img [src]="activeUser?.image" alt="" />
                        <p>{{ msg?.content || '' }}</p>
                    </li>
                </ng-container>
            </ul>
            <div id="search" *ngIf="!ableSearch">
                <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
                <input class="search-input" type="text" [(ngModel)]="searchUser" placeholder="Search.."
                    (keyup.enter)="searchContacts()" />

                <ul *ngIf="searchResults">
                    <li *ngFor="let user of searchResults" (click)="setUserActive(user)">
                        {{ user?.name }} ({{ user?.role }}) - {{user?.companyName}}
                    </li>
                    <li *ngIf="searchResults.length === 0">No results found</li>
                </ul>
            </div>
        </div>
        <div class="message-input">
            <div class="wrap" *ngIf="ableSearch">
                <input type="text" #msgInput placeholder="Write your message..."
                    (keyup.enter)="addNewMessage(msgInput)" />
                <button class="submit" (click)="addNewMessage(msgInput)">
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>