<app-language-selector></app-language-selector>
<app-card [options]="false" [hidHeader]="true" class="view-details">
  <div class="row">
    <div class="col-md-12">
      <h2 id="ttlPageDetails">
        <span>{{ "editProfile.Company.mainTitle" | translate }}</span>
        <a href="javascript:void(0)" (click)="editDetails();" id="btnOrgEdit" *ngIf="!isRM">
          <em class="fas fa-pencil-alt"></em></a>
      </h2>
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col-md-12 col-lg-3">
        <div class="profile-pic text-align-center" id="divProfilePic">
          <img *ngIf="orgDetails.logo" src="{{orgDetails.logo}}" class="profile-img" alt="alt" />
          <div class="company-logo" *ngIf="!orgDetails.logo">
            <span class="logo-txt">{{ orgDetails?.companyName?.trim().slice(0,1) }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="company-details">
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <label id="lblCompany" class="othresp-m-b">{{ "editProfile.Company.Company" | translate }}</label>
              <span class="lbl-show" id="spanCompanyName">{{orgDetails?.companyName}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="isSME || isServiceProvider || isInvester">
              <label id="lblBrnnumber">{{ "editProfile.Company.BRN" | translate }}</label>
              <span class="lbl-show" id="spanBrnNumber">{{orgDetails?.brnNumber}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="isSME || isServiceProvider || isInvester">
              <label id="lblIndustry">{{ "editProfile.Company.Industry" | translate }}</label>
              <span class="lbl-show" id="spanIndustry">{{orgDetails?.industry}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="isSME || isServiceProvider || isInvester">
              <label id="lblIncoporation">{{ "editProfile.Company.Incoporation" | translate }}</label>
              <span class="lbl-show" id="spanIncoporation">{{orgDetails?.incorporationYear}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="orgDetails?.tradeLicense !='' && !isCommunityManager">
              <label id="lblIncoporation">{{ "editProfile.Company.tradeLicenseNumber" | translate }}</label>
              <span class="lbl-show" id="spanIncoporation">{{orgDetails?.tradeLicense}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="orgDetails?.companyRegion != '' && !isCommunityManager">
              <label id="lblIncoporation">{{ "editProfile.Company.companyRegion" | translate }}</label>
              <span class="lbl-show" id="spanIncoporation">{{orgDetails?.companyRegion}}</span>
            </div>
            <div class="col-md-12 col-lg-12" *ngIf="isSME || isServiceProvider || isInvester">
              <label id="lblAbout">{{ "editProfile.Company.About" | translate }}</label>
              <span class="lbl-show" id="spanAbout">{{orgDetails?.about}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-card>


<p-dialog header="" [(visible)]="showorgdetailsDialog" [modal]="true" [style]="{width: '40vw',height : '80vh'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="shareFeed_dialog" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
  styleClass="followersModal">

  <div class="editpersonalinfo">
    <div class="text-center">
      <h4>Edit Company Profile</h4>
    </div>
    <app-org-form *ngIf="editMode" [srcData]="orgDetails.logo" [orgForm]="orgForm" [cmOrgForm]="cmOrgForm"
      [(isSubmit)]="isSubmit" (selectedPic)="selectedPic($event)">
    </app-org-form>

    <div class="row" *ngIf="editMode">
      <div class="col-md-12 col-lg-4">
      </div>
      <div class="col-md-12 col-lg-12 text-right">
        <div class="form-group mt-4">
          <button type="button" class="btn btn-secondary mr-2" (click)="editCancel();" id="btnCancelPageDetails">{{
            "editProfile.personalDetails.cancel" | translate
            }}</button>
          <button type="button" class="btn btn-confirm" (click)="updateDetails();" id="btnSubmitPageDetails"
            *ngIf="isSME || isServiceProvider || isInvester">
            <span class="spinner-grow spinner-grow-sm" role="status" *ngIf="this.buttonPressed"></span>
            <span class="load-text" *ngIf="this.buttonPressed">{{ "editProfile.personalDetails.loading" | translate
              }}</span>
            <span *ngIf="!this.buttonPressed" class="btn-text">{{ "editProfile.personalDetails.submit" | translate
              }}</span>
          </button>
          <button type="button" class="btn btn-confirm" (click)="cmOrgUpdateDetails();" id="btnSubmitPageDetails"
            *ngIf="isCommunityManager">
            <span class="spinner-grow spinner-grow-sm" role="status" *ngIf="this.buttonPressed"></span>
            <span class="load-text" *ngIf="this.buttonPressed">{{ "editProfile.personalDetails.loading" | translate
              }}</span>
            <span *ngIf="!this.buttonPressed" class="btn-text">{{ "editProfile.personalDetails.submit" | translate
              }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>