import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesUrl } from '../../shared/constants/routes-url';
import { EFormService } from 'src/app/core/services/e-form/e-form.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  isContactCentre = false;

  refNumber: string = '';

  constructor(private router: Router, private eFormService: EFormService) { console.log("working done"); }

  ngOnInit() {
    console.log("working done");
    this.checkContactCenter();
    this.setRefNumber();
    this.handleBack();
  }

  setRefNumber() {
    this.eFormService.getApplicationReference().subscribe(application => {
      if (application && application.applicationReference) {
        this.refNumber = application.applicationReference as string;
      }
    });
  }

  checkContactCenter() {
    this.isContactCentre = this.router.url.endsWith(RoutesUrl.THANK_YOU_CONTACT_CENTRE_URL);
  }

  navToContactCenter() {
    this.router.navigate([RoutesUrl.CONTACT_CENTRE]);
  }

  handleBack() {
    if (this.isContactCentre) {
      window.history.pushState({}, '', RoutesUrl.CONTACT_CENTRE);
      window.history.pushState({}, '', RoutesUrl.THANK_YOU_CONTACT_CENTRE_URL);
    } else {
      window.history.pushState({}, '', RoutesUrl.CUSTOMER_SIGN_IN_URL);
      window.history.pushState({}, '', RoutesUrl.THANK_YOU_URL);
    }
  }

}
