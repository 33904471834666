<app-language-selector></app-language-selector>
<div class="app-card-block timelineCard">
    <div class="card-header">{{ "proposedTimeline.title" | translate }}</div>
    <div class="container-timeline">
        <section class="title-section">
            <ul>
                <li>
                    <h3>
                        <div class="text-dicp-title">{{ "proposedTimeline.schedule" | translate }}</div>
                    </h3>
                </li>
                <li class="right-dc">
                    <h3>
                        <div class="text-dicp-title">
                            {{ "proposedTimeline.milestones.milestones" | translate }}
                        </div>
                    </h3>
                </li>
            </ul>
        </section>
        <section id="cd-timeline" class="cd-container">
            <div class="cd-timeline-block active">
                <div class="cd-timeline-img cd-movie"></div>
                <div class="cd-timeline-content">
                    <h3 class="section-title">{{ "proposedTimeline.milestones.title1" | translate }}</h3>
                    <accordion [isAnimated]="true" class="accordion" id="accordion">
                        <accordion-group class="accordion-group">
                            <button id="btnTimeLineHead" class="btn btn-link btn-block clearfix" accordion-heading
                                type="button">
                                <div class="pull-left float-left title">
                                    {{ "proposedTimeline.milestones.disc" | translate }}</div>
                                <div class="float-right pull-right right-side">
                                    <span class="tgdate">{{ "proposedTimeline.milestones.updateDate" | translate }}
                                    </span><br>
                                    <span class="tdate">
                                        {{ "proposedTimeline.milestones.date" | translate }}</span>
                                </div>
                            </button>
                            <p>{{ "proposedTimeline.milestones.disc" | translate }}</p>
                            <ul class="list-inline m-0 ng-tns-c92-48">
                                <li class="list-inline-item">
                                    <a id="aGoogle" href="javascript:void(0);" class="text-muted text-h-danger">
                                        <em class="fab fa-google-plus-square"></em>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a id="aFacebook" href="javascript:void(0);" class="text-muted text-h-primary">
                                        <em class="fab fa-facebook-square"></em>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a id="aTwitter" href="javascript:void(0);" class="text-muted text-h-primary">
                                        <em class="fab fa-twitter-square"></em>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a id="aMail" href="javascript:void(0);" class="text-muted text-h-primary">
                                        <em class="fas fa-envelope"></em>
                                    </a>
                                </li>
                            </ul>
                        </accordion-group>
                    </accordion>
                    <div class="cd-date">
                        <h3>
                            <div class="text-dicp green">{{ "proposedTimeline.schedules.startDate" | translate }}</div>
                            <div class="text-dicp">{{ "proposedTimeline.schedules.date" | translate }}</div>
                        </h3>
                    </div>
                </div> <!-- cd-timeline-content -->
            </div> <!-- cd-timeline-block -->

            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-movie">
                </div> <!-- cd-timeline-img -->

                <div class="cd-timeline-content">
                    <h3 class="section-title">Business Plan</h3>
                    <accordion [isAnimated]="true" id="accordion">
                        <accordion-group [isOpen]="isFirstOpen" class="accordion-group">
                            <button id="btnBusinessPlanHead" class="btn btn-link btn-block clearfix" accordion-heading
                                type="button">
                                <div class="pull-left float-left title">
                                    {{ "proposedTimeline.milestones.disc" | translate }}</div>
                                <div class="float-right pull-right right-side text-right">
                                    <p class="tgdate">
                                        {{ "proposedTimeline.milestones.NeedHlp" | translate }}
                                    </p>
                                    <p class="tdate">
                                        <a id="aVisitOur" href="#.">{{ "proposedTimeline.milestones.vistOur" | translate
                                            }}</a>
                                    </p>
                                </div>
                            </button>
                            <div class="file-list table-responsive">
                                <table id="tblProposedTimeline" class="table table-hover">
                                    <caption></caption>
                                    <thead>
                                        <tr>
                                            <th id="empty"></th>
                                            <th id="DocumentName">Document Name</th>
                                            <th id="dateAdded">Date Added</th>
                                            <th id="documentSize">Document size</th>
                                            <th id="emptyLast"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="fileUploadControl.value.length > 0">
                                        <tr *ngFor="let file of fileUploadControl.value">
                                            <td><span class="file-icon"> <em class="fas fa-file-alt"></em></span></td>
                                            <td><span class="file-name">{{ file.name }}</span></td>
                                            <td>{{jstoday}}</td>
                                            <td>
                                                <div class="file-info">{{file.size}} bytes </div>
                                            </td>
                                            <td (click)="fileUploadControl.removeFile(file)"><span class="close"><em
                                                        class="fas fa-times"></em></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <file-upload class="file-upload" [control]="fileUploadControl" [animation]="animation"
                                id="btnUploadDocument">
                                <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                                    <span *ngIf="isFileDragDropAvailable">Upload Document</span>
                                    <span *ngIf="!isFileDragDropAvailable">Upload Document</span>
                                </ng-template>
                            </file-upload>
                        </accordion-group>
                    </accordion>
                    <div class="cd-date">
                        <h3>
                            <div class="text-dicp">{{ "proposedTimeline.schedules.startDate" | translate }}</div>
                            <div class="text-dicp">{{ "proposedTimeline.schedules.date" | translate }}</div>
                        </h3>
                    </div>
                </div>
            </div>


            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-location">
                </div>

                <div class="cd-timeline-content carousel-card">
                    <h3 class="section-title">{{ "proposedTimeline.milestones.title8" | translate }}</h3>
                    <accordion [isAnimated]="true" id="accordion">
                        <accordion-group [isOpen]="isFirstOpen" class="accordion-group">
                            <button id="btnLocationHead" class="btn btn-link btn-block clearfix" accordion-heading
                                type="button">
                                <div class="pull-left float-left title">
                                    {{ "proposedTimeline.milestones.disc1" | translate }}</div>
                                <div class="float-right pull-right right-side text-right">
                                    <p class="tdate">
                                        <a id="aAllSuggestions" href="javascript:void(0);" data-toggle="modal"
                                            (click)="SuggestedServiceProvidersModal.show()">{{
                                            "proposedTimeline.milestones.allSuggestions" | translate }}</a>
                                    </p>
                                </div>
                            </button>
                            <div class="oppt-service-card">
                                <app-oppt-service-card></app-oppt-service-card>
                            </div>
                        </accordion-group>
                    </accordion>
                    <div class="cd-date">
                        <h3>
                            <div class="text-dicp">{{ "proposedTimeline.milestones.1stMilestone" | translate }}</div>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="clearfix"></div>
</div>
<div class="clearfix"></div>


<app-ui-modal #SuggestedServiceProvidersModal [containerClick]="false" [dialogClass]="'modal-lg all-suggestions-modal'">
    <div class="app-modal-header">
        <h5 class="modal-title">{{ "proposedTimeline.modal.title" | translate }}</h5>
        <button id="btnSuggestProviderClose" type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" (click)="SuggestedServiceProvidersModal.hide()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="search-services-block">
            <div class="row">
                <div class="col-md-5">
                    <div class="results-text">
                        {{ "proposedTimeline.modal.serarchText" | translate }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="autocomplete-block">
                        <input id="txtSearchGrowthPlans" [(ngModel)]="asyncSelected" [typeahead]="dataSource"
                            [typeaheadAsync]="true" typeaheadOptionField="name"
                            (typeaheadLoading)="changeTypeaheadLoading($event)" placeholder="Search Growths plans"
                            class="form-control">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="sort-block">
                        <span>{{ "proposedTimeline.modal.sort" | translate }}</span>
                        <select name="select" class="form-control form-control-default">
                            <option value="opt1">{{ "proposedTimeline.modal.select" | translate }}</option>
                            <option value="opt2">{{ "proposedTimeline.modal.opt1" | translate }}</option>
                            <option value="opt3">{{ "proposedTimeline.modal.opt2" | translate }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button id="btnSuggestProviderFooterClose" type="button" class="btn btn-confirm" data-dismiss="modal"
            (click)="SuggestedServiceProvidersModal.hide()">{{ "buttons.close" | translate }}</button>
    </div>
</app-ui-modal>