import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NouiFormatter } from 'ng2-nouislider';
import { Common } from '../../utility/common';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

export class TimeFormatter implements NouiFormatter {
  to(value: number): string {
    const h = Math.floor(value / 3600);
    const m = Math.floor(value % 3600 / 60);
    const s = value - 60 * m - 3600 * h;
    const values = [h, m, s];
    let timeString: string;
    values.forEach((_, i) => {
      if (values[i] < 10) {
        timeString += '0';
      }
      timeString += values[i].toFixed(0);
      if (i < 2) {
        timeString += ':';
      }
    });
    return timeString;
  }

  from(value: string): number {
    const v = value.split(':').map(parseInt);
    let time = 0;
    time += v[0] * 3600;
    time += v[1] * 60;
    time += v[2];
    return time;
  }
}

@Component({
  selector: 'app-sp-services',
  templateUrl: './sp-services.component.html',
  styleUrls: ['./sp-services.component.scss'],
  animations: [fadeAnimation]
})
export class SpServicesComponent implements OnInit {

  services: any = [];
  description = Common.discp;
  serviceCard = false;
  emptyCard = true;
  public keyupLabelOn = false;
  public keydownLabelOn = false;
  public someRange2: number[] = [0, 5];
  public noOfweeks: number[] = [0, 3];
  public weeksConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 4],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 5
    },
    pips: {
      mode: 'count',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  public ServicePriceRangeConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 10],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 10
    },
    pips: {
      mode: 'steps',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  businesses = Common.helpBusinesses;
  selectedBusinesses = '';

  nOfweeks = Common.nOfweeks;
  selectednOfweeks = 'Weeks';



  onChangeBusinesses(newValue) {
    this.selectedBusinesses = newValue;
  }
  onOfweeks(newValue) {
    this.selectednOfweeks = newValue;
  }

  ngOnInit(): void {
    this.services = [{
      title: 'Single Page Website',
      description: 'Show your company history, team, products you sell and a simple "order now" form, Available same day.',
      duration: '4 - 7',
      cost: '30',
      thumbnail: 'assets/images/card.jpg',
      isHours: true
    }, {
      title: 'E-Commerce website: 10 items. Mauritius only',
      description: 'Receive orders for your inventory. Receive payment in bank account within 2 hours.',
      duration: '24',
      cost: '120',
      thumbnail: 'assets/images/services-1.jpg',
      isHours: true
    }];
  }

  blinkKeyupLabel() {
    this.keyupLabelOn = true;
    setTimeout(() => {
      this.keyupLabelOn = false;
    }, 450);
  }

  blinkKeydownLabel() {
    this.keydownLabelOn = true;
    setTimeout(() => {
      this.keydownLabelOn = false;
    }, 450);
  }

  getStarted() {
    this.serviceCard = false;
    this.emptyCard = true;
  }
  editCard(exampleModalLong) {
    exampleModalLong.hide();
    this.serviceCard = true;
    this.emptyCard = false;
  }

}
