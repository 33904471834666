<div class="d-flex align-items-center justify-content-between footer">
  <button id="btnBack{{ componentId }}" type="button"
    [disabled]="disableBackButton"
    (click)="back()" class="tertiary-btn mb-1 d-none d-md-flex">
    <img id="imgFormFooterBack" src="assets/icons/back-arrow.svg" alt="alt" />
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>
  <button id="btnNext{{ componentId }}"
    type="button"
    class="main-button-red mb-1"
    [disabled]="disableNextButton"
    (click)="save()">
    {{ nextBtnLabel | translate }}
  </button>
  <button id="btnBackSm{{ componentId }}"
    type="button"
    class="main-button-grey mb-1 d-md-none"
    [disabled]="disableBackButton"
    (click)="back()">
    {{ 'COMMON.BACK' | translate }}
  </button>
</div>
