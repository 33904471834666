<form id="frmSearch" autocomplete="off" #searchForm="ngForm">
  <div class="input-group">
    <input id="txtSearch" type="text" name="searchText" class="form-control serachInput" placeholder="Search"
      pattern="^([A-Za-z0-9 \(\)\/\.\“\'\:\;\_\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]{1})([A-Za-z0-9 \(\)\/\.\,\“\'\:\;\_\-\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]+)([A-Za-z0-9 \(\)\/\.\“\'\:\;\_\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]{1})$"
      [(ngModel)]="searchParam" (ngModelChange)="sendEventIfEmpty()" (keydown.enter)="search()" #searchText="ngModel"
      minlength="3">
    <div class="input-group-append">
      <span id="btnClear" class="input-group-text icon clearBtn pr-0" (click)="clearText()"
        *ngIf="searchParam?.length > 0"><em id="iSearchClear" class="fas fa-times"></em></span>
      <span id="btnSearch" class="input-group-text icon searchBtn" (click)="search()"><em id="iSeachIcon"
          class="fas fa-search"></em></span>
    </div>
  </div>
  <div [hidden]="!(searchText.errors && searchText.errors.pattern && submitted)" class="error-container">
    <div class="error-msg" id="txtErrorMsg"> {{ 'SEARCH.SEARCH_ERROR' | translate }}</div>
  </div>
  <div [hidden]="!(searchText.errors && searchText.errors.minlength && submitted)" class="error-container">
    <div class="error-msg" id="txtErrorMsg"> {{ 'SEARCH.MIN_CHARS' | translate }}</div>
  </div>
</form>