import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldMetaData, FieldType } from "../../../core/models/field-meta-data";
import { UtilityService } from "../../../core/services/utility/utility.service";

@Component({
  selector: 'app-input-reactive-field',
  templateUrl: './input-reactive-field.component.html',
  styleUrls: ['./input-reactive-field.component.scss']
})
export class InputReactiveFieldComponent implements OnInit {

  constructor(private utilityService: UtilityService) { }

  @Input() field: FieldMetaData;
  @Input() form: FormGroup;
  @Input() componentId = '';
  @Input() overwrittenFields: string[] = [];
  @Input() changedFields: string[] = [];
  @Output() getData:EventEmitter<any>=new EventEmitter<any>();
  @Input() maxLength:number;
  @Input() minLength:number;
  @Input() submitted:false;
  formData={};
  set disabled(disable: boolean) {
    this._disabled = disable || !this.field.isEditable;
    if (this._disabled) {
      this.field.control.disable();
    } else {
      this.field.control.enable();
    }
  }

  fieldTypeEnum = FieldType;

  _disabled = false;

  @Output()
  blurEvent = new EventEmitter();
  ngOnInit() {
  }

  showOverwritten() {
    return this.overwrittenFields !== undefined &&
      this.overwrittenFields !== null && this.overwrittenFields.indexOf(this.field.name) !== -1;
  }

  showUpdated() {
    return this.changedFields !== undefined && this.changedFields !== null && this.changedFields.indexOf(this.field.name) !== -1;
  }

  showMetData() {
    return this.field.metaData && this.field.metaData.length > 0;
  }

  getTitleCase() {
    this.utilityService.titleCaseTransform(this.field.name);

  }
  getinputData(data){
    for(var key in data){
      this.formData[key]=data[key];
    }
    this.getData.emit(this.formData);
  }
}
