import { Component, Input } from '@angular/core';
import { Common } from '../../utility/common';


@Component({
  selector: 'app-opportunity-item',
  templateUrl: './opportunity-item.component.html',
  styleUrls: ['./opportunity-item.component.scss']
})
export class OpportunityItemComponent {
  @Input() SME: any = {};
  ObjopportunityItem = Common.opportunityItem;

}
