<div class="content-body health-check">

  <div class="gc-center" infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
    <div class="pagedescriptions">
      <p> <em class="fas fa-exclamation-circle"></em>
        Take the following tests to see how well your business is performing. </p>
    </div>
    <app-health-card [HealthCheck]="healthChecks" [viewScoreClickShow]="viewScoreClickShow"
      (getStarted)="setCurrentHealthCheckItem($event);healthCheckModal.show()"
      (viewReport)="setCurrentHealthCheckItem($event);viewReport($event);healthCheckModal.show()"></app-health-card>
  </div>
</div>


<app-ui-modal #healthCheckModal [hideFooter]="true" [dialogClass]="'modal-lg'" [containerClick]="false"
  class="healthCheckModal">
  <div class="app-modal-header">
    <h5 class="modal-title" id="{{healthCheckStarted.id+'header'}}" *ngIf="healthCheckForm && !showReport">
      {{healthCheckStarted.name | translate}}</h5>
    <h5 class="modal-title" *ngIf="showReport" id="{{healthCheckStarted.id+'header'}}">
      {{healthCheckStarted.name | translate}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="closeReport()"
      id="btnClose"><span aria-hidden="true">&times;</span></button>
    <p *ngIf="healthCheckForm && !showReport">{{healthCheckStarted.description | translate}}</p>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card loader-card" *ngIf="showLoader" id="{{'QuestionResults.loader.loaderId' | translate}}">
          <div class="card-body">
            <div class="loader">Loading...</div>
            <p>{{'QuestionResults.loader.disc' | translate}}</p>
            <p>{{'QuestionResults.loader.loadText' | translate}}</p>
          </div>
        </div>
        <app-view-health-score [showMyScore]="showMyScore" *ngIf="showReport"></app-view-health-score>
        <app-health-check-form [CurrentHealthCheck]="healthCheckStarted" (myScoreClick)="userCalculatedScore($event)"
          (myAnswersSaved)="userSavedAnswers($event)" *ngIf="healthCheckForm && !showReport">
        </app-health-check-form>
      </div>
    </div>
  </div>
</app-ui-modal>