<app-language-selector></app-language-selector>
<div class="search-news">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngFor="let item of searchNews; index as i">
      <div class="row">
        <div class="col-lg-3 col-md-5">
          <div class="img-block" *ngIf="item?.topic != 'video'">
            <img src="{{item?.featuredImage?item.featuredImage:'assets/images/news/newsfeed.jpg'}}"
              id="imgSearchNews{{i+1}}" class="img-fluid" alt="alt" />
          </div>

          <div class="embed-responsive embed-responsive-16by9"
            *ngIf="item?.topic == 'video' && (!item.featuredImage || item.clickedPlayButton)">
            <div *ngIf="item?.topic == 'video' && getYoutubeId(item.feedUrl)">
              <app-custom-youtube feedTitle="{{item.title}}" youtubeId="{{getYoutubeId(item.feedUrl)}}"
                playButtonPressed="{{item.clickedPlayButton}}"></app-custom-youtube>
            </div>
            <video *ngIf="item?.topic == 'video' && !getYoutubeId(item.feedUrl)" width="100%" height="500" controls
              preload="auto" [src]="item.videoUrl">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="col-lg-6 col-md-7 p-0">
          <div class="content-blcok">
            <div>
              <h5>{{item.title | translate}}</h5>
              <p>{{item.description | translate }}</p>

              <div class="d-flex flex-wrap justify-content-between">
                <div>
                  <a *ngIf="item?.topic != 'video'" href="{{ item?.feedUrl }}" target="_blank" rel="noopener"
                    id="{{item.id}}{{i+1}}">{{item?.feedUrl | slice:0:50}}</a>
                  <a *ngIf="item?.topic == 'video'" href="javascript:void(0)" id="{{item.id}}{{i+1}}"
                    (click)="setCurrentItem(item,i)">{{
                    item?.feedUrl }}</a>
                </div>

                <div class="downloads">
                  <a *ngIf="!item?.documentUrl && item?.feedUrl && item.topic!='video'" target="_blank" rel="noopener"
                    href="{{ item?.feedUrl }}" id="{{item?.urlMoreId}}{{i+1}}" class="btn btn-primary mx-2">{{ 'Read
                    Now' | translate }}</a>
                  <a *ngIf="item?.documentUrl" href="{{ item?.documentUrl}}" class="btn btn-primary mx-2"
                    id="{{item.documentUrl}}{{i+1}}" download="true">{{
                    'Download Now' | translate }}</a>
                  <a *ngIf="item?.topic == 'video'" href="javascript:void(0)" class="btn btn-primary mx-2"
                    id="btnPlayVideo{{i+1}}" (click)="setCurrentItem(item,i)">{{
                    'Play video' | translate }}</a>
                </div>
              </div>

            </div>


            <div class="footer-block">
              <div class="company d-flex pointer" (click)="comapanyredirection(item.userId , item.roleName)">
                <div class="img-logo">
                  <img src="{{item?.companyLogo}}" id="imgCompany{{i+1}}" class="img-fluid" alt="alt"
                    *ngIf="item.companyLogo" />
                  <span *ngIf="!item.companyLogo" class="c-logo">
                    {{item.companyName?.trim().slice(0,1) }}
                  </span>
                </div>

                <div class="company-info">
                  <h6>
                    {{item.companyName | translate}}
                    <br>
                    <span>{{item.roleName | translate}}</span>
                  </h6>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="row footer-block">
        <!-- <div class="col-md-12 p-0 mb-5">
          <div class="company d-flex justify-content-between">
            <div class="img-logo">
              <img src="{{item?.companyLogo}}" id="imgCompany{{i+1}}" class="img-fluid" alt="alt"
                *ngIf="item.companyLogo" />
              <span *ngIf="!item.companyLogo" class="c-logo">
                {{item.companyName?.trim().slice(0,1) }}
              </span>

              <h6>
                {{item.companyName | translate}}
                <br>
                <span>{{item.roleName | translate}}</span>
              </h6>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-12 col-lg-9 col-xl-12">
          <div class="actions">
            <div class="row">
              <div class="col-lg-12 text-left">
                <a *ngIf="item?.topic != 'video'" href="{{ item?.feedUrl }}" target="_blank" rel="noopener"
                  id="{{item.urlId}}{{i+1}}">{{item?.feedUrl }}</a>
                <a *ngIf="item?.topic == 'video'" href="javascript:void(0)" id="{{item.urlId}}{{i+1}}"
                  (click)="setCurrentItem(item,i)">{{
                  item?.feedUrl }}</a>
              </div>
              <div class="col-lg-12 text-right actions-btn">
                <a *ngIf="!item?.documentUrl && item?.feedUrl && item.topic!='video'" target="_blank" rel="noopener"
                  href="{{ item?.feedUrl }}" id="{{item?.urlMoreId}}{{i+1}}" class="btn btn-primary">{{ 'Read Now' |
                  translate }}</a>
                <a *ngIf="item?.documentUrl" href="{{ item?.documentUrl}}" class="btn btn-primary"
                  id="{{item.documentUrl}}{{i+1}}" download="true">{{
                  'Download Now' | translate }}</a>
                <a *ngIf="item?.topic == 'video' && (testYoutubeId(item.feedUrl) || item.videoUrl)"
                  href="javascript:void(0)" class="btn btn-primary" id="btnPlayVideo{{i+1}}"
                  (click)="setCurrentItem(item,i)">{{
                  'Play video' | translate }}</a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <p-dialog header="" [(visible)]="feedArticalVideoModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class="app-modal-body">
      <div class="thumbnail">
        <div class="thumb">
          <div class="embed-responsive embed-responsive-16by9"
            *ngIf="currentItem && testYoutubeId(currentItem.feedUrl)">
            <youtube-player [videoId]="getYoutubeId(currentItem.feedUrl)"></youtube-player>

            <video *ngIf="currentItem?.topic == 'video' && !testYoutubeId(currentItem.feedUrl)" width="100%"
              height="500" controls preload="auto" [src]="currentItem.videoUrl">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
</div>