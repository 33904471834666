<div class="row">
    <div class="col-md-12">
        <div [id]="'div' + componentId" class="products sp-service">
            <h3 [id]="'tit' + componentId" class="title mt-3" *ngIf="cardTitle">{{ cardTitle }}</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="your-story">
                        <div class="icon-img">
                            <!-- <img [id]="'img' + componentId" [src]="iconPath" *ngIf="iconPath" alt="alt" /> -->
                        </div>
                        <h4 class="sub-title" *ngIf="subTitle" [id]="'lbl' + componentId">{{ subTitle }}</h4>
                        <p class="discp" *ngIf="description">{{ description }}</p>
                        <button [id]="'btn' + componentId" type="button" data-toggle="modal" class="btn btn-secondary"
                            (click)="onBtnClick()" *ngIf="btnLabel">
                            {{ btnLabel }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>