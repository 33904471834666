<app-language-selector></app-language-selector>
<div class="sme-stats">
  <div class="row">
    <!-- <div class="col-md-12">
      <div class="card sme-feed main-card" id="divAddPosts">
        <div class="toparticles mb-3">
          <h5>Get ahead with Premium 🌟
          </h5>
        </div>
        <div class="d-flex ekyb-info mb-2">
          <div style="font-size:16px">Unlock premium features for an enhanced experience!</div>
        </div>
        <div class="d-flex justify-content-center mt-4 ekyb-btns">
          <button class="btn btn-primary comp-btn mx-1" (click)="findSimilarElements()">Get Premium now</button>
        </div>
      </div>
    </div> -->


    <!-- <div class="col-md-12"
      *ngIf="!isCommunityManager && profile?.verifiedProfile == false || orgDetails?.verifiedCompany == false">
      <div class="card sme-feed main-card" id="divAddPosts">
        <div class="toparticles mb-3">
          <h5>Get Verified
            <img src="../../../../assets/images/auth-company-badge.svg" alt="">
          </h5>
        </div>

        <div class="d-flex ekyb-info mb-2">
          <div><em class="fas fa-circle"></em></div>
          <div style="font-size:16px">Complete your eKYC to get a verification check beside your profile name</div>
        </div>

        <div class="d-flex ekyb-info">
          <div> <em class="fas fa-circle"></em></div>
          <div style="font-size:16px">Complete your eKYB to get a verification check beside your company name, only for
            2 ZAR</div>
        </div>

        <div class="d-flex justify-content-center mt-4 ekyb-btns">
          <button class="btn btn-primary comp-btn manual-btn mx-1" *ngIf="profile?.verifiedProfile == false">Start
            eKYC</button>
          <button class="btn btn-primary comp-btn  mx-1" (click)=" startfuction()"
            *ngIf="orgDetails?.verifiedCompany == false">Start
            eKYB</button>
        </div>
      </div>
    </div> -->


    <div class="col-md-12">
      <div class="card sme-feed main-card" id="divAddPosts">
        <div class="toparticles mb-3">
          <h5>Top Articles</h5>
        </div>

        <div class="group-loaders" *ngIf="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
          </div>
        </div>

        <ng-container *ngIf="!loading">
          <div *ngFor="let item of getdataarticles" class="toparticles info">
            <div class="p-0 imgblock">
              <div class="toparticleImg">
                <img
                  src="{{item['featuredImage'] ? item['featuredImage'] : '../../../../../../assets/images/default.jpg' }}"
                  alt="">
              </div>
            </div>
            <div class="ml-3 contentblock">
              <p>{{item['title']}}</p>
              <span style="font-size: 12px;">{{ getRelativeTime(item.updatedTime) }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>



<p-dialog header="" [(visible)]="feedArticalVideoModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal company-auth" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
  [closable]="true" (onHide)="resetfeedArticalVideoModal()">

  <div class="company-details">
    <div *ngIf="showinputs">
      <div class="header text-center">
        <h5>Enter your Company details</h5>
      </div>


      <div class="pagedescriptions">
        <p class="companyauth_msg"> <em class="fas fa-exclamation-circle"></em>
          Please recheck the information before we proceed for authentication.</p>
      </div>

      <form [formGroup]="orgForm">
        <div class="form-group mb-4">
          <label id="lblProductName">{{ "editProfile.Company.Company" | translate}}</label>
          <input id="txtProductName" type="text" class="form-control" formControlName="companyName"
            placeholder="Please enter the Company Name">
          <p *ngIf="orgForm.get('companyName').errors && orgForm.get('companyName').errors.required"
            class="text-danger">
            Company Name is required.
          </p>
        </div>

        <div class="form-group mb-4">
          <label id="lblProductName">{{ "editProfile.Company.tradeLicenseNumber" | translate }}</label>
          <input id="txtProductName" type="text" class="form-control" formControlName="tradeLicense"
            placeholder="Please enter the Trade License Number">
          <p *ngIf="orgForm.get('tradeLicense').errors && orgForm.get('tradeLicense').errors.required"
            class="text-danger">
            Trade License Number is required.
          </p>
        </div>

        <div class="form-group mb-4">
          <label id="lblProductName">{{ "editProfile.Company.companyRegion" | translate }}</label>
          <!-- <input id="txtProductName" type="text" class="form-control" formControlName="companyRegion"
            placeholder="Enter Country Region"> -->

          <span class="p-fluid">
            <p-dropdown placeholder="{{ 'formElementsLables.companyRegionPlaceholder' | translate }}"
              [options]="countryRegionOptions" formControlName="companyRegion"></p-dropdown>
          </span>

          <p *ngIf="orgForm.get('companyRegion').errors && orgForm.get('companyRegion').errors.required"
            class="text-danger">
            Company Region is required.
          </p>
        </div>

        <div class="text-right">
          <button class="btn btn-primary" type="submit" (click)="onSubmit()">Submit</button>
        </div>
      </form>

    </div>


    <div class="spinner uqudo-load uqudo-company d-flex justify-content-center" *ngIf="isLoading">
      <div class="">
        <span class="spinner-border"></span>
        <p>Please wait, We're authenticating your company!</p>
      </div>
    </div>

    <div class="text-center success-msg" *ngIf="showauthinfo">
      <span *ngIf="uqudoData?.companyStatus == 'ACTIVE'">
        <img src="../../../../assets/images/auth-company-badge.svg" alt="">
        <p>
          Congratulations! Your eKYB verification is complete. Your company has been successfully verified.
        </p>
      </span>

      <span *ngIf="uqudoData?.companyStatus != 'ACTIVE'">
        <p>
          Oops! We encountered an issue with your eKYB verification. Please ensure that all the provided information is
          accurate and try again. If the issue persists, please contact our Admin team for assistance.
        </p>
      </span>
    </div>

    <div class="text-center success-msg" *ngIf="errormsg">
      <span>
        <p>Please try again after sometime!</p>
      </span>
    </div>
  </div>
</p-dialog>


<!-- <p-dialog header="" [(visible)]="subscribeModal" [modal]="true" [style]="{width: '60.5vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal company-auth" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true"
  [closable]="true">
  <div class="company-details">
    <div *ngIf="showinputs">
      <div class="header text-center"> -->
<!-- <h5>Select the Subscription plan</h5> -->
<!-- </div>
    </div>
    <h1 class="page-title">
      Choose a plan
    </h1>

    <div class="bill-type">
      <div class="monthly">Billed monthly</div>
      <div class="bill-toggle">
        <input type="checkbox" id="bill-toggle" checked>
        <label for="bill-toggle"></label>
      </div>
      <div class="annually">Billed anually</div>
    </div> -->

<!-- <div class="pricing-table" *ngIf="subsDetails.length">
      <div class="plan starter" *ngFor="let item of subsDetails.slice(0,3)">
        <h2 class="name">{{item.plan}}</h2>
        <h6 class="price"><i class="fas fa-dollar-sign" *ngIf="item.cur"></i>{{item.price}}</h6>
        <ul class="features">
          <li>3 projects</li>
          <li>Unlimited guides</li> -->
<!-- <li>Unlimited flows</li>
          <li>Unlimited branded themes</li>
          <li>Email support</li> -->
<!-- </ul>
        <button class="btn btn-primary" type=" button">
          Choose Plan
        </button>
      </div>


    </div> -->
<!-- <div *ngIf="subsDetails.length">
      <div *ngFor="let item of subsDetails" style="border: 1px solid #d5d5d5;
      border-radius: 8px;
      height: 100px;
      padding: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;">
        <div>
          <h2 style="    font-size: 22px;
        font-weight: 500;
        ">{{item.plan}}</h2>
          <h5 style="    font-size: 15px;
        font-weight: 500;
      ">{{item.price}}</h5>
          <h5 style="    font-size: 15px;
        font-weight: 500;
      ">{{item.cur}}</h5>
        </div>
        <button class="btn btn-confirm">Choose plan</button>
      </div>
    </div> -->


<!-- <div style="border: 1px solid #d5d5d5;
    border-radius: 8px;
    height: 100px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;">
      <h2 style="    font-size: 22px;
      font-weight: 500;
  "> Basic plan</h2>
      <button class="btn btn-confirm" (click)="getplans()">Choose plan</button>
    </div>

    <div style="border: 1px solid #d5d5d5;
    border-radius: 8px;
    height: 100px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;">
      <h2 style="    font-size: 22px;
      font-weight: 500;
  ">Silver plan</h2>
      <button class="btn btn-confirm">Choose plan</button>
    </div>

    <div style="border: 1px solid #d5d5d5;
    border-radius: 8px;
    height: 100px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;">
      <h2 style="    font-size: 22px;
      font-weight: 500;
  ">Gold plan</h2>
      <button class="btn btn-confirm">Choose plan</button>
    </div> -->
<!-- </div>
</p-dialog> -->