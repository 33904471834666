import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../../services/common/common.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-browse-companies',
  templateUrl: './browse-companies.component.html',
  styleUrls: [
    './browse-companies.component.scss',
    '../../../../assets/landing/css/style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class BrowseCompaniesComponent implements OnInit {
  public filters: any;
  public browseCards: any;
  @Input() browsecompanycards: any;
  @Input() companypageNumber: any;
  @Input() totalItems: any;
  @Input() loading: any;
  @Output() passChangePage: EventEmitter<any> = new EventEmitter<any>();

  constructor(private commonService: CommonService) {

  }

  ngOnInit() {
    this.commonService.getCommonData().subscribe(
      res => {
        this.filters = res.UserBrowseProductsServices.CompanyFilters;
        this.browseCards = res.UserBrowseProductsServices.compData;
      })
  }

  handlePageChange(event: any) {
    this.passChangePage.emit(event);
  }
}
