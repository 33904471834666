<div class="counter-section how-it-works">
  <div class="container">
    <h2>{{landingTimeline?.title | translate}}</h2>
    <p>{{landingTimeline?.disc | translate}}</p>
    <div class="timeline-wrapper">
      <div class="timeline">
        <ng-container *ngFor="let item of landingTimeline?.steps">
          <div class="event {{item.className}}">
            <h5>{{item.title | translate}}</h5>
            <p>{{item.disc | translate}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>