import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MessageService, TreeDragDropService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { DateAgoPipe } from '../../helpers/date-ago.pipe'
import { DiscussionService } from '../../../core/services/discussion-service/discussion.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { Common } from 'src/app/utility/common';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss'],
  providers: [DateAgoPipe]
})
export class DiscussionComponent implements OnInit {
  @Input() discussBoard: any;
  isFirstOpen = true;
  editClicked: boolean;
  loading: boolean;
  isCommunityManagerssss: boolean;
  discussions: any = [];

  newAnswer = {};
  isValidNewAnswer = {};
  newAnswerMentions = {};
  buttonPressed = [];
  buttonPressedForError = [];

  editAnswer = {};
  isValidEditAnswer = {};
  editAnswerMentions = {};
  editAnswerButtonPressed = [];
  editAnswerButtonPressedForError = [];

  editQuestion = {};
  editQuestionButtonPressed = [];
  editQuestionButtonPressedForError = [];

  answerLength = 350;
  questionLength = 300;
  pageNumber: number = 1;
  pageSize: number = 10;
  totalItems = 0;
  isRM: boolean;
  isInputValid: boolean;
  isQuestionEdited: boolean;
  clear: boolean = false;
  public forumquestions: any;
  // showReplyInput: boolean = false;

  constructor(
    private discussionService: DiscussionService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private authService: UserAuthService,
    private router: Router
  ) { }
  image;
  userName;
  userPosition;
  ngOnInit(): void {
    this.getDiscussions();
    this.userProfile();
    this.userPosition = this.profileService.userPosition;
    this.discussionService.newQuestion.subscribe(data => {
      this.discussions = [data].concat(this.discussions);
      this.createForms();
    });
    this.isRM = this.authService.isRelationShipManager();

    this.isInputValid = false;
    this.isCommunityManagerssss = this.authService.isCommunityManager();
  }

  userProfile() {
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.image = data.logo;
        this.userName = data.companyName;
      },
      (error) => {
        console.log(error);
      }
    );
    this.profileService.updateEvent.subscribe(
      () => {
        this.userPosition = this.profileService.userPosition;
      }
    );
  }
  createForms() {
    for (let i in this.discussions) {
      this.newAnswer[i] = "";
      this.buttonPressed[i] = false;
      this.buttonPressedForError[i] = false;

      this.editAnswer[i] = {};
      this.editAnswerButtonPressed[i] = {};
      this.editAnswerButtonPressedForError[i] = {};

      this.editQuestion[i] = this.discussions[i].question;
      this.editQuestionButtonPressed[i] = false;
      this.editQuestionButtonPressedForError[i] = false;

      if (this.discussions[i]?.answerList?.content) {
        for (let j in this.discussions[i].answerList.content) {
          this.editAnswer[i][j] = this.discussions[i].answerList.content[j].answer || "";
          this.editAnswerMentions[i + '_' + j] = Common.getMentions(this.editAnswer[i][j]).mentionIds;
          this.isValidEditAnswer[i + '_' + j] = true;
          this.editAnswerButtonPressed[i][j] = false;
          this.editAnswerButtonPressedForError[i][j] = false;
        }
      }
    }

  }
  createMyForm() {
    return new FormGroup({
      questionId: new FormControl(''),
      answer: new FormControl('', [Validators.required, CustomValidators.removeSpaces])
    });
  }

  getDiscussions() {
    this.loading = true;
    this.discussionService.getDiscussions(this.pageNumber - 1, this.pageSize).subscribe(
      (data) => {
        window.scrollTo(0, 0);
        this.discussions = data && Array.isArray(data["content"]) ? data["content"] : [];
        this.totalItems = data.totalElements;
        this.createForms();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }
  goToPage(page: any) {
    if (page < 1) page = 1;
    this.pageNumber = page;
    this.getDiscussions();
  }

  formatAnswer(answers: any) {
    if (answers && answers.length > 0) {
      return answers.length + " answer" + (answers.length > 1 ? "s" : "");
    }
    return "No answer"
  }

  submitAnswer(index: any) {
    this.answerLength = 350;
    this.buttonPressedForError[index] = true;
    console.log(this.newAnswer, 'newAnswer')
    if (!this.newAnswer[index] || this.buttonPressed[index] || !this.isValidNewAnswer[index]) {
      return false;
    }

    this.buttonPressed[index] = true;
    console.log(this.newAnswer[index].userId, 'this.newAnswer')
    let formData = {
      answer: this.newAnswer[index],
      questionId: this.discussions[index].questionId,
      mentions: this.newAnswerMentions[index],
    };

    this.clear = false;
    this.discussionService.submitAnswer(formData).subscribe(
      data => {
        Swal.fire('Great!', 'We’ve received your answer.', 'success');
        this.clear = true;
        this.buttonPressed[index] = false;
        this.isValidNewAnswer[index] = true;
        this.newAnswerMentions[index] = [];
        this.newAnswer[index] = "";
        this.discussions = this.discussions.map(disc => {
          if (disc.questionId == formData.questionId) {
            if (!disc.answerList) { disc.answerList = { content: [] }; }
            disc.answerList.content = [data].concat(disc.answerList.content);

            if (!disc.answerCount) {
              disc.answerCount = 0;
            }
            disc.answerCount = disc.answerCount + 1;
            if (!disc.answerList.totalElements) {
              disc.answerList.totalElements = 0;
            }
            disc.answerList.totalElements = disc.answerList.totalElements + 1;
          }
          this.answerLength = 350;
          return disc;
        });
        this.createForms();
      },
      _error => {
        Swal.fire('Error!', 'This comment seems irrelevant or inappropriate and can’t be posted.', 'error');
        this.buttonPressed[index] = false;
      }
    );
  }


  setEditableAnswer(questionId, answerId) {
    this.discussions = this.discussions.map(disc => {
      if (disc.questionId == questionId) {
        if (!disc.answerList) { disc.answerList = { content: [] }; }
        disc.answerList.content = disc.answerList.content.map(a => {
          if (a.answerId == answerId) {
            a.editClicked = true;
          }
          return a;
        });
      }
      return disc;
    });
  }
  submitEditAnswer(questionIndex, answerIndex) {
    this.editAnswerButtonPressedForError[questionIndex][answerIndex] = true;
    if (!this.editAnswer[questionIndex][answerIndex] || this.editAnswerButtonPressed[questionIndex][answerIndex]
      || !this.isValidEditAnswer[questionIndex + '_' + answerIndex]) {
      return false;
    }

    this.editAnswerButtonPressed[questionIndex][answerIndex] = true;
    let formData = {
      answer: this.editAnswer[questionIndex][answerIndex],
      questionId: this.discussions[questionIndex].questionId,
      answerId: this.discussions[questionIndex].answerList.content[answerIndex].answerId,
      mentions: this.editAnswerMentions[questionIndex + '_' + answerIndex]
    };

    this.discussionService.updateAnswer(formData).subscribe(
      data => {
        this.isInputValid = false;
        this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Answer updated successfully.", detail: '' });
        this.editAnswerButtonPressed[questionIndex][answerIndex] = false;
        this.isValidEditAnswer[questionIndex + '_' + answerIndex] = true;
        this.editAnswerMentions[questionIndex + '_' + answerIndex] = [];
        this.discussions = this.discussions.map(disc => {
          if (disc.questionId == formData.questionId) {
            if (!disc.answerList) { disc.answerList = { content: [] }; }
            disc.answerList.content = disc.answerList.content.map(a => {
              if (a.answerId == formData.answerId) {
                a.answer = formData.answer;
                a.editClicked = false;
              }
              return a;
            });
          }
          return disc;
        });
        this.createForms();
      },
      error => {
        Swal.fire('Error!', 'This comment seems irrelevant or inappropriate and can’t be posted.', 'error');
        this.editAnswerButtonPressed[questionIndex][answerIndex] = false;
      }
    );
  }
  formatText(text: string) {
    text = Common.replaceMentions(text);
    return Common.replaceURLs(text);
  }
  deleteQuestion(questionId) {
    Swal.fire({
      title: 'Are you sure you wish to remove this Question?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      // this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Question deleted successfully.", detail: '' });
      if (result.isConfirmed) {
        this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Question deleted successfully.", detail: '' });
        this.discussionService.deleteQuestion(questionId).subscribe(
          data => {
            this.discussions = this.discussions.filter(disc => {
              if (disc.questionId == questionId) {
                return false;
              } else {
                return true;
              }
            });
            this.createForms();
          },
          error => {
            this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: "Question could not be deleted now, please try later.", detail: '' });
          });
      }
    });
  }
  makeQuestionEditable(questionId) {
    this.discussions = this.discussions.map(disc => {
      if (disc.questionId == questionId) {
        disc.editClicked = true;
      }
      return disc
    });
    this.createForms();
  }
  updateQuestion(index) {
    this.editQuestionButtonPressedForError[index] = true;
    if (!this.editQuestion[index] || this.editQuestionButtonPressed[index]) {
      return false;
    }

    this.editQuestionButtonPressed[index] = true;
    let formData = {
      question: this.editQuestion[index],
      questionId: this.discussions[index].questionId
    };

    this.discussionService.updateQuestion(formData).subscribe(
      data => {
        this.editQuestionButtonPressed[index] = false;
        this.discussions = this.discussions.map(disc => {
          if (disc.questionId == formData.questionId) {
            disc.question = formData.question;
            disc.editClicked = false;
          }
          return disc;
        });
        this.createForms();
        this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Question updated successfully.", detail: '' });
      },
      error => {
        this.editQuestionButtonPressed[index] = false;
        Swal.fire('Error!', 'This comment seems irrelevant or inappropriate and can’t be posted.', 'error');
      }
    );
  }
  deleteAnswer(questionId, answerId) {
    Swal.fire({
      title: 'Are you sure you wish to remove this Answer?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.discussionService.deleteAnswer(answerId).subscribe(
          data => {
            this.discussions = this.discussions.map(disc => {
              if (disc.questionId == questionId) {
                if (!disc.answerList) { disc.answerList = { content: [], totalElements: 1 }; }
                disc.answerList.content = disc.answerList.content.filter(a => {
                  return a.answerId != answerId;
                });
                disc.answerCount = disc.answerCount - 1;
                disc.answerList.totalElements = disc.answerList.totalElements - 1;
              }
              return disc;
            });
            this.loadAnswers(questionId, true);
            this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'success', summary: "Answer deleted successfully.", detail: '' });
          },
          error => {
            this.messageService.add({ key: 'commentMsg', life: 3000, severity: 'error', summary: "Answer could not be deleted now, please try later.", detail: '' });
          });
      }
    });
  }
  loadAnswers(questionId, loadCurrent) {
    let answerPageNumber = 0;
    let totalAnswerPages = 1;
    this.discussions.forEach(disc => {
      if (disc.questionId == questionId) {
        if (!disc.answerList) { disc.answerList = { content: [] }; }
        if (disc.answerList.pageable && disc.answerList.pageable.pageNumber != undefined) {
          if (loadCurrent) {
            answerPageNumber = disc.answerList.pageable.pageNumber;
          } else {
            answerPageNumber = disc.answerList.pageable.pageNumber + 1;
          }
        }
        if (disc.answerList.totalPages != undefined) {
          totalAnswerPages = disc.answerList.totalPages;
        }
      }
    });
    if (answerPageNumber < totalAnswerPages)
      this.discussionService.getAnswers(questionId, answerPageNumber, 5).subscribe(
        (data) => {
          this.discussions = this.discussions.map(disc => {
            if (disc.questionId == questionId) {
              if (!disc.answerList) { disc.answerList = { content: [] }; }
              if (loadCurrent) {
                data.content.forEach(item => {
                  let flag = false;
                  disc.answerList.content.forEach(eitem => {
                    if (eitem.answerId == item.answerId) {
                      flag = true;
                    }
                  });
                  if (!flag)
                    disc.answerList.content = disc.answerList.content.concat([item]);
                });
              } else {
                disc.answerList.content = disc.answerList.content.concat(data.content);
              }
              disc.answerList.pageable = data.pageable;
              disc.answerList.totalElements = data.totalElements;
            }
            return disc;
          });
          this.createForms();
        },
        (error) => {
          console.log(error);
        }
      );
  }
  cancelEditAnswer(questionIndex, answerIndex) {
    this.isInputValid = false;
    this.editAnswerButtonPressedForError[questionIndex][answerIndex] = false;
    this.editAnswerButtonPressed[questionIndex][answerIndex] = false;
    this.isValidEditAnswer[questionIndex + '_' + answerIndex] = true;
    this.editAnswerMentions[questionIndex + '_' + answerIndex] = [];
    let formData = {
      answer: this.editAnswer[questionIndex][answerIndex],
      questionId: this.discussions[questionIndex].questionId,
      answerId: this.discussions[questionIndex].answerList.content[answerIndex].answerId
    };
    this.editAnswerButtonPressed[questionIndex][answerIndex] = false;
    this.discussions = this.discussions.map(disc => {
      if (disc.questionId == formData.questionId) {
        if (!disc.answerList) { disc.answerList = { content: [] }; }
        disc.answerList.content = disc.answerList.content.map(a => {
          if (a.answerId == formData.answerId) {
            a.editClicked = false;
          }
          return a;
        });
      }
      return disc;
    });
  }
  cancelEditQuestion(index) {
    this.isQuestionEdited = false;
    this.editQuestionButtonPressedForError[index] = false;
    this.editQuestionButtonPressed[index] = false;
    let formData = {
      question: this.editQuestion[index],
      questionId: this.discussions[index].questionId
    };
    this.editQuestionButtonPressed[index] = false;
    this.discussions = this.discussions.map(disc => {
      if (disc.questionId == formData.questionId) {
        disc.editClicked = false;
      }
      return disc;
    });
  }

  onNewAnswerMentionChange(item: any, index: number) {
    this.newAnswer[index] = item.dataComment;
    this.isValidNewAnswer[index] = item.isValid;
    this.newAnswerMentions[index] = item.mentions;
  }

  onEditAnswerMentionChange(item: any, index: number, subindex: number) {
    this.isInputValid = true;
    const id = index + '_' + subindex;
    this.editAnswer[index][subindex] = item.dataComment;
    this.isValidEditAnswer[id] = item.isValid;
    this.editAnswerMentions[id] = item.mentions;
  }

  get isCommunityManager() {
    return this.authService.isCommunityManager();
  }

  getLink(userId: any, userRole: any) {
    let url = "/details/" + (userRole ? userRole.toLowerCase() : "cm") + "/" + userId;
    console.log(this.router.url, "urlmai")
    if (this.router.url.indexOf("/user-browse") > -1 ||
      this.router.url.indexOf("/growth-") > -1 ||
      this.router.url.indexOf("search-") > -1 ||
      this.router.url.indexOf("forum") > -1) {
      url = "/user-browse" + url;
    } else {
      url = "/browse" + url;
    }
    return [url];
  }

  comapanyredirection(userId: any, userRole: any) {
    if (!this.isCommunityManagerssss) {
      this.router.navigate(this.getLink(userId, userRole))
    }
  }

  helpful(answerId) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.forumquestions = this.discussions.map((forum) => {

      if (forum?.answerList?.content && forum?.answerList?.content?.length > 0) {
        forum?.answerList?.content.forEach((forumans) => {
          if (forumans.answerId == answerId) {
            if (forumans.unHelpful || (!forumans.helpful && !forumans.unHelpful)) {
              if (forumans.totalUnHelpful > 0 && forumans.unHelpful) {
                forumans.totalUnHelpful = forumans.totalUnHelpful - 1;
              }
              forumans.totalHelpful = forumans.totalHelpful + 1;
              that.discussionService.setForumHelpfulness(answerId, true).subscribe(res => { });
              forumans.helpful = true;
              forumans.unHelpful = false;
            } else if (forumans.helpful) {
              forumans.helpful = false;
              forumans.totalHelpful = forumans.totalHelpful - 1;
              that.discussionService.setForumHelpfulness(answerId, false).subscribe(res => { });
            }
          }
        });
      }
      return forum;
    });
  }
  unHelpful(answerId) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.forumquestions = this.discussions.map((forum) => {
      if (forum?.answerList?.content && forum?.answerList?.content?.length > 0) {
        forum?.answerList?.content.forEach((forumans) => {
          if (forumans.answerId == answerId) {
            if (forumans.helpful || (!forumans.helpful && !forumans.unHelpful)) {
              if (forumans.totalHelpful > 0 && forumans.helpful) {
                forumans.totalHelpful = forumans.totalHelpful - 1;
              }
              forumans.totalUnHelpful = forumans.totalUnHelpful + 1;
              that.discussionService.setForumUnHelpfulness(answerId, true).subscribe(res => { });
              forumans.unHelpful = true;
              forumans.helpful = false;
            } else if (forumans.helpful) {
              forumans.unHelpful = false;
              forumans.totalUnHelpful = forumans.totalUnHelpful - 1;
              that.discussionService.setForumUnHelpfulness(answerId, false).subscribe(res => { });
            }
          }
        });
      }
      return forum;
    });
  }

  groupredirection(groupId) {
    this.router.navigate(['/details/' + groupId]);
  }

  //reply to the answer
  // showsomething(answerId: string, userId: string) {
  //   const user = this.findUserByAnswerAndUserId(answerId, userId);
  //   if (user) {
  //     user.showReplyInput = true;
  //   }
  // }

  // findUserByAnswerAndUserId(answerId: string, userId: string) {
  //   for (const forum of this.discussions) {
  //     const user = forum.answerList?.content.find(forumans => forumans.answerId === answerId && forumans.userId === userId);
  //     if (user) {
  //       return user;
  //     }
  //   }
  //   return null;
  // }

  // sendReply(answerId: string, userId: string, replyMessage: string) {
  //   const user = this.findUserByAnswerAndUserId(answerId, userId);
  //   if (user) {
  //     const reply = {
  //       userId: user.userId,
  //       message: replyMessage,
  //       timestamp: new Date()
  //     };
  //     user.replyMessage = "";
  //     user.showReplyInput = false;
  //   }
  // }

  // onReplyValueChange(value: string, answerId: string, userId: string) {
  //   const user = this.findUserByAnswerAndUserId(answerId, userId);
  //   if (user) {
  //     user.replyMessage = value;
  //   }
  // }
}
