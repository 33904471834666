<app-language-selector></app-language-selector>
<ul class="list">
  <li *ngFor="let item of lists?.list; index as i">
    <div class="row">
      <div class="col-auto p-r-0" *ngIf="item.photo">
        <img src="{{item.photo}}" id="{{item.imgId}}{{i+1}}" class="img-radius img-fluid" alt="alt" />
      </div>
      <div class="col-auto p-r-0" *ngIf="item.icon">
        <em class="{{item.icon}} {{item.iconClass}}"></em>
      </div>
      <div class="col">
        <h6 class="m-b-5">
          <p>{{item.title | translate}}</p>
          <a href="{{item.url}}" id="{{item.aId}}{{i+1}}">{{item.anchor | translate}}</a>
        </h6>
      </div>
    </div>
  </li>
</ul>