import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { Constants } from 'src/app/shared/constants/constants';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ServiceCardService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) { }

  getServiceCards(page: number, size: number, searchText: string, sortAsc: string, sortDesc: string, userId: number) {

    let params;
    if (userId) {
      params = new HttpParams()
        .set('searchText', searchText)
        .set('sortAsc', sortAsc)
        .set('sortDesc', sortDesc)
        .set('pageNumber', page.toString())
        .set('pageSize', size.toString())
        .set('userId', userId.toString());
    } else {
      params = new HttpParams()
        .set('searchText', searchText)
        .set('sortAsc', sortAsc)
        .set('sortDesc', sortDesc)
        .set('pageNumber', page.toString())
        .set('pageSize', size.toString());
    }
    return this.http.get<any>(`${this.BASE_API_URL}api/service-card`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getLatestServiceCards(userId?: any) {
    let url = `${this.BASE_API_URL}api/service-card/latest`;
    if (userId) {
      url += '/' + userId;
    }
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  saveServiceCard(serviceCard: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card`, serviceCard,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  updateServiceCard(serviceCard: any) {
    return this.http.put<any>(`${this.BASE_API_URL}api/service-card`, serviceCard,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getBookings(pageNumber: any, filters: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/getBookings?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + pageNumber, filters,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }

  getAllBookings(pageNumber: any, filters: any, sortColumn, sortOrder) {
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/getAllBookings?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + pageNumber + `&sortBy=` + sortColumn + `&sortOrder=` + sortOrder, filters,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }

  getAllBookingscount(pageNumber: any, filters: any, sortColumn, sortOrder) {
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/getAllBookings?pageSize=` + 100 + `&pageNumber=` + pageNumber + `&sortBy=` + sortColumn + `&sortOrder=` + sortOrder, filters,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }

  replyToBooking(bookingId, message) {
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/replyToBooking`, { bookingId: bookingId, message: message },
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }
  bookService(serviceId: any) {
    const params = new HttpParams()
      .set('serviceId', serviceId);
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/bookService`, { 'serviceId': serviceId },
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  deleteService(serviceId: any) {
    const params = new HttpParams()
      .set('serviceId', serviceId);
    return this.http.post<any>(`${this.BASE_API_URL}api/service-card/deleteService`, { 'serviceId': serviceId },
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  getFeaturedGrowthCards() {
    return this.http.get<any>(`${this.BASE_API_URL}api/growth-cards/getFeaturedGrowthCards`)
      .pipe(tap((appRef: any) => { }));
  }
  getCategoryWiseGrowthCards() {
    return this.http.get<any>(`${this.BASE_API_URL}api/utility-category/getCategoryWiseGrowthCards`)
      .pipe(tap((appRef: any) => { }));
  }
  getAllUtilityCategories() {
    return this.http.get<any>(`${this.BASE_API_URL}api/utility-category/getAllUtilityCategories`)
      .pipe(tap((appRef: any) => { }));
  }
  getCategoryWiseGrowthPartners() {
    return this.http.get<any>(`${this.BASE_API_URL}api/utility-category/getCategoryWiseGrowthPartners`)
      .pipe(tap((appRef: any) => { }));
  }

  saveGrowthCard(growthCard: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/growth-cards/createGrowthCard`, growthCard,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  updateGrowthCard(serviceCard: any) {
    return this.http.put<any>(`${this.BASE_API_URL}api/growth-cards/updateGrowthCard`, serviceCard,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getGCById(gcId: number) {
    return this.http.get<any>(`${this.BASE_API_URL}api/growth-cards/getGCById?gcId=` + gcId)
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }


  growthcardDetail(gcId) {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/growth-cards/getGCDetailById?gcId=` + gcId)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
}
