<div class="row">
  <div class="col-lg-12 col-xl-12 col-md-7">
    <div class="group-loaders" *ngIf="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
      </div>
    </div>
    <app-browse-card [BrowseCards]="browsecompanycards" *ngIf="!loading">
    </app-browse-card>
    <div class="text-center" *ngIf="!totalItems && !loading">No Results</div>
    <app-pagination [totalItems]="totalItems" [currentPage]="companypageNumber" [pageSize]="pageSize" [maxPages]="5"
      (changePage)="goToPage($event)" *ngIf="!loading && totalItems>9"></app-pagination>
  </div>
</div>