<app-language-selector></app-language-selector>
<div class="growth-cards">
  <div class="dropdowns d-flex justify-content-end">
    <div class="d-drop mr-3 ">
      <p-dropdown [options]="utilityCategories" [virtualScroll]="true" [(ngModel)]="utilityCategorySelected"
        id="utilityCategorySelected" placeholder="Select service utility category" optionLabel="name" [showClear]="true"
        [virtualScroll]="true" (onChange)="utilityCategoriesChanged()">
      </p-dropdown>
    </div>
  </div>
  <div class="">
    <div class="">
      <p-dataView #dv [value]="growthCardsData" [paginator]="true" [rows]="9" filterBy="name"
        [totalRecords]="totalpublishedRecords" [showCurrentPageReport]="false" [sortField]="sortField"
        [sortOrder]="sortOrder" layout="grid" [lazy]="true" (onLazyLoad)="getAllGrowthCards($event)"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <ng-template pTemplate="header">
          <div class="d-flex justify-content-between product-header">
            <div class="p-input-icon-left mb-2 md:mb-0">
            </div>
          </div>
        </ng-template>

        <ng-template let-card pTemplate="gridItem">
          <div class="product-grid-item product-carousel">
            <div class="rowm">
              <div class="card">

                <div class="d-flex justify-content-between">
                  <h6 class="mb-1 title cursor-pointer">
                    <span tooltipPosition="top">
                      {{card['growthCardTitle']}}
                    </span>
                  </h6>

                  <div class="mt-3 input-switch">
                    <p-inputSwitch [(ngModel)]="card.featured" (click)="changeFeaturedStatus(card);"
                      [disabled]="card.utilityCategoryList.length == 0"></p-inputSwitch>
                  </div>
                </div>

                <div class="img_mg">
                  <img src="{{card.image ? card.image : '../../../../../assets/images/default.jpg'}}" alt="">
                </div>

                <div class="d-flex card-data companyinfo">
                  <img src="{{ card['growthPartnerLogo'] }}" alt="" style="">
                  <p class="mt-2" style="font-weight: 500;">{{ card['growthPartnerName'] }}</p>
                </div>

                <div class="d-flex card-data mt-2 mx-1">
                  <div class="mr-3">
                    <p>
                      <em class="fas fa-suitcase"></em>
                      {{ card['costEstimate'] }} ZAR
                    </p>
                  </div>

                  <div>
                    <p>
                      <em class="fas fa-calendar-alt"></em>
                      {{ card['timeEstimate'] }}
                    </p>
                  </div>
                </div>

                <div class="card-data">
                  <p class="utility-cat mt-3">
                    <ng-container *ngIf="card.utilityCategoryList.length > 0">
                      <ng-container *ngFor="let data of card.utilityCategoryList">
                        <span
                          [ngClass]="{'more-50 break-all': data.utilityCategoryName.length > 35, 'more-35 break-all': data.utilityCategoryName.length <= 35}">
                          {{data.utilityCategoryName}}
                        </span>
                      </ng-container>

                      <span>
                        <i (click)="setCurrentItem(card);growthCardsModal = true;" class="fas fa-plus pointer"></i>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="card.utilityCategoryList.length == 0">
                      <span class="pointer" (click)="setCurrentItem(card);growthCardsModal = true;">
                        Select utility category
                      </span>
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>

<div class="my-profile">
  <p-dialog header="" [(visible)]="growthCardsModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
    class="feedFormModal" [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal"
    [blockScroll]="true" [resizable]="true" [closable]="true">
    <div class="editpersonalinfo">
      <div class="app-modal-header text-center">
        <h5 class="modal-title" id="ttlShareArticle">
          <span>{{'growthCards.model.title' | translate }}</span>
        </h5>
      </div>

      <form>
        <div class="select-categories">
          <form id="utilityCategoriesToApply" [formGroup]="utilityCategoriesSelectForm" #myNgForm="ngForm">
            <p class="text-center {{ showError ? 'error' : 'help-text' }}">{{ 'growthCards.model.dicp' | translate }}
            </p>
            <div formArrayName="selectedCategories" class="custom-control custom-checkbox m-3"
              *ngFor="let item of utilityCategories; index as i">
              <input (change)="checkItemsSelected()" [formControlName]="i" type="checkbox" class="custom-control-input"
                id="{{'growthCards.model.id' | translate }}{{i+1}}" value="{{item.id}}">
              <label class="custom-control-label break-all"
                for="{{'growthCards.model.id' | translate }}{{i+1}}">{{item.name}}</label>
            </div>
          </form>
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-secondary mr-3" (click)="clearData()">Cancel</button>
          <button type="button" class="btn btn-confirm" (click)="updateUtilityCategoriesForSelectedItem()"
            [disabled]="invalid">Apply</button>
        </div>
      </form>
    </div>
  </p-dialog>
</div>

<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>