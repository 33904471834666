import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants/constants';
import { ClientInfoService } from '../client-info/client-info.service';
import { TokenService } from '../token/token.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  jwt_token: string;

  public feedSubject: BehaviorSubject<any>;
  public feedvalue: Observable<any>;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) {
    this.jwt_token = tokenService.getToken();

    this.feedSubject = new BehaviorSubject<any>(null);
    this.feedvalue = this.feedSubject.asObservable();
  }

  public get currentfeedValue(): any {
    return this.feedSubject.value;
  }

  updatecurrentfeedValue(value: any) {
    this.feedSubject.next(value)
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  fetchFeed(pageNumber): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/feed/getFeed?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + pageNumber)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getFeedForApproval(pageNumber): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getFeedForApproval`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getRejectedFeedList(pageNumber): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getRejectedFeedList`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }


  getFeedForManagerView(pageNumber): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getFeedForManagerView`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getMyFeedContent(pageNumber): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getMyFeedContent`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getTopArticles(pageNumber): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('pageNumber', pageNumber);
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getTopArticles`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  saveFeed(feedData: any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/feed/shareFeed`, feedData)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  updateFeed(feedData: any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/feed/updateFeed`, feedData)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  setHelpfulness(feedId, helpful): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/feed/setHelpfulness?feedId=` + feedId + '&helpful=' + helpful, '')
      .pipe(tap((appRef: any) => { }))
    return res;
  }
  setUnHelpfulness(feedId, unHelpful): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/feed/setUnHelpfulness?feedId=` + feedId + '&unHelpful=' + unHelpful, '')
      .pipe(tap((appRef: any) => { }))
    return res;
  }
  submitReviewStatus(feedId, status): Observable<any> {
    const res: Observable<any> = this.http.post(`${this.BASE_API_URL}api/feed/submitReviewStatus?feedId=` + feedId + '&status=' + status, '')
      .pipe(tap((appRef: any) => { }))
    return res;
  }

  fetchFeedComments(feedId: number, pageSize: number): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/feed/getFeedComments?feedId=` + feedId + `&pageNumber=1&pageSize=` + pageSize)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  getFeedComments(query: string) {
    return this.http.get<any>(`${this.BASE_API_URL}api/feed/comments${query}`,
      {
        headers: this.tokenService.getHttpHeader()
      }
    );
  }

  submitFeedComment(feedId: number, commentText: string, mentions: any, parentCommentId: any): Observable<any> {
    let baseUrl = `${this.BASE_API_URL}api/feed/submitFeedComment`;
    let body = {
      "feedId": feedId,
      "comment": commentText,
      "mentions": mentions,
      "parentCommentId": parentCommentId
    };
    const res: Observable<any> =
      this.http.post(baseUrl, body)
        .pipe(tap((appRef: any) => { }))

    return res;
  }

  deleteFeedComment(commentId: number): Observable<any> {
    const res: Observable<any> = this.http.delete(`${this.BASE_API_URL}api/feed/deleteFeedComment?commentId=` + commentId)
      .pipe(tap((appRef: any) => { }))
    return res;
  }

  updateFeedComment(commentId: number, commentText: string, mentions: any): Observable<any> {
    let baseUrl = `${this.BASE_API_URL}api/feed/updateFeedComment`;
    let body = {
      "commentId": commentId,
      "comment": commentText,
      "mentions": mentions
    };
    const res: Observable<any> =
      this.http.put(baseUrl, body)
        .pipe(tap((appRef: any) => { }))

    return res;
  }

  getRelativeTime(value: any, limit: number) {
    if (value) {
      const differenceInSeconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      // less than 60 seconds ago will show as 'Just now'
      if (differenceInSeconds < 60) {
        return 'Just now';
      }
      const upperLimit = limit > 0 ? limit : 31536000;
      if (differenceInSeconds > upperLimit) {
        return moment(value).format('MMM D, y');
      }
      // All values are in seconds
      const timeIntervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1,
      };
      let counter;
      for (const i in timeIntervals) {
        counter = Math.floor(differenceInSeconds / timeIntervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            // singular (1 day ago)
            return counter + ' ' + i + ' ago';
          } else {
            // plural (2 days ago)
            return counter + ' ' + i + 's ago';
          }
        }
      }
    }
    return value;
  }

  getFormattedDate(value: any) {
    if (value) {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
    return value;
  }

  getFeedDetail(feedId) {
    const res: Observable<any> = this.http.get(`${this.BASE_API_URL}api/feed/getFeedDetails?feedId=` + feedId)
      .pipe(tap((appRef: any) => { }))
    return res;
  }
}
