import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { MessageService } from 'primeng/api';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { Common } from 'src/app/utility/common';
import { Currency } from 'src/app/core/Enums/currency';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { GrowthCardService } from 'src/app/core/services/growth-card/growth-card.service';


export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);


@Component({
  selector: 'app-featuredgc',
  templateUrl: './featuredgc.component.html',
  styleUrls: ['./featuredgc.component.scss']
})
export class FeaturedGcComponent implements OnInit {
  @Output() totalRecordsfavorite = new EventEmitter<number>();
  public videoArticles: any;
  public sideCardContent: any;
  public getGrowthcard: any;
  @Input() type: string = "service";
  @Input() BrowseCards: any = [];
  @Input() searchItems: any = [];
  @Input() featuredgcrefresh: number;
  loading: boolean;
  pageNumber: number = 1;
  pageSize: number = 1;
  totalItems = 0;
  categories: string = "All";
  categoryList: Array<any>;
  allGrowthCard: any;
  responsiveOptions: any;
  responsiveSCOptions: any;
  hasItems = false;
  services: any = [];
  userId: any;
  isPublic: boolean = false;
  totalRecords: number;
  totalhealthRecords: number;
  favoriterecords: number;


  description = Common.discp;
  public keyupLabelOn = false;
  public keydownLabelOn = false;
  public someRange2: number[] = [0, 250000];
  public noOfweeks: number[] = [0, 3];

  maxAllowedSize = 1 * 1024 * 1024;

  public weeksConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 4],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 5
    },
    pips: {
      mode: 'count',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  format = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: Currency.MUR,
    minimumFractionDigits: 0,
  }
  );

  public ServicePriceRangeConfig: any = {
    start: [0, 250000],
    connect: true,
    step: 1000,
    range: {
      min: 0,
      max: 1000000
    },
    pips: {
      mode: 'count',
      values: 2
    },
    format: {
      to: function (value) {
        return Currency.RS + (new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: Currency.MUR,
          minimumFractionDigits: 0,
        }).format(Math.floor(value))).replace("MUR", "");
      },
      from: function (value) {
        return Number(value.replace('Rs', '').trim().replace(/\,/g, ''));
      }
    }
  };

  businesses = Common.helpBusinesses;
  selectedBusinesses = '';
  nOfweeks = Common.nOfweeks;
  isLoggedIn: boolean;
  editMode: boolean;
  selectedService: any;
  imagePath: any;
  previewURL: any;
  records: any;

  constructor(
    private serviceCardService: ServiceCardService,
    private commonService: CommonService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private utility: UtilityService,
    private router: Router,
    private messageService: MessageService,
    private gaService: GoogleAnalyticsService,
    private growthcardservice: GrowthCardService,
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.videoArticles = res.sideContent.Growthcard.video;
        this.sideCardContent = res.sideContent.Growthcard;
        this.getGrowthcard = res.growthcard.Title;
        this.allGrowthCard = res.growthcard.Growthitem.data;
      })
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.responsiveSCOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestServiceList();
    });
    this.growthcardservice.gcfeatured.subscribe(value => {
      this.getFeaturedGrowthCards();
    });
    // this.getCategoryWiseGrowthCards();
    this.getFeaturedGrowthCards();
  }


  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }

  formatTimeEstimatePreview(value) {
    return this.utility.formatTimeEstimate(value);
  }

  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }

  loadLatestServiceList() {
    this.loading = true;
    this.serviceCardService.getLatestServiceCards(this.userId).subscribe(
      (data) => {
        this.services = data;
        if (Array.isArray(this.services) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.services = [];
        this.loading = false;
        console.log(error);
      }
    );
  }
  featuredServices: any = [];
  getFeaturedGrowthCards() {
    this.loading = true;
    this.serviceCardService.getFeaturedGrowthCards().subscribe(
      (data) => {
        this.featuredServices = data;
        this.favoriterecords = data.length;
        this.totalRecordsfavorite.emit(this.favoriterecords);
        if (Array.isArray(this.featuredServices) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.featuredServices = [];
        this.loading = false;
        console.log(error);
      }
    );
  }
  categoryWiseGrowthCards = [];

  redirectToAllCards(id) {
    this.router.navigate(['/growth-cards/all-cards'], {
      queryParams: { categories: id }
    });
  }
  userCanBook(service: any) {
    return this.isLoggedIn && !service.booked;
  }

  onBookBtnClick(service: any) {
    if (service.booking || service.booked) {
      return false;
    }
    service.booking = true;
    this.gaService.logCustomEvent("Booked", "Growth Cards", service.name);
    this.serviceCardService.bookService(service.growthCardId).subscribe(
      data => {
        service.booked = true;
        service.booking = false;
        this.toastMessage("Book Growth Card", "A request has been successfully sent to the Service Provider", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      error => {
        if (Common.isBRNErrors(error)) {
          this.updateBRNPopup = true;
          this.currentBookingItem = service;
        } else {
          service.booking = false;
          this.toastMessage("Book Growth Card", "Unable to book Growth Card! Please try after some time.", "error");
        }
      }
    );
  }
  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }
  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
