import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { Constants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-follow-content',
  templateUrl: './follow-content.component.html',
  styleUrls: ['./follow-content.component.scss']
})
export class FollowContentComponent implements AfterViewInit, OnDestroy, OnInit {
  followersdtOptions: DataTables.Settings = {};
  followers = [];
  @ViewChild(DataTableDirective, { static: false }) followersdtElement: DataTableDirective;
  followersdtTrigger: Subject<any> = new Subject();
  totalFollowers = 0;

  followeesdtOptions: DataTables.Settings = {};
  followees = [];
  @ViewChild(DataTableDirective, { static: false }) followeesdtElement: DataTableDirective;
  followeesdtTrigger: Subject<any> = new Subject();
  totalFollowees = 0;
  totalRecords: number;
  totalRecordsfollowees: number;
  totalPages = 1;
  totalPagesfollowees = 1;
  cols = [
    { field: 'companyName', header: 'Company Name', sortable: true },
    { field: 'industry', header: 'Industry', sortable: true },
    { field: 'id', header: 'Actions', sortable: false }
  ];

  constructor(private profileService: ProfileService, private messageService: MessageService) { }

  async ngOnInit(): Promise<void> {
    //this.unFollow({});
    await this.getFolloweescount("SME");
  }

  async getFolloweescount(userType) {
    this.profileService.getFollowees(0, 0, "", "").subscribe(
      res => {
        if (userType == 'SME') {
          this.totalRecordsfollowees = res.total;
          this.totalPagesfollowees = Math.ceil(this.totalRecordsfollowees / Constants.PAGE_LIMIT);
          window.scrollTo(0, 0);
        }
      })
  }

  getFollowers(event) {
    let sortColumn = event.sortField ? event.sortField : "companyName";
    let sortOrder = (event.sortOrder && event.sortOrder == 1) ? "asc" : "desc";

    this.profileService.getFollowers(event.rows, (event.first / event.rows), sortColumn, sortOrder).subscribe(
      res => {
        this.followers = res.followList;
        this.totalRecords = res.total;
        this.totalPages = Math.ceil(this.totalRecords / Constants.PAGE_LIMIT);
        window.scrollTo(0, 0);
      })
  }
  getFollowees(event) {
    let sortColumn = event.sortField ? event.sortField : "companyName";
    let sortOrder = (event.sortOrder && event.sortOrder == 1) ? "asc" : "desc";

    this.profileService.getFollowees(event.rows, (event.first / event.rows), sortColumn, sortOrder).subscribe(
      res => {
        this.followees = res.followList;
        // this.totalRecordsfollowees = res.total;
        // this.totalPagesfollowees = Math.ceil(this.totalRecordsfollowees / Constants.PAGE_LIMIT);
        // window.scrollTo(0, 0);
      })
  }

  ngAfterViewInit(): void {
    this.followersdtTrigger.next();
    this.followeesdtTrigger.next();
  }

  ngOnDestroy(): void {
    this.followersdtTrigger.unsubscribe();
    this.followeesdtTrigger.unsubscribe();
  }
  rerender(): void {
    this.followersdtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.followersdtTrigger.next();
    });
    this.followeesdtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.followeesdtTrigger.next();
    });
  }
  unFollow(followeeId): void {
    // console.log(followeeId)
    var name = "";
    this.followers.forEach(function (d) {
      if (d.userId == followeeId) {
        name = d.companyName;
      }
    });
    this.profileService.unFollow(followeeId).subscribe(
      res => {
        this.messageService.add({
          key: 'app', life: 3000, severity: 'success',
          summary: "You are no longer following " + name + ". You’ll stop receiving updates in your mailbox.",
          detail: ''
        });
        //console.log(this.followees, "bad");
        for (let i = 0; i < this.followees.length; i++) {
          //console.log(this.followees[i].userId, followeeId);
          if (this.followees[i].userId == followeeId) {
            this.followees.splice(i, 1);
            this.totalRecordsfollowees--;
            break;
          }
        }
        this.followers = this.followers.filter(function (d) {
          if (d.userId != followeeId) {
            return true;
          }
        });
        // this.totalRecords = this.totalRecords - 1;
      });
  }

}
