import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { ProfileService } from '../../../core/services/profile-service/profile.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { MessageService } from 'primeng/api';
import { Common } from '../../../utility/common';

@Component({
  selector: 'app-org-details',
  templateUrl: './org-details.component.html',
  styleUrls: ['./org-details.component.scss']
})
export class OrgDetailsComponent implements OnInit {
  loading: boolean;
  userId: any;
  orgDetails: any = {};
  editMode = false;
  orgForm: FormGroup;
  cmOrgForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  profilePic: any;
  isSME: boolean;
  isServiceProvider: boolean;
  isInvester: boolean;
  isCommunityManager: boolean;
  isRM: boolean;
  showorgdetailsDialog: boolean;
  isDirty: boolean;


  constructor(
    private profileService: ProfileService,
    private authService: UserAuthService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.isSME = this.authService.isSME();
    this.isServiceProvider = this.authService.isServiceProvider();
    this.isInvester = this.authService.isInvester();
    this.isCommunityManager = this.authService.isCommunityManager();
    this.isRM = this.authService.isRelationShipManager();
    this.getCompanyProfile();
  }

  constructForm() {
    this.orgForm = new FormGroup({
      companyName: new FormControl(this.orgDetails ? this.orgDetails.companyName : "", [
        Validators.required,
        CustomValidators.removeSpaces,
        Validators.maxLength(40)
      ]),
      brnNumber: new FormControl(this.orgDetails ? this.orgDetails.brnNumber : "", [
        Validators.required,
        CustomValidators.removeSpaces,
        CustomValidators.patternValidator(Common.brnRegEx, { hasValidBRN: true })
      ]),
      tradeLicense: new FormControl(this.orgDetails ? this.orgDetails.tradeLicense : "", Validators.required),
      companyRegion: new FormControl(this.orgDetails ? this.orgDetails.companyRegion : "", Validators.required),
      industry: new FormControl(this.orgDetails ? this.orgDetails.industry : "", [Validators.required, CustomValidators.removeSpaces]),
      incorporationYear: new FormControl(this.orgDetails ? this.orgDetails.incorporationYear : ""),
      about: new FormControl(this.orgDetails ? this.orgDetails.about : "", [
        Validators.required, CustomValidators.removeSpaces,
        Validators.maxLength(1000)
      ])
    });
  }
  cmConstructForm() {
    this.cmOrgForm = new FormGroup({
      companyName: new FormControl(this.orgDetails ? this.orgDetails.companyName : "", [
        Validators.required, CustomValidators.removeSpaces,
        Validators.maxLength(40)
      ]),
    });
  }

  editDetails() {
    this.showorgdetailsDialog = true;
    this.editMode = true;
    this.constructForm();
    this.cmConstructForm();
  }
  editCancel() {
    this.showorgdetailsDialog = false;
    this.editMode = false;
    this.orgForm.reset();
    this.cmOrgForm.reset();
  }
  selectedPic(imageData) {
    this.profilePic = imageData;
  }
  updateDetails() {
    this.isSubmit = true;
    if (this.orgForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let formData = this.orgForm.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(formData));
    payload.append('file', this.profilePic, "profileImg.jpg");

    this.profileService.saveOrglDetails(payload).subscribe(
      data => {
        this.getCompanyProfile();
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Company / Page details', detail: 'Successfully updated' });
        this.buttonPressed = false;
        this.isSubmit = false;
        this.orgForm.reset();
        this.editMode = false;
        this.showorgdetailsDialog = false;
      },
      error => {
        this.buttonPressed = false;
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Company / Page details', detail: 'Something went wrong' });
      }
    );
  }
  cmOrgUpdateDetails() {
    this.isSubmit = true;
    if (this.cmOrgForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let formData = this.cmOrgForm.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(formData));
    payload.append('file', this.profilePic);

    this.profileService.saveOrglDetails(payload).subscribe(
      data => {
        this.getCompanyProfile();
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Company details', detail: 'Successfully updated' });
        this.buttonPressed = false;
        this.isSubmit = false;
        this.cmOrgForm.reset();
        this.editMode = false;
      },
      error => {
        this.buttonPressed = false;
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Company details', detail: 'Something went wrong' });
      }
    );
  }

  getCompanyProfile() {
    this.loading = true;
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.orgDetails = data ? data : {};
        this.loading = false;
        this.constructForm();
        this.cmConstructForm();
      },
      (error) => {
        this.orgDetails = {};
        this.loading = false;
        console.log(error);
      }
    );
  }
}
