import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { ProfileService } from '../../../core/services/profile-service/profile.service';
import { PASWWORD_PATTERN_CAPCHAR, PASWWORD_PATTERN_NUM, PASWWORD_PATTERN_SMLCHAR, PASWWORD_PATTERN_SPLCHAR } from '../../../shared/constants/constants';
import { UtilityService } from '../../../core/services/utility/utility.service';



@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  editMode = false;
  token: string;
  loading: boolean;
  passwordForm: FormGroup;
  lastPwdDate: any = '';
  isSubmit: boolean;
  buttonPressed: boolean;
  lastDate: any;
  isTextFieldType: boolean;
  isReTextFieldType: boolean;
  isReTextCnfmType: boolean;
  showpasswordDialog: boolean;

  constructor(
    private profileService: ProfileService,
    private authService: UserAuthService,
    private messageService: MessageService,
    private router: Router,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.token = this.authService.getAuthToken();
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl('', [
        Validators.required, CustomValidators.removeSpaces
      ]),
      newPassword: new FormControl('', [
        Validators.required, CustomValidators.removeSpaces,
        Validators.minLength(8),
        Validators.maxLength(25),
        CustomValidators.patternValidator(PASWWORD_PATTERN_NUM, { hasNumber: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_CAPCHAR, { hasCapitalCase: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_SMLCHAR, { hasSmallCase: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_SPLCHAR, { hasSpecialCharacters: true })
      ]),
      confirmPassword: new FormControl('', [Validators.required, CustomValidators.removeSpaces])
    },
      CustomValidators.passwordMatchValidator('newPassword', 'confirmPassword')
    );
    this.getPwdLastupdatedDate();
  }
  editDetails() {
    this.editMode = true;
    this.showpasswordDialog = true
  }
  editCancel() {
    this.passwordForm.reset();
    this.editMode = false;
    this.isSubmit = false;
    this.showpasswordDialog = false;
    // this.passwordForm.reset();

  }
  savePassword() {
    this.isSubmit = true;
    if (this.passwordForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let resetForm = this.passwordForm.value;
    if (resetForm.confirmPassword) delete resetForm.confirmPassword;
    resetForm.token = this.token;

    this.authService.updatePassword(resetForm).subscribe(
      data => {
        this.buttonPressed = false;
        this.isSubmit = false;
        if (data.success) {
          this.lastDate = new Date();
          this.lastPwdDate = this.utilityService.getFormattedDate(this.lastDate);
          this.authService.saveToken(this.token);
          this.messageService.add({ key: 'c', severity: 'success', summary: 'Password', detail: data.message });
          this.passwordForm.reset();
          this.getPwdLastupdatedDate();
          this.editMode = false;
          this.showpasswordDialog = false;
        } else {
          this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: data.message });
          this.passwordForm.reset();
          this.passwordForm.get('oldPassword').clearValidators();
          this.passwordForm.get('newPassword').clearValidators();
          this.passwordForm.get('confirmPassword').clearValidators();
        }
      },
      error => {
        this.buttonPressed = false;
      }
    );
  }

  get f() { return this.passwordForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }
  getPwdLastupdatedDate() {
    this.loading = true;
    this.profileService.getPasswordLastUpdated().subscribe(
      (data) => {
        if (data.success) {
          this.lastDate = new Date(data.success)
          this.lastPwdDate = this.utilityService.getFormattedDate(this.lastDate);
        }
        this.loading = false;
      },
      (error) => {
        this.lastPwdDate = '';
        this.loading = false;
        console.log(error);
      }
    );
  }
  oldPwrdType() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  newPwrdType() {
    this.isReTextFieldType = !this.isReTextFieldType;
  }
  newPwrdConfmType() {
    this.isReTextCnfmType = !this.isReTextCnfmType;
  }

}
