<div class="mcb-container">
  <app-language-selector></app-language-selector>

  <section>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div><img id="imgPlaceholder" src="assets/images/SME-Linker.png" class="img-fluid mb-5" alt="alt"
            style="width:150px;" /></div>
        <div class="welcome-title">Oops!</div>
        <h1><span>403 Forbidden</span></h1>
        <p *ngIf="forbiddenUrl">Trying to access: {{ forbiddenUrl }}</p>
      </div>
      <div class="col-12 col-sm-12 col-md-12 mt-1 mt-md-2">
        <p *ngIf="isValidUser()">We are sorry, but you do not have access to this page or resource.</p>
        <p *ngIf="!isValidUser()">We are sorry, but you do not have access to this application for some reason.
          Please contact us for any queries.</p>
      </div>
    </div>
  </section>

  <div class="row align-items-center">
    <div class="col-12 text-center">
      <button id="btnMyPage" type="button" class="btn btn-confirm mt-5" (click)="goToPage('feed')"
        *ngIf="isValidUser()">Go to Dashboard</button>
      <button id="btnHome" type="button" class="btn btn-confirm mt-5" (click)="goToPage('landing')"
        *ngIf="!isValidUser()">Go to Home</button>
      <button id="btnReLogin" type="button" class="btn btn-confirm mt-5 ml-3"
        (click)="goToPage('signin')">Re-Login</button>
    </div>
  </div>
</div>