<app-language-selector></app-language-selector>
<div class="sme-stats">
    <div class="row">
        <div class="col-md-12">
            <div class="card sme-feed main-card" id="divAddPosts">
                <div class="toparticles">
                    <h5>Top Actives</h5>
                </div>
                <div class="group-loaders" *ngIf="loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
                    </div>
                </div>

                <ng-container *ngIf="!loading">
                    <div *ngFor="let item of getdataarticles" class="toparticles info">
                        <p>{{item['question']}}</p>
                        <span style="font-size: 12px;">{{ getRelativeTime(item.capturedDate) }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>