<div class="form-group mb-4">
  <app-input-textarea-reactive-field [componentId]="" [field]="descriptionField" *ngIf="!options.descriptionField.hide"
    [submitted]="submitted" [minLength]="options.descriptionField.minLength"
    [maxLength]="options.descriptionField.maxLength"></app-input-textarea-reactive-field>
</div>


<div class="form-group mb-3">
  <app-input-reactive-field [componentId]="" [field]="emailField" *ngIf="!options.emailField.hide"
    [submitted]="submitted" [maxLength]="options.emailField.maxLength">
  </app-input-reactive-field>
</div>

<div class="form-group mb-4">
  <app-input-reactive-field [componentId]="" [field]="companyField" *ngIf="!options.companyField.hide"
    [submitted]="submitted" [minLength]="options.companyField.minLength" [maxLength]="options.companyField.maxLength">
  </app-input-reactive-field>
</div>


<div class="form-group mb-4">
  <app-input-reactive-field [componentId]="" [field]="websiteField" *ngIf="!options.websiteField.hide"
    [submitted]="submitted" [maxLength]="options.websiteField.maxLength">
  </app-input-reactive-field>
</div>

<div class="form-group mb-4">
  <app-input-reactive-field [componentId]="" [field]="phoneField" *ngIf="!options.phoneField.hide"
    [submitted]="submitted" [maxLength]="options.phoneField.maxLength">
  </app-input-reactive-field>
</div>