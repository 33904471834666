<app-language-selector></app-language-selector>
<div class="sme-stats">
  <div class="row">
    <div class="col-md-12">
      <div class="card sme-feed main-card" id="divAddPosts" *ngIf="!isRM">
        <div class="card-header">
          <h5 id="ttlContributeCommunity">{{ 'smeFeed.title' | translate }}</h5>
        </div>
        <div class="card-body share-feed">
          <div class="row">
            <div class="col-md-12 px-1">
              <p>
                <span *ngIf="!isCommunityManager">{{ 'smeFeed.disc' | translate }}</span>
                <span *ngIf="isCommunityManager">{{'smeFeed.disccm' | translate}} </span>
                <a href="javascript:void(0);" (click)="showcommunityguidelines()" class="mr-2">Community Guidelines</a>
                <a href="javascript:void(0);" (click)="helpPopup()" class="mx-2">Help?</a>
              </p>
            </div>

            <div class="col-md-12 share-post-user d-flex px-1">

              <div id="imgProfilePic">
                <span class="user-img" *ngIf="!image"> {{ userName?.trim().slice(0,1) }} </span>
                <img *ngIf="image" src="{{image}}" class="img-fluid" alt="alt">
              </div>



              <div class="postshare-block">
                <div class="postshare" (click)="feedForm = 'shareArticle'; showFeedPopup()">
                  Start a post
                </div>
                <div class="share-btn d-flex justify-content-between">
                  <button type="button" class="btn mr-3 photo"
                    (click)="feedForm = 'shareArticle'; showFeedPopup(); hideBtnIcons();"
                    id="{{'smeFeed.buttons.ShareArticleId' | translate}}">
                    <em class="fas fa-image mr-2"></em>
                    <span>{{ 'smeFeed.buttons.ShareArticle' | translate }}</span>
                  </button>
                  <button type="button" class="btn mr-3 video"
                    (click)="feedForm = 'uploadVideo'; showFeedPopup(); hideBtnIcons();"
                    id="{{'smeFeed.buttons.UploadVideoId' | translate}}">
                    <em class="fab fa-youtube mr-2"></em>
                    <span>{{ 'smeFeed.buttons.UploadVideo' | translate }}</span>
                  </button>
                  <button type="button" class="btn document"
                    (click)="feedForm = 'shareDocument'; showFeedPopup(); hideBtnIcons();"
                    id="{{'smeFeed.buttons.ShareDocumentId' | translate}}">
                    <em class="far fa-file-alt mr-2"></em>
                    <span>{{ 'smeFeed.buttons.ShareDocument' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="shareFeed_dialog">
  <p-dialog header="" [(visible)]="feedModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
    class="feedFormModal" [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal"
    [blockScroll]="true" [resizable]="true" [closable]="true" (onHide)="onHide()">

    <div>
      <div class="app-modal-header text-center">
        <h5 class="modal-title" *ngIf="feedForm == 'shareArticle'" id="ttlShareArticle">

          <span>{{'shareArticle.mainTitle' | translate }}</span>
        </h5>
        <h5 class="modal-title" *ngIf="feedForm == 'uploadVideo'" id="ttlUploadVideo">

          <span>{{'uploadVideo.mainTitle' | translate }}</span>
        </h5>
        <h5 class="modal-title" *ngIf="feedForm == 'shareDocument'" id="ttlShareDocument">

          <span>{{'shareDocument.mainTitle' | translate }}</span>
        </h5>
        <h5 class="modal-title" *ngIf="feedForm == 'viewMyContent'" id="ttlViewMyContent">
          {{'FeedMyContent.popupContent.title' | translate }}
        </h5>
      </div>

      <div class="content-middle">
        <div *ngIf="hideIcones">
          <button type="button" class="btn btn-icon mr-3" [ngClass]="{'active': feedForm === 'shareArticle'}"
            (click)="feedForm = 'shareArticle'" id="{{'smeFeed.buttons.ShareArticleId' | translate}}">
            <em class="fas fa-image"></em>
          </button>
          <button type="button" class="btn btn-icon mr-3" [ngClass]="{'active': feedForm === 'uploadVideo'}"
            (click)="feedForm = 'uploadVideo'" id="{{'smeFeed.buttons.UploadVideoId' | translate}}">
            <em class="fab fa-youtube"></em>
          </button>
          <button type="button" class="btn btn-icon" [ngClass]="{'active': feedForm === 'shareDocument'}"
            (click)="feedForm = 'shareDocument'" id="{{'smeFeed.buttons.ShareDocumentId' | translate}}">
            <em class="far fa-file-alt"></em>
          </button>
        </div>


        <div class="shareArticle" *ngIf="feedForm == 'shareArticle'">
          <app-feed-form [options]="shareArticleData" [resetFields]="feedModal" [selectedFeedData]="selectedFeedData"
            (newFeed)="newFeed($event)" (closeModal)="hideFeedPopup()">
          </app-feed-form>
        </div>
        <div class="uploadVideo" *ngIf="feedForm == 'uploadVideo'">
          <app-feed-form [options]="uploadVideoData" [resetFields]="feedModal" [selectedFeedData]="selectedFeedData"
            (newFeed)="newFeed($event)" (closeModal)="hideFeedPopup()">
          </app-feed-form>
        </div>
        <div class="shareDocument" *ngIf="feedForm == 'shareDocument'">
          <app-feed-form [options]="shareDocument" [resetFields]="feedModal" [selectedFeedData]="selectedFeedData"
            (newFeed)="newFeed($event)" (closeModal)="hideFeedPopup()">
          </app-feed-form>
        </div>
      </div>
    </div>
  </p-dialog>
</div>


<p-dialog [(visible)]="feedshareModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
  class="feedFormModal" [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal"
  [blockScroll]="true" [resizable]="true" [closable]="true" (onHide)="onHide()">

  <div *ngIf="communityGuidelines">
    <div class="card-header text-center">
      <h5 class="communityGuidelineshead" id="ttlContributeCommunity">{{ 'smeFeed.sub-title' | translate }}</h5>
      <p class="communityGuidelinessub">{{ 'smeFeed.guideline' | translate }}</p>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-lg-4" *ngFor="let item of feedContent; index as i">
          <div class="accept-style">
            <em class="fas "
              [ngClass]="{'fa-check-circle': item.active == 'yes', 'fa-times-circle': item.active == 'no'}"></em>
            <p>{{ item.content | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="help">
    <app-side-content-card [sideCardContent]="sideCardContent"></app-side-content-card>
  </div>

</p-dialog>

<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>