<app-language-selector></app-language-selector>
<div class="task-card">
  <form [formGroup]="form">
    <ul class="list-unstyled task-list">
      <ng-container *ngFor="let topic of CurrentDisplayForm">
        <li class="{{topic.done?'done':'not-done'}}">
          <div class="row">
            <div class="col-lg-12 col-xl-8">
              <h6 id="{{topic.topic}}">
                <span>{{topic.topic | translate}}</span>
                <span class="float-right count">
                  {{(topic.currTopAnsCount?topic.currTopAnsCount:0)+" / "+topic.questions.length + "
                  question"+(topic.questions.length>1?"s":"")}}</span>
              </h6>
              <accordion [isAnimated]="true" [closeOthers]="oneAtATime" id="accordion_{{topic.topic}}">
                <ng-container *ngFor="let question of topic.questions">
                  <accordion-group
                    [isOpen]="(currentQuestion && question.question.indexOf(currentQuestion)!=-1)?true:false"
                    class="accordion-group" id="accordion_group_{{question.id}}">
                    <button class="{{'btn btn-block clearfix ' +(question.done?'done':'')}}" accordion-heading
                      type="button" id="{{question.question}}">
                      <div class="pull-left float-left  text-left question-title">
                        <div>{{question.question | translate}}</div>
                        <p *ngIf="question?.answerDescription" class="text-muted">{{question.answerDescription |
                          translate}}</p>
                      </div>
                    </button>
                    <div class="contents">
                      <ng-container>
                        <div class="custom-control custom-radio" *ngFor="let answer of question.answers">
                          <input type="radio" id="{{topic.topic}}_{{question.id}}_{{answer.id}}"
                            name="{{question.question}}" class="custom-control-input"
                            [formControlName]="question.question" value="{{answer.answer}}"
                            [attr.checked]="answer.selected?true:null" (change)="saveResponse($event)"
                            [attr.disabled]="isRM?'disabled':null">
                          <label class="custom-control-label pointer"
                            for="{{topic.topic}}_{{question.id}}_{{answer.id}}"
                            id="lbl{{question.id}}{{answer.id}}">{{answer.answer | translate}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </accordion-group>

                </ng-container>
              </accordion>
            </div>
          </div>
        </li>
      </ng-container>
      <li class="not-done">
        <div class="row">
          <div class="col-md-8">
            <h6>
              <span>{{"Submit"| translate}}</span>
            </h6>
            <accordion [isAnimated]="true" [closeOthers]="oneAtATime" id="accordion">
              <accordion-group [isOpen]="" class="accordion-group">
                <button class="btn btn-block clearfix" accordion-heading type="button">
                  <div class="pull-left float-left  text-left question-title">
                    <div>{{"Your benchmarking" | translate}}</div>
                  </div>
                </button>
                <div class="contents">
                  <ng-container>
                    <p>{{"We’ll use your response to calculate your score with recommendations to improve your
                      business." | translate}}</p>
                    <p>Please review your response carefully before submitting as you will not be allowed to take the
                      {{CurrentHealthCheck.name}} for the next 3 months.</p>
                  </ng-container>
                  <div class="text-right mt-5">
                    <button class="btn btn-confirm" type="button" id="CalculateScore"
                      disabled="{{disableCalculateForm?'disabled':null}}" (click)="showReports()">{{"Calculate my score"
                      | translate}}</button>
                  </div>
                </div>
              </accordion-group>
            </accordion>
          </div>
        </div>
      </li>
    </ul>
  </form>
</div>