<div class="scrollmenu">
    <perfect-scrollbar class="perfect-scrollbar" [style.max-height]="'100%'" [style.max-height]="'500px'">
        <div class="dragboard page-height">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="title">{{ "yourSpecialisation.title" | translate }}</h3>
                    <p class="dscp">{{ "yourSpecialisation.dscp" | translate }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="dragboard-wrapper">
                        <div class="dragboard-list">
                            <div class="dragboard-cards" [dragula]='"task-group"'>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil1" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil2" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil3" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil4" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil1" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil2" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil3" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil4" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil1" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil2" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil3" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                                <div class="dragboard-task">
                                    <div class="dragboard-task-title">{{ "yourSpecialisation.skils.skil4" | translate }}
                                    </div>
                                    <small
                                        class="card-text">{{ "yourSpecialisation.skils.details" | translate }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="dragboard-wrapper">
                                <div class="dragboard-list">
                                    <div class="proven-skills">
                                        <h5>{{ "yourSpecialisation.provenSkills" | translate }}</h5>
                                        <div class="dragboard-cards" [dragula]='"task-group"'>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="dragboard-wrapper">
                                <div class="dragboard-list">
                                    <div class="do-more">
                                        <div class="dragboard-cards" [dragula]='"task-group"'>
                                            <h5>{{ "yourSpecialisation.doMore" | translate }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
    <div class="row">
        <div class="col-sm-12 centered-content text-right">
            <button id="btnSpecialisationBack" type="button" class="btn btn-secondary btn-sm mr-2" awPreviousStep>Back</button>
            <button id="btnSpecialisationCreatePage" type="button" class="btn btn-confirm btn-sm" awNextStep>Create my Page</button>
        </div>
    </div>
</div>