import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { Constants } from 'src/app/shared/constants/constants';
import { CommonService } from '../../../services/common/common.service';
import { MessageService } from 'primeng/api';


class MyFeedContent {
  id: number;
  service: string;
  bookedBy: string;
  dateBooked: string;
  reviewStatus: string;
  updatedTime: string;
}

@Component({
  selector: 'app-cm-pending-feed',
  templateUrl: './cm-pending-feed.component.html',
  styleUrls: ['./cm-pending-feed.component.scss']
})
export class CmPendingFeedComponent implements OnInit {
  @ViewChild('MyContentPostDetails', { static: false }) MyContentPostDetails: ElementRef;
  public newsFeeds = [];
  public statusLine: any;
  public recommendAtions = [];
  public trendingTopics = [];
  loader: boolean;
  showLoader = true;
  dtOptions: DataTables.Settings = {};
  myFeedContent: MyFeedContent[];
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  totalRecords: number;
  limtitRecords = 10;
  firstRecord = 0;
  loading: boolean;
  selectedFeedData: any;
  @Output() pendingRecordsEmitter = new EventEmitter();

  // cols = [
  //   { field: 'title', header: 'Post details', sortable: false },
  //   { field: 'topic', header: 'Content Type', sortable: false },
  //   { field: 'provider', header: 'Provider', sortable: false },
  //   { field: 'submittedDate', header: 'Date Published', sortable: false },
  //   { field: 'helpful', header: 'Helpful', sortable: false },
  //   { field: 'unhelpful', header: 'Unhelpful', sortable: false }
  // ];
  // colsFeed = [
  //   { field: 'title', header: 'Post details', sortable: false },
  //   { field: 'submittedDate', header: 'Date submitted', sortable: false },
  //   { field: 'updatedTime', header: 'Last updated', sortable: false },
  //   { field: 'companyName', header: 'Submitted by', sortable: false },
  //   { field: 'status', header: 'Status / Action', sortable: false }
  // ];
  feedCmArticalViewModal: boolean;


  constructor(
    private router: Router,
    private commonService: CommonService,
    private messageService: MessageService,
    private feedService: FeedService,

  ) {
  }
  pageNumber = 0;
  totalPages = 1;
  fetchFeeds() {
    this.showLoader = true;
    this.feedService.fetchFeed(this.pageNumber).subscribe(
      (res: any) => {
        this.showLoader = false;
        if (this.pageNumber == 0) {
          if (this.newsFeeds.length != res.feed.length)
            this.newsFeeds = res.feed;
        } else {
          this.newsFeeds = this.newsFeeds.concat(res.feed);
        }
        this.statusLine = res.statusLine;
        this.recommendAtions = res.recommendations;
        this.trendingTopics = res.trendingTopics;
        if (res.feed.length == Constants.PAGE_LIMIT)
          this.pageNumber += 1;
      }
    )
  }
  current = "cmFeed";

  ngOnInit(): void {

    this.feedService.feedvalue.subscribe(value => {
      this.getFeedForApproval({ first: this.firstRecord, rows: this.limtitRecords });
    })

    this.fetchFeeds();
    var url = this.router.url;
    if (url == "/feed") {
      this.current = "cmFeed";
    } else {
      this.current = "cmPublishedFeed";
    }
    const that = this;
    if (url == "/cm-feed") {
      this.getFeedForApproval({ first: this.firstRecord, rows: this.limtitRecords });
    }
    // else {
    //   this.getFeedForManagerView({ first: this.firstRecord, rows: this.limtitRecords });
    // }

    let feedmodal = { id: Number, isUpdated: Boolean, topic: "" }
    // this.feedService.feedvalue.subscribe(value => {
    //   this.newFeedAdded(feedmodal)
    // })
  }

  updatedFeed(feed) {
    this.newsFeeds = this.newsFeeds.map(function (a) {
      if (a.id == feed.id) {
        return feed;
      }
    });
  }

  shareType: string;
  editFeedModal: boolean;

  editFeads(feedData) {
    this.feedCmArticalViewModal = false;
    this.selectedFeedData = feedData;
    this.shareType = feedData.topic == 'article' ? "shareArticle" : feedData.topic == 'video' ? "uploadVideo" : "shareDocument";
    this.editFeedModal = true;
  }
  hideEditFeedModal() {
    this.editFeedModal = false;
  }

  getFeedForApproval(event) {
    this.loading = true;
    this.firstRecord = event.first;
    this.limtitRecords = event.rows;
    this.feedService.getFeedForApproval((event.first / event.rows)).subscribe(
      res => {
        this.myFeedContent = res.content;
        this.totalRecords = res.totalElements;
        this.totalPages = Math.ceil(this.totalRecords / Constants.PAGE_LIMIT);
        this.pendingRecordsEmitter.emit(this.totalRecords);
        this.loading = false;
        window.scrollTo(0, 0);
      })
  }

  currentMyFeed: MyFeedContent;
  getDateString() {
    var today = new Date();
    var month = today.getUTCMonth() + 1;
    var dateString = today.getDate().toString();
    if (dateString.length == 1) {
      dateString = "0" + dateString;
    }
    var monthString = month.toString();
    if (monthString.length == 1) {
      monthString = "0" + monthString;
    }
    return dateString + "/" + (monthString) + "/" + today.getUTCFullYear();
  }
  onStatusUpdate(data: any) {
    var feedId = data.feedId;
    var reviewStatus = data.reviewStatus;
    var dateString = this.getDateString();
    this.myFeedContent = this.myFeedContent.map(function (feed) {
      if (feed.id == feedId) {
        feed.reviewStatus = reviewStatus;
        feed.updatedTime = dateString;
      }
      return feed;
    });
    if (this.currentMyFeed)
      if (this.currentMyFeed.id == feedId) {
        this.currentMyFeed.reviewStatus = reviewStatus;
        this.currentMyFeed.updatedTime = dateString;
      }
    this.feedService.submitReviewStatus(feedId, reviewStatus).subscribe(res => {
      this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Successfully ' + (reviewStatus == "approved" ? "Approved" : "Rejected"), detail: '' });
      this.feedService.updatecurrentfeedValue(reviewStatus)
      this.feedCmArticalViewModal = false;
      this.getFeedForApproval({ first: this.firstRecord, rows: this.limtitRecords });
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    });
  }
  onScroll(e) {
    this.fetchFeeds();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  newFeedAdded(feed) {
    console.log(feed, "DDDDDD")
    if (feed && !feed["isUpdated"]) {
      this.firstRecord = 0;
    }
    let url = this.router.url;
    if (url == "/cm-feed") {
      this.getFeedForApproval({ first: this.firstRecord, rows: this.limtitRecords });
    } else {
      // this.getFeedForManagerView({ first: this.firstRecord, rows: this.limtitRecords });
    }
  }
}
