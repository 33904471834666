import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FieldMetaData, FieldSourceModel, TextAreaFieldMetaData, TextFieldMetaData } from '../../core/models/field-meta-data';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { MessageService } from 'primeng/api';
import { GroupsService } from 'src/app/pages/sme-groups/groups.service';

@Component({
  selector: 'app-feed-form',
  templateUrl: './feed-form.component.html',
  styleUrls: ['./feed-form.component.scss']
})
export class FeedFormComponent implements OnInit, OnChanges {
  @Input() options: any = {
    titleField: {},
    descriptionField: {},
    linkField: {}
  };
  @Input() resetFields: any;
  @Input() selectedFeedData: any;
  @Output() newFeed = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  loader: boolean;
  formFields: FieldMetaData[] = [];
  titleField: any;
  linkField: any;
  descriptionField: any;
  uploadText = '';
  public animation: boolean = false;
  public fileUploadControl = new FileUploadControl().setListVisibility(false);
  fileAccept = ".pdf,.jpg,.jpeg";
  acceptedImageFormates = ".jpeg,.jpg,.png";
  selectedImage: any;
  srcData: any;
  uploadedFiles: Array<File> = [];
  feedData: any = { topic: "article" };
  currentMyFeed: any;
  feedFormGroup: FormGroup;
  submitted = false;
  maxAllowedSize = 1 * 1024 * 1024;
  deleteDownloadFile: boolean = true;
  showUploadFile: boolean;
  showerroemsg: boolean;
  results: any[] = [];
  filteredCountries: any[] = [];
  vitscroll: boolean = false;
  articleVisibleToPublic: boolean = true;
  isPublic: true;
  selectedGroups: any[] = [];
  isCommunityManager: boolean;

  constructor(
    private feedService: FeedService,
    private authService: UserAuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private groupService: GroupsService
  ) {
    this.filteredCountries = [];
  }

  ngOnInit(): void {
    if (this.options.titleField.label != 'shareArticle.title.title') {
      this.uploadText = (this.options.titleField.label == ('shareDocument.title.title')) ? 'Upload a document' : 'Upload a video';
      this.feedData.topic = (this.options.titleField.label == ('shareDocument.title.title')) ? 'document' : 'video';
    } else {
      this.feedData.topic = "article";
    }
    this.buildFields();
    if (this.resetFields && !this.selectedFeedData) {
      this.clearFields();
    }

    this.groupService.getmygroupdata().subscribe((data: any) => {
      this.results = data.map(v => {
        return { value: v.name, label: v.name, image: v.image, groupId: v.id };
      });
    });

    this.isCommunityManager = this.authService.isCommunityManager();
  }
  urlreg = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
  titleregex = /^([A-Za-z0-9 \(\)\/\.\“\'\:\;\_\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]{1})([A-Za-z0-9 \(\)\/\.\,\“\'\:\;\_\-\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]+)([A-Za-z0-9 \(\)\/\.\“\'\:\;\_\@\?éçàèùâêîôûëïüÉÇÀÈÙÂÊÎÔÛËÏÜ]{1})$/;

  buildFields() {
    //console.log(this.options);
    this.titleField = new TextFieldMetaData(
      new FormControl(
        this.selectedFeedData ? this.selectedFeedData.title : '',
        [
          Validators.required, CustomValidators.removeSpaces,
          Validators.maxLength(this.options.titleField.maxLength),
          Validators.minLength(this.options.titleField.minLength),
          CustomValidators.patternValidator(this.titleregex, { hasNoSpecialCharacters: true })
        ]),
      this.options.titleField.id,
      this.options.titleField.label,
      this.options.titleField.placeholderText,
      'title',
      this.options.titleField.validMsg,
      this.options.titleField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.titleField.type,
    );
    this.descriptionField = new TextAreaFieldMetaData(
      new FormControl(this.selectedFeedData ? this.selectedFeedData.description : '', [Validators.maxLength(this.options.descriptionField.maxLength),
      Validators.minLength(this.options.descriptionField.minLength)]),
      this.options.descriptionField.id,
      this.options.descriptionField.label,
      this.options.descriptionField.placeholderText,
      'description',
      this.options.descriptionField.validMsg,
      this.options.descriptionField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      this.options.descriptionField.type,
    );
    var validatorArray = [Validators.maxLength(this.options.linkField.maxLength), Validators.pattern(this.urlreg)];
    var required = this.options.linkField.required;
    if (this.uploadText == 'Upload a video') {
      validatorArray.push(Validators.required, CustomValidators.removeSpaces);
      required = true;
    } else if (required) {
      validatorArray.push(Validators.required, CustomValidators.removeSpaces);
    }
    this.linkField = new TextFieldMetaData(
      new FormControl(this.selectedFeedData ? this.selectedFeedData.feedUrl : '', validatorArray),
      this.options.linkField.id,
      this.options.linkField.label,
      this.options.linkField.placeholderText,
      'link',
      this.options.linkField.validMsg,
      required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.linkField.type,
    )
    this.feedFormGroup = new FormGroup({
      title: this.titleField.control,
      description: this.descriptionField.control,
      feedUrl: this.linkField.control,
      feedImage: new FormControl(
        this.uploadText == 'Upload a video' ? '' : null,
        this.uploadText != 'Upload a video' ? Validators.required : null
      ),
      isPublic: new FormControl(this.selectedFeedData ? this.selectedFeedData.isPublic : ''),
      selectedGroups: new FormControl([])
    });
    if (this.uploadText == 'Upload a document') {
      this.fileAccept = ".pdf,.jpg,.jpeg";
    }
    if (this.uploadText == 'Upload a video') {
      this.fileAccept = ".mov,.mp4"
    }
    if (this.selectedFeedData) {
      this.deleteDownloadFile = true;
      this.showUploadFile = false;
    }
  }
  ngOnChanges() {
    this.buildFields();
    if (this.resetFields && !this.selectedFeedData) {
      this.clearFields();
    }
  }
  getData(data) {
    if (data.name == "link") {
      this.feedData["feedUrl"] = data.value;
    } else {
      this.feedData[data.name] = data.value;
    }
  }
  featuredImageFiles = [];

  onFileChanged(event) {
    this.featuredImageFiles = event.target.files;
    if (!this.validFileType(event.target.files[0].name, this.acceptedImageFormates)) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image should be in either .jpeg, .jpg, or .png format", detail: '' });
      return;
    }

    if (event.target.files[0].size > this.maxAllowedSize) {
      event.target.value = ''
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }
    var fileReader = new FileReader();
    var that = this;
    fileReader.onload = function (fileLoadedEvent) {
      that.srcData = fileLoadedEvent.target.result;
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }
  uploadedFilesconverted = [];
  readFiles() {
    if (this.uploadedFiles.length > 0) {
      this.filesErrorMessage = "";
      var uploadedFiles = [];
      var that = this;
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        uploadedFiles.push(fileLoadedEvent.target.result);
        that.uploadedFilesconverted = uploadedFiles;
      }
      fileReader.readAsDataURL(this.uploadedFiles[this.uploadedFiles.length - 1]);
    }
  }
  filesErrorMessage = "";
  validFileType(filename, extensions) {
    var dotSplits = filename.split(".");
    var fileExtention = dotSplits[dotSplits.length - 1];
    if (extensions.indexOf(fileExtention.toLowerCase()) != -1) {
      return true;
    } else {
      return false;
    }
  }
  validateFilesUploaded(fileCheck) {
    console.log(this.uploadedFiles[this.uploadedFiles.length - 1]);
    if (this.uploadText == 'Upload a document' || this.uploadText == 'Upload a video') {

      if (this.uploadText == 'Upload a document') {
        if (this.uploadedFiles.length > 0 && this.feedData["feedUrl"]) {
          this.filesErrorMessage = "You can only submit a link or upload a document, but not both.";
          return false;
        }
      }
      if (fileCheck && this.uploadedFiles.length == 0 && !this.feedData["feedUrl"]) {
        this.filesErrorMessage = "select a file or provide url";
        if (this.uploadText == 'Upload a video') {//since video upload disabled 
          this.filesErrorMessage = "Provide url for the video";
        }
        return false;
      } else if (fileCheck && this.uploadText == 'Upload a document' && !this.feedData["feedUrl"]) {
        if (!this.validFileType(this.uploadedFiles[this.uploadedFiles.length - 1]?.name, this.fileAccept)) {
          this.filesErrorMessage = "Please select a valid file. Accepted file formats are .pdf, .jpg or .jpeg.";
          return false;
        }
        if (this.uploadedFiles[this.uploadedFiles.length - 1].size > this.maxAllowedSize) {
          this.uploadedFiles = [];
          this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "The file exceeds size limit, please upload a file under 1mb.", detail: '' });
          return false;
        }
      }
    }
    this.filesErrorMessage = "";
    return true;
  }
  submitForm() {
    this.submitted = true;
    this.feedFormGroup.markAllAsTouched();
    if (this.feedFormGroup.invalid) {
      return false;
    }

    this.readFiles();
    if (!this.validateFilesUploaded(true)) {
      return false;
    }
    this.loader = true;
    this.feedService.saveFeed(this.getFormData(this.feedData)).subscribe(
      res => {
        this.loader = false;
        if (res.error) {
          this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: 'Sorry, this link has already been added. Please select a new link to add.', detail: '' });
        } else {
          if (!this.authService.isCommunityManager()) {
            this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Your ' + this.feedData.topic + ' has been submitted to Community Manager for Approval', detail: '' });
          } else {
            this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Your ' + this.feedData.topic + ' has been successfully added and Approved', detail: '' });
          }
          this.newFeed.emit(res);
        }
      }
    )
    this.closeModal.emit();
    this.clearFields();
  }
  updateForm() {
    this.submitted = true;
    this.feedFormGroup.markAllAsTouched();
    if (this.feedFormGroup.invalid) {
      return false;
    }

    this.readFiles();
    if (!this.validateFilesUploaded(false)) {
      return false;
    }
    this.loader = true;
    delete this.feedData.isUpdated;
    this.feedService.updateFeed(this.getFormData(this.feedData)).subscribe(
      res => {
        this.loader = false;
        if (res.error) {
          this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: res.error, detail: '' });
        } else {
          if (!this.authService.isCommunityManager()) {
            this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Your ' + this.feedData.topic + 'has been submitted to Community Manager for Approval', detail: '' });
          } else {
            this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Your ' + this.feedData.topic + 'has been successfully updated', detail: '' });
          }
          this.feedService.updatecurrentfeedValue(this.feedData.topic)
          this.feedData["isUpdated"] = true;
          this.newFeed.emit(this.feedData);
          // this.ngOnInit();
        }
      }
    )
    this.closeModal.emit();
    this.clearFields();
  }
  deleteFile() {
    this.deleteDownloadFile = false;
    this.showUploadFile = true;
  }
  clearFields() {
    this.showUploadFile = false;
    this.submitted = false;
    if (this.feedFormGroup) {
      this.feedFormGroup.reset();
      this.feedFormGroup.markAsUntouched();
    }
    this.featuredImageFiles = [];
    this.uploadedFiles = [];
    this.srcData = null;
    this.filesErrorMessage = "";
  }
  cancelForm() {
    this.closeModal.emit();
    this.clearFields();
  }
  getFormData(item: any) {
    let selArr: any = [];
    const selectGrpArr: any = this.feedFormGroup.controls['selectedGroups'].value;
    if (selectGrpArr && Array.isArray(selectGrpArr)) {
      selectGrpArr.forEach(id => {
        selArr.push(id.groupId);
      });
      console.log(selArr);
    }

    if (this.selectedFeedData) item.id = this.selectedFeedData.id;
    item['isPublic'] = this.feedFormGroup.controls['isPublic'].value;

    // let selectedGroupsArray: any = this.feedFormGroup.get('selectedGroups') as FormArray;
    // const groupIds = selectedGroupsArray.controls.map((group: FormGroup) => group.get('groupId')?.value);
    // item.selectedGroups = groupIds;

    // item['selectedGroups'] = this.feedFormGroup.controls['selectedGroups']?.groupId.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(item));
    payload.append('featuredImage', this.featuredImageFiles[0]);
    payload.append('selectedGroups', selArr);
    if (this.uploadedFiles.length) {
      if (this.uploadText == 'Upload a document') {
        payload.append('document', this.uploadedFiles[this.uploadedFiles.length - 1]);
      }
      if (this.uploadText == 'Upload a video') {
        payload.append('video', this.uploadedFiles[this.uploadedFiles.length - 1]);
      }
    }
    return payload;
  }

  onToggleChange(event: any) {
    const switchValue = event.checked;
    console.log('Switch value changed:', switchValue);
  }

  filterCountry(event: any) {
    const query = event.query.toLowerCase();
    this.filteredCountries = this.results.filter(option =>
      option.value.toLowerCase().includes(query)
    );
    this.vitscroll = true;
  }
}
