<app-language-selector></app-language-selector>
<div class="h-list-header">
  <h6 class="text-left" id="ttlChatName">{{ chatMessage.name }}</h6>
  <a href="javascript:void(0);" class="h-back-user-list" (click)="this.onChatToggle.emit();" id="btnClose"><em
      class="fas fa-times"></em></a>
</div>

<div class="h-list-body" id="divChat">
  <div class="main-chat-cont">
    <div class="main-friend-chat">
      <ng-template #CHATNOTFOUND>
        <div class="media chat-messages text-center">
          <div class="media-body chat-menu-content">
            <div class="">
              <p class="chat-cont">{{ 'chatBot.notFound' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="chatMessage.chat; else CHATNOTFOUND">
        <perfect-scrollbar ngClass="elite-scroll" [style.max-width]="'100%'" [style.max-height]="'calc(60vh - 110px)'"
          [usePSClass]="'elite'" [disabled]="null" class="perfect-scrollbar">
          <div *ngFor="let messages of chatMessage.chat; index as i">
            <div class="media chat-messages">
              <a *ngIf="messages.type" class="media-left photo-table" href="javascript:void(0);" id="btnPhoto{{i+1}}">
                <img class="media-object img-radius img-radius m-t-5" src="{{ chatMessage.photo }}"
                  alt="{{ chatMessage.name }}" id="imgPhoto{{i+1}}">
              </a>
              <div class="media-body"
                [ngClass]="{'chat-menu-content' : messages.type, 'chat-menu-reply': !messages.type}">
                <div class="">
                  <span *ngIf="messages.type">{{ 'chatBot.CommunityManager' | translate }}</span>
                  <span *ngIf="!messages.type">{{ 'chatBot.you' | translate }}</span>
                  <p class="chat-cont">{{ messages.msg }}</p>
                </div>
              </div>
            </div>
            <div *ngIf="messages.type" class="select-option">
              <h6 id="ttlSelectOption{{i+1}}">{{ 'chatBot.SelectOption.title' | translate }}</h6>
              <span (click)="sendMsgFree($event)" id="btnOption1{{i+1}}">
                {{ 'chatBot.SelectOption.option1' | translate }}
              </span>
              <span (click)="sendMsgFree($event)" id="btnOptio2{{i+1}}">
                {{ 'chatBot.SelectOption.option2' | translate }}
              </span>
              <span (click)="sendMsgFree($event)" id="btnOption3{{i+1}}">
                {{ 'chatBot.SelectOption.option3' | translate }}
              </span>
              <span (click)="sendMsgFree($event)" id="btnOption4{{i+1}}">
                {{ 'chatBot.SelectOption.option4' | translate }}
              </span>
              <h6 class="mt-3" id="ttlOrSelectOption{{i+1}}">{{ 'chatBot.SelectOption.title2' | translate }}</h6>
            </div>
          </div>
          <div #newChat [innerHTML]="newReplay"></div>
          <div *ngIf="friendWriting" class="media chat-messages typing">
            <a class="media-left photo-table" href="javascript:void(0);" id="btnPhoto"><img
                class="media-object img-radius img-radius m-t-5" src="{{chatMessage.photo}}"
                alt="{{ chatMessage.name }}" id="imgPhoto"></a>
            <div class="media-body chat-menu-content">
              <div class="rem-msg">
                <p class="chat-cont">{{ 'chatBot.Typing' | translate }}</p>
              </div>
              <p class="chat-time">{{ 'chatBot.now' | translate }}</p>
            </div>
          </div>
        </perfect-scrollbar>
      </ng-container>
    </div>
  </div>
</div>
<div class="h-list-footer" id="divChatFooter">
  <div class="input-group" (focusout)="this.message_error = false;" id="divError">
    <input type="text" [(ngModel)]="message" name="h-chat-text" class="form-control h-send-chat"
      placeholder="Write hear . . " (keyup)="sentMsg(1)" (keyup.enter)="sentMsg(0)" id="txtChatTyping">
    <button type="submit" class="input-group-append btn-send btn btn-primary"
      [ngClass]="{'btn-danger': message_error === true}" (click)="sentMsg(0)" id="btnCubmit">
      <em class="fas fa-paper-plane"></em>
    </button>
  </div>
</div>