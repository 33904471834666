import {FormControl} from '@angular/forms';
import {RadioInput} from "./radio-input";

export enum FieldType {
  CURRENCY,
  DROPDOWN,
  MULTI_SELECT,
  TEXT,
  TEXT_AREA,
  DATE_TIME_PICKER,
  MOBILE,
  DISABLED_DEFAULT_CURRENCY,
  SEARCHABLE_DROPDOWN,
  RADIO,
  NUMBER,
  YEAR_MONTH_DATE
}

export enum FieldSourceModel {
  APPLICATION_DETAIL,
  CUSTOMER_DETAIL,
  CLIENT_PROFILE,
  APPLICANT_DETAIL,
  EXISTING_LOAN,
  MORATORIUM_DETAILS,
  APPLICANT_ADDRESS,
  ARREARS_DETAILS
}

export class ToolTipMetaData {
  content = '';
  displayPosition = '';

  constructor(content, displayPosition = '') {
    this.content = content;
    this.displayPosition = displayPosition;
  }

}

export class FieldMetaData {
  fieldType: FieldType;
  labelText = '';
  control: FormControl = undefined;
  name = '';
  propertyInModel = '';
  fieldSourceModel: FieldSourceModel = FieldSourceModel.APPLICANT_DETAIL;
  display = true;
  mandatory = true;
  isEditable = true;
  showOptional = false;
  metaData = '';
  toolTipMetaData: ToolTipMetaData;

  constructor(
    fieldType: FieldType,
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    isEditable = true,
    display = true,
    showOptional = false,
    metaData = ''
  ) {
    this.fieldType = fieldType;
    this.labelText = labelText;
    this.control = control;
    this.name = name;
    this.toolTipMetaData = toolTipMetaData;
    this.propertyInModel = propertyInModel;
    this.mandatory = mandatory;
    this.fieldSourceModel = fieldSourceModel;
    this.isEditable = isEditable;
    this.display = display;
    this.showOptional = showOptional;
    this.metaData = metaData;
  }

  isValuePresent() {
    return this.control.value !== null && this.control.value !== undefined && this.control.value !== '';
  }
}

export class PhoneNumberFieldMetaData extends FieldMetaData {
  inputList: any[] = [];
  errorMsg = '';

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    inputList: any[] = [],
    mandatory = true,
    errorMsg = '',
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    showOptional = true
  ) {
    super(FieldType.MOBILE, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData, true, true, showOptional);
    this.inputList = inputList;
    this.errorMsg = errorMsg;
  }
}

export class DropdownFieldMetaData extends FieldMetaData {
  inputList: any[] = [];
  displayProperty = 'displayValue';
  valueProperty = 'value';

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    inputList: any[] = [],
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    displayProperty = 'displayValue',
    valueProperty = 'value',
    toolTipMetaData: ToolTipMetaData = null
  ) {
    super(FieldType.DROPDOWN, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.inputList = inputList;
    this.displayProperty = displayProperty;
    this.valueProperty = valueProperty;
  }
}

export class SearchableDropdownFieldMetaData extends FieldMetaData {
  inputList: any[] = [];
  displayProperty = 'displayValue';
  valueProperty = 'value';
  countryMode = false;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    inputList: any[] = [],
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    displayProperty = 'displayValue',
    valueProperty = 'value',
    toolTipMetaData: ToolTipMetaData = null,
    countryMode = false,
    showOptional = false
  ) {
    super(FieldType.SEARCHABLE_DROPDOWN, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData, true, true, showOptional);
    this.inputList = inputList;
    this.displayProperty = displayProperty;
    this.valueProperty = valueProperty;
    this.countryMode = countryMode;
    this.showOptional = showOptional;
  }
}

export class CurrencyFieldMetaData extends FieldMetaData {
  calculatorRequired = false;
  zeroAllowed = true;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    calculatorRequired = false,
    showOptional = false
  ) {
    super(FieldType.CURRENCY, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.calculatorRequired = calculatorRequired;
    this.showOptional = showOptional;
  }

  isValueEntered(): boolean {
    return super.isValuePresent() && this.isAmountEntered();
  }

  isValuePresent(): boolean {
    return super.isValuePresent() && this.isAmountEntered(this.zeroAllowed);
  }

  isAmountEntered(zeroAllowed = false) {
    return (
      this.control.value.amount !== null &&
      this.control.value.amount !== undefined &&
      (zeroAllowed || this.control.value.amount !== 0)
    );
  }
}

export class RadioMetaData extends FieldMetaData {
  options: RadioInput[];
  responsive = true;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    mandatory = true,
    options: RadioInput[] = [],
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    isEditable = true,
    showOptional = false,
    responsive = true
  ) {
    super(FieldType.RADIO, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData, isEditable, true, showOptional);
    this.options = options;
    this.responsive = responsive;
  }
}

export class TextFieldMetaData extends FieldMetaData {
  maxLength = 50;
  errorMsg = '';
  type = 'text';

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    errorMsg = '',
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    maxLength = 50,
    isEditable = true,
    type = 'text',
    showOptional = false
  ) {
    super(FieldType.TEXT, control, name, labelText, propertyInModel, mandatory,
      fieldSourceModel, toolTipMetaData, isEditable, true, showOptional, '');
    this.maxLength = maxLength;
    this.errorMsg = errorMsg;
    this.type = type;
  }
}

export class NumberFieldMetaData extends FieldMetaData {
  maxLength = 50;
  errorMsg = '';
  precision = 0;
  allowNegative = false;
  min: number;
  max: number;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    errorMsg = '',
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    maxLength = 50,
    isEditable = true,
    showOptional = false,
    precision: number = 0,
    min: number = undefined,
    max: number = undefined,
    allowNegative: boolean = false
  ) {
    super(FieldType.NUMBER, control, name, labelText, propertyInModel, mandatory,
      fieldSourceModel, toolTipMetaData, isEditable, true, showOptional, '');
    this.maxLength = maxLength;
    this.errorMsg = errorMsg;
    this.min = min;
    this.max = max;
    this.precision = precision;
    this.allowNegative = allowNegative;
  }
}

export class TextAreaFieldMetaData extends FieldMetaData {
  maxLength = 500;
  errorMsg = '';
  showLabel = true;
  rows = 2;
  fullWidth = false;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    errorMsg = '',
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    maxLength = 500,
    showLabel = true,
    rows = 2,
    fullWidth = false
  ) {
    super(FieldType.TEXT_AREA, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.maxLength = maxLength;
    this.errorMsg = errorMsg;
    this.showLabel = showLabel;
    this.rows = rows;
    this.fullWidth = fullWidth;
  }
}

export class MultiSelectFieldMetaData extends FieldMetaData {
  inputList: any[] = [];
  displayProperty = 'displayValue';
  valueProperty = 'value';

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    inputList: any[] = [],
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    displayProperty = 'displayValue',
    valueProperty = 'value'
  ) {
    super(FieldType.MULTI_SELECT, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.inputList = inputList;
    this.displayProperty = displayProperty;
    this.valueProperty = valueProperty;
  }

  isValuePresent(): boolean {
    return super.isValuePresent() && this.control.value.length > 0;
  }
}

export class DateFieldMetaData extends FieldMetaData {
  max: Date | string = new Date();
  min: Date | string = new Date('1900-01-01');
  placeHolder = 'e.g DD/MM/YYYY';
  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    placeholder = 'e.g DD/MM/YYYY',
    fieldType = FieldType.DATE_TIME_PICKER,
    max: Date | string = new Date(),
    min: Date | string = new Date('1900-01-01')
  ) {
    super(fieldType, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData);
    this.max = max;
    this.min = min;
    this.placeHolder = placeholder;
  }

  getDateValue() {
    let value;
    if (this.isValuePresent()) {
      value = new Date(this.control.value);
    }
    return value;
  }
}

export class DisableDefaultCurrencyMetadata extends FieldMetaData {
  maxAmount: number;
  zeroAllowed = true;
  precision: number;

  constructor(
    control: FormControl,
    name: string,
    labelText: string,
    propertyInModel: string,
    mandatory = true,
    fieldSourceModel = FieldSourceModel.APPLICANT_DETAIL,
    toolTipMetaData: ToolTipMetaData = null,
    maxAmount: number = null,
    isEditable = true,
    display = true,
    showOptional = false,
    metaData = '',
    precision = 0
  ) {
    super(FieldType.DISABLED_DEFAULT_CURRENCY, control, name, labelText, propertyInModel, mandatory, fieldSourceModel, toolTipMetaData, isEditable,
      display, showOptional, metaData);
    this.maxAmount = maxAmount;
    this.precision = precision;
  }

  isValuePresent(): boolean {
    return super.isValuePresent() && this.isAmountEntered(this.zeroAllowed);
  }

  isAmountEntered(zeroAllowed = false) {
    return zeroAllowed || this.control.value !== 0;
  }
}

export function getControlByName(fields: FieldMetaData[], name: string): FormControl {
  return fields.filter(field => field.name === name)[0].control;
}

export function getFieldByName(fields: FieldMetaData[], name: string): FieldMetaData {
  return fields.filter(field => field.name === name)[0];
}



export function areFieldsValid(fields: FieldMetaData[]) {
  let valid = true;
  fields
    .filter(field => field.display)
    .forEach(field => {
      valid = (valid && getValueStatus(field) && ( !field.control.errors))});
  return valid;
}

function getValueStatus(field: FieldMetaData) {
  return !field.mandatory || (field.mandatory && field.isValuePresent());
}
