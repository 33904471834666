import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FeedStatus } from '../../constants/constants';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { Router } from '@angular/router';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';

@Component({
  selector: 'app-feed-popup-view',
  templateUrl: './feed-popup-view.component.html',
  styleUrls: ['./feed-popup-view.component.scss']
})
export class FeedPopupViewComponent implements OnInit, OnChanges {
  @Output() onEditPopup = new EventEmitter<any>();
  FeedStatus = FeedStatus;
  @Input()
  set feed(feed: any) {
    this.currentMyFeed = feed;
  }
  isCommunityManager: boolean;
  isRM: boolean;
  request: boolean;
  get feed(): any { return this.currentMyFeed; }
  @Input() CMView: boolean;
  @Output() onStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Input() showPostPreviewFooter: boolean;
  @Input() showPostcomment: boolean;
  @Input() submittedby: boolean = true;
  @Input() showinfo: boolean = true;
  companyProfile: any;
  constructor(private ref: ChangeDetectorRef,
    private router: Router,
    private authService: UserAuthService,
    private feedService: FeedService,
    private profileService: ProfileService) { }
  currentMyFeed: any = {};
  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    this.isCommunityManager = this.authService.isCommunityManager();
    if (this.router.url.indexOf("/feed") > -1) {
      this.request = true;
    } else {
      this.request = false;
    }

    this.companyProfile = this.profileService.orgDetails;
    if (!this.companyProfile) {
      this.getCompanyProfile();
    }

  }

  getCompanyProfile() {
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.companyProfile = data;
      },
      (error) => {
        this.companyProfile = null;
      }
    );
  }

  ngOnChanges() {
    this.ref.detectChanges();
  }
  onStatusUpdated(feedId, reviewStatus) {
    this.onStatusUpdate.emit({ feedId: feedId, reviewStatus: reviewStatus });
  }
  onEditFeed() {
    this.onEditPopup.emit(this.currentMyFeed)
  }

  toggleFeedComment(currentMyFeed: any) {
    currentMyFeed.toggle = !currentMyFeed.toggle;
  }
}
