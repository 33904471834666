import { Component } from '@angular/core';

@Component({
  selector: 'app-sp-about',
  templateUrl: './sp-about.component.html',
  styleUrls: ['./sp-about.component.scss']
})
export class SpAboutComponent {


}
