<div class="row">
  <div class="col-md-12">
    <div class="YourPortfolio">
      <h3 class="title">Your Portfolio</h3>
      <div class="row">
        <div class="col-md-4">
          <app-card id="crdYourPortfolioChart1" [hidHeader]="true" cardClass="no-shadow border-r-0">
            <app-apex-chart
              chartID="radial-1-cac"
              [chartConfig]="this.chartDB.radial1"
            ></app-apex-chart>
          </app-card>
        </div>
        <div class="col-md-4">
          <app-card id="crdYourPortfolioChart2" [hidHeader]="true" cardClass="no-shadow border-r-0">
            <app-apex-chart
              chartID="radial-2-cac"
              [chartConfig]="this.chartDB.radial2"
            ></app-apex-chart>
          </app-card>
        </div>
        <div class="col-md-4">
          <app-card id="crdYourPortfolioChart3" [hidHeader]="true" cardClass="no-shadow border-r-0">
            <app-apex-chart
              chartID="radial-3-cac"
              [chartConfig]="this.chartDB.radial3"
            ></app-apex-chart>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>
