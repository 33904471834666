export enum Duration {
    IS_HOURS = 'isHours',
    IS_DAYS = 'isDays',
    IS_WEEKS = 'isWeeks',
    IS_MONTHS = 'isMonths',
    DURATION_TYPE = 'durationType',
    HOURS = 'Hours',
    HOUR = 'Hour',
    DAYS = 'Days',
    DAY = 'Day',
    WEEKS = 'Weeks',
    WEEK = 'Week',
    MONTHS = 'Months',
    MONTH = 'Month'
}