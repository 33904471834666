import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { HealthCheckService } from 'src/app/core/services/health-check/health-check.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Constants } from 'src/app/shared/constants/constants';
import { CommonService } from '../../../services/common/common.service';


@Component({
  selector: 'app-health-checks',
  templateUrl: './health-checks.component.html',
  styleUrls: ['./health-checks.component.scss']
})
export class HealthChecksComponent implements OnInit {
  @ViewChild('healthCheckModal', { static: false }) healthCheckModal: any;
  @Output() totalRecordsChanged = new EventEmitter<number>();
  viewScoreClickShow = false;
  public videoArticles: any;
  public healthChecks = [];
  public showMyScore: any;
  public HealthCheckFormData: any;
  healthCheckForm = true;
  showReport = false;
  showLoader = false;
  public sideCardContent: any;


  constructor(
    private commonService: CommonService, private healthCheckService: HealthCheckService,
    private utility: UtilityService,
    private gaService: GoogleAnalyticsService
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.videoArticles = res.sideContent.HealthCheck.video;
        this.sideCardContent = res.sideContent.HealthCheck;
      })
  }
  totalRecords = 0;
  pageNumber = 0;
  ngOnInit(): void {
    this.gaService.logCustomEvent("Master page loaded", "Healthcheck");
    this.getHealthCheckQuestions();
  }
  inProgress = false;
  totalPages = 1;
  getHealthCheckQuestions() {
    if (this.inProgress || this.pageNumber >= this.totalPages) {
      return false;
    }
    this.inProgress = true;
    this.healthCheckService.getHealthChecks(this.pageNumber).subscribe(
      res => {
        this.inProgress = false;
        if (res) {
          this.totalRecords = res.totalElements ? res.totalElements : 0;
          this.totalPages = Math.ceil(this.totalRecords / Constants.PAGE_LIMIT);
          if (Array.isArray(res.content)) {
            this.healthChecks = this.healthChecks.concat(res.content);
          }
          this.pageNumber++;

          this.totalRecordsChanged.emit(this.totalRecords);
        }
      },
      (error: any) => {
        this.inProgress = false;
        this.showLoader = false;
      }
    );
  }

  closeReport() {
    document.body.style.overflow = 'auto';
    this.viewScoreClickShow = true;
    this.healthCheckModal.hide();
    this.healthCheckForm = true;
    this.showReport = false;
    document.body.style.overflow = 'auto';
    window.scrollTo(0, 0);
  }
  healthCheckStarted = { id: "" };
  setCurrentHealthCheckItem(item) {
    this.healthCheckStarted = item;
    document.body.style.overflow = 'hidden';
    window.scrollTo(0, 0);
  }

  userCalculatedScore(score, noLoader) {
    let self = this;
    this.showMyScore = score;
    this.showLoader = false;
    this.showReport = false;
    this.healthCheckForm = false;
    if (noLoader) {
      setTimeout(function () {
        self.showReport = true;
        self.showLoader = false;
      }, 1);
    } else {
      setTimeout(function () {
        self.showReport = true;
        self.showLoader = false;
      }, 2000);
    }
    this.healthChecks = this.healthChecks.map((hc) => {
      if (hc.id == score.id) {
        hc.hasSavedResponse = true;
        hc.dateTaken = this.utility.getDateString();
        hc.numberOfDaysBefore = 89;
        hc = Object.assign(hc, score);
      }
      return hc;
    });
  }
  userSavedAnswers(data) {
    this.healthChecks = this.healthChecks.map(function (hc) {
      if (hc.id == data.healthCheckId) {
        hc.hasSavedResponse = true;
        hc.questionsAnswered = data.noOfQuestionsAnswered;
      }
      return hc;
    });
  }
  onScroll(e) {
    this.getHealthCheckQuestions();
  }
  viewReport(healthCheck) {
    this.showReport = true;
    this.healthCheckService.viewReport(healthCheck.id).subscribe(
      res => {
        this.userCalculatedScore(res, true);
      }
    )
  }
}
