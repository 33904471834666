import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { HealthCheckService } from 'src/app/core/services/health-check/health-check.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-health-check-form',
  templateUrl: './health-check-form.component.html',
  styleUrls: ['./health-check-form.component.scss']
})
export class HealthCheckFormComponent implements OnInit, OnChanges {
  @Input() CurrentHealthCheck: any = {};
  HealthCheckForm: any;
  CurrentAnswers: any;
  @Output() myScoreClick: EventEmitter<boolean> = new EventEmitter();
  @Output() myAnswersSaved: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  disableCalculateForm = true;
  isOpen = true;
  oneAtATime: boolean = true;
  isRM:boolean;
  constructor(
    private healthCheckService: HealthCheckService,
    private messageService: MessageService,
    private gaService:GoogleAnalyticsService,
    private authService: UserAuthService
  ) {
    this.form = new FormGroup({});
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getHealthCheckContent();
  }
  CurrentDisplayForm: any;
  constructForm() {
    var alldone = true;
    if (this.HealthCheckForm?.length) {
      var CurrentAnswers = this.CurrentAnswers;
      var currTopAnsCount=0;
      this.CurrentDisplayForm = this.HealthCheckForm.map(function (topic1) {
        currTopAnsCount=0;
        return {
          topic: topic1.topic,
          questions: topic1.questions.map(function (question1) {

            var doneflag = false;
            if (CurrentAnswers && CurrentAnswers.topic && CurrentAnswers.topic.length) {
              CurrentAnswers.topic.forEach(function (stopic) {
                if (stopic.topic == topic1.topic) {
                  stopic.answers.forEach(function (sanswer) {
                    if (sanswer.questionId == question1.id) {
                      currTopAnsCount++;
                      doneflag = true;
                    }
                  });
                }
              });
            }
            if (doneflag == false) {
              alldone = false;
            }
            return {
              question: question1.question,
              id:question1.id,
              done: doneflag,
              answerDescription: question1.answerDescription,
              answers: question1.answers.map(function (answer1) {
                if (CurrentAnswers && CurrentAnswers.topic && CurrentAnswers.topic.length)
                  CurrentAnswers.topic.forEach(function (stopic) {
                    if (stopic.topic == topic1.topic) {
                      stopic.answers.forEach(function (sanswer) {
                        if (sanswer.questionId == question1.id) {
                          if(answer1.id == sanswer.answerId){
                            answer1.selected = true;
                          }else{
                            answer1.selected = false;
                          }
                        }
                      });
                    }
                  });
                return answer1;
              })
            }
          }),
          currTopAnsCount:currTopAnsCount
        }
      });
      this.CurrentDisplayForm = this.CurrentDisplayForm.map(function (topic2) {
        var allQuestionsDone = true;
        for (var i = 0; i < topic2.questions.length; i++) {
          if (!topic2.questions[i].done) {
            allQuestionsDone = false;
          }
        }
        topic2.done = allQuestionsDone;
        if (allQuestionsDone == false) {
          alldone = false;
        }
        return topic2;
      });

      if (alldone) {
        this.disableCalculateForm = false;
      } else {
        this.disableCalculateForm = true;
      }
        var allOptions = {};
        if (this.CurrentDisplayForm.length > 0) {
          for (var topic of this.CurrentDisplayForm) {
            for (var question of topic["questions"]) {
              var selectedAnswer = "";
              for (var answer of question.answers) {
                if (answer.selected) {
                  selectedAnswer = answer.answer;
                }
              }
              allOptions[question.question] = new FormControl(selectedAnswer);
            }
          }
        }
        this.form = new FormGroup(allOptions);
      
    } else {
      this.form = new FormGroup({});
      this.CurrentDisplayForm = undefined;
    }
  }
  ngOnInit(): void {
    this.isRM=this.authService.isRelationShipManager();
    this.getHealthCheckContent();
  }
  getHealthCheckContent() {
    var that = this;
    if (this.CurrentHealthCheck?.id) {
      this.healthCheckService.getHealthCheckContent(this.CurrentHealthCheck.id).subscribe(
        res => {
          this.healthCheckService.getSavedUserResponses(this.CurrentHealthCheck.id).subscribe(res2 => {
            that.HealthCheckForm = res.topic;
            if (!res2["error"]) {
              that.CurrentAnswers = res2;
            } else {
              that.CurrentAnswers = undefined;
            }
            that.constructForm();
          });
        }
      )
    }
  }
  noOfQuestionsAnswered=0;
  constructSaveAnswersData(data) {
    var count=0;
    var formdata = {
      "healthCheckId": this.CurrentHealthCheck.id,
      "topic": []
    }
    for (var topic of this.HealthCheckForm) {
      var answers = [];
      for (var question of topic["questions"]) {
        for (var answer of question["answers"]) {
          if (data[question.question] == answer.answer) {
            count++;
            answers.push({
              "answerId": answer.id,
              "questionId": question.id
            });
          }
        }
      }
      formdata.topic.push({
        "answers": answers,
        "topic": topic.topic
      });
    }
    this.noOfQuestionsAnswered=count;
    return formdata;
  }
  currentQuestion="";
  saveResponse(e) {
    this.currentQuestion=e?.target?.getAttribute("ng-reflect-name");
    var data = this.constructSaveAnswersData(this.form.value);
    if(!this.CurrentAnswers){
      this.gaService.logCustomEvent( "Started", "Healthcheck", this.CurrentHealthCheck.name );
    }
    this.CurrentAnswers = data;
    this.constructForm();
    this.healthCheckService.saveUserResponse(data).subscribe(
      res => {
        this.messageService.add({ key: 'app', life: 3000, severity: 'success', summary: 'Your response has been recorded successfully', detail: '' });
        this.myAnswersSaved.emit({healthCheckId:this.CurrentHealthCheck.id,noOfQuestionsAnswered:this.noOfQuestionsAnswered});
      }
    )
  }
  showReports() {
    this.gaService.logCustomEvent( "Completed", "Healthcheck", this.CurrentHealthCheck.name );
    var data = this.constructSaveAnswersData(this.form.value);
    this.healthCheckService.submitAnswers(data).subscribe(
      res => {
        res.id = this.CurrentHealthCheck.id
        this.myScoreClick.emit(res);
      }
    )

  }
  getKeys(object) {
    return Object.keys(object);
  }

}
