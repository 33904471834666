<div class="flex-features believe-sme" id="features">
  <div class="container">
    <div class="flex-split">
      <div class="f-right">
        <div class="right-content wow fadeInUp" data-wow-delay="0.2s">
          <h2>{{landingVideoArticle?.title | translate}}</h2>
          <p>{{landingVideoArticle?.disc | translate}}</p>
          <p>
            <span>{{landingVideoArticle?.steps | translate}}</span>
          </p>
        </div>
      </div>
      <div class="f-left">
        <div class="left-content wow fadeInUp" data-wow-delay="0.3s">
          <img id="imgLandingVideoArticle" class="img-fluid" src="{{landingVideoArticle?.img}}" data-toggle="modal"
            (click)="videoModalPop.show()" alt="alt" />
        </div>
      </div>
    </div>
  </div>
</div>

<app-ui-modal #videoModalPop [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-body">
    <div class="thumbnail">
      <div class="thumb">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe id="ifrVideoArticle" title="Video Article" class="embed-responsive-item"
            src="https://www.youtube.com/embed/bWdeGTJxMQc"></iframe>
        </div>
      </div>
    </div>
  </div>
</app-ui-modal>