<app-language-selector></app-language-selector>
<div class="row">
    <div class="col-sm-12 your-area-expertise">
        <h5 class="sub-title">{{'yourAreaOfExpertise.title' | translate}}</h5>
        <perfect-scrollbar class="perfect-scrollbar" [style.max-height]="'100%'" [style.max-height]="'400px'">
            <ul class="area-expertise">
                <li id="lstAreaOfExpertise1">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise1" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise1" class="custom-control-label" for="radAreaExpertise1">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.sales' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise2">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise2" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise2" class="custom-control-label" for="radAreaExpertise2">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.marketing' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise3">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise3" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise3" class="custom-control-label" for="radAreaExpertise3">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.communication' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise4">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise4" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise4" class="custom-control-label" for="radAreaExpertise4">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.webDevelopment' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise5">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise5" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise5" class="custom-control-label" for="radAreaExpertise5">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.accounting' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise6">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise6" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise6" class="custom-control-label" for="radAreaExpertise6">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.operation' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="lstAreaOfExpertise7">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise7" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise7" class="custom-control-label" for="radAreaExpertise7">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.businessDevelopment' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
                <li id="">
                    <div class="custom-control custom-radio">
                        <input id="radAreaExpertise8" type="radio" name="your-area-expertise"
                            class="custom-control-input">
                        <label id="lblYourExpertise8" class="custom-control-label" for="radAreaExpertise8">
                            <span class="label">
                                <em class="fas fa-briefcase"></em>
                                <span>{{'yourAreaOfExpertise.logistics' | translate}}</span>
                            </span>
                        </label>
                    </div>
                </li>
            </ul>
        </perfect-scrollbar>
    </div>
    <p class="more">Don’t see what you’re looking for? <a id="aCreateYours" href="#.">Create yours</a></p>
    <div class="col-sm-12 centered-content text-right">
        <button id="btnCreateYoursBack" type="button" class="btn btn-secondary btn-sm mr-2" awPreviousStep>Back</button>
        <button id="btnCreateYoursContinue" type="button" class="btn btn-confirm btn-sm" awNextStep>Continue</button>
    </div>
</div>