<app-language-selector></app-language-selector>
<div class="browse-filters" [ngClass]="{'filter-lbl-all':(filterslbl || gCardsfiltersLbl)}">
    <h3 *ngIf="filterslbl || gCardsfiltersLbl" class="filterslbl">
        <ng-container *ngIf="filterslbl">
            {{filterslbl}}
        </ng-container>
        <ng-container *ngIf="gCardsfiltersLbl">
            {{gCardsfiltersLbl}}
        </ng-container>
    </h3>
    <div class="custom-control custom-checkbox" *ngFor="let item of newFilters; index as i"
        [ngClass]="{'lbl-all':(item.label == 'All')}">
        <input type="checkbox" class="custom-control-input" id="cheFilter{{i+1}}" [checked]="item.checked"
            (change)="onChkChange(i, $event)">
        <label class="custom-control-label" id="lblFilter{{i+1}}" for="cheFilter{{i+1}}">{{item.label}}</label>
    </div>
</div>