import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  scrollEnd: BehaviorSubject<boolean>;

  constructor() {
    this.scrollEnd = new BehaviorSubject<boolean>(false);
  }
}
