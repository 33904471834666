import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IOption } from 'ng-select';
import { SelectOptionService } from 'src/app/shared/components/select/select-option.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomValidators } from 'src/app/shared/helpers/custom.validators';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { NAME_PATTERN, BRN_PATTERN, COMP_NAME_PATTERN, URL_PATTERN } from 'src/app/shared/constants/constants';
import { UqudoService } from 'src/app/pages/authentication/signup/uqudo.service';
import { v4 as uuidv4 } from 'uuid';
import uqudoSdkFactory, { DocumentType } from '../../../assets/uqudo_interface/uqudoSDK';

@Component({
  selector: 'app-became-gp',
  templateUrl: './became-gp.component.html',
  styleUrls: ['./became-gp.component.scss']
})
export class BecameGPComponent implements OnInit, OnChanges {

  @Input()
  modelPopupStatus: boolean;

  investmentOption: Array<any> = [];

  registerForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  filteredCountries = [];
  vitscroll: boolean = false;
  uq_auth: boolean = false
  personal_auth: boolean = false;
  token = '';
  faceImg: any;
  emiratesId: any;

  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authService: UserAuthService,
    private messageService: MessageService,
    public selectOptionService: SelectOptionService,
    private route: ActivatedRoute,
    private uqudoService: UqudoService,
  ) {
    this.filteredCountries = [];
  }

  ngOnInit() {
    this.getAccessToken();
    this.selectOptionService.getIndustryTypes().subscribe((data: any) => {
      this.investmentOption = data.map(v => {
        return { value: v.industryName, label: v.industryName };
      });
    });
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(25),
      CustomValidators.patternValidator(NAME_PATTERN, { hasNoSpecialCharacters: true })
      ]),
      lastName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.maxLength(35),
      CustomValidators.patternValidator(NAME_PATTERN, { hasNoSpecialCharacters: true })
      ]),
      position: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(40),
      CustomValidators.patternValidator(NAME_PATTERN, { hasNoSpecialCharacters: true })
      ]),
      brnNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$/)
      ]),
      email: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.email,
      Validators.maxLength(100)
      ]),
      companyName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(40),
      CustomValidators.patternValidator(COMP_NAME_PATTERN, { hasNoSpecialCharacters: true })
      ]),
      industry: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      website: new FormControl('', [
        CustomValidators.removeSpaces,
        Validators.maxLength(100),
        Validators.pattern(URL_PATTERN)
      ]),
      areaOfInterest: new FormControl('', [Validators.required]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["modelPopupStatus"] && changes["modelPopupStatus"]["currentValue"]) {
      this.isSubmit = false;
      this.buttonPressed = false;
      // this.registerForm.reset();
    }
  }

  register() {
    this.isSubmit = true;
    if (this.registerForm.invalid || this.buttonPressed) {
      return false;
    }
    this.buttonPressed = true;
    let userDetail = this.registerForm.value;
    userDetail.areaOfInterest = this.registerForm.value.areaOfInterest.map(option => option.value);
    userDetail.personalId = this.emiratesId;

    this.authService.registerGP(userDetail, this.faceImg).subscribe(
      data => {
        this.buttonPressed = false;
        this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Success', detail: 'Your request has been successfully received.' });
        this.isSubmit = false;
        this.registerForm.reset();
        setTimeout(() => {
          this.router.navigate(['/thank-you'], {
            queryParams: {
              email: userDetail.email,
              type: 'growth-partner'
            }
          });
        }, 1000);
      },
      error => {
        let msg = 'Error occur while register account for you. Please contact administrator.';
        this.buttonPressed = false;
        if (error.status == 400) {
          if (error.error == "Fail -> Email is already in use!") {
            msg = 'Email is already in use! Please enter different email address.';
          }
        }
        this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: msg });
      }
    );
  }

  get f() { return this.registerForm.controls; }

  inValid(control: any, submitted: any) {
    return control?.invalid && (control.dirty || submitted);
  }

  onBackClick(event: any) {
    this.backClick.emit(event);
  }

  filterCountry(event: any) {
    const query = event.query.toLowerCase();
    this.filteredCountries = this.investmentOption.filter(option =>
      option.value.toLowerCase().includes(query)
    );
    this.vitscroll = true;
  }

  gotonext() {
    this.uq_auth = !this.uq_auth
  }

  getupload() {
    this.personal_auth = !this.personal_auth
  }

  /////////////////////uq-int
  getAccessToken() {
    this.uqudoService.getToken().subscribe((response) => {
      const data: any = response;
      this.token = data.payload.token;
    });
  }

  async startEnrollment() {
    this.closeModal.emit(true);
    const sessionId = uuidv4();
    const uqudoSdk = uqudoSdkFactory.create({
      sessionId: sessionId,
      baseURL: 'https://id.dev.uqudo.io',
      accessToken: this.token,
    });
    try {
      const result = await uqudoSdk.enrollment({
        scan: {
          documentType: DocumentType.UAE_ID,
          disableExpiryValidation: true,
        },
        onSuccess: (result) => {
          this.isLoading.emit(true);
          this.uqudoService
            .getDataFromSessionId(sessionId)
            .subscribe((response) => {
              const data: any = response;
              const reqData = {
                front: data.payload.documents[0].scan.front,
                back: data.payload.documents[0].scan.back,
              }
              this.closeModal.emit(false);
              this.isLoading.emit(false);
              this.personal_auth = !this.personal_auth;
              this.uq_auth = true;
              const fullName = reqData.front.fullName;
              const nameParts = fullName.split(' ');
              const lastName = nameParts.pop();
              const firstName = nameParts.join(' ');
              console.log(firstName, lastName, 'lastName')
              this.registerForm.patchValue({
                firstName: firstName,
                lastName: lastName
              });
              this.faceImg = data.payload.documents[0].scan.faceImage;
              this.emiratesId = reqData.front.identityNumber
            });
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
