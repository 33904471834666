import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants/constants';
import { ClientInfoService } from '../client-info/client-info.service';
import { StorageService } from '../storage/storage.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class GrowthCardService {
  jwt_token: string;

  public gcfeaturedSubject: BehaviorSubject<string>;
  public gcfeatured: Observable<string>;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();

    this.gcfeaturedSubject = new BehaviorSubject<string>(null);
    this.gcfeatured = this.gcfeaturedSubject.asObservable();
  }

  public get currentfeaturedValue(): string {
    return this.gcfeaturedSubject.value;
  }

  updatecurrentfeaturedValue(value: any) {
    this.gcfeaturedSubject.next(value)
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  getAllGrowthCards(pageNumber, category_id, sortBy, sortOrder, onlyApproved): Observable<any> {
    const params = new HttpParams()
      .set('pageSize', Constants.PAGE_LIMIT.toString())
      .set('category_id', category_id)
      .set('pageNumber', pageNumber)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('onlyApproved', onlyApproved)
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/growth-cards`, { params })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getAllUtilityCategories(): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/growth-cards/getAllUtilityCategories`)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  changeFeaturedStatus(data): Observable<any> {
    const res: Observable<any> = this.http
      .put(`${this.BASE_API_URL}api/growth-cards`, data)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  saveCategoryGrowthCardMapping(data): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/growth-cards`, data)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
}
