<div class="testimonial-section" id="testimonial-section">
  <div class="container">
    <div class="testimonial-content">
      <div class="row text-center">
        <div class="col-md-12">
          <h2>{{successStoriesCarousel?.title | translate}}</h2>
          <div class="testimonials">
            <ngb-carousel [interval]="2000">
              <ng-template ngbSlide class="bannerStyle" *ngFor="let item of successStoriesCarousel?.slides">
                <div class="testimonial-single">
                  <div class="imgBlock">
                    <img id="imgStoriesPhoto" class="img-circle" src="assets/landing/images/{{item.photo}}" alt="alt">
                    <h3 class="name">{{item.name | translate}}</h3>
                    <p>{{item.designation | translate}}</p>
                  </div>
                  <div class="testimonial-text wow fadeInUp" data-wow-delay="0.2s">
                    <p>{{item.disc | translate}}</p>
                    <button id="btnStoriesConfirm" class="btn btn-confirm">{{item.button | translate}}</button>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>