import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { FeedService } from '../../core/services/feed-service/feed.service';
import { Constants, FeedStatus } from '../../shared/constants/constants';
import { CommonService } from '../../services/common/common.service';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';


class MyFeedContent {
  service: string;
  bookedBy: string;
  dateBooked: string;
  status: string;
}

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  public newsFeeds: any = [];
  public groupFeeds: any = [];
  public statusLine: any;
  public recommendAtions: any = [];
  public trendingTopics: any = [];
  showLoader: boolean = false;
  dtOptions: DataTables.Settings = {};
  myFeedContent: MyFeedContent[];
  currentMyFeed: any;
  isSme: boolean;
  isServiceProvider: boolean;
  isInvester: boolean;
  isCommunityManager: boolean;
  FeedStatus: any = FeedStatus;
  public videoArticles: any;
  public sideCardContent: any;
  totalRecords: number;
  feedTotalRecords: number;
  myFeedTotalRecords: number;
  limtitRecords = 10;
  firstRecord = 0;
  loading: boolean;
  pageNumber: number = 0;
  totalPages: number = 1;
  totalFeedPages: number = 1;
  isRM: boolean;
  cols = [
    { field: 'title', header: 'Post details', sortable: false }
  ];
  feedArticalViewModal: boolean;
  totalPendings: any;
  totalRejects: any;

  @ViewChild('MyContentPostDetails', { static: false }) MyContentPostDetails: ElementRef;
  gridUIClass = "";
  messageService: any;
  feedId: any;
  showingdetails: boolean;
  feedDetail: any = [];
  companyProfile: any;
  loadingDetails: boolean;



  constructor(
    private router: Router,
    private commonService: CommonService,
    private feedService: FeedService,
    private userAuthService: UserAuthService, private profileService: ProfileService, private gaService: GoogleAnalyticsService,
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.videoArticles = res.sideContent?.newsFeed?.video;
        this.sideCardContent = res.sideContent?.newsFeed;
      })
    this.fetchFeeds();
  }

  apiLoaded = false;

  ngOnInit(): void {

    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }


    this.isSme = this.userAuthService.isSME();
    this.isServiceProvider = this.userAuthService.isServiceProvider();
    this.isRM = this.userAuthService.isRelationShipManager();

    this.isInvester = this.userAuthService.isInvester();
    this.isCommunityManager = this.userAuthService.isCommunityManager();
    this.loading = true;

    this.feedService.feedvalue.subscribe(value => {
      this.fetchFeeds()
    })
    this.companyProfile = this.profileService.orgDetails;
    if (!this.companyProfile) {
      this.getCompanyProfile();
    }
  }

  getCompanyProfile() {
    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.companyProfile = data;
      },
      (error) => {
        this.companyProfile = null;
      }
    );
  }


  fetchFeeds() {
    if (this.showLoader || (this.pageNumber >= this.totalFeedPages)) {
      return false;
    }
    this.showLoader = true;
    this.feedService.fetchFeed(this.pageNumber).subscribe(
      (res: any) => {
        this.showLoader = false;
        this.totalRecords = res.totalRecords ? res.totalRecords : 0;
        this.feedTotalRecords = this.totalRecords;
        this.totalFeedPages = Math.ceil(this.totalRecords / Constants.PAGE_LIMIT);
        this.statusLine = res.statusLine;
        this.recommendAtions = res.recommendations;
        this.trendingTopics = res.trendingTopics;
        if (Array.isArray(res.feed)) {
          this.newsFeeds = this.newsFeeds.concat(res.feed);
        }
        this.pageNumber++;
      },
      (error: any) => {
        this.showLoader = false;
      }
    );
  }


  getMyFeedContent(event: any) {
    this.loading = true;
    this.feedService.getMyFeedContent((event.first / event.rows)).subscribe(
      res => {
        this.myFeedContent = res.myContent;
        this.totalRecords = res.totalRecords;
        this.myFeedTotalRecords = this.totalRecords;
        this.loading = false;
        window.scrollTo(0, 0);
        this.totalPages = Math.ceil(this.totalRecords / Constants.PAGE_LIMIT);
      })
  }

  onScroll(e: any) {
    this.fetchFeeds();
  }

  getDateString() {
    var today = new Date();
    var month = today.getUTCMonth() + 1;
    var dateString = today.getDate().toString();
    if (dateString.length == 1) {
      dateString = "0" + dateString;
    }
    var monthString = month.toString();
    if (monthString.length == 1) {
      monthString = "0" + monthString;
    }
    return dateString + "/" + (monthString) + "/" + today.getUTCFullYear();
  }

  newFeedAdded(feed: any) {
    feed.updatedTime = this.getDateString();
    feed.submittedDate = this.getDateString();
    feed.reviewStatus = "pending";
    if (this.myFeedContent) {
      this.myFeedContent.unshift(feed);
    } else {
      this.myFeedContent = [feed];
    }
    this.getMyFeedContent({ first: 0, rows: 10 })
  }

  recordsEmitter(event) {
    this.totalPendings = event;
  }

  RejectsRecordsEmitter(event) {
    this.totalRejects = event;
  }

  onDelete() {
    console.log("dddffgghhhjjjjkkkkllllljhhhh", this.newsFeeds)
    if (this.feedId)
      this.feedService.submitReviewStatus(this.feedId, "Removed").subscribe(res => {
        this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Successfully Removed', detail: '' });
        this.feedService.updatecurrentfeedValue("Removed");
      });
  }

  youtubeRegex = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  getYoutubeId(url) {
    if (!this.youtubeRegex.test(url)) {
      return false;
    }
    return url.match(this.youtubeRegex)[1];
  }
  testYoutubeId(url) {
    return this.youtubeRegex.test(url);
  }

  handleShowDetail(itemId: number) {
    this.loadingDetails = true;
    this.feedDetail = []
    console.log('Clicked item ID:', itemId);
    window.scrollTo(0, 0);
    this.showingdetails = !this.showingdetails
    this.feedService.getFeedDetail(itemId).subscribe(
      (res: any) => {
        if (Array.isArray(res.feed)) {
          this.feedDetail = this.feedDetail.concat(res.feed);
          this.loadingDetails = false;
        }
      },
      error => {
        console.error(error);
        this.loadingDetails = false;
      });
  }

  updatePlayButtonClicked(id) {
    this.feedDetail = this.feedDetail.map((feed) => {
      if (feed.id == id) {
        this.logViewEvent(feed.title);
        feed.clickedPlayButton = true;
      }
      return feed;
    });
  }

  logViewEvent(title) {
    this.gaService.logCustomEvent("Viewed Newsfeed item", "Newsfeed", title);
  }

  getRelativeTime(value: any) {
    return this.feedService.getRelativeTime(value, 0);
  }

  toggleFeedComment(feed: any) {
    feed.toggle = !feed.toggle;
  }

  helpful(id) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.feedDetail = this.feedDetail.map((feed) => {
      if (isNaN(feed.totalHelpful)) {
        feed.totalHelpful = 0;
      }
      if (isNaN(feed.totalUnHelpful)) {
        feed.totalUnHelpful = 0;
      }
      if (feed.id == id) {
        if (feed.unHelpful || (!feed.helpful && !feed.unHelpful)) {
          if (feed.totalUnHelpful > 0 && feed.unHelpful) {
            feed.totalUnHelpful = feed.totalUnHelpful - 1;
          }
          feed.totalHelpful = feed.totalHelpful + 1;
          that.feedService.setHelpfulness(id, true).subscribe(res => { });
          feed.unHelpful = false;
          feed.helpful = true;
        } else if (feed.helpful) {
          feed.helpful = false;
          feed.totalHelpful = feed.totalHelpful - 1;
          that.feedService.setHelpfulness(id, false).subscribe(res => { });
        }
      }
      return feed;
    });
  }
  unHelpful(id) {
    if (this.isRM) {
      return;
    }
    var that = this;
    this.feedDetail = this.feedDetail.map((feed) => {
      if (isNaN(feed.totalHelpful)) {
        feed.totalHelpful = 0;
      }
      if (isNaN(feed.totalUnHelpful)) {
        feed.totalUnHelpful = 0;
      }
      if (feed.id == id) {
        if (feed.helpful || (!feed.helpful && !feed.unHelpful)) {
          feed.totalUnHelpful = feed.totalUnHelpful + 1;
          if (feed.totalHelpful > 0 && feed.helpful) {
            feed.totalHelpful = feed.totalHelpful - 1;
          }
          that.feedService.setUnHelpfulness(id, true).subscribe(res => { });
          feed.unHelpful = true;
          feed.helpful = false;
        } else if (feed.unHelpful) {
          feed.unHelpful = false;
          feed.totalUnHelpful = feed.totalUnHelpful - 1;
          that.feedService.setUnHelpfulness(id, false).subscribe(res => { });
        }
      }
      return feed;
    });
  }

  groupredirection(groupId) {
    this.router.navigate(['/details/' + groupId]);
  }
}
