<div class="features parallax {{signupAs?.className}}" id="features">
  <div class="container">
    <div class="features-content">
      <div class="row  justify-content-md-center">
        <div class="col-md-12">
          <div class="signup-box">
            <div class="signup-block">
              <h2>{{signupAs?.title | translate}}</h2>
              <p>{{signupAs?.disc | translate}}</p>
              <div class="form wow fadeInUp" data-wow-delay="0.3s">
                <form id="frmSignupAs" class="subscribe-form wow zoomIn" action="javascript:" method="post" accept-charset="UTF-8"
                  enctype="application/x-www-form-urlencoded" autocomplete="off" novalidate>
                  <input id="txt{{signupAs?.input.id}}" class="{{signupAs?.input.className}}" 
                    type="{{signupAs?.input.type}}" name="{{signupAs?.input.name}}"
                    placeholder="{{signupAs?.input.placeholder}}" autocomplete="off">
                    <button id="btn{{signupAs?.button.id}}"
                    class="{{signupAs?.button.className}}" type="{{signupAs?.button.type}}"
                    >{{signupAs?.button.lable}}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>