import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnChanges {
  resetForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  showsuccessmsg: boolean;
  showerrorsmsg: boolean;
  @Input() clear: boolean = false;


  constructor(
    private authService: UserAuthService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.clear) {
      this.resetForm.reset();
      this.clear = false;
      this.isSubmit = false;
    }
  }

  ngOnInit() {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.email])
    });
  }

  resetPassword() {
    this.isSubmit = true;
    if (this.resetForm.invalid || this.buttonPressed) {
      return false;
    }
    this.buttonPressed = true;
    let userDetail = this.resetForm.value;

    this.authService.resetPassword(userDetail).subscribe(
      data => {
        this.buttonPressed = false;
        // this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Great, we’ve sent you a link to ' + userDetail.email + ' to reset your password.' });
        // alert("this")
        this.buttonPressed = false;
        this.isSubmit = false;
        this.resetForm.reset();
        this.showsuccessmsg = true;
        setTimeout(() => {
          this.router.navigate(['/signin']);
          this.showsuccessmsg = false;;
        }, 3000);

      },
      error => {
        this.buttonPressed = false;
        // this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: 'We did not find any account with the email address you provided.' });
        this.isSubmit = false;
        this.resetForm.reset();
        this.showerrorsmsg = true;
        setTimeout(() => {
          this.showerrorsmsg = false;;
        }, 3000);
      }
    );
  }

  // resetPassword() {
  //   this.isSubmit = true;
  //   if (this.resetForm.invalid || this.buttonPressed) {
  //     return false;
  //   }
  //   this.buttonPressed = true;
  //   let userDetail = this.resetForm.value;
  //   this.authService.resetPassword(userDetail).subscribe(
  //     data => {
  //       this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Great, we’ve sent you a link to ' + userDetail.email + ' to reset your password.' });
  //       this.isSubmit = false;
  //       this.resetForm.reset();
  //       setTimeout(() => {
  //         this.router.navigate(['/signin']);
  //       }, 3000);
  //     },
  //     error => {
  //       this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: 'We did not find any account with the email address you provided.' });
  //     }
  //   );
  // }


  get f() { return this.resetForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

}
