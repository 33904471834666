import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-browse-results',
  templateUrl: './browse-results.component.html',
  styleUrls: ['./browse-results.component.scss']
})
export class BrowseResultsComponent implements OnInit {
  @Input() lists: any;
  @Input() categories: any;

  constructor() { }

  ngOnInit(): void {
  }

}
