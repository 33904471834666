<app-language-selector></app-language-selector>
<!-- Investor -->
<div class="" *ngIf="accountTypeo">
  <div class="row auth-center">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="auth-content container">
        <div class="text-center">
          <h1 class="auth-title" id="ttlTtile">
            <span>{{ "signUp.title1" | translate }} </span>
          </h1>
          <p class="sub-head">Hi, thank you for your interest in applying as an Investor on SME LINKER!
          </p>
        </div>

        <h1 class="auth-title" id="offlineMessage">
          <p class="side-head">All our Investors:</p>
          <ul class="auth-title-list">
            <li>
              <p><span><i class="fas fa-check"></i></span>Are professional investment firms</p>
            </li>
            <li>
              <p><span><i class="fas fa-check"></i></span>Have the desire to support our ecosystem of entrepreneurs by
                providing financial support that
                contributes to their growth</p>
            </li>
          </ul>
          <div class="content-side">
            <p class="side-head">To apply as an Investor on SME LINKER please send us an email
              on
              <a href="mailto:help@smelinker.co.za" id="btnHelloSMECONNECT">help@smelinker.co.za </a> and provide us
              with
              the
              following:
            </p>
            <ul class="auth-title-list">
              <li>
                <p><span>1</span>Copy of Company Registration Certificate</p>
              </li>
              <li>
                <p><span>2</span>Copy of FSCA licence and FSP number</p>
              </li>
              <li>
                <p><span>3</span>Company Profile detailing your company's products or service and teams, or a link to
                  your website</p>
              </li>
            </ul>
            <p><span>4</span>Upon receipt of your email, our team shall assess your request and reply within 24 to 48
              working hours</p>
          </div>
        </h1>
      </div>
    </div>
  </div>
</div>

<!-- entrepreneurs signup -->
<div *ngIf="!accountTypeo">
  <!-- <div class="auth-center-authenticate text-center" *ngIf="!uq_auth">
    <div *ngIf="!personal_auth">
      <div class="row">
        <div class="col-lg-5 mobile-hide ">
          <img src="../../../../assets/images/auth-bg.png" alt="">
        </div>
        <div class="col-lg-7 col-md-12 m-auto">
          <h1 class="auth-title" id="ttlTtile">
            <span>Please authenticate yourself</span>
          </h1>
          <p class="auth-text">Please provide an identity proof by either uploading your ID or filling in the details
            manually.</p>
          <span class="btn-group-auth">
            <button class="btn btn-primary comp-btn mx-2" (click)="startEnrollment()">get started</button> <br>
            <button class="btn btn-primary comp-btn manual-btn mx-2" (click)="gotonext()">Do it manually</button>
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="signupform" *ngIf="!uq_auth && accountType == 'SME' || accountType == 'SERVICE_PROVIDER'">
    <form id="frmSignUp" [formGroup]="registerForm" autocomplete="on" class="signup" *ngIf="!exp">
      <div class="row auth-center-signup">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="auth-side-form">
            <div class="auth-content text-center">
              <h1 class="auth-title " id="ttlTtile" *ngIf="accountType == 'SME'">
                <span>{{ "signUp.title1" | translate }} </span>
              </h1>
              <h1 class="auth-title " id="ttlTtile" *ngIf="accountType == 'SERVICE_PROVIDER'">
                <span>{{ "signUp.title2" | translate }} </span>
              </h1>
              <p class="pt-2 pb-2" *ngIf="accountType == 'SME'">
                <span>{{ "signUp.descriptionsub2" | translate }}</span> <br>
                <span>{{ "signUp.description" | translate }}</span>
              </p>
              <p class="pt-2 pb-2" *ngIf="accountType == 'SERVICE_PROVIDER'">
                <span>{{ "signUp.description2" | translate }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="auth-side-form">
            <div class="auth-content">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12  tab-menu">
                  <p-tabView [(activeIndex)]="index" [scrollable]="true">
                    <p-tabPanel header="Personal Details">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblFirstName">{{ "formElementsLables.Firstname" | translate }}</label>
                            <input id="txtFirtName" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.fNamePlaceholder' | translate }}" autocomplete="off"
                              [ngClass]="{'is-invalid':inValid(f.firstName, isSubmit)}" formControlName="firstName" />
                            <div class="invalid-feedback" *ngIf="inValid(f.firstName, isSubmit)">
                              <div *ngIf="f.firstName.errors.required" id="err_FirstName">Please enter your first name
                              </div>
                              <div *ngIf="f.firstName.errors.minlength" id="err_minLength_firstName">First Name should
                                contain
                                at
                                least 3 characters</div>
                              <div *ngIf="f.firstName.errors.maxlength" id="err_maxLength_firstName">First Name should
                                not
                                exceed
                                25
                                characters</div>
                              <div *ngIf="f.firstName.errors.hasNoSpecialCharacters" id="err_specialChar_firstName">
                                First
                                Name
                                should
                                contain alphabetical characters only</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblLastName">{{ "formElementsLables.Lastname" | translate }}</label>
                            <input id="txtLastName" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.lNamePlaceholder' | translate }}" autocomplete="off"
                              [ngClass]="{'is-invalid':inValid(f.lastName, isSubmit)}" formControlName="lastName" />
                            <div class="invalid-feedback" *ngIf="inValid(f.lastName, isSubmit)">
                              <div *ngIf="f.lastName.errors.required" id="err_LastName">Please enter your last name
                              </div>
                              <div *ngIf="f.lastName.errors.minlength" id="err_minLength_lastName">Last Name should
                                contain at
                                least 3
                                characters</div>
                              <div *ngIf="f.lastName.errors.maxlength" id="err_maxLength_lastName">Last Name should
                                not
                                exceed
                                35
                                characters</div>
                              <div *ngIf="f.lastName.errors.hasNoSpecialCharacters" id="err_specialChar_lastName">Last
                                Name
                                should
                                contain alphabetical characters only</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblEmail">{{ "formElementsLables.email" | translate }}</label>
                            <input id="txtEmail" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.emailPlaceholder' | translate }}" autocomplete="off"
                              [ngClass]="{'is-invalid':inValid(f.email, isSubmit)}" formControlName="email" />
                            <div class="invalid-feedback" *ngIf="inValid(f.email, isSubmit)">
                              <div *ngIf="f.email.errors.required" id="err_EmailId">Please insert a valid email
                                address
                              </div>
                              <div *ngIf="f.email.errors.maxlength" id="err_maxLength_email">email address should not
                                exceed 100
                                characters</div>
                              <div *ngIf="f.email.errors.pattern" id="err_maxLength_email"> Email must be a valid
                                pattern</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblPosition">{{ "formElementsLables.positionTitle" | translate }}</label>
                            <input id="txtPosition" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.positionPlaceholder' | translate }}"
                              autocomplete="off" [ngClass]="{'is-invalid':inValid(f.position, isSubmit)}"
                              formControlName="position" />
                            <div class="invalid-feedback" *ngIf="inValid(f.position, isSubmit)">
                              <div *ngIf="f.position.errors.required" id="err_Position">Please enter position</div>
                              <div *ngIf="f.position.errors.minlength" id="err_minLength_position">Position/Title
                                should
                                contain
                                at
                                least 3 characters</div>
                              <div *ngIf="f.position.errors.maxlength" id="err_maxLength_position">Position/Title
                                Should
                                not
                                exceed 40
                                characters</div>
                              <div *ngIf="f.position.errors.hasNoSpecialCharacters" id="err_specialChar_position">
                                Position/Title
                                Should contain only alphabets</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <label id="lblPassword" class="pwd-label">{{ "formElementsLables.passwordSignUp" | translate
                            }}</label>
                          <div class="input-group mb-3 pwd-show">
                            <input id="txtPassword" [type]="isTextFieldType ? 'text' : 'password'" class="form-control"
                              placeholder="{{ 'formElementsLables.passwordPlaceholder' | translate }}"
                              autocomplete="off" [ngClass]="{'is-invalid':inValid(f.password, isSubmit)}"
                              formControlName="password" />
                            <div class="input-group-append">
                              <span class="input-group-text" (click)="togglePasswordFieldType()"
                                id="togglePasswordFieldType">
                                <em [ngClass]="isTextFieldType ? 'fas fa-eye-slash' : 'fas fa-eye'"></em>
                              </span>
                            </div>
                            <div class="invalid-feedback" *ngIf="inValid(f.password, isSubmit)">
                              <div *ngIf="f.password.errors.required" id="err_Password">Please enter password</div>
                              <div *ngIf="f.password.errors.minlength ||
                          f.password.errors.hasNumber ||
                          f.password.errors.hasCapitalCase ||
                          f.password.errors.hasSmallCase ||
                          f.password.errors.hasSpecialCharacters" id="err_Password_criteria_mismatch">Your password
                                must
                                contain
                                at least:</div>
                              <ul>
                                <li *ngIf="f.password.errors.minlength" id="err_ValidPasswordMinLimit">8 characters
                                </li>
                                <li *ngIf="f.password.errors.hasNumber" id="err_ValidPasswordNumber">1 number</li>
                                <li *ngIf="f.password.errors.hasCapitalCase" id="err_ValidPasswordCapital">1 capital
                                  letter</li>
                                <li *ngIf="f.password.errors.hasSmallCase" id="err_ValidPasswordSmall">1 small letter
                                </li>
                                <li *ngIf="f.password.errors.hasSpecialCharacters" id="err_ValidPasswordSpecial">1
                                  special
                                  character
                                </li>
                              </ul>
                              <div *ngIf="f.password.errors.maxlength" id="err_ValidPasswordMaxLimit">Should not
                                exceed
                                25
                                characters
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <label id="lblConfirmPassword" class="pwd-label">{{ "formElementsLables.confirmPwdSignUp" |
                            translate
                            }}</label>
                          <div class="input-group mb-3 pwd-show">
                            <input id="txtConfirmPassword" [type]="isReTextFieldType ? 'text' : 'password'"
                              class="form-control" placeholder="{{ 'formElementsLables.confirmPwdSignUp' | translate }}"
                              autocomplete="off" [ngClass]="{'is-invalid':inValid(f.confirmPassword, isSubmit)}"
                              formControlName="confirmPassword" />
                            <div class="input-group-append">
                              <span class="input-group-text" (click)="toggleRePasswordFieldType()"
                                id="toggleRePasswordFieldType">
                                <em [ngClass]="isReTextFieldType ? 'fas fa-eye-slash' : 'fas fa-eye'"></em>
                              </span>
                            </div>
                            <div class="invalid-feedback" *ngIf="inValid(f.confirmPassword, isSubmit)">
                              <div *ngIf="f.confirmPassword.errors.required" id="err_CofirmPassword">Please enter
                                confirm
                                password
                              </div>
                              <div *ngIf="f.confirmPassword.errors.NoPassswordMatch" id="err_PasswordMatch">Passwords
                                must
                                match
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-right mt-2">
                        <button class="btn btn-confirm" [disabled]="!isRegisterFirstStepValid()"
                          (click)="openNext()">Next</button>
                      </div>
                    </p-tabPanel>

                    <p-tabPanel header="Company Details">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblCompany">{{ "formElementsLables.company" | translate }}</label>
                            <input id="txtCompany" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.companyPlaceholder' | translate }}" autocomplete="off"
                              [ngClass]="{'is-invalid':inValid(f.companyName, isSubmit)}"
                              formControlName="companyName" />
                            <div class="invalid-feedback" *ngIf="inValid(f.companyName, isSubmit)">
                              <div *ngIf="f.companyName.errors.required" id="err_CompanyName">Please enter company
                                name
                              </div>
                              <div *ngIf="f.companyName.errors.minlength" id="err_minLength_companyName">Company Name
                                should
                                contain
                                at least 3 characters</div>
                              <div *ngIf="f.companyName.errors.maxlength" id="err_maxLength_companyName">Company Name
                                Should not
                                exceed 40 characters</div>
                              <div *ngIf="f.companyName.errors.hasNoSpecialCharacters" id="err_specialChar_companyName">
                                Company
                                Name
                                should not contain any Special Character</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblIndustry">{{ "formElementsLables.Industry" | translate }}</label>
                            <div class="options" id="divIndustry">
                              <span class="p-fluid">
                                <p-multiSelect [options]="groupedCities" [group]="true" defaultLabel="Select a Industry"
                                  display="chip" (onPanelShow)="testfor()">

                                  <ng-template let-group let-template pTemplate="group">
                                    <div class="d-flex justify-content-between">
                                      <span>{{group.label}}</span>
                                      <i class="pi pi-angle-down" (click)="handleClick(group)"></i>
                                    </div>
                                  </ng-template>
                                </p-multiSelect>
                              </span>
                              <div class="invalid-feedback" *ngIf="inValid(f.industry, isSubmit)">
                                <div *ngIf="f.industry.errors.required" id="err_Industry">Please select your industry
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblBrn">{{ "formElementsLables.tradeLicenseNumber" | translate }}</label>
                            <input id="txtBrn" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.tradeLicenseNumberPlaceholder' | translate }}"
                              autocomplete="off" formControlName="tradeLicense"
                              [ngClass]="{'is-invalid':inValid(f.tradeLicense, isSubmit)}" />
                            <div class="invalid-feedback" *ngIf="inValid(f.tradeLicense, isSubmit)">
                              <div *ngIf="f.tradeLicense.errors.required" id="err_TradeLicenseNumber">Please enter
                                your Trade License Number
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblBrn">{{ "formElementsLables.companyRegion" | translate }}</label>
                            <span class="p-fluid">
                              <p-dropdown placeholder="{{ 'formElementsLables.companyRegionPlaceholder' | translate }}"
                                [options]="countryRegionOptions" formControlName="companyRegion"
                                [ngClass]="{'is-invalid':inValid(f.companyRegion, isSubmit)}"></p-dropdown>
                            </span>


                            <div class="invalid-feedback" *ngIf="inValid(f.companyRegion, isSubmit)">
                              <div *ngIf="f.companyRegion.errors.required" id="errBrnInvalid">
                                Please enter your Company Region</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblBrn">{{ "formElementsLables.brn" | translate }}</label>
                            <input id="txtBrn" type="text" class="form-control"
                              placeholder="{{ 'formElementsLables.brnPlaceholder' | translate }}" autocomplete="off"
                              formControlName="brnNumber" [ngClass]="{'is-invalid':inValid(f.brnNumber, isSubmit)}" />
                            <div class="invalid-feedback" *ngIf="inValid(f.brnNumber, isSubmit)">
                              <div *ngIf="f.brnNumber.errors.required" id="errBrnInvalid">
                                Please insert Company Registration Number</div>
                              <div *ngIf="f.brnNumber.errors.pattern" id="err_brnNumber_pattern">Please insert a valid
                                Company Registration Number (YYYY/XXXXXX/ZZ)</div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="d-flex justify-content-between mt-2 ">

                        <div class="auth-back-btn">
                          <p id="btnPAccountType" (click)="openPrev()">
                            <a href=" javascript:void(0);" id="btnAccountTypeBack">
                              {{ "buttons.back" | translate }}
                            </a>
                          </p>
                        </div>

                        <div class="text-right mt-2">
                          <button class="btn btn-confirm" [disabled]="!isRegistersecondStepValid()"
                            (click)="openNext()">Next</button>
                        </div>
                      </div>

                    </p-tabPanel>
                    <p-tabPanel header="Additional Information">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblHeard">{{ "Where did you hear about SME LINKER" | translate }}</label>
                            <div class="options" id="divHeard">
                              <div class="p-fluid">
                                <p-dropdown [options]="heardFromOption"
                                  placeholder=" Tell {{ 'us how you learned about SME LINKER' | translate }}"
                                  [ngClass]="{'is-invalid':inValid(f.heardFrom, isSubmit)}" formControlName="heardFrom"
                                  id="selHeard">
                                </p-dropdown>
                              </div>
                              <div class="invalid-feedback" *ngIf="inValid(f.heardFrom, isSubmit)">
                                <div *ngIf="f.heardFrom.errors.required" id="err_heardFrom">Please where did you hear
                                  about
                                  SME LINKER
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="form-group mb-3">
                            <label id="lblHeard">{{ "Area of Interest" | translate }}</label>
                            <div class="options" id="divHeard">
                              <div class="p-fluid">
                                <p-autoComplete formControlName="areaOfInterest" [maxlength]=5 [dropdown]="true"
                                  [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)"
                                  field="value" [multiple]="true" placeholder="Select Options"
                                  [ngClass]="{'is-invalid':inValid(f.areaOfInterest, isSubmit)}"></p-autoComplete>
                              </div>
                              <div class="invalid-feedback" *ngIf="inValid(f.areaOfInterest, isSubmit)">
                                <div *ngIf="f.areaOfInterest.errors.required" id="err_heardFrom">Please select your area
                                  of interest
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                          <div class="form-group mb-3 mt-4">
                            <div class="custom-control custom-checkbox termsncon p-0">
                              <input id="chkTermsConditions" type="checkbox" class="custom-control-input"
                                [ngClass]="{'is-invalid':inValid(f.terms, isSubmit)}" formControlName="terms">
                              <label id="lblTermsConditions" for="chkTermsConditions" class="custom-control-label">
                                <span class="termnc ">
                                  Accept
                                  <a id="aTermsConditions" [href]="termsURL" target="_blank" rel="noopener">Terms of
                                    use</a>
                                  and
                                  <a id="aTermsConditions" [href]="privacyURL" target="_blank" rel="noopener">Privacy
                                    Policy</a>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 signupmain m-auto">
                          <button id="btnSignUp" type="button" (click)="register()"
                            class="btn btn-block btn-confirm mb-3 mt-2" [disabled]="registerForm.invalid"
                            [ngClass]="{'btn-disabled':registerForm.invalid}" *ngIf="accountType == 'SME'">
                            <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
                            <span *ngIf="buttonPressed" class="load-text">Loading..</span>
                            <span *ngIf="!buttonPressed" class="btn-text">{{ "buttons.signUp" | translate }}</span>
                          </button>

                          <button id="btnSignUp" type="button" (click)="verifyPopup = true"
                            class="btn btn-block btn-confirm mb-3 mt-2" [disabled]="registerForm.invalid"
                            [ngClass]="{'btn-disabled':registerForm.invalid}" *ngIf="accountType == 'SERVICE_PROVIDER'">
                            <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
                            <span *ngIf="buttonPressed" class="load-text">Loading..</span>
                            <span *ngIf="!buttonPressed" class="btn-text">{{ "buttons.signUp" | translate }}</span>
                          </button>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                          <span>Please fill in the requirement fields before you can submit.</span>
                        </div>
                      </div>

                      <div class="text-left mt-2">
                        <div class="auth-back-btn">
                          <p id="btnPAccountType" (click)="openPrev()">
                            <a href=" javascript:void(0);" id="btnAccountTypeBack">
                              {{ "buttons.back" | translate }}
                            </a>
                          </p>
                        </div>
                      </div>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <app-thank-you *ngIf="exp"></app-thank-you> -->
    <div *ngIf="exp" class="text-center verification-mail mb-5">
      <h1>Verify your email</h1> <br>

      <p>An email has been sent to your mail with a link to verify your account. If you have not
        received the
        email after a few minutes, please check your spam folder</p>
      <button class="btn btn-primary" routerLink="/signin">Back to Login</button>
    </div>

  </div>
</div>
<p-toast position="top-center" key="c" [baseZIndex]="5000" id="pToastMsg"></p-toast>

<p-dialog header="" [(visible)]="verifyPopup" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" [blockScroll]="true" [resizable]="true">
  <div class="app-modal-body verifypopup">
    <div class="icon-img ">
      <img src="assets/images/about-icon.svg" alt="alt" />
    </div>
    <h4 class="sub-title">{{"verifyPopup.title" | translate}}</h4>
    <p class="discp">{{"verifyPopup.description" | translate}}</p>
    <button type="button" class="btn btn-confirm" (click)="register()">
      {{ "verifyPopup.signUp" | translate }}
    </button>
  </div>
</p-dialog>