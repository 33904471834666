<app-language-selector></app-language-selector>
<ng-container *ngIf="field && field.display">
  <ng-container [ngSwitch]="field.fieldType">
    <app-input-text-reactive-field *ngSwitchCase="fieldTypeEnum.TEXT" [field]="field" [disabled]="_disabled"
      [componentId]="componentId" (getData)="getinputData($event)" [maxLength]="maxLength" [minLength]="minLength" [submitted]="submitted">
      <ng-container labelTemplate *ngTemplateOutlet="fieldStatusTemplate"></ng-container>
    </app-input-text-reactive-field>
    <app-input-textarea-reactive-field *ngSwitchCase="fieldTypeEnum.TEXT_AREA" [field]="field" [disabled]="_disabled"
      [componentId]="componentId" (blurEvent)="blurEvent.emit()" [maxLength]="maxLength" [minLength]="minLength" [submitted]="submitted">
      <ng-container labelTemplate *ngTemplateOutlet="fieldStatusTemplate"></ng-container>
    </app-input-textarea-reactive-field>
  </ng-container>
  <ng-template #fieldStatusTemplate>
    <div class="d-flex h-100">
      <div class="pl-half" *ngIf="showOverwritten()">
        <div class="overwritten-container">
          <div id="lblOverwritten{{getTitleCase()}}{{componentId}}" class="content">{{ 'KYC.OVERWRITTEN' | translate }}
          </div>
        </div>
      </div>
      <div class="pl-half" *ngIf="!showOverwritten() && showUpdated()">
        <div class="update-container">
          <div class="content">{{ 'KYC.UPDATED' | translate }}</div>
        </div>
      </div>
      <div class="pl-half align-self-end" *ngIf="showMetData()">
        <div class="optional">
          {{ field.metaData | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>