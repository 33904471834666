import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../core/services/profile-service/profile.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  loading: boolean;
  profile: any = {};
  userId: any;
  editMode = false;
  personalDetailsForm: FormGroup;
  personalDetails: any = {};
  isSubmit: boolean;
  buttonPressed: boolean;
  profilePic: any;
  showpersonaldetailsDialog: boolean;
  originalData: any = {};
  isDirty: boolean;


  constructor(
    private profileService: ProfileService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getProfile();
  }
  constructForm() {
    this.personalDetailsForm = new FormGroup({
      firstName: new FormControl(this.profile ? this.profile.firstName : "", [
        Validators.required,
        CustomValidators.removeSpaces,
        Validators.minLength(3),
        Validators.maxLength(25),
        CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      lastName: new FormControl(this.profile ? this.profile.lastName : "", [
        Validators.required,
        CustomValidators.removeSpaces,
        Validators.maxLength(35),
        CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      title: new FormControl(this.profile ? this.profile.title : "", [
        Validators.required,
        CustomValidators.removeSpaces,
        Validators.maxLength(40),
        CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      email: new FormControl(this.profile ? this.profile.email : "")
    });

    this.originalData = this.personalDetailsForm.value;
  }
  editDetails() {
    this.showpersonaldetailsDialog = true;
    this.editMode = true;
    this.constructForm();
  }
  editCancel() {
    this.showpersonaldetailsDialog = false;
    this.editMode = false;
    this.personalDetailsForm.reset();
  }
  selectedPic(imageData) {
    this.profilePic = imageData;
  }

  updateDetails() {
    this.isSubmit = true;
    console.log(this.personalDetailsForm, "test")
    if (this.personalDetailsForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let formData = this.personalDetailsForm.value;
    let payload = new FormData();
    payload.append('data', JSON.stringify(formData));
    payload.append('file', this.profilePic, "profileImg.jpg");
    this.profileService.savePersonalDetails(payload).subscribe(
      data => {
        this.buttonPressed = false;
        this.isSubmit = false;
        this.getProfile();
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Personal details', detail: 'Successfully updated' });
        this.editMode = false;
        this.showpersonaldetailsDialog = false;
        this.personalDetailsForm.reset();
      },
      error => {
        this.buttonPressed = false;
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Personal details', detail: 'Something went wrong' });
      }
    );
  }



  getProfile() {
    this.loading = true;
    this.profileService.getProfileDetails().subscribe(
      (data) => {
        this.profile = data ? data : {};
        this.loading = false;
        this.constructForm();
      },
      (error) => {
        this.profile = {};
        this.loading = false;
        console.log(error);
      }
    );
  }

}
