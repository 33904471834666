<app-language-selector></app-language-selector>
<div class="row">
    <div class="col-md-12">
        <div class="sp-portfolio">
            <h3 class="title">{{ "SPportfolio.title" | translate }}</h3>
            <div class="icon-img">
                <img id="imgProviderPortfolio" src="assets/images/img-no.png" alt="alt" />
            </div>
            <h4 class="sub-title">{{ "SPportfolio.subTitle" | translate }}</h4>
            <p class="discp">
                {{ "SPportfolio.description" | translate }}
            </p>
            <button id="btnPortfolioGetStarted" type="button" class="btn btn-secondary">
                {{ "buttons.getStarted" | translate }}
            </button>
        </div>
    </div>
</div>