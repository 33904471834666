<app-language-selector></app-language-selector>
<app-card id="crdOpportunitiesPerformance" cardTitle="{{'opportunitiesPerformance.title' | translate}}"
    cardClass="oppr-performance" [options]="false">
    <div class="row top-lables">
        <div class="col-sm-12 col-md-2">
            <div class="lables">{{'opportunitiesPerformance.name' | translate}}</div>
        </div>
        <div class="col-sm-12 col-md-5">
            <div class="lables">{{'opportunitiesPerformance.PLANStatus' | translate}}</div>
        </div>
        <div class="col-sm-12 col-md-2">
            <div class="lables">{{'opportunitiesPerformance.Investment' | translate}}</div>
        </div>
        <div class="col-sm-12 col-md-3">
            <div class="lables">{{'opportunitiesPerformance.return' | translate}}</div>
        </div>
    </div>
</app-card>