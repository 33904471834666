<app-language-selector></app-language-selector>
<app-card [options]="false" [hidHeader]="true" class="view-details">
  <div class="row">
    <div class="col-md-12">
      <h2 id="ttlPersonalDetails">
        <span>{{ "editProfile.personalDetails.mainTitle" | translate }}</span>
        <a href="javascript:void(0)" (click)="editDetails();" id="btnPersonalEdit"><em
            class="fas fa-pencil-alt"></em></a>
      </h2>
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col-md-12 col-lg-3">
        <div class="profile-pic" id="divProfile">
          <img src="{{profile?.image}}" class="profile-img" id="imgProfilePic">
          <div class="profile-logo" *ngIf="!profile.image">
            <span class="logo-txt">{{ profile?.firstName?.trim().slice(0,1) }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="user-details">
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <label id="lblFirstName">{{ "editProfile.personalDetails.FirstName" | translate }}</label>
              <span class="lbl-show" id="spanFirstName">{{profile?.firstName}}</span>
            </div>
            <div class="col-md-6 col-lg-4">
              <label id="lblLastName">{{ "editProfile.personalDetails.LastName" | translate }}</label>
              <span class="lbl-show" id="spanLastName">{{profile?.lastName}}</span>
            </div>
            <div class="col-md-6 col-lg-4">
              <label id="lblEmail">{{ "editProfile.personalDetails.Email" | translate }}</label>
              <span class="lbl-show" id="spanEmail">{{profile?.email}}</span>
            </div>
            <div class="col-md-6 col-lg-4">
              <label id="lblTitle">{{ "editProfile.personalDetails.Title" | translate }}</label>
              <span class="lbl-show" id="spanTitle">{{profile?.title}}</span>
            </div>
            <div class="col-md-6 col-lg-4" *ngIf="profile?.personalId">
              <label id="lblTitle">{{ "editProfile.personalDetails.emiratesId" | translate }}</label>
              <span class="lbl-show" id="spanTitle">{{profile?.personalId}}</span>
            </div>
            <div class="col-md-6 col-lg-4">
              <app-update-password></app-update-password>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-card>

<p-dialog header="" [(visible)]="showpersonaldetailsDialog" [modal]="true" [style]="{width: '40vw',height : '80vh'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="shareFeed_dialog" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
  styleClass="followersModal">

  <div class="editpersonalinfo">
    <div class="text-center">
      <h4>Edit Profile</h4>
    </div>
    <app-personal-details-form *ngIf="editMode" [srcData]="profile.image" [personalDetailsForm]="personalDetailsForm"
      [(isSubmit)]="isSubmit" (selectedPic)="selectedPic($event)">
    </app-personal-details-form>
    <div class="row" *ngIf="editMode">
      <div class="col-md-12 col-lg-4">

      </div>
      <div class="col-md-12 col-lg-12 text-right">
        <div class="form-group mt-4">
          <button type="button" class="btn btn-secondary mr-2" (click)="editCancel();" id="btnCancelPersonalDetails">{{
            "editProfile.personalDetails.cancel" | translate }}</button>
          <button type="button" class="btn btn-confirm" (click)="updateDetails();" id="btnSubmitPersonalDetails">
            <span class="spinner-grow spinner-grow-sm" role="status" *ngIf="this.buttonPressed"></span>
            <span class="load-text" *ngIf="this.buttonPressed">{{ "editProfile.personalDetails.loading" | translate
              }}</span>
            <span *ngIf="!this.buttonPressed" class="btn-text">{{ "editProfile.personalDetails.submit" | translate
              }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>