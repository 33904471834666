import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { CmService } from 'src/app/core/services/cm/cm.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-utility-categories',
  templateUrl: './utility-categories.component.html',
  styleUrls: ['./utility-categories.component.scss'],
  providers: [NgbDropdownConfig]
})
export class UtilityCategoriesComponent implements OnInit {
  @Output() totalRecordsChanged = new EventEmitter<number>();
  categories: any;
  checked1: boolean = false;
  utilityCategoriesModal= false;
  deleteUtilityCategoriesModal=false;
  maxLength=75;
  currentUtility:any;
  currentUtilityCategoryTitle="";
  characterLength=0;
  sortOptions: SelectItem[];
  totalutilityRecords: number;

  sortOrder: number;

  sortField: string;
  constructor(private cmService: CmService,private messageService:MessageService) {
  }
  updateCharacterLength(event: any) {
    this.characterLength = event.target.value.length;
  }
  setCurrentItem(utility){
    if(utility){
      this.currentUtility=utility;
      this.currentUtilityCategoryTitle=utility.categoryTitle;
      this.characterLength = utility.categoryTitle.length;
    }else{
      this.currentUtility=null;
      this.currentUtilityCategoryTitle="";
      this.characterLength = 0;
    }
  }
  showModel(){
    this.utilityCategoriesModal=true;
  }
  hideModel(){
    this.utilityCategoriesModal=false;
    this.currentUtility=null;
    this.currentUtilityCategoryTitle="";
    this.characterLength = 0;
  }
  ngOnInit() {
   this.getAllUtilityCategories();
  }
  getAllUtilityCategories(){
    this.cmService.getUtilityCategories().subscribe(
      res => {
        this.categories = res;
        this.totalutilityRecords = res.length;
        this.totalRecordsChanged.emit(this.totalutilityRecords);
      }
    );
  }
  add(){
    this.cmService.addUtilityCategory({categoryTitle:this.currentUtilityCategoryTitle}).subscribe(
      res => {
        if(res.success){
          this.hideModel();
          this.messageService.add({ key: 'app', life: 3000, severity: 'success', summary: 'The new utility category has been added successfully', detail: '' });
          this.getAllUtilityCategories();
        }else{
          this.messageService.add({ key: 'app', life: 3000, severity: 'error', summary: res.error, detail: '' });
        }
      }
    );
  }
  update(){
    this.cmService.updateUtilityCategory({id:this.currentUtility.id,categoryTitle:this.currentUtilityCategoryTitle}).subscribe(
      res => {
        if(res.success){
          this.currentUtility.categoryTitle=this.currentUtilityCategoryTitle;
          this.hideModel();
          this.getAllUtilityCategories();
          this.messageService.add({ key: 'app', life: 3000, severity: 'success', summary: 'Utility category successfully updated', detail: '' });
        }else{
          this.messageService.add({ key: 'app', life: 3000, severity: 'error', summary: res.error, detail: '' });
        }
      }
    );
  }
  updateStatus(category){
    this.cmService.updateUtilityCategoryStatus(category.id,category.status).subscribe(
      res => {
       
      }
    );
  }
  delete(){
    this.cmService.deleteUtilityCategory(this.currentUtility.id).subscribe(
      res => {
        if(res.success){
          this.getAllUtilityCategories();
          this.messageService.add({ key: 'app', life: 3000, severity: 'success', summary: 'Utility category deleted successfully', detail: '' });
        }else{
          this.messageService.add({ key: 'app', life: 3000, severity: 'error', summary: res.error, detail: '' });
        }
      }
    );
  }
  categoriesReordered(){
    const priorityorder=this.categories.map((item,index)=>{
        return {id:item.id,priority_number:index+1};
    });
    this.cmService.updatePriorityOrder(priorityorder).subscribe(
      res => {
        this.categories=this.categories.map((item,index)=>{
          item.priorityNumber=index+1;
          return item;
        });
      }
    );
  }
}
