import { Component } from '@angular/core';

@Component({
  selector: 'app-your-area-expertise',
  templateUrl: './your-area-expertise.component.html',
  styleUrls: ['./your-area-expertise.component.scss']
})
export class YourAreaExpertiseComponent {



}
