import { Injectable } from '@angular/core';
import {EformData} from "../../models/e-form-model";
import {Observable, of} from "rxjs";
import {TokenService} from "../token/token.service";
import {HttpClient} from "@angular/common/http";
import {catchError, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EFormService {

  private applicationRequest: EformData;

  private applicationReference: any;

  constructor(private http: HttpClient,
              private tokenService: TokenService) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructue
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  createEFormApplication(application: EformData): Observable<EformData> {
    return this.http
      .post<EformData>('api/eform', application, {
        headers: this.tokenService.getHttpHeader()
      })
      .pipe(
        tap((appRef: any) => {}),
        catchError(this.handleError<EformData>('createEFormApplication'))
      );
  }

  getApplicationRequest(): Observable<EformData> {
    return of(this.applicationRequest);
  }

  updateApplicationRequest(applicationRequest: EformData): Observable<EformData> {
    this.applicationRequest = applicationRequest;
    return of(this.applicationRequest);
  }

  setApplicationReference(reference: any) {
    this.applicationReference = reference;
  }

  getApplicationReference() {
    return of(this.applicationReference);
  }
}
