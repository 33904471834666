export enum Roles {
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  COMMUNITY_MANAGER = 'COMMUNITY_MANAGER',
  CONTRACTOR_HOLDER = 'CONTRACTOR_HOLDER',
  INVESTOR = 'INVESTOR',
  RELATIONSHIP_MANAGER = 'RELATIONSHIP_MANAGER',
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  SME = 'SME'
}
