import { Component } from '@angular/core';
import { CountryISO } from 'ngx-intl-tel-input';
import { SearchCountryField } from 'ngx-intl-tel-input';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-about-your-business',
  templateUrl: './about-your-business.component.html',
  styleUrls: ['./about-your-business.component.scss'],
  providers: [NgbDropdownConfig]
})
export class AboutYourBusinessComponent {
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];


  constructor() {
    this.preferredCountries = [CountryISO.SouthAfrica, CountryISO.Canada];
  }


}
