import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextFieldMetaData } from "../../../core/models/field-meta-data";

@Component({
  selector: 'app-input-text-reactive-field',
  templateUrl: './input-text-reactive-field.component.html',
  styleUrls: ['./input-text-reactive-field.component.scss']
})
export class InputTextReactiveFieldComponent implements OnInit {
  constructor() { }

  @Input()
  field: TextFieldMetaData;
  @Input()
  maxLength:number;
  @Input()
  minLength:number;

  @Input()
  disabled = true;

  @Input()
  submitted = false;
  
  @Input()
  componentId = '';
  @Output() getData:EventEmitter<any>=new EventEmitter<any>();

  ngOnInit() {
    if(!this.field) {
      this.field = new TextFieldMetaData(new FormControl(''), 'dummy', 'Dummy', 'dummy', 'dummy');
    }
  }
  onChange(name,value){
   this.getData.emit({name:name,value:value});
  }

}
