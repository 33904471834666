import { Component } from '@angular/core';

@Component({
  selector: 'app-your-growth',
  templateUrl: './your-growth.component.html',
  styleUrls: ['./your-growth.component.scss']
})
export class YourGrowthComponent {


}
