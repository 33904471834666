import { Component } from '@angular/core';
import { ChartService } from '../../shared/chart/apex-chart/apex-chart.service';
import { ChartDB } from '../../shared/chart/chart/chart-data';

@Component({
  selector: 'app-your-portfolio',
  templateUrl: './your-portfolio.component.html',
  styleUrls: ['./your-portfolio.component.scss']
})
export class YourPortfolioComponent {
  public chartDB: any;

  constructor(public apexEvent: ChartService) {
    this.chartDB = ChartDB;
  }



}
