import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: [
    './landing.component.scss'
  ],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class LandingComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(
    private router: Router,
    public authService: UserAuthService
  ) { }

  ngOnInit() {
    if (!this.authService.isSessionExpired()) {
      let url = '/signin';
      if (this.authService.isServiceProvider()) {
        url = '/service-provider';
      }
      if (this.authService.isInvester()) {
        url = '/investor';
      }
      if (this.authService.isCommunityManager()) {
        url = '/cm-feed';
      }
      if (this.authService.isSME()) {
        url = '/sme-profile'
      }
      if (this.authService.isRelationShipManager()) {
        url = '/feed';
      }
      this.router.navigate([url]);
    }
  }
}
