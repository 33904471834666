<app-language-selector></app-language-selector>
<ul class="timeline-details" *ngIf="growthPlanDetails?.milestones?.length">
  <ng-container *ngFor="let milestone of growthPlanDetails.milestones; index as i">
    <li id="li{{milestone.id | translate}}{{i+1}}">
      <h5 id="ttl{{milestone.id | translate}}{{i+1}}">{{milestone.milestoneName | translate}}</h5>
      <div class="details-card" id="div{{milestone.id | translate}}{{i+1}}">
        <p id="p{{milestone.id | translate}}{{i+1}}">{{milestone.milestoneDescription | translate}}</p>
        <hr>
        <ul class="key-blocks">
          <ng-container *ngFor="let task of milestone.tasks; index as j">
            <li id="p{{task.id | translate}}Key{{i+1}}{{j+1}}">{{task.task | translate}}</li>
          </ng-container>
        </ul>
        <app-alert type="info">
          <em class="fas fa-exclamation-circle"></em>
          <span>{{'You’ll be able to update the growth plan when activated by the sponsor' | translate}}</span>
        </app-alert>
      </div>
    </li>
  </ng-container>
</ul>