<app-language-selector></app-language-selector>
<!-- <div class="auth-center-authenticate text-center" *ngIf="!uq_auth">
  <div *ngIf="!personal_auth">
    <div class="row">
      <div class="col-lg-5 mobile-hide ">
        <img src="../../../../assets/images/auth-bg.png" alt="">
      </div>
      <div class="col-lg-7 col-md-12 m-auto">
        <h1 class="auth-title" id="ttlTtile">
          <span>Please authenticate yourself</span>
        </h1>
        <p class="auth-text">Please provide an identity proof by either uploading your ID or filling in the details
          manually.</p>
        <span class="btn-group-auth">
          <button class="btn btn-primary comp-btn mx-2" (click)="startEnrollment()">get started</button> <br>
          <button class="btn btn-primary comp-btn manual-btn mx-2" (click)="gotonext()">Do it manually</button>
        </span>
      </div>
    </div>
  </div>
</div> -->
<div class="auth-center" style="min-width:100%; min-height: 0px;" *ngIf="!uq_auth">
  <form id="frmSignUp" [formGroup]="registerForm" autocomplete="on" class="signup">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="auth-side-form pb-3">
          <div class="auth-content">
            <div class="text-center">
              <h1 class="auth-title" id="ttlTtile">
                <span>{{ "signUp.title1" | translate }} </span>
              </h1>
              <p>Become a Service Provider</p>
            </div>
            <p>
              <span>{{ "signUp.gpBecameDesc" | translate }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="auth-side-form">
          <div class="auth-content">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblFirstName">{{ "formElementsLables.Firstname" | translate }}</label>
                  <input id="txtFirtName" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.fNamePlaceholder' | translate }}" autocomplete="off"
                    [ngClass]="{'is-invalid':inValid(f.firstName, isSubmit)}" formControlName="firstName" />
                  <div class="invalid-feedback" *ngIf="inValid(f.firstName, isSubmit)">
                    <div *ngIf="f.firstName.errors.required" id="err_FirstName">Please enter your first name</div>
                    <div *ngIf="f.firstName.errors.minlength" id="err_minLength_firstName">First Name should contain at
                      least 3 characters</div>
                    <div *ngIf="f.firstName.errors.maxlength" id="err_maxLength_firstName">First Name should not exceed
                      25
                      characters</div>
                    <div *ngIf="f.firstName.errors.hasNoSpecialCharacters" id="err_specialChar_firstName">First Name
                      should
                      contain alphabetical characters only</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblCompany">{{ "formElementsLables.company" | translate }}</label>
                  <input id="txtCompany" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.companyPlaceholder' | translate }}" autocomplete="off"
                    [ngClass]="{'is-invalid':inValid(f.companyName, isSubmit)}" formControlName="companyName" />
                  <div class="invalid-feedback" *ngIf="inValid(f.companyName, isSubmit)">
                    <div *ngIf="f.companyName.errors.required" id="err_CompanyName">Please enter company name</div>
                    <div *ngIf="f.companyName.errors.minlength" id="err_minLength_companyName">Company Name should
                      contain
                      at least 3 characters</div>
                    <div *ngIf="f.companyName.errors.maxlength" id="err_maxLength_companyName">Company Name Should not
                      exceed 40 characters</div>
                    <div *ngIf="f.companyName.errors.hasNoSpecialCharacters" id="err_specialChar_companyName">Company
                      Name
                      should not contain any Special Character</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblLastName">{{ "formElementsLables.Lastname" | translate }}</label>
                  <input id="txtLastName" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.lNamePlaceholder' | translate }}" autocomplete="off"
                    [ngClass]="{'is-invalid':inValid(f.lastName, isSubmit)}" formControlName="lastName" />
                  <div class="invalid-feedback" *ngIf="inValid(f.lastName, isSubmit)">
                    <div *ngIf="f.lastName.errors.required" id="err_LastName">Please enter your last name</div>
                    <div *ngIf="f.lastName.errors.minlength" id="err_minLength_lastName">Last Name should contain at
                      least 3
                      characters</div>
                    <div *ngIf="f.lastName.errors.maxlength" id="err_maxLength_lastName">Last Name should not exceed 35
                      characters</div>
                    <div *ngIf="f.lastName.errors.hasNoSpecialCharacters" id="err_specialChar_lastName">Last Name should
                      contain alphabetical characters only</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblIndustry">{{ "formElementsLables.Industry" | translate }}</label>
                  <div class="options" id="divIndustry">
                    <span class="p-fluid">
                      <p-dropdown [options]="investmentOption" [virtualScroll]="true"
                        placeholder="Select your {{ 'formElementsLables.Industry' | translate }}"
                        [ngClass]="{'is-invalid':inValid(f.industry, isSubmit)}" formControlName="industry"
                        id="selIndustry">
                      </p-dropdown>
                    </span>
                    <div class="invalid-feedback" *ngIf="inValid(f.industry, isSubmit)">
                      <div *ngIf="f.industry.errors.required" id="err_Industry">Please select your industry</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblPosition">{{ "formElementsLables.positionTitle" | translate }}</label>
                  <input id="txtPosition" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.positionPlaceholder' | translate }}" autocomplete="off"
                    [ngClass]="{'is-invalid':inValid(f.position, isSubmit)}" formControlName="position" />
                  <div class="invalid-feedback" *ngIf="inValid(f.position, isSubmit)">
                    <div *ngIf="f.position.errors.required" id="err_Position">Please enter position</div>
                    <div *ngIf="f.position.errors.minlength" id="err_minLength_position">Position/Title should contain
                      at
                      least 3 characters</div>
                    <div *ngIf="f.position.errors.maxlength" id="err_maxLength_position">Position/Title Should not
                      exceed 40
                      characters</div>
                    <div *ngIf="f.position.errors.hasNoSpecialCharacters" id="err_specialChar_position">Position/Title
                      Should contain only alphabets</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblBrn">{{ "formElementsLables.brn" | translate }}</label>
                  <input id="txtBrn" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.brnPlaceholder' | translate }}" autocomplete="off"
                    formControlName="brnNumber" [ngClass]="{'is-invalid':inValid(f.brnNumber, isSubmit)}" />
                  <div class="invalid-feedback" *ngIf="inValid(f.brnNumber, isSubmit)">
                    <div *ngIf="f.brnNumber.errors.required" id="errBRN">Please enter Company Registration Number</div>
                    <div *ngIf="f.brnNumber.errors.pattern" id="err_brnNumber_pattern">Please insert a valid
                      Company Registration Number (YYYY/XXXXXX/ZZ)</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblEmail">{{ "formElementsLables.email" | translate }}</label>
                  <input id="txtEmail" type="text" class="form-control"
                    placeholder="{{ 'formElementsLables.emailPlaceholder' | translate }}" autocomplete="off"
                    [ngClass]="{'is-invalid':inValid(f.email, isSubmit)}" formControlName="email" />
                  <div class="invalid-feedback" *ngIf="inValid(f.email, isSubmit)">
                    <div *ngIf="f.email.errors.required" id="err_EmailId">Please insert a valid email address</div>
                    <div *ngIf="f.email.errors.email" id="err_ValidEmailId">Your email address should contain '@' and a
                      valid domain</div>
                    <div *ngIf="f.email.errors.maxlength" id="err_maxLength_email">email address should not exceed 100
                      characters</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblBrn">{{ "commonForm.Website.title" | translate }} (optional)</label>
                  <input id="txtBrn" type="text" class="form-control"
                    placeholder="{{ 'commonForm.Website.placeholder' | translate }}" autocomplete="off"
                    formControlName="website" [ngClass]="{'is-invalid':inValid(f.website, isSubmit)}" />
                  <div class="invalid-feedback" *ngIf="inValid(f.website, isSubmit)">
                    <div *ngIf="f.website.errors.pattern" id="errValidWebsite">
                      Please enter valid website name
                    </div>
                    <div *ngIf="f.website.errors.maxlength" id="errMaxLengthWebsite">
                      Website should not exceed 100 characters
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group mb-3">
                  <label id="lblHeard">{{ "Area of Interest" | translate }}</label>
                  <div class="p-fluid">
                    <p-autoComplete formControlName="areaOfInterest" [maxlength]=5 [dropdown]="true"
                      [suggestions]="filteredCountries" (completeMethod)="filterCountry($event)"
                      [virtualScroll]="vitscroll" field="value" [multiple]="true" placeholder="Select Options"
                      [ngClass]="{'is-invalid':inValid(f.areaOfInterest, isSubmit)}"></p-autoComplete>
                  </div>
                  <div class="invalid-feedback" *ngIf="inValid(f.areaOfInterest, isSubmit)">
                    <div *ngIf="f.areaOfInterest.errors.required" id="err_heardFrom">Please select your area
                      of interest
                    </div>
                  </div>
                </div>
              </div>


              <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="auth-back-btn pt-4">
                  <p (click)="onBackClick($event)" id="btnBackLink">
                    {{ "buttons.back" | translate }}
                  </p>
                </div>
              </div> -->
              <div class="col-sm-12 col-md-6 col-lg-6 signupmain">
                <button id="btnSignUp" type="button" (click)="register()" class="btn btn-block btn-confirm mb-0 mt-4"
                  [disabled]="registerForm.invalid" [ngClass]="{'btn-disabled':registerForm.invalid}">
                  <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
                  <span *ngIf="buttonPressed" class="load-text">Loading..</span>
                  <span *ngIf="!buttonPressed" class="btn-text">{{ "buttons.submit" | translate }}</span>
                </button>
                <div class="tool-tip">
                  <span>Please fill in the requirement fields before you can submit.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<p-toast position="top-center" key="c" [baseZIndex]="5000" id="pToastMsg"></p-toast>