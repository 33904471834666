import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorModel } from '../../models/error';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  httpError: BehaviorSubject<ErrorModel>;
  httpLoader: BehaviorSubject<boolean>;
  error: ErrorModel;
  constructor() {
    this.error = new ErrorModel();
    this.httpError = new BehaviorSubject<ErrorModel>(this.error);
    this.httpLoader = new BehaviorSubject<boolean>(false);
  }
}
