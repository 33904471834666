<div class="community" id="community">
    <div class="container">
        <div class="community-content" id="divSMECONNECTBusiness">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 text-center">
                    <h1 class="wow fadeInUp mt" data-wow-delay="0s" id="ttlNeedMoreSMECONNECT">
                        <span class="sub-txt">{{'landing.landing.subtitle1' | translate}}</span>
                        <span>{{'landing.landing.title' | translate}}</span>
                        <span class="sub-txt">{{'landing.landing.subtitle2' | translate}}</span>
                    </h1>
                    <h1 class="wow fadeInUp" data-wow-delay="0s" id="ttlBusiness">{{'landing.landing.title2' |
                        translate}}</h1>
                </div>
            </div>
        </div>
    </div>
</div>