<app-language-selector></app-language-selector>
<div class="content-body m-b-40 growth-card growth-partners">
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="row">
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let partner of partners">
            <app-card [hidHeader]="true" cardClass="text-left partner-card" (click)="navigateCompanyPage(partner)">
              <div class="partners pointer">
                <div class="companyLogo">
                  <img class="img-fluid"
                    [src]="partner.logo ?  partner.logo : '../../../../../../assets/images/default.jpg'  "
                    id="imPartners{{i+1}}" alt="alt">
                </div>
                <div class="company pointer" id="ttlPartners{{i+1}}">
                  <h6>{{partner.companyName}}</h6>
                </div>
                <div class="company pointer" id="ttlPartners{{i+1}}">
                  <p>{{partner.enterpriseType}}</p>
                </div>
                <div class="company pointer" id="ttlPartners{{i+1}}">
                  <p>{{partner?.about?.slice(0,280)}}
                    <span *ngIf="partner?.about?.length>280">...</span>
                  </p>

                </div>
              </div>
            </app-card>
          </div>
        </div>

      </div>
    </div>

    <!-- <app-pagination [totalItems]="totalItems" [currentPage]="pageNumber" [pageSize]="pageSize" [maxPages]="5"
      (changePage)="goToPage($event)" *ngIf="!loading && totalItems > pageSize"></app-pagination> -->

  </div>
</div>