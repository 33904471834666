<app-language-selector></app-language-selector>
<div class="row">
    <div class="col-sm-12">
        <perfect-scrollbar class="perfect-scrollbar" [style.max-height]="'400px'">
            <h5 class="sub-title">{{'buildServiceProvider.aboutYourBusiness.title' | translate}}</h5>
            <form id="frmBasicInfo">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="lblBusinessName">{{'buildServiceProvider.aboutYourBusiness.businessName' |
                            translate}}</label>
                        <input id="txtBusinessName" type="text" class="form-control" placeholder="Business Name">
                    </div>
                    <div class="form-group col-md-6">
                        <label id="lblEmail">{{'buildServiceProvider.aboutYourBusiness.contactEmail' |
                            translate}}</label>
                        <input id="txtEmail" type="email" class="form-control"
                            placeholder="{{'buildServiceProvider.aboutYourBusiness.ex' | translate}}">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="lblTeamSize">{{'buildServiceProvider.aboutYourBusiness.teamSize.teamSize' |
                            translate}}</label>
                        <select class="form-control">
                            <option selected>{{'buildServiceProvider.aboutYourBusiness.teamSize.option1' | translate}}
                            </option>
                            <option>{{'buildServiceProvider.aboutYourBusiness.teamSize.option2' | translate}}</option>
                            <option>{{'buildServiceProvider.aboutYourBusiness.teamSize.option3' | translate}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label
                            id="lblIncorporationYear">{{'buildServiceProvider.aboutYourBusiness.incorporationYear.incorporationYear'
                            | translate}}</label>
                        <select class="form-control">
                            <option selected>
                                {{'buildServiceProvider.aboutYourBusiness.incorporationYear.option1' | translate}}
                            </option>
                            <option>{{'buildServiceProvider.aboutYourBusiness.incorporationYear.option2' | translate}}
                            </option>
                            <option>{{'buildServiceProvider.aboutYourBusiness.incorporationYear.option3' | translate}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label id="lblAboutBusiness">{{'buildServiceProvider.aboutYourBusiness.aboutYourBusiness' |
                        translate}}</label>
                    <textarea id="texAboutBusiness" class="form-control"
                        placeholder="{{'buildServiceProvider.aboutYourBusiness.aboutPlace' | translate}}"></textarea>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="lblWebsite">{{'buildServiceProvider.aboutYourBusiness.website' | translate}}</label>
                        <input id="txtWebsite" type="text" class="form-control"
                            placeholder="{{'buildServiceProvider.aboutYourBusiness.websiteUel' | translate}}">
                    </div>
                    <div class="form-group col-md-6">
                        <label id="lblContact" class="block">{{'buildServiceProvider.aboutYourBusiness.phoneNumber' |
                            translate}}</label>
                        <ngx-intl-tel-input [cssClass]="'form-control'"
                            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                            [phoneValidation]="true" name="phone" [separateDialCode]="true">
                        </ngx-intl-tel-input>
                    </div>
                </div>
                <div class="form-group">
                    <label id="lblAddress">{{'buildServiceProvider.aboutYourBusiness.addressLable' | translate}}</label>
                    <input id="txtAddress1" type="text" class="form-control"
                        placeholder="{{'buildServiceProvider.aboutYourBusiness.address1' | translate}}">
                </div>
                <div class="form-group">
                    <input id="txtAddress2" type="text" class="form-control"
                        placeholder="{{'buildServiceProvider.aboutYourBusiness.address2' | translate}}">
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input id="chkOwnerOfBusiness" class="form-check-input" type="checkbox">
                        <label id="lblOwnerCheck" class="form-check-label"
                            for="gridCheck">{{'buildServiceProvider.aboutYourBusiness.checkBox' | translate}}</label>
                    </div>
                </div>
            </form>
        </perfect-scrollbar>
    </div>
    <div class="col-sm-12 centered-content text-right">
        <div class="btn-group mt-10">
            <button id="btnAboutBusinessContinue" type="button" class="btn btn-confirm btn-sm"
                awNextStep>{{'buttons.continue' | translate}}</button>
        </div>
    </div>
</div>