<div class="mcb-container">
  <app-language-selector></app-language-selector>

  <!-- Heading -->
  <section>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div><img id="imgPlaceholder" src="assets/images/SME-Linker.png" class="img-fluid mb-5" alt="alt"
            style="width:150px;" /></div>
        <div class="welcome-title">{{'PAGE-NOT-FOUND.welcome-title' | translate}}</div>
        <h1>{{'PAGE-NOT-FOUND.title-top' | translate}}
          <span>{{'PAGE-NOT-FOUND.title-bottom' | translate}}</span>
        </h1>
      </div>
      <div class="col-12 col-sm-12 col-md-12 mt-1 mt-md-2">
        <p>{{'PAGE-NOT-FOUND.para-line-1' | translate}}</p>
        <p>{{'PAGE-NOT-FOUND.para-line-2' | translate}}</p>
      </div>
    </div>
  </section>

  <!-- Bottom navigation -->

  <div class="row align-items-center">
    <div class="col-12 text-center">
      <a [href]="smeconnectURL" id="btnNext" type="button" class="btn btn-confirm mt-5 go-home-btn">Go to Home</a>
    </div>
  </div>
</div>