import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { CommonService } from 'src/app/services/common/common.service';
import { PRICE_NOT_AVAILABLE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-growthcard-item',
  templateUrl: './growthcard-item.component.html',
  styleUrls: ['./growthcard-item.component.scss']
})
export class GrowthcardItemComponent implements OnInit {
  @Input() gcId: any;
  @Input() componentId: string;
  @Input() company: string;
  @Input() serviceToProvide: string;
  @Input() description: string;
  @Input() duration: string;
  @Input() price: string;
  @Input() thumbnail: string;
  @Input() logo: string;
  @Input() viewPageUrl: string;
  @Input() durationType: string;

  @Input() serviceDescriptionUrl: string = "";
  @Input() canBook: boolean;
  @Input() imageUrls: string[] = [];
  @Input() isOwnGrowthCard: boolean | undefined;
  @Output() book: EventEmitter<any> = new EventEmitter();
  @Output() itemClicked: EventEmitter<[string, any]> = new EventEmitter<[string, any]>();

  canPreview: boolean = false;
  isRM: boolean;
  isCommunityManager: boolean;
  hasButtons: boolean = true;

  buttons: string[] = ["I'm Interested"];

  constructor(
    private utility: UtilityService,
    private authService: UserAuthService,
    private commonService: CommonService, private router: Router
  ) { }

  ngOnInit(): void {
    this.isRM = this.authService.isRelationShipManager();
    this.isCommunityManager = this.authService.isCommunityManager();

    if (this.buttons.length === 0) {
      this.hasButtons = false;
    }
  }

  ngOnChanges(): void {
  }
  getCostRange() {
    return this.utility.getServicePriceString(this.price);
  }
  showCurrency() {
    return this.utility.getServicePriceString(this.price) != PRICE_NOT_AVAILABLE;
  }
  doBooking(e: any) {
    e.id = e.growthCardId;
    this.book.emit(e);
  }
  getMoreDetails(url: any) {
    if (url && url.indexOf("http") == 0) {
      window.open(url, "_blank");
    } else {
      if (!url) {
        url = "api/growth-cards/viewGCAsPDF?gcId=" + this.gcId;
      }
      this.commonService.downloadPDF(url).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }
  }

  onItemClicked() {
    this.itemClicked.emit([this.gcId, this.serviceToProvide]);
  }

  groupredirection(groupId) {
    this.router.navigate(['/details/' + groupId]);
  }
}
