import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';


@Component({
  selector: 'app-search-news',
  templateUrl: './search-news.component.html',
  styleUrls: ['./search-news.component.scss']
})
export class SearchNewsComponent implements OnInit {
  @Input() searchNews: any;
  feedArticalVideoModal = false;
  currentItem: any;
  isCommunityManager: boolean;

  constructor(private sanitizer: DomSanitizer,
    private gaService: GoogleAnalyticsService,
    private authService: UserAuthService,
    private router: Router) {
  }
  apiLoaded = false;
  ngOnInit(): void {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
    this.isCommunityManager = this.authService.isCommunityManager();
  }
  setCurrentItem(item: any) {
    this.currentItem = null;
    setTimeout(() => {
      this.currentItem = item;
      this.feedArticalVideoModal = true;
    }, 500);
  }
  youtubeRegex = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  getYoutubeId(url) {
    if (!this.youtubeRegex.test(url)) {
      return false;
    }
    return url.match(this.youtubeRegex)[1];
  }
  testYoutubeId(url) {
    return this.youtubeRegex.test(url);
  }

  getLink(companyId: any, roleName: any) {
    let url = "/details/" + (roleName ? roleName.toLowerCase() : "cm") + "/" + companyId;
    console.log(this.router.url, "urlmai")
    if (this.router.url.indexOf("/user-browse") > -1 ||
      this.router.url.indexOf("/growth-") > -1 ||
      this.router.url.indexOf("search-") > -1 ||
      this.router.url.indexOf("feed") > -1) {
      url = "/user-browse" + url;
    } else {
      url = "/browse" + url;
    }
    return [url];
  }

  comapanyredirection(companyId: any, roleName: any) {
    if (!this.isCommunityManager) {
      this.router.navigate(this.getLink(companyId, roleName))
    }
  }

}
