import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOption } from 'ng-select';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientInfoService } from 'src/app/core/services/client-info/client-info.service';

@Injectable({
    providedIn: 'root'
})
export class SelectOptionService {
    readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

    constructor(
        private http: HttpClient,
        private clientInfoService: ClientInfoService
    ) { }
    public static readonly PLAYER_ONE: Array<IOption> = [
        { value: 'Equity Financing', label: 'Equity Financing' },
        { value: 'Minority Stake', label: 'Minority Stake' },
        { value: 'Significant Minority Stake', label: 'Significant Minority Stake' },
        { value: 'Majority Stake', label: 'Majority Stake' },
        { value: 'Significant Majority Stake', label: 'Significant Majority Stake' },
        { value: 'Any', label: 'Any' }
    ];

    public static readonly Duration: Array<IOption> = [
        { value: '0', label: '6 - 12 Months' },
        { value: '1', label: '12 - 16 Months' },
        { value: '2', label: '1 - 2 Years' },
        { value: '3', label: '2 - 3 Years' },
        { value: '4', label: '3 - 4 Years' },
        { value: '5', label: 'Any' }
    ];
    public static readonly Category: Array<IOption> = [
        { value: 'Accounting', label: 'Accounting' },
        { value: 'Governance', label: 'Governance' },
        { value: 'Investment', label: 'Investment' },
        { value: 'Legal & Commercial', label: 'Legal & Commercial' },
    ];
    public static readonly Format: Array<IOption> = [
        { value: 'word', label: 'Word' },
        { value: 'pdf', label: 'PDF' },
        { value: 'excel', label: 'Excel' },
    ];
    public static readonly TurnOver: Array<IOption> = [
        { value: "below 2 M", label: "Below 2M" },
        { value: "2-5 M", label: "2 - 5M" },
        { value: "5-12 M", label: "5 - 12M" },
        { value: "12-30 M", label: "12 - 30M" },
        { value: "30-50 M", label: "30 - 50M" },
        { value: "above 50 M", label: "Above 50M" }
    ];

    public static readonly Incorporated: Array<IOption> = [
        { value: SelectOptionService.getDateStringBySustractingDays(365), label: "More than 1 years" },
        { value: SelectOptionService.getDateStringBySustractingDays(2 * 365), label: "More than 2 years" },
        { value: SelectOptionService.getDateStringBySustractingDays(3 * 365), label: "More than 3 years" },
        { value: SelectOptionService.getDateStringBySustractingDays(4 * 365), label: "More than 4 years" },
        { value: SelectOptionService.getDateStringBySustractingDays(5 * 365), label: "More than 5 years" }
    ];
    public static readonly Goal: Array<IOption> = [
        { value: '0', label: 'Market Expansion' },
        { value: '1', label: 'Market Expansion 2' },
        { value: '2', label: 'Market Expansion 3' },
        { value: '3', label: 'Market Expansion 4' },
        { value: '4', label: 'Market Expansion 5' }
    ];
    public static readonly Financial: Array<IOption> = [
        { value: '0', label: 'Loan + Equity' },
        { value: '1', label: 'Loan + Equity 2' },
        { value: '2', label: 'Loan + Equity 3' },
        { value: '3', label: 'Loan + Equity 4' },
        { value: '4', label: 'Loan + Equity 5' },
    ];

    public static readonly STATS_OPTIONS: Array<IOption> = [
        { value: "all", label: "All" },
        { value: "last-7-days", label: "Last 7 days" },
        { value: "last-30-days", label: "Last 30 days" },
        { value: "last-3-months", label: "Last 3 months" }
    ];
    public static getDateStringBySustractingDays(days) {
        var date = new Date();
        date.setDate(date.getDate() - days);
        var month = date.getUTCMonth() + 1;
        var monthString = month.toString();
        if (monthString.length == 1) {
            monthString = "0" + monthString;
        }
        var dateString = date.getDate() + "";
        if (dateString.length == 1) {
            dateString = "0" + dateString;
        }
        return date.getUTCFullYear() + "-" + (monthString) + "-" + dateString;
    }

    public cloneOptions(options: Array<IOption>): Array<IOption> {
        return options.map(option => ({ value: option.value, label: option.label }));
    }


    getInvestmentOption(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.PLAYER_ONE);
    }


    getCategoryOptions(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Category);
    }
    getDurationOption(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Duration);
    }
    getFormatOptions(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Format);
    }

    getGoalOption(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Goal);
    }
    getTurnOverOptions(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.TurnOver);
    }
    getIncorporatedOptions(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Incorporated);
    }
    getFinancialOption(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.Financial);
    }
    INDUSTRY_TYPES;
    getIndustryTypes() {
        if (this.INDUSTRY_TYPES) {
            return new Observable((observer) => { observer.next(this.INDUSTRY_TYPES); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/auth/getIndustries`).pipe(tap((appRef: any) => {
            this.INDUSTRY_TYPES = appRef;
        }));
    }

    SUBINDUSTRY_TYPES
    getsubIndustryTypes(data: string) {
        if (this.SUBINDUSTRY_TYPES) {
            return new Observable((observer) => { observer.next(this.SUBINDUSTRY_TYPES); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/auth/getSubIndustries?data=${encodeURIComponent(data)}`).pipe(tap((appRef: any) => {
            this.SUBINDUSTRY_TYPES = appRef;
        }));
    }

    PRODUCT_CATEGORIES;
    getProductCategories() {
        if (this.PRODUCT_CATEGORIES) {
            return new Observable((observer) => { observer.next(this.PRODUCT_CATEGORIES); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/product/getProductCategories`).pipe(tap((appRef: any) => {
            this.PRODUCT_CATEGORIES = appRef;
        }));
    }
    SERVICECARD_CATEGORIES;
    getServicecardCategories() {
        if (this.SERVICECARD_CATEGORIES) {
            return new Observable((observer) => { observer.next(this.SERVICECARD_CATEGORIES); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/service-card/getServiceCategories`).pipe(tap((appRef: any) => {
            this.SERVICECARD_CATEGORIES = appRef;
        }));
    }
    WHERE_DID_YOU_HERE_ABOUT_SMECONNECT;

    getWhereDidYouHearAboutSMECONNECTOPtion() {
        if (this.WHERE_DID_YOU_HERE_ABOUT_SMECONNECT) {
            return new Observable((observer) => { observer.next(this.WHERE_DID_YOU_HERE_ABOUT_SMECONNECT); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/auth/getRegisterReasons`).pipe(tap((appRef: any) => {
            this.WHERE_DID_YOU_HERE_ABOUT_SMECONNECT = appRef;
        }));
    }

    COUNTRY_REGION
    getCountryRegion() {
        if (this.COUNTRY_REGION) {
            return new Observable((observer) => { observer.next(this.COUNTRY_REGION); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/auth/getCountryRegions`).pipe(tap((appRef: any) => {
            this.COUNTRY_REGION = appRef;
        }));
    }
    getStatsOptions(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.STATS_OPTIONS);
    }

    RESOURCE_TYPES
    getresourceType() {
        if (this.RESOURCE_TYPES) {
            return new Observable((observer) => { observer.next(this.RESOURCE_TYPES); observer.complete(); });
        }
        return this.http.get<any>(`${this.BASE_API_URL}api/resource/getResourceType`).pipe(tap((appRef: any) => {
            this.RESOURCE_TYPES = appRef;
        }));
    }
}
