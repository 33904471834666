import { AfterContentChecked, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-custom-youtube',
  templateUrl: './custom-youtube.component.html',
  styleUrls: ['./custom-youtube.component.scss']
})
export class CustomYoutubeComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() youtubeId: any;
  @Input() playButtonPressed: any;
  @Input() feedTitle: any;
  constructor(private gaService: GoogleAnalyticsService) { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    // console.log(this.playButtonPressed);
  }
  player: YT.Player;
  savePlayer(player) {
    this.player = player.target;
    if (this.playButtonPressed) {
      this.play();
    }
  }
  onStateChange(event) {
  }
  play() {
    if (this.feedTitle) {
      this.gaService.logCustomEvent("Viewed Newsfeed item", "Newsfeed", this.feedTitle);
    }
    this.player.playVideo();
  }
  pause() {
    this.player.pauseVideo();
  }
  @ViewChild('youtubeDiv') m;
  private isVisible: boolean = false;
  ngAfterContentChecked(): void {
    if (this.m && this.player && this.m.nativeElement) {
      if (this.isInViewport(this.m.nativeElement)) {
        this.isVisible = true;
        //this.play();
      }
      else {
        this.isVisible = false;
        this.pause();
      }
    }
  }
  isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

}
