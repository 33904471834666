import { Component } from '@angular/core';
import { IOption } from 'ng-select';
import { SelectOptionService } from '../../shared/components/select/select-option.service';


@Component({
  selector: 'app-your-investment',
  templateUrl: './your-investment.component.html',
  styleUrls: ['./your-investment.component.scss']
})
export class YourInvestmentComponent {
  investmentOption: Array<IOption> = this.selectOptionService.getInvestmentOption();


  constructor(public selectOptionService: SelectOptionService) {
  }



}
