<div class="align-items-stretch">
  <div class="row auth-center">
    <app-language-selector></app-language-selector>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="auth-side-form">
        <div class="">
          <form id="frmResetPassword" [formGroup]="resetForm" autocomplete="on">
            <h1 class="auth-title" id="lblEnterEmail">
              <span>{{ "forgtotPwd.title" | translate }}</span>
            </h1>
            <p class="mb-5">
              <span>{{ "forgtotPwd.description" | translate }}</span>
            </p>
            <div class="form-group mb-3">
              <label id="lblEmail">{{ "formElementsLables.email" | translate }}</label>
              <input id="txtEmail" type="email" class="form-control"
                placeholder="{{ 'formElementsLables.emailPlaceholder' | translate }}" autocomplete="off"
                [ngClass]="{'is-invalid':inValid(f.email, isSubmit)}" formControlName="email" />
              <div class="invalid-feedback" *ngIf="inValid(f.email, isSubmit)">
                <div *ngIf="f.email.errors.required" id="err_EmailId">Please enter email address</div>
                <div *ngIf="f.email.errors.email" id="err_ValidEmailId">Please enter valid email address</div>
              </div>
            </div>
            <button id="btnSubmit" class="btn btn-block btn-confirm mb-0" (click)="resetPassword()">
              <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
              <span *ngIf="buttonPressed" class="load-text">Loading..</span>
              <span *ngIf="!buttonPressed" class="btn-text">{{ "buttons.submit" | translate }}</span>
            </button>
          </form>

          <div class="resetmessage">
            <h6 *ngIf="showsuccessmsg" class="success">
              <em class="fas fa-check"></em>
              Great, we’ve sent you a link to your registered email to reset your password
            </h6>
            <h6 *ngIf="showerrorsmsg" class="error">
              <em class="fas fa-times-circle"></em>
              We did not find any account with the email address you provided
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>