import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit {
  @Output() selectedPic: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSubmit: boolean;
  @Input() personalDetailsForm: FormGroup;
  @Input() srcData: any;
  acceptedImageFormates = ".jpeg,.jpg,.png";
  maxAllowedSize = 1 * 1024 * 1024;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropimage: boolean = false;
  imageCroppedFlag: boolean = false;
  croppedImageBase64: string = ''

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    if (!this.personalDetailsForm) {
      this.personalDetailsForm = new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        title: new FormControl(''),
        email: new FormControl('')
      });
    }
  }

  validFileType(filename, extensions) {
    var dotSplits = filename.split(".");
    var fileExtention = dotSplits[dotSplits.length - 1];
    if (extensions.indexOf(fileExtention.toLowerCase()) != -1) {
      return true;
    } else {
      return false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
    this.imageCroppedFlag = true;
  }

  async onButtonClicked() {
    this.srcData = await this.base64ToBlob(
      this.croppedImageBase64
    )
    this.selectedPic.emit(this.srcData);
    this.cropimage = false;
  }


  async base64ToBlob(data) {
    const arr = data.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: 'image/jpeg' });
  }

  onFileChanged(event) {
    this.cropimage = true
    this.imageChangedEvent = event;
    if (!this.validFileType(event.target.files[0].name, this.acceptedImageFormates)) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image should be in either .jpeg, .jpg, or .png format", detail: '' });
      return;
    }

    if (event.target.files[0].size > this.maxAllowedSize) {
      event.target.value = ''
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }
    this.selectedPic.emit(event.target.files[0])
    let fileReader = new FileReader();
    let that = this;
    fileReader.onload = function (fileLoadedEvent) {
      that.srcData = fileLoadedEvent.target.result;
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  get f() { return this.personalDetailsForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

}
