<div class='{{" "+inputCardClass}}' [ngClass]="cardClass" [@cardRemove]="cardRemove" #toAnimate>
  <div [@collapsedCard]="collapsedCard" *ngIf="this.options; else subMenuContent">
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>

  </div>
  <ng-template #subMenuContent>
    <div class="card-body" [ngClass]="blockClass">
      <div class="d-flex justify-content-end">
        <div class="btn-group card-option dropdown " ngbDropdown *ngIf="isCommunityManager">
          <!-- <button type="button" class="btn dropdown-toggle btn-icon" ngbDropdownToggle>
            <em class="fas fa-ellipsis-v"></em>
          </button>
          <ul class="list-unstyled card-option dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <li class="dropdown-item close-card"
              (click)="cardRemoveAction({feedId: rowData.id, reviewStatus: 'rejected'})"><a href="javascript:void(0);"><em
                  class="far fa-trash-alt"></em> Remove</a></li>
          </ul> -->
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </ng-template>
  <div *ngIf="isCardFooter" class="card-footer" [ngClass]="footerClass">
    <ng-content select=".app-card-footer"></ng-content>
  </div>
  <div class="card-loader" *ngIf="loadCard"><em class="pct-loader1 anim-rotate"></em></div>
</div>