import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserAuthService } from '../services/auth-service/user-auth.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    public authService: UserAuthService,
    private router: Router,
    private location: Location
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const allowed_roles = next.data.allowed_roles;
    const roles = this.authService.getRoles();

    for (var i in roles) {
      if (allowed_roles && (allowed_roles.indexOf('*') > -1 || allowed_roles.indexOf(roles[i]) > -1)) {
        return true;
      }
    }

    sessionStorage.setItem('forbidden-url', window.location.href.replace(this.location.path(), '') + state.url);
    this.router.navigate(['/error-403-page-forbidden']);

    return false;
  }
}
