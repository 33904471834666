export class FriendsList {
  public static friends = [
    {
      id: 1,
      photo: 'assets/images/user/avatar-1.jpg',
      name: 'MCBot',
      new: 3,
      status: 1,
      time: 'typing'
    }
  ];
}
