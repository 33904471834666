import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";


import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ServiceProvidersService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) { }



  // getAllServiceProviders(pageNumber: number, pageSize: number){
  //   const params = new HttpParams()
  //       .set('pageNumber', pageNumber.toString())
  //       .set('pageSize', pageSize.toString());
  //     return this.http.get<any>(`${this.BASE_API_URL}api/service-provider/getAllServiceProviders`)
  //     .pipe(tap((appRef: any) => { } ));
  //   }

  getAllServiceProviders(pageNumber: number, pageSize: number) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    return this.http.get<any>(`${this.BASE_API_URL}api/service-provider/getAllServiceProviders`, { params })
      .pipe(
        tap((response: any) => {
          // Handle the response if needed
        })
      );
  }

}
