import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { UserAuthService } from '../../../../core/services/auth-service/user-auth.service';
import { ProfileService } from '../../../../core/services/profile-service/profile.service';

@Component({
    selector: 'app-nav-right',
    templateUrl: './nav-right.component.html',
    styleUrls: ['./nav-right.component.scss'],
    providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
    userName: string = "";
    userPosition: string = "";
    image: string = "";
    loading: boolean;
    profile: any = {};
    togglemessage: boolean = false

    constructor(
        private router: Router,
        private authService: UserAuthService,
        private profileService: ProfileService
    ) {
        this.profileService.updateEvent.subscribe(
            () => {
                this.image = this.profileService.profilePic;
                this.userName = this.profileService.userName;
                this.userPosition = this.profileService.userPosition;
            }
        );
    }

    ngOnInit() {
        this.getProfile();
    }
    getProfile() {
        this.loading = true;
        this.profileService.getProfileDetails().subscribe(
            (data) => {
                this.profile = data ? data : {};
                this.loading = false;
            },
            (error) => {
                this.profile = {};
                this.loading = false;
                // console.log(error);
            }
        );
    }
    logout() {
        this.authService.clearLocalData();
        this.router.navigate(['/signin']);
    }

    messenger() {
        this.togglemessage = !this.togglemessage;
    }
}
