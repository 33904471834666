import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { SelectModule } from 'ng-select';
import { ArchwizardModule } from 'angular-archwizard';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { AlertModule, CardModule, ModalModule, FeedCardModule } from './components';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { NouisliderModule } from 'ng2-nouislider';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { DataTablesModule } from 'angular-datatables';

import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CarouselModule as CarouselsModule } from 'primeng/carousel';
import { MessageService } from 'primeng/api';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbDropdownModule, NgbNavModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MentionModule } from 'angular-mentions';
import { MultiSelectModule } from 'primeng/multiselect';

import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { I18nConfService } from "../core/services/i18n-conf/i18n-conf.service";
import { SearchComponent } from "./components/search/search.component";
import { FormFooterComponent } from './components/form-footer/form-footer.component';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from "ng2-currency-mask";
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { HeaderBannerComponent } from '../components/header-banner/header-banner.component';
import { AnalyticsComponent } from '../components/analytics/analytics.component';
import { AboutComponent } from '../components/about/about.component';
import { MeetOurTeamComponent } from '../components/meet-our-team/meet-our-team.component';
import { GetInTouchComponent } from '../components/get-in-touch/get-in-touch.component';
import { YourProfileComponent } from '../components/your-profile/your-profile.component';
import { YourStoryComponent } from '../components/your-story/your-story.component';
import { YourGrowthComponent } from '../components/your-growth/your-growth.component';
import { NgbProgressbarModule, NgbPopoverModule, NgbTooltipModule, NgbAccordionModule, NgbCollapseModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { OurStoryBannerComponent } from '../components/our-story-banner/our-story-banner.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { YourInvestmentComponent } from '../components/your-investment/your-investment.component';
import { PerformanceComponent } from '../components/performance/performance.component';
import { YourPortfolioComponent } from '../components/your-portfolio/your-portfolio.component';
import { ApexChartComponent } from './chart/apex-chart/apex-chart.component';
import { ChartService } from './chart/apex-chart/apex-chart.service';
import { SelectOptionService } from './components/select/select-option.service';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { VideoArticleComponent } from './components/video-article/video-article.component';
import { OpportunityItemComponent } from '../components/opportunity-item/opportunity-item.component';
import { ProposedTimelineComponent } from '../components/proposed-timeline/proposed-timeline.component';
import { InvestmentSummaryComponent } from '../components/investment-summary/investment-summary.component';
import { OpportunitiesPerformanceComponent } from '../components/opportunities-performance/opportunities-performance.component';
import { OpportunitiesPerformanceStatusComponent } from '../components/opportunities-performance-status/opportunities-performance-status.component';
import { BuildServiceProviderComponent } from '../components/build-service-provider/build-service-provider.component';
import { AboutYourBusinessComponent } from '../components/about-your-business/about-your-business.component';
import { YourAreaExpertiseComponent } from '../components/your-area-expertise/your-area-expertise.component';
import { YourSpecialisationComponent } from '../components/your-specialisation/your-specialisation.component';
import { SpHeaderBannerComponent } from '../components/sp-header-banner/sp-header-banner.component';
import { SpMeetOurTeamComponent } from '../components/sp-meet-our-team/sp-meet-our-team.component';
import { SpAboutComponent } from '../components/sp-about/sp-about.component';
import { SpServicesComponent } from '../components/sp-services/sp-services.component';
import { SpPortfolioComponent } from '../components/sp-portfolio/sp-portfolio.component';
import { SkillsComponent } from '../components/skills/skills.component';
import { ServicesCardComponent } from '../components/services-card/services-card.component';
import { InvestorBannerComponent } from '../components/investor-banner/investor-banner.component';
import { OpptServiceCardComponent } from '../components/oppt-service-card/oppt-service-card.component';
import { ServiceCardComponent } from '../components/oppt-service-card/service-card/service-card.component';
import { YourOwnGrowthPlanComponent } from '../components/your-own-growth-plan/your-own-growth-plan.component';
import { SmeServicesComponent } from '../components/sme-services/sme-services.component';
import { SpGrowthcardComponent } from '../components/sp-growthcard/sp-growthcard.component';
import { GrowthcardFormComponent } from '../components/growthcard-form/growthcard-form.component';
import { LandingTimelineComponent } from './components/landing-timeline/landing-timeline.component';
import { HeaderCarouselComponent } from './components/header-carousel/header-carousel.component';
import { LandingVideoArticleComponent } from './components/landing-video-article/landing-video-article.component';
import { SignupAsComponent } from './components/signup-as/signup-as.component';
import { LandingFeaturesComponent } from './components/landing-features/landing-features.component';
import { SuccessStoriesCarouselComponent } from './components/success-stories-carousel/success-stories-carousel.component';
import { StatusLineComponent } from './components/status-line/status-line.component';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { ListComponent } from './components/list/list.component';
import { TopicsComponent } from './components/topics/topics.component';
import { resultCardComponent } from './components/result-card/result-card.component';
import { FeedFormComponent } from './components/feed-form/feed-form.component';
import { InputTextReactiveFieldComponent } from './components/input-field/input-text-reactive-field/input-text-reactive-field.component';
import { InputTextareaReactiveFieldComponent } from './components/input-field/input-textarea-reactive-field/input-textarea-reactive-field.component';
import { StartupCardComponent } from './components/startup-card/startup-card.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { SmeProductsComponent } from '../components/sme-products/sme-products.component';
import { GrowthItemComponent } from './components/growth-item/growth-item.component';
import { HealthCardComponent } from './components/health-card/health-card.component';
import { HealthCheckFormComponent } from './components/health-check-form/health-check-form.component';
import { ViewHealthScoreComponent } from '../components/view-health-score/view-health-score.component';
import { ScoreServiceCardComponent } from './components/score-service-card/score-service-card.component';
import { ChatMsgComponent } from './components/chat-msg/chat-msg.component';
import { AuthMessageComponent } from '../components/auth-message/auth-message.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { AboutFormComponent } from './components/about-form/about-form.component';
import { FaqsComponent } from '../components/faqs/faqs.component';
import { BrowseCategoriesComponent } from './components/browse-categories/browse-categories.component';
import { BrowseBennerComponent } from './components/browse-benner/browse-benner.component';
import { BrowseResultsComponent } from './components/browse-results/browse-results.component';
import { BrowseCardComponent } from './components/browse-card/browse-card.component';
import { BrowseFilterComponent } from './components/browse-filter/browse-filter.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { FollowContentComponent } from './components/follow-content/follow-content/follow-content.component';
import { LandingHeaderComponent } from '../components/landing/landing-header/landing-header.component';
import { LandingFooterComponent } from '../components/landing/landing-footer/landing-footer.component';
import { CommunityComponent } from '../components/landing/community/community.component';
import { PartnersComponent } from '../components/landing/partners/partners.component';
import { NewsEventsComponent } from '../components/landing/news-events/news-events.component';
import { MyStoryFormComponent } from './components/my-story-form/my-story-form.component';
import { InputReactiveFieldComponent } from './components/input-reactive-field/input-reactive-field.component';
import { DateAgoPipe } from './helpers/date-ago.pipe';
import { FeedShareComponent } from './components/feed-share/feed-share.component';
import { OwnContentComponent } from './components/own-content/own-content.component';
import { GrowthItemDetailsComponent } from './components/growth-item-details/growth-item-details.component';
import { FeedPopupViewComponent } from './components/feed-popup-view/feed-popup-view.component';
import { ErrorPageNotFoundComponent } from '../components/error-page-not-found/error-page-not-found.component';
import { ErrorPageForbiddenComponent } from '../components/error-page-forbidden/error-page-forbidden.component';
import { PublicProfileComponent } from '../components/public-profile/public-profile.component';
import { SearchCompaniesComponent } from '../components/search-companies/search-companies.component'
import { SearchServicesComponent } from '../components/search-services/search-services.component'
import { PaginationComponent } from './components/pagination/pagination.component';
import { LandingSignUpComponent } from './components/landing-sign-up/landing-sign-up.component';
import { ReadyToSignupComponent } from './components/ready-to-signup/ready-to-signup.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { OrgDetailsComponent } from './components/org-details/org-details.component';
import { SideContentCardComponent } from './components/side-content-card/side-content-card.component';
import { PersonalDetailsFormComponent } from './components/personal-details-form/personal-details-form.component';
import { OrgFormComponent } from './components/org-form/org-form.component';
import { CustomYoutubeComponent } from './components/custom-youtube/custom-youtube.component';
import { GrowthcardItemComponent } from './components/growthcard-item/growthcard-item.component';
import { BRNFormComponent } from './components/brn-form/brn-form.component';
import { FeedCommentComponent } from './components/feed-comment/feed-comment.component';
import { SearchNewsComponent } from './components/search-news/search-news.component';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { BecameGPComponent } from '../components/became-gp/became-gp.component';
import { FirstLoginPopupComponent } from './components/first-login-popup/first-login-popup.component';
import { SignupComponent } from '../pages/authentication/signup/signup.component';
import { ResetPasswordComponent } from '../pages/authentication/reset-password/reset-password.component';
import { TabViewModule } from 'primeng/tabview';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DataViewModule } from 'primeng/dataview';
import { GrowthPlansComponent } from '../pages/my-growth/growth-plans/growth-plans.component';
import { HealthChecksComponent } from '../pages/my-growth/health-checks/health-checks.component';
import { UtilityCategoriesComponent } from '../pages/utility-categories/utility-categories.component';
import { GrowthCardsComponent } from '../pages/growth-cards/growth-cards.component';
import { BrowseCompaniesComponent } from '../pages/user-browse/browse-companies/browse-companies.component';
import { GrowthPartnerComponent } from '../pages/growth-partners/growth-partner/growth-partner.component';
import { CmPendingFeedComponent } from './components/cm-pending-feed/cm-pending-feed.component';
import { CmRejectedFeedComponent } from './components/cm-rejected-feed/cm-rejected-feed.component';
import { FeaturedGcComponent } from '../pages/featuredgc/featuredgc.component';
import { CmRejectedgcComponent } from '../pages/cm-rejectedgc/cm-rejectedgc.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { VirtualScrollerModule } from 'primeng/virtualscroller'
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessengerComponent } from '../components/messenger/messenger.component';
import { GroupsHeaderBannerComponent } from '../components/groups-header-banner/groups-header-banner.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FeedComponent } from '../pages/feed/feed.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/');
}

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: '.',
  precision: 2,
  prefix: 'MUR ',
  suffix: '',
  thousands: ','
};

@NgModule({
  declarations: [
    LanguageSelectorComponent,
    SearchComponent,
    FormFooterComponent,
    HeaderBannerComponent,
    OurStoryBannerComponent,
    AnalyticsComponent,
    AboutComponent,
    MeetOurTeamComponent,
    GetInTouchComponent,
    YourProfileComponent,
    YourStoryComponent,
    YourGrowthComponent,
    TimelineComponent,
    YourInvestmentComponent,
    PerformanceComponent,
    YourPortfolioComponent,
    ApexChartComponent,
    SearchFilterComponent,
    GrowthItemComponent,
    VideoArticleComponent,
    OpportunityItemComponent,
    ProposedTimelineComponent,
    InvestmentSummaryComponent,
    OpportunitiesPerformanceComponent,
    OpportunitiesPerformanceStatusComponent,
    BuildServiceProviderComponent,
    AboutYourBusinessComponent,
    YourAreaExpertiseComponent,
    YourSpecialisationComponent,
    SpHeaderBannerComponent,
    SpServicesComponent,
    SpAboutComponent,
    SpMeetOurTeamComponent,
    SpPortfolioComponent,
    SkillsComponent,
    ServicesCardComponent,
    InvestorBannerComponent,
    OpptServiceCardComponent,
    ServiceCardComponent,
    YourOwnGrowthPlanComponent,
    SmeServicesComponent,
    SpGrowthcardComponent,
    GrowthcardFormComponent,
    LandingTimelineComponent,
    HeaderCarouselComponent,
    LandingVideoArticleComponent,
    SignupAsComponent,
    LandingFeaturesComponent,
    SuccessStoriesCarouselComponent,
    StatusLineComponent,
    NewsFeedComponent,
    ListComponent,
    TopicsComponent,
    resultCardComponent,
    FeedFormComponent,
    InputTextReactiveFieldComponent,
    InputTextareaReactiveFieldComponent,
    StartupCardComponent,
    ProductCardComponent,
    SmeProductsComponent,
    HealthCardComponent,
    HealthCheckFormComponent,
    ViewHealthScoreComponent,
    ScoreServiceCardComponent,
    ChatMsgComponent,
    AuthMessageComponent,
    ContactFormComponent,
    AboutFormComponent,
    FaqsComponent,
    BrowseCategoriesComponent,
    BrowseBennerComponent,
    BrowseResultsComponent,
    BrowseCardComponent,
    BrowseFilterComponent,
    DiscussionComponent,
    FollowContentComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    CommunityComponent,
    PartnersComponent,
    NewsEventsComponent,
    MyStoryFormComponent,
    InputReactiveFieldComponent,
    DateAgoPipe,
    FeedShareComponent,
    OwnContentComponent,
    GrowthItemDetailsComponent,
    FeedPopupViewComponent,
    ErrorPageNotFoundComponent,
    ErrorPageForbiddenComponent,
    PublicProfileComponent,
    SearchCompaniesComponent,
    SearchServicesComponent,
    PaginationComponent,
    LandingSignUpComponent,
    ReadyToSignupComponent,
    PersonalDetailsComponent,
    OrgDetailsComponent,
    UpdatePasswordComponent,
    SideContentCardComponent,
    PersonalDetailsFormComponent,
    OrgFormComponent,
    CustomYoutubeComponent,
    GrowthcardItemComponent,
    BRNFormComponent,
    FeedCommentComponent,
    SearchNewsComponent,
    CommentBoxComponent,
    BecameGPComponent,
    FirstLoginPopupComponent,
    SignupComponent,
    ResetPasswordComponent,
    ThankYouComponent,
    GrowthPlansComponent,
    HealthChecksComponent,
    UtilityCategoriesComponent,
    GrowthCardsComponent,
    BrowseCompaniesComponent,
    GrowthPartnerComponent,
    CmPendingFeedComponent,
    CmRejectedFeedComponent,
    FeaturedGcComponent,
    CmRejectedgcComponent,
    MessengerComponent,
    GroupsHeaderBannerComponent,
    FeedComponent

  ],
  imports: [
    CommonModule,
    CardModule,
    FeedCardModule,
    ModalModule,
    AlertModule,
    RouterModule,
    FormsModule,
    TagInputModule,
    SelectModule,
    TabsModule.forRoot(),
    DataTablesModule,
    ArchwizardModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CurrencyMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbCarouselModule,
    NgbPopoverModule,
    PerfectScrollbarModule,
    DragulaModule,
    NouisliderModule,
    CarouselModule,
    FileUploadModule,
    DropdownModule,
    DialogModule,
    TableModule,
    ToastModule,
    OrderListModule,
    InputSwitchModule,
    CarouselsModule,
    PdfViewerModule,
    YouTubePlayerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbModule,
    MentionModule,
    MultiSelectModule,
    TabViewModule,
    // TabMenuModule
    TagModule,
    TooltipModule,
    DataViewModule,
    AutoCompleteModule,
    VirtualScrollerModule,
    RadioButtonModule,
    ImageCropperModule

  ],
  exports: [
    ReactiveFormsModule,
    CarouselModule,
    CardModule,
    FeedCardModule,
    ModalModule,
    AlertModule,
    TagInputModule,
    SelectModule,
    ArchwizardModule,
    DataTablesModule,
    InfiniteScrollModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbCarouselModule,
    NgbPopoverModule,
    NouisliderModule,
    DragulaModule,
    FileUploadModule,
    LanguageSelectorComponent,
    TranslateModule,
    SearchComponent,
    FormFooterComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HeaderBannerComponent,
    OurStoryBannerComponent,
    AnalyticsComponent,
    AboutComponent,
    MeetOurTeamComponent,
    GetInTouchComponent,
    YourProfileComponent,
    YourStoryComponent,
    YourGrowthComponent,
    TimelineComponent,
    YourInvestmentComponent,
    PerformanceComponent,
    YourPortfolioComponent,
    ApexChartComponent,
    SearchFilterComponent,
    GrowthItemComponent,
    VideoArticleComponent,
    OpportunityItemComponent,
    ProposedTimelineComponent,
    InvestmentSummaryComponent,
    OpportunitiesPerformanceComponent,
    OpportunitiesPerformanceStatusComponent,
    BuildServiceProviderComponent,
    AboutYourBusinessComponent,
    PerfectScrollbarModule,
    YourAreaExpertiseComponent,
    YourSpecialisationComponent,
    SpHeaderBannerComponent,
    SpServicesComponent,
    SpAboutComponent,
    SpMeetOurTeamComponent,
    SpPortfolioComponent,
    SkillsComponent,
    ServicesCardComponent,
    InvestorBannerComponent,
    OpptServiceCardComponent,
    ServiceCardComponent,
    YourOwnGrowthPlanComponent,
    SmeServicesComponent,
    SpGrowthcardComponent,
    GrowthcardFormComponent,
    LandingTimelineComponent,
    HeaderCarouselComponent,
    LandingVideoArticleComponent,
    SignupAsComponent,
    LandingFeaturesComponent,
    SuccessStoriesCarouselComponent,
    StatusLineComponent,
    NewsFeedComponent,
    ListComponent,
    TopicsComponent,
    resultCardComponent,
    FeedFormComponent,
    InputTextReactiveFieldComponent,
    InputTextareaReactiveFieldComponent,
    StartupCardComponent,
    ProductCardComponent,
    SmeProductsComponent,
    HealthCardComponent,
    HealthCheckFormComponent,
    ViewHealthScoreComponent,
    ScoreServiceCardComponent,
    ChatMsgComponent,
    AuthMessageComponent,
    ContactFormComponent,
    AboutFormComponent,
    FaqsComponent,
    BrowseCategoriesComponent,
    BrowseBennerComponent,
    BrowseResultsComponent,
    BrowseCardComponent,
    BrowseFilterComponent,
    DiscussionComponent,
    FollowContentComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    CommunityComponent,
    PartnersComponent,
    NewsEventsComponent,
    MyStoryFormComponent,
    InputReactiveFieldComponent,
    DateAgoPipe,
    FeedShareComponent,
    OwnContentComponent,
    GrowthItemDetailsComponent,
    FeedPopupViewComponent,
    ErrorPageNotFoundComponent,
    ErrorPageForbiddenComponent,
    PublicProfileComponent,
    SearchCompaniesComponent,
    SearchServicesComponent,
    PaginationComponent,
    DropdownModule,
    DialogModule,
    TableModule,
    ToastModule,
    OrderListModule,
    InputSwitchModule,
    CarouselsModule,
    PdfViewerModule,
    LandingSignUpComponent,
    ReadyToSignupComponent,
    PersonalDetailsComponent,
    OrgDetailsComponent,
    UpdatePasswordComponent,
    SideContentCardComponent,
    YouTubePlayerModule,
    PersonalDetailsFormComponent,
    OrgFormComponent,
    GrowthcardItemComponent,
    BRNFormComponent,
    NgbDropdownModule,
    NgbNavModule,
    NgbModule,
    MentionModule,
    SearchNewsComponent,
    CommentBoxComponent,
    BecameGPComponent,
    MultiSelectModule,
    FirstLoginPopupComponent,
    SignupComponent,
    ResetPasswordComponent,
    TabViewModule,
    // TabMenuModule
    ThankYouComponent,
    TagModule,
    TooltipModule,
    DataViewModule,
    GrowthPlansComponent,
    HealthChecksComponent,
    UtilityCategoriesComponent,
    GrowthCardsComponent,
    BrowseCompaniesComponent,
    GrowthPartnerComponent,
    CmPendingFeedComponent,
    CmRejectedFeedComponent,
    FeaturedGcComponent,
    CmRejectedgcComponent,
    AutoCompleteModule,
    VirtualScrollerModule,
    RadioButtonModule,
    MessengerComponent,
    GroupsHeaderBannerComponent,
    ImageCropperModule,
    FeedComponent
  ],
  providers: [
    I18nConfService,
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    SelectOptionService,
    ChartService,
    DragulaService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    MessageService
  ]
})
export class SharedModule {
}
