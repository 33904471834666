<app-language-selector></app-language-selector>
<div class="card results-card" id="divResultsCard">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="">
        <h5 id="ttlCardTitle">{{item.name | translate}}
        </h5>
      </div>

      <div class="text-right" *ngIf="communityManager">
        <div ngbDropdown class="d-inline-block ng-drop">
          <i class="fas fa-ellipsis-v pointer menu" ngbDropdownToggle></i>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <div class="d-flex justify-content-between pointer" ngbDropdownItem (click)="EditResource()">
              <span>Edit</span>
              <span><em class="fas fa-pen"></em></span>
            </div>
            <div class="d-flex justify-content-between pointer" ngbDropdownItem (click)="deleteResource()">
              <span>Delete</span>
              <span><em class="fas fa-trash"></em></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="resource_desc">{{item?.description}}</p>
    </div>

    <div>
      <div class="col-lg-8 col-xl-6 p-0"><label class="label" id="lblCategory">{{item.resourcetype |
          translate}}</label>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div class="d-flex justify-content-between align-items-center">
      <div class="">
        <div class="companyLogos d-flex align-items-center">
          <span *ngIf="!item?.companyLogo" class="feed-company-logo">{{item.description?.trim().slice(0,1)}}</span>
          <span class="imageFluid m-0" *ngIf="item?.companyLogo && item?.companyLogo !=''">
            <img src="{{ item?.companyLogo?item.companyLogo:'' }}" id="imgCompanyLogo" class="img-fluid" alt="logo" />
          </span>

          <p class="disc" id="pDesc">{{item?.resourceCompanyName}}</p>
        </div>
      </div>

      <div class="text-right pptdownload pointer">
        <em pTooltip="Preview Document" tooltipPosition="top" class="pi pi-eye" (click)="setCurrentItem(item);"></em>
        <em pTooltip="Download Document" tooltipPosition="top" class="pi pi-download" (click)="download(item)"></em>
      </div>
    </div>
  </div>
</div>