import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { SearchService } from 'src/app/core/services/search-service/search.service';
import { Common } from 'src/app/utility/common';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() idPrefix: string = '';
  @Input() value: any;
  @Input() placeholder: string = '';
  @Input() maxlength: number = 280;
  @Input() disableMention: boolean = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("commentBox1", { static: false }) commentBox1: ElementRef;
  @ViewChild("commentBox2", { static: false }) commentBox2: ElementRef;

  commentText: string = '';
  formattedComment: string = '';
  lastSelectItem: any;
  selectedItems: any = {};
  mentionConfig: any;
  mentionLabelKey: string = Common.mentionLabelKey;
  mentionIdKey: string = Common.mentionIdKey;
  httpItems: Observable<Observable<any[]>>;
  inputDivTriggered = false;
  @Input() clear: boolean = false;

  private searchTermStream = new Subject();

  constructor(
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.mentionConfig = {
      disableSearch: true,
      labelKey: this.mentionLabelKey
    };

    this.httpItems = this.searchTermStream
      .debounceTime(500)
      .distinctUntilChanged()
      .switchMap((term: string) => this.getItems(term));
  }

  ngAfterViewInit() {
    if (this.value) {
      setTimeout(() => { this.formatValue(this.value); }, 0);
    }
  }

  formatValue(comment: string) {
    const data = Common.getMentions(comment);
    if (Array.isArray(data.mentions)) {
      this.selectedItems = {};
      for (let mention of data.mentions) {
        this.selectedItems[mention[this.mentionIdKey]] = mention;
      }
    }
    if (data.formatted) {
      if (this.disableMention) {
        this.commentBox2.nativeElement.innerHTML = data.formatted;
      } else {
        this.commentBox1.nativeElement.innerHTML = data.formatted;
      }
    }
    this.commentText = data.comment;
    this.formattedComment = data.formatted;
  }

  async getItems(term: any): Promise<Observable<any[]>> {
    if (!term) {
      return Observable.of([]);
    }

    let item: any = await this.searchService.searchTagUsersAsyc(term);
    console.log(item)
    console.log(this.mentionConfig);
    return item;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.clear) {
      this.commentText = ''
    }

    if (changes.value && !changes.value.currentValue) {
      if (this.disableMention) {
        if (this.commentBox2) this.commentBox2.nativeElement.innerHTML = '';
      } else {
        if (this.commentBox1) this.commentBox1.nativeElement.innerHTML = '';
      }
    }
  }

  onSearchTerm(term: any) {
    if (!this.disableMention) {
      this.searchTermStream.next(term);
    }
  }

  onItemSelect(item: any) {
    if (!this.disableMention) {
      let newItem = {};
      newItem[this.mentionIdKey] = item[this.mentionIdKey];
      newItem[this.mentionLabelKey] = item[this.mentionLabelKey];
      this.lastSelectItem = newItem;
      this.selectedItems[newItem[this.mentionIdKey]] = newItem;
    }
  }

  onInputDiv(e: any, el: any, commentBox) {
    this.clear = false;
    this.formattedComment = el.innerHTML;
    if (this.lastSelectItem && !this.disableMention) {
      let lastPos = this.getCaretPosition(el);
      let itemId = this.lastSelectItem[this.mentionIdKey];
      let itemName = this.lastSelectItem[this.mentionLabelKey];
      let findEl = "@" + itemName;
      let replaceEl = '<span class="mention-user" contenteditable="false" data-id="' + itemId + '"><span>@</span>' + itemName + '</span>&nbsp;';
      this.formattedComment = this.formattedComment.replace(findEl, replaceEl);
      el.innerHTML = this.formattedComment;
      this.setCaretPosition(el, lastPos);
      this.lastSelectItem = null;
    }
    let commentData = this.parseCommentEl(el);
    this.commentText = commentData.comment;
    this.valueChange.emit({
      textComment: this.commentText,
      htmlComment: this.formattedComment,
      dataComment: commentData.data,
      mentions: commentData.mentions,
      isValid: this.commentText && this.commentText.length <= this.maxlength
    });

    // commentBox.removeEventListener('input', this.onInputDiv);
    //  Remove the event listener
    this.inputDivTriggered = true;
  }

  parseCommentEl(element: any) {
    let retObj = { mentions: [], comment: '', data: '' };
    if (element.childNodes.length > 0) {
      const firstNode = element.childNodes[0];
      let noPrefix = firstNode.nodeType == 1 && firstNode.tagName == 'DIV';
      for (let node of element.childNodes) {
        if (node.nodeType == 1 && node.tagName == 'DIV') {
          let parsedComment = this.parseCommentEl(node);
          retObj.mentions = [...retObj.mentions, ...parsedComment.mentions];
          if (!noPrefix) {
            retObj.comment += '\n';
            retObj.data += '\n';
            noPrefix = true;
          }
          retObj.comment += parsedComment.comment + '\n';
          retObj.data += parsedComment.data + '\n';
        } else {
          if (node.tagName == 'SPAN') {
            const item = this.selectedItems[node.dataset.id];
            if (item) {
              const itemId = item[this.mentionIdKey];
              if (retObj.mentions.indexOf(itemId) == -1) {
                retObj.mentions.push(itemId);
              }
              retObj.data += '@' + JSON.stringify(item);
            }
          } else {
            retObj.data += node.textContent;
          }
          retObj.comment += node.textContent;
        }
      }
    }
    return retObj;
  }

  setCaretPosition(element: any, lastPos: number) {
    let range = document.createRange();
    let sel = window.getSelection();
    if (element.childNodes.length > 0) {
      let currNode = null;
      for (let node of element.childNodes) {
        if (lastPos >= node.textContent.length) {
          lastPos = lastPos - node.textContent.length;
        } else {
          currNode = node;
          break;
        }
      }
      if (currNode) {
        if (currNode.nodeType == 1) {
          this.setCaretPosition(currNode, lastPos);
        } else {
          range.setStart(currNode, lastPos + 1);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
  }

  getCaretPosition(element: any) {
    let caretOffset = 0;
    let doc = element.ownerDocument || element.document;
    let win = doc.defaultView || doc.parentWindow;
    let sel = null;
    if (typeof win.getSelection != "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        let range = win.getSelection().getRangeAt(0);
        let preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {
      let textRange = sel.createRange();
      let preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  onPaste(event: any) {
    event.preventDefault();
    let clipboardData = event.clipboardData || window["clipboardData"];
    let textContent = clipboardData.getData('text/plain')
    document.execCommand('insertText', false, textContent);
  }

}
