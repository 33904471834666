<app-language-selector></app-language-selector>
<div>
    <div class="content-body m-b-40 growth-card">
        <div class="row">
            <div class="col-lg-12 col-xl-12">

                <div class="featured-growth-cards">
                    <h3 class="sub-title d-flex justify-content-between">
                        <span class="addServiceBtn" @fadeAnimation>
                        </span>
                    </h3>

                    <div>
                        <div class="car-details">
                            <div class="p-grid p-nogutter">
                                <div class="row p-0">
                                    <div *ngIf="featuredServices.value === null">
                                        <h4 class="sub-title">No growth cards yet</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let service of featuredServices">
                                        <app-growthcard-item [serviceToProvide]="service.growthCardTitle"
                                            [componentId]="'id' + service.growthCardId" [gcId]="service.growthCardId"
                                            [thumbnail]="service.image?service.image:'assets/images/browse-results.jpg'"
                                            [duration]="formatTimeEstimate(service)"
                                            [durationType]="getDurationType(service)" [price]="service.costEstimate"
                                            [logo]="service.growthPartnerLogo ? service.growthPartnerLogo :'assets/images/company-default_grey.png'"
                                            [company]="service.growthPartnerName"
                                            [viewPageUrl]="'/user-browse/details/'+(service.role?service.role.toLowerCase():'service_provider')+'/'+service.growthPatnerId"
                                            [description]="service.description"
                                            [serviceDescriptionUrl]="service.serviceDescriptionUrl"
                                            (book)="onBookBtnClick(service)" [canBook]="userCanBook(service)">
                                        </app-growthcard-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<p-dialog header="" [(visible)]="updateBRNPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal product-card-modal"
    [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
    [resizable]="true">
    <div class="app-modal-body brn-modal">
        <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
    </div>
</p-dialog>
<p-toast position="top-center" key="app" [baseZIndex]="9999999" id="pToastMsg"></p-toast>