import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ScrollService } from "./core/services/scroll.service";
import { TokenService } from "./core/services/token/token.service";
import { UserAuthService } from "./core/services/auth-service/user-auth.service";
import { GoogleAnalyticsService } from "./core/services/google-analytics/google-analytics.service";
import { BroadcastService } from "./core/services/broadcast/broadcast.service";
import { environment } from '../environments/environment';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    routerSubscription: any;
    @HostListener("window:scroll", ["$event"])

    onWindowScroll() {
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        if (pos > (max - 20)) {
            this.isScrollAtEnd = true;
            this.scrollService.scrollEnd.next(this.isScrollAtEnd);
        } else if (this.isScrollAtEnd && pos <= (max - 160)) {
            this.isScrollAtEnd = false;
            this.scrollService.scrollEnd.next(this.isScrollAtEnd);
        }
    }

    isScrollAtEnd = false;
    constructor(
        private router: Router,
        public renderer: Renderer2,
        private scrollService: ScrollService,
        private authService: UserAuthService,
        private tokenService: TokenService,
        private messageService: MessageService,
        private service: GoogleAnalyticsService,
        private broadcastService: BroadcastService
    ) {
        if (environment.gaTrackingId) {
            // register google tag manager
            const gTagManagerScript = document.createElement('script');
            gTagManagerScript.async = true;
            gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
            document.head.appendChild(gTagManagerScript);

            // register google analytics
            const gaScript = document.createElement('script');
            gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${environment.gaTrackingId}');
        `;
            document.head.appendChild(gaScript);
        }
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.service.logPageView(document.location.href, evt.url);
            }
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        if (document.getElementById('non-angular-loader')) {
            this.renderer.addClass(document.getElementById('non-angular-loader'), 'off');
        }
        this.checkJWTtokenExp();
        setInterval(() => { this.checkJWTtokenExp() }, 60000);
        this.authService.refreshAuth().subscribe(data => { }, error => { });
        this.displayHttpErrors();
        this.recallJsFuntions();
    }
    recallJsFuntions() {
        this.router.events
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('assets/js/custom.js');
                window.scrollTo(0, 0);
            });
    }
    checkJWTtokenExp() {
        if (!this.authService.isSessionExpired()) {
            const date = this.tokenService.getTokenExpirationDate();
            if (date !== null && date !== undefined) {
                let expireInMins = (date.valueOf() - new Date().valueOf()) / 1000 / 60;
                if (expireInMins <= 30 && expireInMins > 29) {
                    this.toastMessage("Session", "Your session will expire in 30 minutes.", "error");
                } else if (expireInMins <= 10 && expireInMins > 9) {
                    this.toastMessage("Session", "Your session will expire in 10 minutes.", "error");
                } else if (expireInMins <= 5 && expireInMins > 4) {
                    this.toastMessage("Session", "Your session will expire in 5 minutes.", "error");
                } else if (expireInMins <= 1 && expireInMins > 0) {
                    this.toastMessage("Session", "Your session is about to expire. You will be logged out in a minute.", "error");
                } else if (expireInMins <= 0) {
                    this.authService.clearLocalData();
                    this.router.navigate(['/signin']);
                    this.toastMessage("Session Expired!", "Your session got expired. Redirecting to login page. Please login again.", "error");
                }
            }
        }
    }

    displayHttpErrors() {
        this.broadcastService.httpError.subscribe(
            (values) => {
                if (values.hasError && values.type) {
                    if (values.type == 'SERVER') {
                        this.toastMessage("SERVER ERROR", "Sorry, our servers are currently down, please try again later.", "error");
                    }
                    if (values.type == 'CLIENT' && values.items.selector == 'UNAUTHORIZED'
                        && !this.authService.isSessionExpired()) {
                        this.authService.clearLocalData();
                        this.router.navigate(['/signin']);
                        this.toastMessage("SESSION EXPIRED", "Your session got expired! Redirecting to login page. Please login again.", "error");
                    }
                }
            }
        );
    }

    toastMessage(summary: string, detail: string, type: string) {
        this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
    }

    onDeactivate() {
        document.body.scrollTop = 0;
    }

    onActivate($event) {
        window.scroll(0, 0);
    }
}
