import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

interface City {
  name: string,
  // path: string
}

@Component({
  selector: 'app-browse-categories',
  templateUrl: './browse-categories.component.html',
  styleUrls: ['./browse-categories.component.scss']
})
export class BrowseCategoriesComponent implements OnInit {
  @Input() lists: any;
  @Input() categories: any;

  browse: City[];
  selectedCity: City;

  constructor(
    private router: Router
  ) { 
    this.browse = [
      
      {name: 'select'},
      {name: 'by Industry'},
      {name: 'by Service'},
      {name: 'by Product'},
      
  ];
  }

  ngOnInit(): void {

  }

  browseEnterprenuers(event){
    console.log(event)
    if (event.value.path) {
      this.router.navigate([event.value.path])
    }
  }

}
