import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as numberToWords from 'number-to-words';
import {v1 as uuid} from 'uuid';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {TokenService} from "../token/token.service";
import { Currency } from '../../Enums/currency';
import { Duration } from '../../Enums/duration';
import { PRICE_NOT_AVAILABLE } from 'src/app/shared/constants/constants';

// characters used for checksum calculation
const _ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  CHAR_CODE_9 = 57;
  CHAR_CODE_0 = 48;
  CHAR_CODE_DOT = 46;
  CHAR_CODE_ZERO = 0;
  QUESTION_ANSWER_NO = 'No';
  QUESTION_ANSWER_YES = 'Yes';

  constructor(private datePipe: DatePipe, private tokenService: TokenService,
              private titleCasePipe: TitleCasePipe) {
  }

  getFormattedNumber(num: number | string, precision = 0): string {
    if (num === 0) {
      return '0';
    } else if (num) {
      const numStr = num.toString();
      const numberData = parseFloat(numStr);
      return this.FormatNumber(numberData, precision);
    } else {
      return '';
    }
  }

  FormatNumber(num: number, precision = 0, addSuffix = false): string {
    if (addSuffix) {
      return this.addSuffixToNumber(num.toFixed(0)).toString();
    } else {
      return num.toLocaleString('en-US', {maximumFractionDigits: precision});
    }
  }

  ToNumber(num: string): number {
    if (num) {
      return Number(num.toString().replace(/,/g, ''));
    } else {
      return 0;
    }
  }

  removeThousandSeparator(value: string) {
    return value.replace(/,/g, '');
  }

  addThousandSeparatorToElement(value: string): string {
    if (value) {
      return this.FormatNumber(Number(this.removeThousandSeparator(value)));
    }
    return value;
  }

  validateNumber(value: string): boolean {
    const pattern = /^(0|[1-9][0-9]*)$/;
    if (!pattern.test(value)) {
      return false;
    }
    return true;
  }

  validateRegex(value: string, regex): boolean {
    if (!new RegExp(regex).test(value)) {
      return false;
    }
    return true;
  }

  validateLength(value: string, maxLength = 0): boolean {
    if (maxLength !== 0 && this.removeThousandSeparator(value).length >= maxLength) {
      return false;
    }
    return true;
  }

  validateAlphaNumeric(character: string): boolean {
    const pattern = /^[\sa-zA-Z0-9'\.,-]+$/;
    if (!pattern.test(character)) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param {Date} date :  date to be formatted
   * @param {number} type : type indicated format type
   * 1 = day, month, year (default), 2= year, month, day. 3 = month, day, year
   * @param {string} joinBy : default is '/' e.g day-month-year
   * @returns {string}
   */
  getFormattedDate(date: Date, type = 1, joinBy = '/'): string {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    switch (type) {
      case 1:
        return [day, month, year].join(joinBy);
      case 2:
        return [year, month, day].join(joinBy);
      case 3:
        return [month, day, year].join(joinBy);
    }

    return [day, month, year].join(joinBy);
  }

  getDateFormatedFromArray(createdDate) {
    return createdDate[1] + '/' + createdDate[2] + '/' + createdDate[0];
  }

  preventCharForDecimalFields(charCode) {
    return (
      (charCode >= this.CHAR_CODE_0 && charCode <= this.CHAR_CODE_9) ||
      charCode === this.CHAR_CODE_DOT ||
      charCode === this.CHAR_CODE_ZERO
    );
  }

  preventCharForIntergerFields(charCode) {
    return (charCode >= this.CHAR_CODE_0 && charCode <= this.CHAR_CODE_9) || charCode === this.CHAR_CODE_ZERO;
  }

  addSuffixToNumber(num) {
    const firstDigit = num % 10,
      followingDigits = num % 100;
    if (firstDigit === 1 && followingDigits !== 11) {
      return num + 'st';
    }
    if (firstDigit === 2 && followingDigits !== 12) {
      return num + 'nd';
    }
    if (firstDigit === 3 && followingDigits !== 13) {
      return num + 'rd';
    }
    return num + 'th';
  }

  calcCheckDigit(number: string) {
    // Calculate the check digit for the number."""
    let check = 0;
    number
      .substr(0, 13)
      .split('')
      .forEach((item, index) => {
        check += (14 - index) * _ALPHABET.indexOf(item);
      });
    const checkVal = 17 - check;
    return _ALPHABET.substr(checkVal - 17 * Math.floor(checkVal / 17), 1);
  }

  getDateFromNic(number: string) {
    // Convert the part of the number that represents a date
    const day = Number(number.substring(1, 3));
    const month = Number(number.substring(3, 5));
    let year = Number(number.substring(5, 7));
    const curYear = new Date()
      .getFullYear()
      .toString()
      .substr(2, 2);
    if (Number(curYear) > year) {
      year = year + 2000;
    } else {
      year = 1900 + year;
    }
    return new Date(year, month, day);
  }

  isAlpha(ch) {
    return /^[A-Z]+$/i.test(ch);
  }

  validateNic(number: string) {
    try {
      // Check if the number is a valid ID number."""
      number = number.trim();
      if (number.length !== 14) {
        return false;
      }
      if (!this.isAlpha(number.split('')[0]) || this.isAlpha(number.substr(1, 12))) {
        return false;
      }
      if (this.calcCheckDigit(number) !== number.substr(13, 1)) {
        return false;
      }
      this.getDateFromNic(number);
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * This function will return first and last word from the complete applicant name
   * @param completeName
   */
  formatName(completeName: string) {
    if (!completeName || completeName.indexOf(' ') < 0) {
      return completeName;
    }
    return (
      completeName.substr(0, completeName.indexOf(' ')) +
      completeName.substr(completeName.lastIndexOf(' '), completeName.length - 1)
    );
  }

  getLocalDateAsISOString(): string {
    return new Date().toISOString().split('T')[0];
  }

  convertMilliSecToYear(milliseconds: number): number {
    return Math.ceil(Math.abs(milliseconds) / (365 * this.getMilliSecondsInOneDay()));
  }

  isNotEmpty(str: string) {
    return str !== undefined && str !== null && str.length !== 0;
  }

  addUniqueItemsToArray(sourceArray = [], newItems = []) {
    const array = sourceArray.concat(newItems);
    return array.filter((el, i, a) => i === a.indexOf(el));
  }

  getAgeFromDateOfBirth(dob: any): number {
    return moment().diff(dob, 'years');
  }

  getNumbersToWordsOrdinal(numberToBeConverted: number, superscript: boolean = false) {
    let ordinalString: string = numberToWords.toOrdinal(numberToBeConverted);
    if (superscript) {
      const splitString = ordinalString.split(/([0-9]+)/g).filter(item => item.length !== 0);
      ordinalString = splitString[0] + splitString[1].sup();
    }
    return ordinalString;
  }

  getUUID() {
    return uuid();
  }

  formateDate(date = new Date()) {
    return this.datePipe.transform(date, 'MM-dd-yyyy hh-mm-ss');
  }

  formatDate(date = new Date()) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }
  getDateString() {
    var today = new Date();
    var month = today.getUTCMonth() + 1;
    var dateString=today.getDate().toString();
    if(dateString.length==1){
      dateString="0"+dateString;
    }
    var monthString = month.toString();
    if (monthString.length == 1) {
      monthString = "0" + monthString;
    }
    return dateString + "/" + (monthString) + "/" + today.getUTCFullYear();
  }

  dateToCalenderString(formattedDate: Date): string {
    let dd = formattedDate.getDate().toString();
    let mm = (formattedDate.getMonth() + 1).toString(); // January is 0!

    const yyyy = formattedDate.getFullYear();
    if (Number(dd) < 10) {
      dd = '0' + dd;
    }
    if (Number(mm) < 10) {
      mm = '0' + mm;
    }
    return `${yyyy}-${mm}-${dd}`;
  }

  groupElementsOfArray<T>(elements: T[], property: string): Map<any, T[]> {
    const elementsGroupMap: Map<any, T[]> = new Map([]);
    elements.forEach(element => {
      let items = elementsGroupMap.get(element[property]);
      if (items === undefined || items === null) {
        items = [];
      }
      items.push(element);
      elementsGroupMap.set(element[property], items);
    });
    return elementsGroupMap;
  }

  getNumberToWord(index: number) {
    return this.titleCasePipe.transform(numberToWords.toWordsOrdinal(index));
  }

  titleCaseTransform(name) {
    return this.titleCasePipe.transform(name);
  }

  getMilliSecondsInOneDay() {
    return 24 * 60 * 60 * 1000;
  }
  getPriceString(price){
    if(!price || price==0){
     return PRICE_NOT_AVAILABLE;
    }
    return (new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: Currency.MUR,
      minimumFractionDigits: 0,
    }).format(Math.floor(Number(price?price:0)))).replace("MUR", "");
  }
  getServicePriceString(priceEstimate){
    let cost = priceEstimate ? priceEstimate : '';
    if (cost && cost.split("-").length == 2) {
      if(cost.split("-")[0].trim()=="0" && cost.split("-")[1].trim()=="0"){
        return PRICE_NOT_AVAILABLE;
      }else{
        if(cost.split("-")[0].trim()==cost.split("-")[1].trim()){
          return this.getPriceString(cost.split("-")[0].trim());
        }
        if(cost.split("-")[0].trim()=="0" && cost.split("-")[1].trim()!="0"){
          return this.getPriceString(cost.split("-")[1].trim());
        }
        return (cost.split("-")[0].trim()!="0"?this.getPriceString(cost.split("-")[0].trim()):"0") + " - " +
        (cost.split("-")[1].trim()!="0"?this.getPriceString(cost.split("-")[1].trim()):"0");
      }
    }else if(!priceEstimate || priceEstimate=="0" || priceEstimate==0) {
      return PRICE_NOT_AVAILABLE;
    }else{
      return this.getPriceString(parseInt(cost));
    }
  }
  formatTimeEstimate(timeEstimate: any) {
    let newStr = timeEstimate && timeEstimate.trim() && timeEstimate.trim().toLowerCase() != 'unavailable' ? timeEstimate : '0';
    newStr=newStr.trim();
    if(newStr.split(" ").length==1){
      return parseInt(newStr);
    }
    let stringValue = (newStr.split(" ")[newStr.split(" ").length-1]).toUpperCase();
    newStr = newStr.toUpperCase().replace(stringValue, '').trim();
    if(newStr.split("-").length==2 && newStr.split("-")[0].trim() == newStr.split("-")[1].trim()){
      return newStr.split("-")[0];
    }
    if(newStr.split("-").length==2 && newStr.split("-")[0].trim()=="0"){
      return newStr.split("-")[1];
    }
    return newStr;
  }

  getDurationType(timeEstimate: any) {
    let newStr = timeEstimate ? timeEstimate : '';
    newStr=newStr.trim();
    let stringValue = (newStr.split(" ")[newStr.split(" ").length-1]).toUpperCase();
    let durationString = stringValue as keyof typeof Duration;
    return Duration[durationString]?Duration[durationString]:Duration.HOURS;
  }
}
