import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { TokenService } from "../token/token.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { Constants } from 'src/app/shared/constants/constants';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) { }

  getProducts(page: number, size: number, searchText: string, sortAsc: string, sortDesc: string, userId: number) {
    
    let params;
    if (userId) {
      params = new HttpParams()
        .set('searchText', searchText)
        .set('sortAsc', sortAsc)
        .set('sortDesc', sortDesc)
        .set('pageNumber', page.toString())
        .set('pageSize', size.toString())
        .set('userId', userId.toString());
    } else {
      params = new HttpParams()
      .set('searchText', searchText)
      .set('sortAsc', sortAsc)
      .set('sortDesc', sortDesc)
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString());
    }
    return this.http.get<any>(`${this.BASE_API_URL}api/product`,
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  getLatestProducts(userId?: any) {
    let url = `${this.BASE_API_URL}api/product/latest`;
    if (userId) {
      url += '/' + userId;
    }
    return this.http.get<any>(url,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  saveProduct(product: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/product`, product,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  updateProduct(product: any) {
    return this.http.put<any>(`${this.BASE_API_URL}api/product`, product,
      {
        headers: this.tokenService.getMultiPartHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  bookProduct(productId: any) {
    const params = new HttpParams()
      .set('productId', productId);
    return this.http.post<any>(`${this.BASE_API_URL}api/product/bookProduct`, { 'productId': productId },
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  deleteProduct(productId:any){
    const params = new HttpParams()
      .set('productId', productId);
    return this.http.post<any>(`${this.BASE_API_URL}api/product/deleteProduct`, { 'productId': productId },
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  getBookings(pageNumber: any, filters: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/product/getBookings?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + pageNumber, filters,
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }
  replyToBooking(bookingId, message) {
    return this.http.post<any>(`${this.BASE_API_URL}api/product/replyToBooking`, { bookingId: bookingId, message: message },
      {
        headers: this.tokenService.getHttpHeader()
      }
    )
      .pipe(
        tap(
          (appRef: any) => { }
        ));
  }

}
