export enum Constants {
  IP_API_URL = 'jsonip.com',
  MAURITIUS_DIAL_CODE = '+91',
  TOTAL_EXPOSURE_LIMIT = 500000,
  DISCOUNTED_LOAN_ID = "21068",
  PAGE_LIMIT = 9,
}
export enum FeedStatus {
  pending = "Review in Progress",
  approved = "Approved",
  rejected = "Rejected"
}
export const ACCEPTED_FILE_TYPE_FOR_DOCUMENT = [
  'image/png',
  'image/svg+xml',
  'image/jpeg',
  'application/pdf'
];

export const PDF_ONLY_DOCUMENT_TYPE = [
  'application/pdf'
];

export const PDF_ONLY_DOCUMENTS = [
  'AddendumLetter'
];

export const EMAIL_SUPPORTED_DOCUMENTS = [
  'TaxReturn',
  'SupportingLetter',
  'SpecialApprovals',
  'EmployerLetter',
  'Payslip'
];

export const PASWWORD_PATTERN_SPLCHAR = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const PASWWORD_PATTERN_CAPCHAR = /[A-Z]/;
export const PASWWORD_PATTERN_SMLCHAR = /[a-z]/;
export const PASWWORD_PATTERN_NUM = /\d/;
export const URL_PATTERN = '(http[s]?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
export const MOBILE_PATTERN = /^\+27\d{9}$/;
export const NAME_PATTERN = /^[A-Za-z ]+$/;
export const BRN_PATTERN = /^[A-Z][0-9]{8}$/;
export const COMP_NAME_PATTERN = /^[A-Za-z0-9 ]+$/;

export const ACCEPTED_FILE_EXTENSION_FOR_EMAIL_DOCUMENT = ['application/vnd.ms-outlook', '.msg'];

export const PRICE_NOT_AVAILABLE = "Price not available";