<ul *ngIf="pager && pager.pages && pager.pages.length" class="pagination mt-3 float-right">
    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
        <a (click)="setPage(1)" class="page-link">
            <em class="fas fa-step-backward"></em>
        </a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
        <a (click)="setPage(page)" class="page-link">{{page}}</a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
        <a (click)="setPage(pager.totalPages)" class="page-link">
            <em class="fas fa-step-forward"></em>
        </a>
    </li>
</ul>