<app-startup-card [cardTitle]="'SpServices.title' | translate" [subTitle]="'SpServices.subTitle1' | translate"
    [description]="'SpServices.description1' | translate" componentId="SmeServicesCard"
    iconPath="assets/images/product-image.png" btnLabel="My first growth card" (btnClick)="openGCForm()"
    *ngIf="(!hasItems || services.length==0) && !loading && !isPublic"></app-startup-card>
<app-startup-card [cardTitle]="'SpServices.title' | translate" [subTitle]="'SpServices.subTitle2' | translate"
    [description]="'SpServices.description2' | translate" componentId="SmeServicesCard"
    iconPath="assets/images/product-image.png" *ngIf="(!hasItems || services.length==0) && !loading && isPublic">
</app-startup-card>
<div class="row" *ngIf="hasItems && !loading && services.length>0">
    <div class="col-md-12">
        <div id="divSmeServicesCard" class="products sp-service">
            <h3 id="ttlSmeServicesCard" class="title mb-2">
                <span>{{ "SpServices.title" | translate }} <em *ngIf="!isPublic" class="fas fa-plus-circle"
                        (click)="openGCForm()"></em></span>
                <span class="addServiceBtn" @fadeAnimation *ngIf="!isPublic && services.length>3">
                    <a id="aSmeAddService" href="javascript:void(0);" [routerLink]="['/all-growthcards']">View All</a>
                </span>
            </h3>
            <div class="row" @fadeAnimation>
                <div class="col-md-12">
                    <div class="products mb-0 service-cards" *ngIf="services && services.length>0">
                        <div class="product-carousel">
                            <div class="car-details">
                                <div class="p-grid p-nogutter">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                                            *ngFor="let  service of services| slice:0:3">
                                            <app-services-card [serviceToProvide]="service.name"
                                                [description]="service.description"
                                                [componentId]="'SmeServiceCard' + index" [cost]="service.priceEstimate"
                                                [thumbnail]="service.image?service.image:'assets/images/service.png'"
                                                [duration]="formatTimeEstimate(service)" [isHours]="service.isHours"
                                                [durationType]="getDurationType(service)"
                                                [isGrowthCard]="service.growthCard"
                                                [serviceDescriptionUrl]="service.serviceDescriptionUrl"
                                                [approvalStatus]="service.approvalStatus" [gcId]="service.id"
                                                (edit)="onEditBtnClick(service, $event)"
                                                (delete)="onDeleteClick(service)" (book)="onBookBtnClick(service)"
                                                [canEdit]="!isPublic"
                                                [canBook]="service?.isOwnService ? false : userCanBook(service)"
                                                [bookingdone]="service?.isOwnService ? false : bookingdoneuser(service)">
                                            </app-services-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-growthcard-form [action]="actionName" (loadLatestServiceList)="loadLatestServiceList()"
    [gcFormTitle]="actionName + ' a Growth Card'" [currentGcId]="currentGcId"
    [(openGCForm)]="openGCModel"></app-growthcard-form>

<p-dialog header="" [(visible)]="deleteServiceModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'"
    class="feedFormModal product-card-modal" [maximizable]="false" [draggable]="false" [resizable]="false"
    id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">
    <div class="app-modal-body">
        <h6>You are about to delete a Service</h6>
        <p>Are you sure you want to proceed ?</p>
    </div>
    <div class="app-modal-footer text-center">
        <button id="btnSmeAddProductCancel" type="button" class="btn btn-secondary mr-3"
            (click)="deleteServiceModal = false">Cancel</button>
        <button id="btnSmeAddProductSave" type="button" class="btn btn-confirm" (click)="deleteConfirmed();">
            <span *ngIf="buttonPressed" class="spinner-grow spinner-grow-sm" role="status"></span>
            <span class="btn-text">Delete</span>
        </button>
    </div>
</p-dialog>
<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>
<p-dialog header="" [(visible)]="updateBRNPopup" [modal]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal product-card-modal"
    [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
    [resizable]="true">
    <div class="app-modal-body brn-modal">
        <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
    </div>
</p-dialog>