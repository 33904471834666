import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { ActivatedRoute } from '@angular/router';

import { ProfileService } from '../../core/services/profile-service/profile.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { MessageService } from 'primeng/api';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { GroupsService } from 'src/app/pages/sme-groups/groups.service';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';

@Component({
  selector: 'app-groups-header-banner',
  templateUrl: './groups-header-banner.component.html',
  styleUrls: ['./groups-header-banner.component.scss']
})
export class GroupsHeaderBannerComponent implements OnInit {
  public followCount: any;

  userId: any = 0;
  isPublic: boolean = false;
  loading: boolean;
  loadingbanner: boolean;
  loadingdetails: boolean;
  profile: any = {};
  canFollow: boolean = false;
  isUserFollowing: boolean = true;
  buttonPressed: boolean;
  unFollowButtonPressed: boolean;
  followersList: any = [];
  hasItems = false;
  about: string = "";
  followersModal: boolean;
  address: string = "";
  contactNo: string = "";
  email: string = "";
  linkedIn: string = "";
  website: string = "";
  getbannerimg: any;
  bannerupdating: boolean;
  uploadedImage: any = null;
  growthcardDetails: any;
  growthcardName: any;

  groupId: string;

  @Input() loadingBanner: boolean = false;
  @Input() getBannerImg: string | undefined;
  @Input() groupDetails: any;

  constructor(
    private commonService: CommonService,
    private profileService: ProfileService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private gaService: GoogleAnalyticsService,
    private groupsService: GroupsService,
    private gcservice: ServiceCardService
  ) {
    this.profileService.updateConatact.subscribe(
      () => {
        this.address = this.profileService.address;
        this.contactNo = this.profileService.contactNo;
        this.email = this.profileService.email;
        this.linkedIn = this.profileService.linkedIn;
        this.website = this.profileService.website;
      }
    );
  }

  ngOnInit(): void {
    this.groupId = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe(params => {
      this.growthcardName = params['growthcard'];
    });

    if (this.growthcardName) {
      this.getgcdetails();
    } else {
      this.getdetails();
    }
  }


  getdetails() {
    this.loadingbanner = true;
    this.loadingdetails = true;
    this.groupsService.getgroupDetails(this.groupId).subscribe(
      (response) => {

        console.log('groupDetails:', response);
        this.groupDetails = response;
        this.loadingbanner = false;
        this.loadingdetails = false;
        this.getbannerimg = this.groupDetails?.grpBanner
      },
      (error) => {
        console.error('Error:', error);
        this.loadingbanner = false;
        this.loadingdetails = false;
      }
    );
  }


  getgcdetails() {
    this.gcservice.growthcardDetail(this.groupId).subscribe(
      (response: any) => {
        console.log(response, 'gcservice')
        this.growthcardDetails = response;
      },
      (error) => {
        // this.loadingmembers = false;
        console.error('GC Error:', error);
      }
    )
  }

  handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.uploadedImage = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  submit(groupId, fileInput: any) {
    // this.loadingbanner = true;
    const file = fileInput.files[0];

    if (file) {
      let payload = new FormData();
      payload.append('file', file);
      this.bannerupdating = true;
      this.groupsService.updategroupbanner(groupId, payload)
        .subscribe(
          res => {
            this.loadingbanner = true;
            this.getbannerimg = res?.success;
            this.bannerupdating = false;
            this.uploadedImage = "";
            this.loadingbanner = false;
          },
          error => {
            this.loadingbanner = false;
            this.bannerupdating = false;
          }
        );
    }
  }

  joingroup(Id: number) {
    const requestBody = { groupId: Id };
    this.groupsService.joingroups(requestBody).subscribe(
      (response) => {
        console.log('Response:', response);
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Success', detail: 'Request sent to the Group Admin' });
      },
      (error) => {
        console.error('Error:', error);
        this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      }
    )
  }

  resetdialog() {
    this.uploadedImage = "";
  }

  onButtonClick() {
    const fileUpload = document.getElementById('file-upload') as HTMLInputElement;
    fileUpload.accept = 'image/*';
    fileUpload.click();
  }

  unfollowGroup(Id: number) {
    const requestBody = { groupId: Id };
    this.groupsService.leavegroup(requestBody).subscribe(
      (response) => {
        this.getdetails()
        console.log('Response:', response);
        this.messageService.add({ key: 'c', severity: 'success', summary: 'Success', detail: 'leave group successfully' });
      },
      (error) => {
        console.error('Error:', error);
        this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      }
    )
  }
}
