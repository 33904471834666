import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const externalUrl = route.data['externalUrl'];
    window.location.href = environment[externalUrl]?environment[externalUrl]:externalUrl;
    return true;
  }
}