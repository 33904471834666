<app-language-selector></app-language-selector>
<div class="utility-categories growth-cards">
  <div class="row">
    <div class="col-lg-12 p-0">
      <div class="ui-orderlist ui-widget">
        <div class="ui-orderlist-list-container">
          <div class="row">
            <div class="col-lg-6 col-xl-8">
              <h2 class="title" id="ttlUtilityCategories">{{ 'utilityCategories.title' | translate }}</h2>
              <p class="disc content-h">{{ 'utilityCategories.dscp' | translate }}</p>
            </div>
            <div class="col-lg-6 col-xl-4 text-right">
              <button type="button" class="btn btn-confirm list-btn" (click)="setCurrentItem(null);showModel();"
                id="btnAddNewCategory">{{
                'utilityCategories.addBtn' | translate
                }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">

      <p-dataView #dv [value]="categories" [rows]="9" filterBy="name" [showCurrentPageReport]="false"
        [sortField]="sortField" [sortOrder]="sortOrder" [paginator]="true" [rows]="6" layout="grid">
        <ng-template pTemplate="header">
          <div class="d-flex justify-content-between product-header">

            <div class="p-input-icon-left mb-2 md:mb-0">
            </div>
          </div>
        </ng-template>

        <ng-template let-category pTemplate="gridItem">
          <div class="utility-cat category-item">
            <div class="utility-cat">
              <div class="card" style="padding:0px 20px 20px">
                <div class="category-ttile">

                  <h6 class="mb-1 title cursor-pointer">
                    <span pTooltip='{{category.categoryTitle | translate}}' tooltipPosition="top">
                      {{ category.categoryTitle | slice:0:28 }}
                      <span *ngIf="category.categoryTitle.length > 28">...</span>
                    </span>
                  </h6>
                </div> <br>

                <div class="d-flex justify-content-between">
                  <div class="category-number">
                    <label>Number of growth cards</label>
                    <h5>{{category.growthCardCount | translate}}</h5>
                  </div>
                  <div class="category-status">
                    <label class="lbl-block">Status</label>
                    <p-inputSwitch [(ngModel)]="category.status" (onChange)="updateStatus(category)"></p-inputSwitch>
                  </div>
                  <div class="category-action">
                    <div class="dropdown text-left" ngbDropdown>
                      <button class="dropbtn" id="btnDropbtn" ngbDropdownToggle><em
                          class="fas fa-ellipsis-v"></em></button>
                      <ul class="dropdown-menu" ngbDropdownMenu>
                        <li> <a href="javascript: void(0)" id="btnEdit"
                            (click)="showModel();setCurrentItem(category);">Edit</a></li>
                        <li><a href="javascript: void(0)" id="btnDelete"
                            (click)="setCurrentItem(category);deleteUtilityCategoriesModal=true;">Delete</a> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-template>
      </p-dataView>


    </div>
  </div>
</div>
<p-dialog header="" [(visible)]="utilityCategoriesModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal shareFeed_dialog" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appUtilityCategoriesModal" [blockScroll]="true" [resizable]="true">
  <div class="app-modal-body">
    <div class="text-center">
      <h5 *ngIf="!currentUtility" class="modal-title" id="ttlViewMyContent">
        {{'utilityCategories.poupTitle' | translate }}
      </h5>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <label id="lblUtilityCategories">{{ "utilityCategories.lable" | translate }}</label>
              <textarea class="form-control" id="txtUtilityCategories" [(ngModel)]="currentUtilityCategoryTitle"
                placeholder="{{ 'utilityCategories.placeholder' | translate }}" maxlength="{{maxLength}}"
                (keyup)="updateCharacterLength($event)"></textarea>
              <div class="float-right form-group" *ngIf="maxLength > 0">
                {{ (maxLength-characterLength)>-1?maxLength-characterLength:0 }} characters left
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-4">

      </div>
      <div class="col-md-12 col-lg-8 text-right">
        <div class="form-group">
          <button type="button" class="btn btn-secondary mr-2" id="btnUtilityCategoriesCancel"
            (click)="utilityCategoriesModal = false">
            {{ "utilityCategories.cancel" | translate }}
          </button>
          <button *ngIf="!currentUtility" type="button" class="btn btn-confirm" id="btnUtilityCategoriesSubmit"
            (click)="add()">
            {{ "utilityCategories.submit" | translate }}
          </button>
          <button *ngIf="currentUtility" type="button" class="btn btn-confirm" id="btnUtilityCategoriesSubmit"
            (click)="update()">
            {{ "Update" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>


<p-dialog header="" [(visible)]="deleteUtilityCategoriesModal" [modal]="true" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appdeleteUtilityCategoriesModal" [blockScroll]="true" [resizable]="true">
  <p-header class="p-header">
    <div class="app-modal-header">
      <h5 class="modal-title" id="ttlViewMyContent">
        {{'Delete utility category' | translate }}
      </h5>
    </div>
  </p-header>
  <div class="app-modal-body">
    <perfect-scrollbar class="perfect-scrollbar" [style.max-height]="'550px'">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              Are you sure you want to delete utility category <span>{{currentUtility?.categoryTitle}}</span>? It will
              not be
              visible to users and all growth cards assigned will be unassigned to this category.
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="row">
      <div class="col-md-12 col-lg-4">

      </div>
      <div class="col-md-12 col-lg-8 text-right">
        <div class="form-group">
          <button type="button" class="btn btn-secondary mr-2" id="btndeleteUtilityCategoriesCancel"
            (click)="deleteUtilityCategoriesModal = false;">
            {{ "utilityCategories.cancel" | translate }}
          </button>
          <button type="button" class="btn btn-confirm" id="btndeleteUtilityCategoriesSubmit"
            (click)="deleteUtilityCategoriesModal=false;delete();">
            {{ "Delete" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<p-toast key="app" position="top-center" [baseZIndex]="9999999" id="pAppToastMsg"></p-toast>