<div class="navbar-content navmenu-scroll">
    <div class="">
        <div class="main-menu-header">
            <div class="user-details">
                <div id="more-details">
                    <div class="company-fname-img">
                        <img src="assets/images/SME-Linker.png" class="img-fluid company-logo" alt="alt"
                            (click)="logoclick()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
        <ng-container *ngFor="let item of navigation">
            <app-nav-group id="app-nav-group" class="app-nav-group" *ngIf="item.type=='group'" [item]="item">
            </app-nav-group>
            <app-nav-collapse id="app-nav-collapse" *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
            <app-nav-item id="app-nav-item" *ngIf="item.type=='item'" [item]="item"></app-nav-item>
        </ng-container>
    </ul>
</div>


<div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal"
    id="layout-sidenav">
    <a id="aSideNavPrev" href="javascript:void(0);" class="sidenav-horizontal-prev" [ngClass]="prevDisabled"
        (click)="scrollMinus()"></a>
    <div class="sidenav-horizontal-wrapper">

        <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner"
            (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group id="app-nav-group" class="app-nav-group" *ngIf="item.type=='group'" [item]="item">
                </app-nav-group>
                <app-nav-collapse id="app-nav-collapse" *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item id="app-nav-item" *ngIf="item.type=='item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a id="aSideNavNext" href="javascript:void(0);" class="sidenav-horizontal-next" [ngClass]="nextDisabled"
        (click)="scrollPlus()"></a>
</div>