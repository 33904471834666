<div class="container"
    *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
    <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
    <div class="m-header">
        <a href="javascript:void(0);" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
        <a id="aSMEProfile" (click)="goToViewPage()" href="javascript:void(0);" class="b-brand"
            *ngIf="isSME || isServiceProvider || isInvester">
            <span class="logo-img" *ngIf="companylogo">
                <img id="imgNavBarLogo" [src]="companylogo" alt="alt" class="logo" />
            </span>

            <div class="company-fname" *ngIf="!companylogo">
                <div class="company-logo">
                    <span class="logo-txt">
                        {{ companyName?.trim().slice(0,26) }}<ng-container
                            *ngIf="companyName?.length>26">...</ng-container>
                    </span>
                </div>
            </div>
        </a>
        <a id="aNavBarMore" class="mob-toggler" [ngClass]="{ on: this.menuClass }" href="javascript:void(0);"
            (click)="toggleMobOption()"><em class="fas fa-ellipsis-v"></em></a>
    </div>
    <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
        <app-nav-left class="m-auto top-search"></app-nav-left>
        <app-nav-right class="ml-auto1"></app-nav-right>
    </div>
</ng-template>