<div class="row">
    <div class="col-md-12">
        <form id="frmMyStory" [formGroup]="storyForm">
            <ul>
                <li id="liPast">
                    <div class="form-group">
                        <label id="lblPastTitle">The Past</label>
                        <input type="text" class="form-control" id="txtPastTitle" 
                            placeholder="Title - One line summary" formControlName="pastTitle"
                            [ngClass]="{'is-invalid':inValid(f.pastTitle, isSubmit)}">
                        <div class="invalid-feedback" *ngIf="inValid(f.pastTitle, isSubmit)">
                            <div *ngIf="f.pastTitle.errors.required">Please enter past title</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label id="lblPast">The Past</label>
                        <textarea class="form-control" id="txtaPast" rows="3"
                            placeholder="Share how you started your business." formControlName="past"
                            [ngClass]="{'is-invalid':inValid(f.past, isSubmit)}"></textarea>
                        <div class="invalid-feedback" *ngIf="inValid(f.past, isSubmit)">
                            <div *ngIf="f.past.errors.required">Please enter past</div>
                        </div>
                    </div>
                </li>
                <li id="liNow">
                    <div class="form-group">
                        <label id="lblNowTitle">Now</label>
                        <input type="text" class="form-control" id="txtNowTitle" 
                            placeholder="Title - One line summary" formControlName="nowTitle"
                            [ngClass]="{'is-invalid':inValid(f.nowTitle, isSubmit)}">
                        <div class="invalid-feedback" *ngIf="inValid(f.nowTitle, isSubmit)">
                            <div *ngIf="f.nowTitle.errors.required">Please enter now title</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label id="lblNow">Now</label>
                        <textarea class="form-control" id="txtaNow" rows="3"
                            placeholder="What is the status of your business" formControlName="now"
                            [ngClass]="{'is-invalid':inValid(f.now, isSubmit)}"></textarea>
                        <div class="invalid-feedback" *ngIf="inValid(f.now, isSubmit)">
                            <div *ngIf="f.now.errors.required">Please enter now</div>
                        </div>
                    </div>
                </li>
                <li id="liFuture">
                    <div class="form-group">
                        <label id="lblFutureTitle">The Future</label>
                        <input type="text" class="form-control" id="txtFutureTitle" 
                            placeholder="Title - One line summary" formControlName="futureTitle"
                            [ngClass]="{'is-invalid':inValid(f.futureTitle, isSubmit)}">
                        <div class="invalid-feedback" *ngIf="inValid(f.futureTitle, isSubmit)">
                            <div *ngIf="f.futureTitle.errors.required">Please enter future title</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label id="lblFuture">The Future</label>
                        <textarea class="form-control" id="txtaFuture" rows="3"
                            placeholder="What you aim for the future of your business" formControlName="future"
                            [ngClass]="{'is-invalid':inValid(f.future, isSubmit)}"></textarea>
                        <div class="invalid-feedback" *ngIf="inValid(f.future, isSubmit)">
                            <div *ngIf="f.future.errors.required">Please enter future</div>
                        </div>
                    </div>
                </li>
            </ul>
        </form>
    </div>
</div>