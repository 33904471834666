import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LayoutSharedModule } from './layout/shared/shared.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { MessageService } from 'primeng/api';

import { MainComponent } from './layout/main/main.component';
import { NormalLayoutComponent } from './layout/normal-layout/normal-layout.component';
import { AuthComponent } from './layout/auth/auth.component';
import { LandingComponent } from './layout/landing/landing.component';
import { NavigationComponent } from './layout/main/navigation/navigation.component';
import { NavContentComponent } from './layout/main/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './layout/main/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './layout/main/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './layout/main/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './layout/main/nav-bar/nav-bar.component';
import { NavLeftComponent } from './layout/main/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './layout/main/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './layout/main/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './layout/main/configuration/configuration.component';

/* Menu Items */
import { NavigationItem } from './layout/main/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './core/services/http-interceptors/http-interceptor.service';
import { HttpErrorCategorizeService } from "./core/services/http-error-categorize/http-error-categorize.service";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { FooterComponent } from "./components/footer/footer.component";
import { HttpLoaderComponent } from "./components/http-loader/http-loader.component";
import { TranslateModule } from "@ngx-translate/core";
import { BroadcastService } from './core/services/broadcast/broadcast.service';
import { HttpErrorComponent } from "./components/http-error/http-error.component";

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { RedirectGuard } from './core/guards/redirect.guard';
import { UserAuthService } from './core/services/auth-service/user-auth.service';

import { ToggleFullScreenDirective } from './layout/shared/full-screen/toggle-full-screen';

export function initApp(authService: UserAuthService) {
    return (): Promise<any> => {
        return authService.doInit();
    }
}


@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        AuthComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        ConfigurationComponent,
        ToggleFullScreenDirective,
        NormalLayoutComponent,
        LandingComponent,
        FooterComponent,
        HttpLoaderComponent,
        HttpErrorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutSharedModule,
        SharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [UserAuthService],
            multi: true
        },
        NavigationItem,
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        BroadcastService,
        HttpErrorCategorizeService,
        TitleCasePipe,
        DatePipe,
        AuthGuard,
        RoleGuard,
        RedirectGuard,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
