<div class="content-body m-b-40">
    <div class="">
        <div class="">
            <div class="">
                <div class="gc-center">
                    <div class="pagedescriptions">
                        <p> <em class="fas fa-exclamation-circle"></em>
                            Get a roadmap to success by our best & trusted service providers.</p>
                    </div>
                    <div infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
                        <div class="" *ngFor="let growthItem of growthPlans">
                            <app-growth-item [growthItem]="growthItem" [showMoreButton]="true"></app-growth-item>
                        </div>
                    </div>
                    <div class="spinner-border text-center" role="status" *ngIf="showLoader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>