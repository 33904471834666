import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/core/services/auth-service/user-auth.service';
import { FeedService } from 'src/app/core/services/feed-service/feed.service';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-feed-share',
  templateUrl: './feed-share.component.html',
  styleUrls: ['./feed-share.component.scss']
})
export class FeedShareComponent implements OnInit {
  @Output() newFeedAdded = new EventEmitter();
  @Output() onCancel = new EventEmitter<any>();
  @Input() feedForm: string;
  @Input() feedModal: boolean;
  @Input() selectedFeedData: any;

  public newsFeeds = [];
  public statusLine: any;
  public recommendAtions = [];
  public trendingTopics = [];
  showLoader = true;
  shareArticleData: any;
  uploadVideoData: any;
  shareDocument: any;
  feedContent: any;
  image: string = "";
  userName: string = "";
  sharepost: boolean;
  communityGuidelines: boolean;
  help: boolean;
  public sideCardContent: any;
  dtOptions: DataTables.Settings = {};
  feedshareModal: boolean;
  hideIcones: boolean;
  isCommunityManager: boolean;
  loading: boolean;


  constructor(
    private router: Router,
    private commonService: CommonService,
    private feedService: FeedService,
    private profileService: ProfileService,
    private authService: UserAuthService
  ) {
    this.shareArticleData = {
      titleField: {
        label: 'shareArticle.title.title',
        placeholderText: 'shareArticle.title.placeholder',
        type: 'text',
        required: true,
        id: 'shareArticle.title.id',
        validMsg: 'shareArticle.title.errorMsg',
        maxLength: 150,
        minLength: 3
      },
      linkField: {
        label: 'shareArticle.link.title',
        placeholderText: 'shareArticle.link.placeholder',
        type: 'text',
        required: true,
        id: 'shareArticle.link.id',
        validMsg: 'shareArticle.link.errorMsg',
        maxLength: 250
      },
      descriptionField: {
        label: 'shareArticle.description.title',
        placeholderText: 'shareArticle.description.placeholder',
        type: 'text',
        required: false,
        id: 'shareArticle.description.id',
        validMsg: 'shareArticle.description.errorMsg',
        maxLength: 500,
        minLength: 3
      }
    }
    this.uploadVideoData = {
      titleField: {
        label: 'uploadVideo.title.title',
        placeholderText: 'uploadVideo.title.placeholder',
        type: 'text',
        required: true,
        id: 'uploadVideo.title.id',
        validMsg: 'uploadVideo.title.errorMsg',
        maxLength: 150,
        minLength: 3
      },
      linkField: {
        label: 'uploadVideo.link.title',
        placeholderText: 'uploadVideo.link.placeholder',
        type: 'text',
        required: true,
        id: 'uploadVideo.link.id',
        validMsg: 'uploadVideo.link.errorMsg',
        maxLength: 100,
      },
      descriptionField: {
        label: 'uploadVideo.description.title',
        placeholderText: 'uploadVideo.description.placeholder',
        type: 'text',
        required: false,
        id: 'uploadVideo.description.id',
        validMsg: 'uploadVideo.description.errorMsg',
        maxLength: 500,
        minLength: 3
      }
    }
    this.shareDocument = {
      titleField: {
        label: 'shareDocument.title.title',
        placeholderText: 'shareDocument.title.placeholder',
        type: 'text',
        required: true,
        id: 'shareDocument.title.id',
        validMsg: 'shareDocument.title.errorMsg',
        maxLength: 150,
        minLength: 3
      },
      linkField: {
        label: 'shareDocument.link.title',
        placeholderText: 'shareDocument.link.placeholder',
        type: 'text',
        required: false,
        id: 'shareDocument.link.id',
        validMsg: 'shareDocument.link.errorMsg',
        maxLength: 2000,
      },
      descriptionField: {
        label: 'shareDocument.description.title',
        placeholderText: 'shareDocument.description.placeholder',
        type: 'text',
        required: false,
        id: 'shareDocument.description.id',
        validMsg: 'shareDocument.description.errorMsg',
        maxLength: 500,
        minLength: 3
      }
    }

    // this.profileService.updateEvent.subscribe(
    //   () => {
    //     this.image = this.profileService.profilePic;
    //     this.userName = this.profileService.userName;
    //   }
    // );

    this.commonService.getCommonData().subscribe(
      res => {
        // this.videoArticles = res.sideContent?.newsFeed?.video;
        this.sideCardContent = res.sideContent?.newsFeed;
      })
    // this.fetchFeeds();
  }

  isRM: boolean;

  async ngOnInit(): Promise<void> {
    await this.profileService.updateEvent.subscribe(
      () => {
        this.image = this.profileService.profilePic;
        this.userName = this.profileService.userName;
      }
    );

    this.getProfile();

    this.isRM = this.authService.isRelationShipManager();
    this.commonService.getCommonData().subscribe(
      res => {
        this.feedContent = res.smeFeed.content;
      });

    this.isCommunityManager = this.authService.isCommunityManager();


  }

  profile: any = {};


  showFeedPopup() {
    this.feedModal = true;
    // console.log(this.selectedFeedData);
    this.sharepost = true;
    this.communityGuidelines = false;
    this.help = false;
    this.hideIcones = true;
  }
  hideBtnIcons() {
    this.hideIcones = false;
  }
  hideFeedPopup() {
    this.feedModal = false;
  }

  showcommunityguidelines() {
    this.feedshareModal = true;
    this.communityGuidelines = true;
    this.help = false;
    this.sharepost = false;
  }

  helpPopup() {
    this.feedshareModal = true;
    this.help = true;
    this.communityGuidelines = false;
    this.sharepost = false;
  }

  onHide() {
    this.onCancel.emit();
  }
  newFeed(feed) {
    this.newFeedAdded.emit(feed);
  }

  getProfile() {
    this.loading = true;
    this.profileService.getProfileDetails().subscribe(
      (data) => {
        this.profile = data ? data : {};
        this.loading = false;
      },
      (error) => {
        this.profile = {};
        this.loading = false;
        // console.log(error);
      }
    );
  }
}
