import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../services/common/common.service';
import { GrowthPlanService } from '../../../core/services/growth-plan/growth-plan.service';
import { Constants } from 'src/app/shared/constants/constants';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-growth-plans',
  templateUrl: './growth-plans.component.html',
  styleUrls: ['./growth-plans.component.scss']
})
export class GrowthPlansComponent implements OnInit {
  @Output() totalRecordsChanged = new EventEmitter<number>();
  public videoArticles: any;
  public grouthCardData: any;
  public growthPlans = [];
  public sideCardContent: any;

  constructor(
    private commonService: CommonService,
    private growthPlanService: GrowthPlanService,
    private gaService:GoogleAnalyticsService
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.videoArticles = res.sideContent.GrowthPlan.video;
        this.grouthCardData = res.smeGrowthItem.data;
      })
    this.getGrowthPlans();
  }
  pageNumber = 0;
  showLoader = false;
  totalhealthRecords=0;
  totalPages=1;
  getGrowthPlans() {
    if(this.showLoader || this.pageNumber >= this.totalPages) {
      return false;
    }
    this.showLoader = true;
    this.growthPlanService.searchGrowthPlans(this.pageNumber, {}).subscribe(
      res => {
        this.showLoader = false;
        this.totalhealthRecords = res.totalElements?res.totalElements:0;
        this.totalPages = Math.ceil(this.totalhealthRecords/Constants.PAGE_LIMIT);
        if(Array.isArray(res.content)){
          this.growthPlans = this.growthPlans.concat(res.content);
        }
        this.pageNumber += 1;
        this.totalRecordsChanged.emit(this.totalhealthRecords);
      },
      (error: any) => {
        this.showLoader = false;
      }
    )
  }
  ngOnInit(): void {
    this.gaService.logCustomEvent( "Master page loaded", "Growthplan" );
  }
  onScroll(e) {
    this.getGrowthPlans();
  }



}
