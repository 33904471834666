<app-language-selector></app-language-selector>
<div class="sp-header-banner sme-header-banner">

    <div class="company-banner">
        <div class="banner-img">
            <div class="banner-loading" *ngIf="loadingbanner">
                <div class="spinner-border" role="status">
                    <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
                </div>
            </div>

            <ng-container *ngIf="!loadingbanner">
                <img [src]="(!growthcardDetails?.growthCardTitle) ? (getbannerimg ? getbannerimg : './assets/images/sme-banner.jpg') : (growthcardDetails?.image ? growthcardDetails.image : './assets/images/sme-banner.jpg')"
                    alt="">
                <span class="upload-img">
                    <em *ngIf="groupDetails?.roleStatus === 'owner' && !growthcardDetails?.growthCardTitle"
                        class="fas fa-camera pointer" (click)="bannerupdating = true"></em>
                </span>
            </ng-container>
        </div>
    </div>


    <div class="header-container">
        <div class="header-loading" *ngIf="loadingdetails">
            <div class="spinner-border" role="status">
                <span class="sr-only">{{ "editProfile.personalDetails.loading" | translate }}</span>
            </div>
        </div>

        <ng-container *ngIf="!loadingdetails">
            <div class="content">
                <h2 class="title">
                    {{growthcardDetails?.growthCardTitle}}
                </h2>
            </div>
            <div class="d-flex">
                <div class="text-align-center">
                    <div class="company-page-logo">
                        <img [src]="!growthcardDetails?.growthCardTitle ? (groupDetails?.image ? groupDetails?.image : './assets/default-image.jpg') : (growthcardDetails?.growthPartnerLogo ? growthcardDetails?.growthPartnerLogo : './assets/default-image.jpg')"
                            class="img-fluid company-logo-img" alt="" />
                        <div *ngIf="(!growthcardDetails?.growthCardTitle && !groupDetails?.image) || (growthcardDetails?.growthCardTitle && !growthcardDetails?.growthPartnerName && !growthcardDetails?.growthPartnerLogo)"
                            class="company-logo">
                            <span class="logo-txt">{{ (!growthcardDetails?.growthCardTitle && !groupDetails?.image)
                                ?
                                (groupDetails?.name?.trim().slice(0, 1)) :
                                (growthcardDetails?.growthPartnerName?.trim().slice(0, 1)) }}</span>
                        </div>
                    </div>
                </div>

                <div class="header-info">
                    <div class="content d-flex justify-content-between">
                        <div>
                            <h2 class="title">
                                {{ !growthcardDetails?.growthCardTitle ? groupDetails?.name :
                                growthcardDetails?.growthPartnerName }}
                            </h2>
                        </div>

                        <button
                            *ngIf="groupDetails?.roleStatus !== 'owner'  && groupDetails?.joinStatus !== 'join' && !growthcardDetails?.growthCardTitle"
                            type="button" class="btn btn-confirm join-btn" id="btnSubmit"
                            (click)="joingroup(groupDetails?.id)">
                            Join
                        </button>

                        <div ngbDropdown class="d-inline-block ng-drop"
                            *ngIf="groupDetails?.roleStatus !== 'owner' && groupDetails?.joinStatus === 'join'">
                            <i class="fas fa-ellipsis-v pointer menu" ngbDropdownToggle></i>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <div class="pointer" ngbDropdownItem (click)="unfollowGroup(groupDetails?.id)">Leave
                                    group</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="follow-info" *ngIf="!growthcardDetails?.growthCardTitle">
                            <i class="fas fa-users"></i>
                            {{ groupDetails?.membersCount === 0 ? 'No Members following' :
                            (groupDetails?.membersCount
                            === 1 ? groupDetails?.membersCount + ' Member following' : groupDetails?.membersCount +
                            ' Members following') }}
                        </p>

                        <div class="d-flex growthcard-info" *ngIf="growthcardDetails?.growthCardTitle">
                            <p class="estimates"> <em class="fas fa-suitcase mr-1"></em>
                                {{growthcardDetails?.costEstimate}}</p>
                            <p class="estimates"> <em
                                    class="fas fa-calendar-alt mr-1"></em>{{growthcardDetails?.timeEstimate}}</p>
                        </div>


                        <div class="groups-list">
                            <div class="d-flex group-block" *ngFor="let item of growthcardDetails?.groupsData">
                                <div>
                                    <img class="group-img" src="{{item?.groupImg}}" alt="">
                                </div>
                                <div class="group-info">
                                    <p class="group-name">{{item.groupName}}
                                    </p>
                                    <div>
                                        <div></div>
                                        <p>
                                            <i class="fas fa-users"></i>
                                            {{item.membersCount}} members
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <ng-container *ngIf="!growthcardDetails?.growthCardTitle">
                            <label class="label-badge">{{ groupDetails?.industry }}</label>
                            <label class="label-badge">{{ groupDetails?.type }}</label>
                        </ng-container>

                        <ng-container *ngIf="growthcardDetails?.growthCardTitle">
                            <label class="label-badge">{{growthcardDetails?.industry}}</label>
                            <label class="label-badge">{{growthcardDetails?.languages}}</label>
                        </ng-container>

                        <span ngbDropdown placement="top-end" class="d-inline-block share-link"
                            *ngIf="!growthcardDetails?.growthCardTitle">
                            <i class="fas fa-share-alt pointer" ngbDropdownToggle></i>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                                <div class="d-flex justify-content-between p-0">
                                    <i class="fas fa-link" ngbDropdownItem></i>
                                    <i class="fab fa-whatsapp" ngbDropdownItem></i>
                                    <i class="fab fa-facebook-f" ngbDropdownItem></i>
                                    <i class="fas fa-envelope" ngbDropdownItem></i>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div class="aboutdata" *ngIf="!growthcardDetails?.growthCardTitle">
                <div>
                    <p class="discp">{{groupDetails?.description}}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>



<p-dialog header="" [(visible)]="bannerupdating" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true"
    [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal" [maximizable]="false"
    [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
    styleClass="followersModal" (onHide)="resetdialog()">
    <div class="app-modal-body banner-upload">
        <input type="file" #fileInput (change)="handleFileInput($event)">
        <div class="pointer text-center upload-area" (click)="fileInput.click()">
            <span *ngIf="!uploadedImage">
                <em class="fas fa-arrow-circle-up"></em>
                <p>Upload your banner image</p>
            </span>
            <img *ngIf="uploadedImage" [src]="uploadedImage" alt="Uploaded Image">
        </div>


        <div class="text-center mt-3">
            <button type="button" class="btn btn-primary comp-btn"
                (click)="submit(groupDetails.id, fileInput)">upload</button>
        </div>
    </div>
</p-dialog>

<p-toast position="top-center" key="c" [baseZIndex]="5000" id="pToastMsg"></p-toast>