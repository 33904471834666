<div class="footer landing-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="footer-text">
                    <img src="assets/images/SME-Linker.png" alt="logo" class="main-logo">
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <ul class="footer-menu">
                    <li id="lblQuickRegistration">{{'landing.footer.Registration.title' | translate}}</li>
                    <li class="pointer"><a id="aRegistrationLink1" href="javascript:void(0);"
                            (click)="redirectToSignup('SME')">{{'landing.footer.Registration.link1' |
                            translate}}</a></li>
                    <li class="pointer"><a id="aRegistrationLink2" href="javascript:void(0);"
                            (click)="redirectToSignup('SERVICE_PROVIDER')">{{'landing.footer.Registration.link2'
                            |
                            translate}}</a></li>
                    <li class="pointer"><a id="aRegistrationLink3" href="javascript:void(0);"
                            (click)="redirectToSignup('INVESTOR')">{{'landing.footer.Registration.link3' |
                            translate}}</a></li>
                </ul>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <ul class="footer-menu">
                    <li id="lblCommunity">{{'landing.footer.Community.title' | translate}}</li>
                    <li><a id="aCommunityLink4" [href]="faqURL">{{'landing.footer.Community.link4' |
                            translate}}</a>
                    </li>
                    <li>
                        <a href=""><span class="mr-2">{{'landing.footer.WriteUs' | translate}}</span></a>
                    </li>
                    <li>
                        <p>
                            <a id="aMailTo"
                                href="mailto:{{'landing.footer.email' | translate}}">{{'landing.footer.email' |
                                translate}}</a>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <ul class="footer-menu">
                    <li id="lblLegal">{{'landing.footer.Legal.title' | translate}}</li>
                    <li><a id="aLegalLink1" [href]="termsURL">{{'landing.footer.Legal.link1' |
                            translate}}</a>
                    </li>
                    <li><a id="aLegalLink2" [href]="privacyURL">{{'landing.footer.Legal.link2' |
                            translate}}</a></li>
                    <li><a id="aLegalLink3" [href]="cookieURL">{{'landing.footer.Legal.link3' |
                            translate}}</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="footer-copyright">
    <div class="container text-center">
        <p>© 2022 – 2023. <a href="https://www.cloudseed.io/" target="_blank">Cloudseed Technologies LLP</a>. All
            rights reserved.</p>
    </div>
</div>