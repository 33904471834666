<div class="pcoded-navbar theme-horizontal custom-horizontal">
    <ul class="navbar-nav mr-auto">
        <li class="nav-item">
            <app-nav-search></app-nav-search>
        </li>
    </ul>
    <div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal"
        id="layout-sidenav">
        <div class="sidenav-horizontal-wrapper">
            <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner"
                (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
                <ng-container *ngFor="let item of navigation">
                    <app-nav-group id="app-nav-group" *ngIf="item.type == 'group'" [item]="item" class="app-nav-group">
                    </app-nav-group>
                    <app-nav-collapse id="app-nav-collapse" *ngIf="item.type == 'collapse'"
                        [item]="item"></app-nav-collapse>
                    <app-nav-item id="app-nav-item" *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
                </ng-container>
            </ul>
        </div>
    </div>
</div>