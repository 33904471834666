<app-language-selector></app-language-selector>
<div class="service-card mini-service-card">
  <app-card id="crdService" [customHeader]="true" headerClass="border-0 p-0 pb-0" [options]="false"
    cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
    <div class="app-card-header position-relative">
      <div class="cover-img-block">
        <img id="imgServiceUser" src="assets/images/user/avatar-1.jpg" alt="alt" class="img-fluid img-radius">
      </div>
      <div class="service-info">
        <h6 class="mb-1 mt-3 title">
          <span>{{ "Spservices.servicesCard.miniCard.title" | translate }}</span>
        </h6>
        <p class="mb-1 discp">{{ "Spservices.servicesCard.miniCard.dscp" | translate }}</p>
      </div>
    </div>
    <div class="text-left">
      <div class="service-info">
        <h6 class="mb-1 mt-3s title">
          <span>{{ "Spservices.servicesCard.miniCard.mainTitle" | translate }}</span>
        </h6>
      </div>
    </div>
    <hr class="b-wid-1 my-4">

    <ul class="footer-values">
      <li class="icon">
        <h6>{{ "Spservices.servicesCard.miniCard.Weeks" | translate }}</h6>
        <p class="mb-0">{{ "Spservices.servicesCard.miniCard.Week" | translate }}</p>
      </li>
      <li class="icon">
        <h6>{{ "Spservices.servicesCard.miniCard.MURN" | translate }}</h6>
        <p class="mb-0">{{ "Spservices.servicesCard.miniCard.MUR" | translate }}</p>
      </li>
    </ul>
    <div class="text-right">
      <button id="btnInvite" class="btn btn-secondary mt-4">{{ "buttons.Invite" | translate }}</button>
    </div>
  </app-card>
</div>