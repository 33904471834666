import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private router: Router,
    public authService: UserAuthService
  ) { }

  ngOnInit() {
    if (!this.authService.isSessionExpired()) {
      let url = '/signin';
      if (this.authService.isServiceProvider()) {
        url = '/service-provider';
      }
      if (this.authService.isInvester()) {
        url = '/investor';
      }
      if (this.authService.isCommunityManager()) {
        url = '/feed';
      }
      if (this.authService.isSME()) {
        url = '/sme-profile'
      }
      if (this.authService.isRelationShipManager()) {
        url = '/feed'
      }
      this.router.navigate([url]);
    }
  }

}
