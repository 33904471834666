import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Common } from '../../utility/common';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ServiceCardService } from '../../core/services/service-card-service/service-card.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { SelectOptionService } from '../../shared/components/select/select-option.service';
import { Currency } from "../../core/Enums/currency"
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { CommonService } from '../../services/common/common.service';


export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-sp-growthcard',
  templateUrl: './sp-growthcard.component.html',
  styleUrls: ['./sp-growthcard.component.scss'],
  animations: [fadeAnimation]
})
export class SpGrowthcardComponent implements OnInit {
  userId: any;
  isPublic: boolean = false;
  isServiceProvider: boolean;
  growthCardService: any;
  approveModal: boolean;
  hasItems = false;
  description = Common.discp;
  public keyupLabelOn = false;
  public keydownLabelOn = false;
  public someRange2: number[] = [0, 250000];
  public noOfweeks: number[] = [0, 3];
  responsiveOptions: any;
  maxAllowedSize = 1 * 1024 * 1024;

  actionName: string = "Add";
  currentGcId: number = -1;
  openGCModel: boolean = false;

  public weeksConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 4],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 5
    },
    pips: {
      mode: 'count',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  format = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: Currency.MUR,
    minimumFractionDigits: 0,
  }
  );

  public ServicePriceRangeConfig: any = {
    start: [0, 250000],
    connect: true,
    step: 1000,
    range: {
      min: 0,
      max: 1000000
    },
    pips: {
      mode: 'count',
      values: 2
    },
    format: {
      to: function (value) {
        return Currency.RS + (new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: Currency.MUR,
          minimumFractionDigits: 0,
        }).format(Math.floor(value))).replace("MUR", "");
      },
      from: function (value) {
        return Number(value.replace('Rs', '').trim().replace(/\,/g, ''));
      }
    }
  };

  businesses = Common.helpBusinesses;
  selectedBusinesses = '';
  nOfweeks = Common.nOfweeks;
  selectednOfweeks = '';
  isSubmit: boolean;
  buttonPressed: boolean;
  loading: boolean;
  isLoggedIn: boolean;
  editMode: boolean;
  selectedService: any;
  services: any = [];
  imagePath: any;
  previewURL: any;
  addServiceModal: boolean;
  addGrowthModal: boolean = false;
  deleteServiceModal: boolean;
  constructor(
    private serviceCardService: ServiceCardService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private utility: UtilityService,
    private gaService: GoogleAnalyticsService,
    private commonService: CommonService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.commonService.getCommonData().subscribe(
      res => {
        this.growthCardService = res.growthCardService.content;
      })
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestServiceList();
    });
    this.isServiceProvider = this.authService.isServiceProvider();
  }

  onChangeBusinesses(newValue) {
    this.selectedBusinesses = newValue;
  }

  onOfweeks(newValue) {
    this.selectednOfweeks = newValue;
  }

  blinkKeyupLabel() {
    this.keyupLabelOn = true;
    setTimeout(() => {
      this.keyupLabelOn = false;
    }, 450);
  }

  blinkKeydownLabel() {
    this.keydownLabelOn = true;
    setTimeout(() => {
      this.keydownLabelOn = false;
    }, 450);
  }


  loadServiceList(event: any) {
    this.loading = true;
    let q = '';
    let sortAsc = event.sortField && event.sortOrder == 1 ? event.sortField : '';
    let sortDesc = event.sortField && event.sortOrder == -1 ? event.sortField : '';
    this.serviceCardService.getServiceCards((event.first / event.rows), event.rows, q, sortAsc, sortDesc, this.userId).subscribe(
      (data) => {
        this.services = data.content;
        if (Array.isArray(this.services) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.services = [];
        this.loading = false;
      }
    );
  }

  loadLatestServiceList() {
    this.loading = true;
    this.serviceCardService.getLatestServiceCards(this.userId).subscribe(
      (data) => {
        this.services = data;
        if (Array.isArray(this.services) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.services = [];
        this.loading = false;
      }
    );
  }

  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }
  formatTimeEstimatePreview(value) {
    return this.utility.formatTimeEstimate(value);
  }
  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }

  onEditBtnClick(s: any, e: any) {
    if (e && e.editDraftGC) {
      this.actionName = 'Edit';
      this.currentGcId = s.id;
      this.openGCModel = true;
    }
  }
  currentService;
  onDeleteClick(s: any) {
    this.currentService = s;
    this.deleteServiceModal = true;
  }
  deleteConfirmed() {
    if (this.currentService.deleting) {
      return false;
    }
    this.currentService.deleting = true;
    this.serviceCardService.deleteService(this.currentService.id).subscribe(
      data => {
        this.deleteServiceModal = false;
        this.currentService.deleting = false;
        this.services = this.services.filter(s => { return this.currentService.id != s.id; });
        this.toastMessage("Delete Service", "Your Service Card has been removed", "success");
      },
      error => {
        this.deleteServiceModal = false;
        this.currentService.deleting = false;
        this.toastMessage("Delete Service", "Unable to delete service! Please try after some time.", "error");
      }
    );
  }
  userCanBook(service: any) {
    return this.isPublic && this.isLoggedIn && !service.booked;
  }

  bookingdoneuser(service: any) {
    return this.isPublic && this.isLoggedIn && service.booked;
  }

  onBookBtnClick(service: any) {
    if (service.booking || service.booked) {
      return false;
    }
    service.booking = true;
    this.gaService.logCustomEvent("Booked", "Growth Cards", service.name);
    this.serviceCardService.bookService(service.id).subscribe(
      data => {
        service.booked = true;
        service.booking = false;
        if (service.growthCard) {
          this.toastMessage("Book Growth Card", "A request has been successfully sent to the Service Provider", "success");
        }
        else {
          this.toastMessage("Book Service", "Service booked successfully", "success");
        }
      },
      error => {
        service.booking = false;
        if (Common.isBRNErrors(error)) {
          this.updateBRNPopup = true;
          this.currentBookingItem = service;
        } else {
          if (service.growthCard) {
            this.toastMessage("Book Growth Card", "Unable to book Growth Card! Please try after some time.", "error");
          }
          else {
            this.toastMessage("Book Service", "Unable to book service! Please try after some time.", "error");
          }
        }
      }
    );
  }
  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

  openGCForm() {
    this.actionName = 'Add';
    this.currentGcId = 0;
    this.openGCModel = true;
  }
}
