import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IOption } from 'ng-select';
import { SelectOptionService } from '../../../shared/components/select/select-option.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CustomValidators } from '../../../shared/helpers/custom.validators';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { PASWWORD_PATTERN_CAPCHAR, PASWWORD_PATTERN_NUM, PASWWORD_PATTERN_SMLCHAR, PASWWORD_PATTERN_SPLCHAR } from '../../../shared/constants/constants';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { environment } from 'src/environments/environment';
import { UqudoService } from './uqudo.service';
import { v4 as uuidv4 } from 'uuid';
import uqudoSdkFactory, { DocumentType } from '../../../../assets/uqudo_interface/uqudoSDK';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';

// interface City {
//   name: string;
//   code: string;
// }

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnChanges {

  @Input() accountTypeo: boolean
  @Input() accountType: string;
  @Input() formGroup: string;

  investmentOption: Array<any> = [];
  heardFromOption: Array<any> = [];
  isTextFieldType: boolean;
  isReTextFieldType: boolean;
  registerForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  verifyPopup: boolean;
  smeconnectURL: any;
  termsURL: any;
  @Input() reset: boolean
  index: number = 0;
  verifyemail: boolean
  exp: boolean
  privacyURL: any;
  uq_auth: boolean;
  personal_auth: boolean;
  fileToUpload: any;
  imageUrl: any;
  token = '';
  groupedCities: any = [];
  predefinedCities: any = [];
  @Output() closeModal = new EventEmitter<boolean>();
  showLoader: boolean;
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  faceImg: any;
  emiratesId: any;
  countryRegionOptions: any;
  countries: string[];
  filteredCountries: any[];
  selectedCountries: any[];
  vitscroll: boolean = false;
  industryType: any

  constructor(
    private router: Router,
    private authService: UserAuthService,
    private messageService: MessageService,
    public selectOptionService: SelectOptionService,
    private route: ActivatedRoute,
    private gaService: GoogleAnalyticsService,
    private uqudoService: UqudoService,
    private profileService: ProfileService
  ) {
    this.filteredCountries = [];
  }

  clearItems(group: any) {
    group.items = [];
  }

  handleClick(group: any) {
    if (Array.isArray(group.items) && group.items.length > 0) {
      this.clearItems(group);
    } else {
      for (let i = 0; i < this.predefinedCities.length; i++) {
        if (this.predefinedCities[i]['label'] == group['label']) {
          group.items = this.predefinedCities[i]['items']
          break;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.registerForm.reset()
    }
  }

  ngOnInit() {
    this.getAccessToken();
    this.smeconnectURL = environment.smeconnectURL;
    this.termsURL = environment.termsURL;
    this.privacyURL = environment.privacyURL
    this.route.queryParams.subscribe(
      params => {
        this.accountType = params.accountType ? params.accountType : "SME";
      }
    );

    this.selectOptionService.getIndustryTypes().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.selectOptionService.getsubIndustryTypes(data[i]['industryName']).subscribe((d: any) => {
          let items = []
          let records = {}
          for (let j = 0; j < d.length; j++) {
            items.push({
              label: d[j]['subIndustryName'], value: d[j]['subIndustryName']
            })
          }
          records = {
            label: data[i]['industryName'], value: data[i]['industryName'],
            items: items
          }
          this.groupedCities.push({
            label: data[i]['industryName'], value: data[i]['industryName'],
            items: []
          })
          this.predefinedCities.push(records)
        });
      }
    });

    this.selectOptionService.getWhereDidYouHearAboutSMECONNECTOPtion().subscribe((data: any) => {
      this.heardFromOption = data.map(v => {
        return { value: v.reason, label: v.reason };
      });
    });

    this.selectOptionService.getCountryRegion().subscribe((data: any) => {
      this.countryRegionOptions = data.map(v => {
        return { value: v.countryCode, label: v.countryName };
      });
    });

    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(25),
      CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      lastName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.maxLength(35),
      CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      position: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(40),
      CustomValidators.patternValidator(/^[A-Za-z ]+$/, { hasNoSpecialCharacters: true })
      ]),
      brnNumber: new FormControl('', [
        Validators.required, Validators.pattern(/^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$/)
      ]),
      tradeLicense: new FormControl('', Validators.required),
      companyRegion: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, CustomValidators.removeSpaces, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      Validators.maxLength(100)
      ]),
      companyName: new FormControl('', [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(40),
      CustomValidators.patternValidator(/^[A-Za-z0-9 ]+$/, { hasNoSpecialCharacters: true })
      ]),
      terms: new FormControl('', [Validators.requiredTrue]),
      industry: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      heardFrom: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      areaOfInterest: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required, CustomValidators.removeSpaces,
        Validators.minLength(8),
        Validators.maxLength(25),
        CustomValidators.patternValidator(PASWWORD_PATTERN_NUM, { hasNumber: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_CAPCHAR, { hasCapitalCase: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_SMLCHAR, { hasSmallCase: true }),
        CustomValidators.patternValidator(PASWWORD_PATTERN_SPLCHAR, { hasSpecialCharacters: true })
      ]),
      confirmPassword: new FormControl('', [Validators.required, CustomValidators.removeSpaces])
    },
      CustomValidators.passwordMatchValidator('password', 'confirmPassword')
    );
  }


  register() {
    this.isSubmit = true;
    if (this.registerForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;

    let userDetail = this.registerForm.value;

    if (userDetail.confirmPassword) delete userDetail.confirmPassword;
    if (userDetail.terms) delete userDetail.terms;
    userDetail.areaOfInterest = this.registerForm.value.areaOfInterest.map(option => option.value);
    userDetail.role = [this.accountType];
    userDetail.personalId = this.emiratesId;

    this.authService.registerSME(userDetail, this.faceImg).subscribe(
      data => {
        this.gaService.logCustomEvent("heard_about_smeconnect", userDetail.heardFrom, userDetail.email);
        this.gaService.logCustomEvent("Referred by", "Signup", userDetail.heardFrom);
        this.buttonPressed = false;
        if (this.accountType == 'SME') {
          this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Success', detail: 'You have successfully signed up with SME.' });
          this.isSubmit = false;
          this.registerForm.reset();

          this.isSubmit = false;
          this.registerForm.reset();
          this.exp = !this.exp
        }
        else {
          this.verifyPopup = false;
          this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: 'Success', detail: 'Your request has been successfully received.' });
          this.isSubmit = false;
          this.registerForm.reset();
          setTimeout(() => {
            this.router.navigate(['/thank-you'], {
              queryParams: {
                email: userDetail.email,
                type: 'growth-partner'
              }
            });
          }, 1000);
        }
      },
      error => {
        let msg = 'Error occur while register account for you. Please contact administrator.';
        this.buttonPressed = false;
        if (error.status == 400) {
          if (error.error == "Fail -> Email is already in use!") {
            msg = 'Email is already in use! Please enter different email address.';
          }
        }
        this.messageService.add({ key: 'c', severity: 'error', summary: 'Error', detail: msg });
      }
    );
  }

  togglePasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  toggleRePasswordFieldType() {
    this.isReTextFieldType = !this.isReTextFieldType;
  }

  get f() { return this.registerForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

  openNext() {
    this.index = (this.index === 2) ? 0 : this.index + 1;
  }

  openPrev() {
    this.index = (this.index === 0) ? 2 : this.index - 1;
  }


  isRegisterFirstStepValid() {
    const isCustomValid = this.registerForm.value.firstName !== "" &&
      this.registerForm.value.lastName !== "" &&
      this.registerForm.value.email !== "" &&
      this.registerForm.value.position !== "" &&
      this.registerForm.value.password !== "" &&
      this.registerForm.value.confirmPassword !== "";

    const isAngularValid = this.registerForm.get('firstName').valid &&
      this.registerForm.get('lastName').valid &&
      this.registerForm.get('email').valid &&
      this.registerForm.get('position').valid &&
      this.registerForm.get('password').valid &&
      this.registerForm.get('confirmPassword').valid;

    return isCustomValid && isAngularValid;
  }

  isRegistersecondStepValid() {
    const isCustomValid = this.registerForm.value.brnNumber !== "" &&
      this.registerForm.value.tradeLicense !== "" &&
      this.registerForm.value.companyRegion !== "" &&
      this.registerForm.value.companyName !== "" &&
      this.registerForm.value.industry !== "";


    const isAngularValid = this.registerForm.get('brnNumber').valid &&
      this.registerForm.get('tradeLicense').valid &&
      this.registerForm.get('companyRegion').valid &&
      this.registerForm.get('companyName').valid &&
      this.registerForm.get('industry').valid;

    return isCustomValid && isAngularValid;
  }

  gotonext() {
    this.uq_auth = !this.uq_auth
  }

  getupload() {
    this.personal_auth = !this.personal_auth
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  ///uqudo int///
  getAccessToken() {
    this.uqudoService.getToken().subscribe((response) => {
      const data: any = response;
      this.token = data.payload.token;
    });
  }

  async startEnrollment() {

    this.closeModal.emit(true);

    const sessionId = uuidv4();
    const uqudoSdk = uqudoSdkFactory.create({
      sessionId: sessionId,
      baseURL: 'https://id.dev.uqudo.io',
      accessToken: this.token,
    });



    try {
      const result = await uqudoSdk.enrollment({
        scan: {
          documentType: DocumentType.UAE_ID,
          disableExpiryValidation: true,
        },

        onSuccess: (result) => {
          this.isLoading.emit(true);
          this.uqudoService
            .getDataFromSessionId(sessionId)
            .subscribe((response) => {
              const data: any = response;

              const reqData = {
                front: data.payload.documents[0].scan.front,
                back: data.payload.documents[0].scan.back,
              }

              this.closeModal.emit(false);

              this.isLoading.emit(false);


              this.personal_auth = !this.personal_auth;
              this.uq_auth = true;
              const fullName = reqData.front.fullName;
              const nameParts = fullName.split(' ');
              const lastName = nameParts.pop();
              const firstName = nameParts.join(' ');

              this.registerForm.patchValue({
                firstName: firstName,
                lastName: lastName
              });

              this.faceImg = data.payload.documents[0].scan.faceImage;
              this.emiratesId = reqData.front.identityNumber

            });
        },
      });

    } catch (error) {
      console.log(error);
    }
  }

  filterCountry(event: any) {
    const query = event.query.toLowerCase();
    this.filteredCountries = this.investmentOption.filter(option =>
      option.value.toLowerCase().includes(query)
    );
    this.vitscroll = true;
  }

}
