import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-error-page-not-found',
  templateUrl: './error-page-not-found.component.html',
  styleUrls: ['./error-page-not-found.component.scss']
})
export class ErrorPageNotFoundComponent implements OnInit {

  smeconnectURL: any;

  constructor(
    private router: Router,
    public userAuthService: UserAuthService
  ) { }

  ngOnInit() {
    this.smeconnectURL = environment.smeconnectURL
  }


  goToHome() {
    this.router.navigate([this.smeconnectURL]);
  }
}
