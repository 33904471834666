import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  applicationID: string;
  constructor(private http: HttpClient, private storageService: StorageService) {}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructue
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  reset() {
    this.applicationID = this.storageService.getFromLocal('CURRENT_LOAN_APPLICATION_DATA_ID');

    return this.http.delete(`api/reset/${this.applicationID}`).pipe(
      tap((appRef: any) => {}
      ),
      catchError(this.handleError<string>('reset'))
    );
  }
}
