<app-language-selector></app-language-selector>
<div class="card" style="margin-bottom: 100px;">

    <div class="card-body question-results">
        <div class="row">
            <div class="col-md-12 text-center">
                <h4 id="{{showMyScore?.ttlId}}">{{showMyScore?.title | translate}}</h4>
            </div>
            <div class="col-md-4">
                <div class="score-circle" id="divOverallScore">
                    <p>{{"Overall Score" | translate}}</p>
                    <div>{{showMyScore?.score?.toFixed() | translate}}%</div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="summary">
                    <h5 id="{{showMyScore?.subTtlId}}">
                        {{showMyScore?.subtitle | translate}}
                    </h5>
                    <p>{{showMyScore?.summary | translate}}</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="moreDetails">
                    <h6 id="{{showMyScore?.moreDetails?.ttlId}}" class="moreDetailsTitle">
                        {{showMyScore?.moreDetails?.title | translate}}</h6>
                    <accordion [isAnimated]="true" [closeOthers]="oneAtATime" id="accordion">
                        <ng-container *ngFor="let item of showMyScore?.moreDetails; index as i">
                            <accordion-group [isOpen]="item?.isOpen" class="accordion-group">
                                <button class="btn btn-block clearfix" accordion-heading type="button"
                                    id="{{item.scoreDscTitleId}}{{i+1}}">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="score">
                                                <h6 id="{{item.section}}{{i+1}}">
                                                    {{item.section | translate}}
                                                </h6>
                                                <p>{{item.scoreText | translate}}</p>
                                                <div>{{item.sectionScore?.toFixed() | translate}}%</div>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="scoreDsc">
                                                <h6>{{item.scoreDscTitle | translate}}</h6>
                                                <p>{{item.scoreDsc | translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                                <div class="contents">
                                    <div class="row">
                                        <div class="col-md-3">
                                        </div>
                                        <div class="col-md-9">
                                            <p>{{item.scoreDsc2 | translate}}</p>
                                        </div>
                                        <div class="col-md-12 ui-carousel carousel-card">
                                            <div class="oppt-service-card">
                                                <app-score-service-card
                                                    [healthCards]="item.suggestedServices"></app-score-service-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                        </ng-container>
                    </accordion>
                </div>
            </div>
        </div>

    </div>