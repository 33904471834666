import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOption } from 'ng-select';
import { SelectOptionService } from '../../../shared/components/select/select-option.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserAuthService } from '../../../core/services/auth-service/user-auth.service';
import { ProfileService } from 'src/app/core/services/profile-service/profile.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-org-form',
  templateUrl: './org-form.component.html',
  styleUrls: ['./org-form.component.scss']
})
export class OrgFormComponent implements OnInit {
  investmentOption: Array<any> = [];
  @Output() selectedPic: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSubmit: boolean;
  @Input() orgForm: FormGroup;
  @Input() cmOrgForm: FormGroup;
  @Input() srcData: any;
  acceptedImageFormates = ".jpeg,.jpg,.png";
  maxAllowedSize = 1 * 1024 * 1024;
  year = new Date().getFullYear();
  startYear: any;
  range = [];
  years: any[];
  selectedYear: any;
  isSME: boolean;
  isServiceProvider: boolean;
  isInvester: boolean;
  isCommunityManager: boolean;
  orgDetails: any = {};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropimage: boolean = false;
  imageCroppedFlag: boolean = false;
  croppedImageBase64: string = ''

  constructor(
    private messageService: MessageService,
    public selectOptionService: SelectOptionService,
    private authService: UserAuthService,
    private profileService: ProfileService
  ) {
    this.startYear = this.startYear || 1920;
    for (let i = this.startYear; i <= this.year; i++) {
      this.range.push({
        label: i.toString(),
        value: i.toString()
      });
    }
    this.years = this.range;
  }
  ngOnInit(): void {
    this.isSME = this.authService.isSME();
    this.isServiceProvider = this.authService.isServiceProvider();
    this.isInvester = this.authService.isInvester();
    this.isCommunityManager = this.authService.isCommunityManager();
    this.selectOptionService.getIndustryTypes().subscribe((data: any) => {
      this.investmentOption = data.map(v => {
        return { value: v.industryName, label: v.industryName };
      });
    });

    // this.getCompanyProfile()

    this.profileService.getOrgDetails().subscribe(
      (data) => {
        this.orgDetails = data ? data : {};
        console.log(this.orgDetails)
      },
      (error) => {
        this.orgDetails = {};
        console.log(error);
      }
    );

  }

  validFileType(filename, extensions) {
    var dotSplits = filename.split(".");
    var fileExtention = dotSplits[dotSplits.length - 1];
    if (extensions.indexOf(fileExtention.toLowerCase()) != -1) {
      return true;
    } else {
      return false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
    this.imageCroppedFlag = true;
  }

  async onButtonClicked() {
    this.srcData = await this.base64ToBlob(
      this.croppedImageBase64
    )
    this.selectedPic.emit(this.srcData);
    this.cropimage = false;
  }


  async base64ToBlob(data) {
    const arr = data.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: 'image/jpeg' });
  }

  onFileChanged(event) {
    this.cropimage = true
    this.imageChangedEvent = event;
    if (!this.validFileType(event.target.files[0].name, this.acceptedImageFormates)) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image should be in either .jpeg, .jpg, or .png format", detail: '' });
      return;
    }

    if (event.target.files[0].size > this.maxAllowedSize) {
      event.target.value = ''
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }
    this.selectedPic.emit(event.target.files[0])
    let fileReader = new FileReader();
    let that = this;
    fileReader.onload = function (fileLoadedEvent) {
      that.srcData = fileLoadedEvent.target.result;
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  get f() { return this.orgForm.controls; }
  get c() { return this.cmOrgForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

}