import { Injectable } from '@angular/core';
import { ErrorModel, ErrorItems } from '../../models/error';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorCategorizeService {
  HTTP_CODES = {
    400: {
      selector: 'BAD_REQUEST',
      subtitle: 'Please try again.',
      icon: 'error-type-1.svg',
      buttonText: 'Ok',
      clickType: 'close'
    },
    401: {
      selector: 'UNAUTHORIZED',
      subtitle: 'Please try with valid access',
      icon: 'error-type-1.svg',
      buttonText: 'ok',
      clickType: 'close'
    },
    403: {
      selector: 'FORBIDDEN',
      subtitle: 'Please sign in to resume your session',
      icon: 'error-type-2.svg',
      buttonText: 'Sign in',
      clickType: 'navigate'
    },
    OTHER: {
      selector: 'OTHER',
      subtitle: 'Please try again later',
      icon: 'error-type-1.svg',
      buttonText: 'Ok',
      clickType: 'close'
    },
    INVALID_USER: {
      selector: 'INVALID_USER',
      subtitle: 'Please sign in with valid credentials',
      icon: 'error-type-2.svg',
      buttonText: 'ok',
      clickType: 'close'
    }
  };
  OTHER = 'OTHER';
  CLIENT_ERROR_TYPE = 'CLIENT';
  SERVER_ERROR_TYPE = 'SERVER';
  START_CLIENT_ERR_CODE = 400;
  END_CLIENT_ERR_CODE = 499;
  START_SERVER_ERR_CODE = 500;
  END_SERVER_ERR_CODE = 599;
  FORBIDDEN_CODE = 403;

  get http_codes(): any {
    return this.HTTP_CODES;
  }

  shouldRedirectUser(code): boolean {
    return code === this.FORBIDDEN_CODE;
  }

  getType(statusCode: number): string {
    let type = null;
    if (statusCode >= this.START_CLIENT_ERR_CODE && statusCode <= this.END_CLIENT_ERR_CODE) {
      type = this.CLIENT_ERROR_TYPE;
    } else if (statusCode >= this.START_SERVER_ERR_CODE && statusCode <= this.END_SERVER_ERR_CODE) {
      type = this.SERVER_ERROR_TYPE;
    } else {
      type = this.OTHER;
    }
    return type;
  }
  categorize(code, url): ErrorModel {
    const error = new ErrorModel(true, code);
    error.type = this.getType(code);
    error.items = this.getErrorItem(error.code, url);
    return error;
  }
  getErrorItem(code: number, url: string): ErrorItems {
    let item = this.HTTP_CODES[code] || this.HTTP_CODES.OTHER;
    if (code === this.FORBIDDEN_CODE && this.isAuthenticateApi(url)) {
      item = this.HTTP_CODES.INVALID_USER;
    }
    return item;
  }
  isAuthenticateApi(url: string) {
    const REGEX_LAST_PART = '[^/]+$';
    const AUTHENTICATE_API_END_WITH = 'ibLogin';
    const AUTHENTICATE_BACKEND_API_END_WITH = 'ldapLogin';
    return (url.match(REGEX_LAST_PART)[0] === AUTHENTICATE_API_END_WITH ||
      url.match(REGEX_LAST_PART)[0] === AUTHENTICATE_BACKEND_API_END_WITH);
  }
}
