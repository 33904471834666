import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 *  This service can be used to use local storage through out the application.
 *  As of now storage is set to SESSION STORAGE.
 */
export class StorageService {
  CURRENT_LOAN_APPLICATION_DATA_ID = 'CURRENT_LOAN_APPLICATION_DATA_ID';
  public INITIATOR_ID = 'INITIATOR_ID';
  public MAIN_APPLICANT_CIR = 'MAIN_APPLICANT_CIR';


  saveInLocal(key, value) {
    localStorage.setItem(key, value);
  }

  getFromLocal(key) {
    return localStorage.getItem(key);
  }

  clearLocal() {
    localStorage.clear();
  }

  removeFromLocal(key) {
    localStorage.removeItem(key);
  }

  getCurrentApplicationId(): string {
    return this.getFromLocal(this.CURRENT_LOAN_APPLICATION_DATA_ID);
  }

  saveCurrentApplicationId(applicationId: string) {
    this.saveInLocal(this.CURRENT_LOAN_APPLICATION_DATA_ID, applicationId);
  }
}
