<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12">
    <div class="your-story">
      <h3 class="title">{{ "yourInvestment.title" | translate }}</h3>
      <div class="icon-img">
        <img id="imgYourInvestment" src="assets/images/icon@2x.png" alt="alt" />
      </div>
      <h4 class="sub-title">{{ "yourGrowth.subTitle" | translate }}</h4>
      <p class="discp">
        {{ "yourInvestment.description" | translate }}
      </p>
      <button id="btnInvestmentStart" type="button" class="btn btn-secondary" data-toggle="modal" (click)="yourInvestment.show()"
        >
        {{ "buttons.getStarted" | translate }}
      </button>
    </div>
  </div>
</div>
<app-ui-modal #yourInvestment [hideFooter]="true" [dialogClass]="'modal-lg modal-dialog-centered investmentType-modal'"
  [containerClick]="true">
  <div class="app-modal-header">
    <h5 class="modal-title">Register as an Investor</h5>
    <button id="btnInvestmentClose" type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="yourInvestment.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="checks">
      <div class="custom-control custom-radio">
        <input id="radBusinessOwner" type="radio" name="investmentType" class="custom-control-input" />
        <label id="lblBusinessOwner" class="custom-control-label" for="businessOwner">
          <span>{{ "typeOfInvestment.option1" | translate }}</span></label>
      </div>
      <div class="options">
        <ng-select class="ng-select" [ngClass]="'ng-select'" [options]="investmentOption" [placeholder]="'Select Option'">
        </ng-select>
      </div>
      <div class="custom-control custom-radio">
        <input id="radInvestor" type="radio" name="investmentType" class="custom-control-input" />
        <label id="lblInvestor" class="custom-control-label"
          for="investor"><span>{{ "typeOfInvestment.option2" | translate }}</span></label>
      </div>
      <div class="custom-control custom-radio">
        <input id="radContractorHolder" type="radio" name="investmentType" class="custom-control-input" />
        <label id="lblContractorHolder" class="custom-control-label"
          for="contractorHolder"><span>{{ "typeOfInvestment.option3" | translate }}</span></label>
      </div>
      <div class="custom-control custom-radio">
        <input id="radAny" type="radio" name="investmentType" class="custom-control-input" />
        <label id="lblInvestmentType" class="custom-control-label" for="any"><span>{{ "typeOfInvestment.option4" | translate }}</span></label>
      </div>
    </div>
    <button id="btnInvestmentContinue" type="button" class="btn btn-primary mb-0" [routerLink]="['/investor/sme-search']">
      {{ "buttons.continue" | translate }}
    </button>
  </div>
</app-ui-modal>