import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { SearchService } from '../../core/services/search-service/search.service';
import { SelectOptionService } from '../../shared/components/select/select-option.service';
import { Search } from '../../core/Enums/search-fields';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';
import { IOption } from 'ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-search-services',
  templateUrl: './search-services.component.html',
  styleUrls: [
    './search-services.component.scss',
    '../../../assets/landing/css/style.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class SearchServicesComponent implements OnInit {

  @Input() type: string = "service";
  @Input() filterslbl;
  @Input() growthPartnersLbl;
  @Input() growthCardsLbl;
  @Input() gCardsfiltersLbl;

  @Input() browseproductcards: any;
  @Input() servicepageNumber: any;
  @Input() servicestotalItems: any;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  loading: boolean;
  searchItems: any;
  pageNumber: number = 1;
  pageSize: number = 9;
  totalItems = 0;
  categories: string = "All";
  categoryList: Array<IOption> = [];
  Search = Search;
  resultsDisplayTitle = "";
  growthcardItems: any;

  constructor(
    private searchService: SearchService,
    private selectOptionService: SelectOptionService,
    private serviceCardService: ServiceCardService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.categories = params.categories ? params.categories : "All";
      }
    );
    this.totalPages = 1;
    this.pageNumber = 1;
    if (this.type == Search.GROWTH_CARDS || this.type == Search.GROWTH_PARTNERS) {
      this.getUtilityCategories();
    } else {
      if (this.type == Search.PRODUCT) {
        this.selectOptionService.getProductCategories().subscribe((data: any) => {
          this.categoryList = data.map(v => {
            return { value: v.category, label: v.category };
          });
        });
      } else {
        this.selectOptionService.getServicecardCategories().subscribe((data: any) => {
          this.categoryList = data.map(v => {
            return { value: v.category, label: v.category };
          });
        });
      }
      // this.searchServices();
    }
  }
  getUtilityCategories() {
    this.serviceCardService.getAllUtilityCategories().subscribe(
      (data) => {
        this.categoryList = data.map(item => {
          return { label: item.categoryTitle, value: item.id } as IOption
        });
        this.searchgc();
      },
      (error) => {
        console.log(error);
      }
    );
  }


  totalPages = 1;
  // searchServices() {
  //   if (this.loading || this.pageNumber >= (this.totalPages + 1)) {
  //     return false;
  //   }
  //   this.loading = true;
  //   let action = this.type == Search.PRODUCT ? Search.SEARCH_PRODUCTS : (this.type == "service" ? Search.SEARCH_SERVICES : this.type);
  //   this.searchService[action](this.categories, this.pageNumber - 1, this.pageSize).subscribe(
  //     (data) => {
  //       // if (this.searchItems && Array.isArray(this.searchItems.content) && (this.type == Search.GROWTH_CARDS || this.type == Search.GROWTH_PARTNERS)) {
  //       //   this.searchItems.content = this.searchItems.content.concat(data.content);
  //       // } else {
  //       //   this.searchItems = data;
  //       //   window.scrollTo(0, 0);
  //       // }
  //       this.searchItems = data;
  //       window.scrollTo(0, 0);
  //       this.loading = false;
  //       this.totalItems = data.totalElements;
  //       this.totalPages = Math.ceil(this.totalItems / Constants.PAGE_LIMIT);
  //       let selectedCats = "";
  //       if (this.categories == "All") {
  //         selectedCats = this.categoryList.map(i => { return i.label }).join(",");
  //       } else {
  //         const selectedCatsIdsArray = this.categories ? this.categories.split(",") : [];
  //         selectedCats = this.categoryList.map(i => {
  //           return (this.categories ? ((selectedCatsIdsArray.indexOf(i.value + "") > -1) ? i.label : null) : i.label);
  //         }).filter(v => v !== null).join(",");
  //       }
  //       // if (this.type == Search.GROWTH_CARDS) {
  //       //   this.resultsDisplayTitle = "You're viewing growth cards for: " + selectedCats + " (" + this.totalItems + ")";
  //       //   this.pageNumber++;
  //       // } else if (this.type == Search.GROWTH_PARTNERS) {
  //       //   this.resultsDisplayTitle = "You're viewing service providers for: " + selectedCats + " (" + this.totalItems + ")";
  //       //   this.pageNumber++;
  //       // }
  //     },
  //     (error) => {
  //       this.searchItems = null;
  //       this.loading = false;
  //       // console.log(error);
  //     }
  //   );
  // }

  searchgc() {
    if (this.loading || this.pageNumber >= (this.totalPages + 1)) {
      return false;
    }
    this.loading = true;
    let action = this.type == Search.PRODUCT ? Search.SEARCH_PRODUCTS : (this.type == "service" ? Search.SEARCH_SERVICES : this.type);
    this.searchService[action](this.categories, this.pageNumber - 1, this.pageSize).subscribe(
      (data) => {
        this.growthcardItems.content = this.growthcardItems.content.concat(data.content);
        this.loading = false;
        this.totalItems = data.totalElements;
        this.totalPages = Math.ceil(this.totalItems / Constants.PAGE_LIMIT);
        let selectedCats = "";
        if (this.type == Search.GROWTH_CARDS) {
          this.resultsDisplayTitle = "You're viewing growth cards for: " + selectedCats + " (" + this.totalItems + ")";
          this.pageNumber++;
        } else if (this.type == Search.GROWTH_PARTNERS) {
          this.resultsDisplayTitle = "You're viewing service providers for: " + selectedCats + " (" + this.totalItems + ")";
          this.pageNumber++;
        }
      },
      (error) => {
        this.searchItems = null;
        this.loading = false;
        console.log(error);
      }
    );
  }

  goToPage() {
    let page: any = 0;
    if (page < 1) {
      page = 1;
    }
    this.changePage.emit(page);
  }

}
