import { Injectable } from "@angular/core";
import { Roles } from "../../Enums/roles";
import { Router } from "@angular/router";
import { Observable, of, BehaviorSubject } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { TokenService } from "../token/token.service";
import { StorageService } from "../storage/storage.service";
import { ClientInfoService } from "../client-info/client-info.service";
import { tap } from "rxjs/operators";
import { ActorType } from "../../Enums/actor-type";
import { Actor } from "../../restModels/loanApplicationRequest";
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  roles: string[] = [];
  tags: string[] = [];
  backendUsers: string[] = [
    Roles.ADMIN,
    Roles.COMMUNITY_MANAGER,
    Roles.INVESTOR,
    Roles.SERVICE_PROVIDER,
    Roles.SME
  ];
  jwt_token: string;
  force_reset_token: string;

  firstLogin = new BehaviorSubject<boolean>(false);

  isAdmin(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.ADMIN).length > 0;
  }

  isSME(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.SME).length > 0;
  }

  isServiceProvider(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.SERVICE_PROVIDER).length > 0;
  }

  isCommunityManager(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.COMMUNITY_MANAGER).length > 0;
  }

  isInvester(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.INVESTOR).length > 0;
  }

  isRelationShipManager(): boolean {
    return this.tokenService.getRoles().filter(r => r === Roles.RELATIONSHIP_MANAGER).length > 0;
  }

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();
    if (this.jwt_token) {
      const token = this.tokenService.getDecodedAccessToken(this.jwt_token);
      this.roles = this.rolesFromToken(token);
      this.tags = token.tags;
    }
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  MULTIROLE: string = 'MULTIROLE';

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result);
    };
  }

  authenticate(user: any): Observable<string> {
    let headers = null;
    if (user && user.captcha) {
      headers = new HttpHeaders({ 'X-CAPTCHA': user.captcha });
      delete user.captcha;
    }
    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/auth/signin`, user, { headers: headers })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  setUserRoleAndTagsFromToken(token: string) {
    const tokenInfo = this.tokenService.getDecodedAccessToken(token);
    this.roles = this.rolesFromToken(tokenInfo);
    this.tags = tokenInfo.tags;
  }

  logout(): Observable<boolean> {
    return this.http
      .delete(`auth/logout/`, { headers: this.tokenService.getHttpHeader() })
      .pipe(tap((appRef: any) => {
      }));
  }

  getRSAPublicKey() {
    return this.http.get(`auth/keys`, { responseType: 'text' }).pipe(tap((appRef: any) => {
    }));
  }

  saveToken(token) {
    this.tokenService.saveToken(token);
    this.setUserRoleAndTagsFromToken(token);
  }

  removeToken() {
    this.tokenService.removeToken();
  }

  saveMultiroleCheck(check) {
    this.storageService.saveInLocal(this.MULTIROLE, check);
  }

  rolesFromToken(tokenInfo) {
    return tokenInfo.roles;
  }

  logOutAndClearDataFromStorage(logOutUrl: string, component: any) {
    function clearData() {
      this.clearLocalData();
      this.location.go(logOutUrl);
      component.reload();
    }

    this.logout().subscribe(
      response => {
        clearData.call(this);
      },
      () => {
        clearData.call(this);
      }
    );
  }

  clearLocalData() {
    this.storageService.clearLocal();
    this.tokenService.setUserInfo(null);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  createCustomerNoParam(params: HttpParams, customerNo) {
    if (customerNo !== undefined && customerNo !== null) {
      params = params.set('customerNo', customerNo);
    }
    return params;
  }

  getRole(): Roles {
    let role: Roles;
    if (this.isAdmin()) {
      role = Roles.ADMIN;
    } else if (this.isSME()) {
      role = Roles.SME;
    } else if (this.isServiceProvider()) {
      role = Roles.SERVICE_PROVIDER;
    } else if (this.isInvester()) {
      role = Roles.INVESTOR;
    } else if (this.isCommunityManager()) {
      role = Roles.COMMUNITY_MANAGER;
    } else if (this.isRelationShipManager()) {
      role = Roles.RELATIONSHIP_MANAGER;
    }
    return role;
  }

  getTags() {
    return this.tags;
  }

  getCurrentActor(): Actor {
    const actor = new Actor();
    actor.username = this.tokenService.getUserName();
    actor.firstName = this.tokenService.getFirstName();
    actor.lastName = this.tokenService.getLastName();
    actor.type = ActorType.INITIATOR;
    return actor;
  }

  registerSME(userDetails: any, faceImg: any): Observable<string> {
    let endPoint = "register-sme";
    if (userDetails.role[0] == "SERVICE_PROVIDER")
      endPoint = "register-growth-partner";

    let payload = new FormData();
    if (faceImg) {
      endPoint = "register-uq-sme";
      delete userDetails.role;
      payload.append('data', JSON.stringify(userDetails));
      const binaryString = atob(faceImg);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      payload.append('faceImg', blob, "faceImg.jpg");
    }

    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/auth/` + endPoint, faceImg ? payload : userDetails, { responseType: 'text' })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  registerGP(userDetails: any, faceImg: any) {
    let endPoint = "register-growth-partner";
    let payload = new FormData();
    if (faceImg) {
      endPoint = "register-uq-growth-partner";
      delete userDetails.role;
      payload.append('data', JSON.stringify(userDetails));
      const binaryString = atob(faceImg);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      payload.append('faceImg', blob, "faceImg.jpg");
    }

    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/auth/` + endPoint, faceImg ? payload : userDetails, { responseType: 'text' })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  resendRegistrationToken(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get(`${this.BASE_API_URL}api/auth/resendRegistrationToken`,
      { params: params, responseType: 'text' }).pipe(tap((appRef: any) => { }));
  }

  verifyUserActivation(token: string) {
    return this.http.post(`${this.BASE_API_URL}api/auth/registrationConfirm`, { token: token },
      { responseType: 'text' }).pipe(tap((appRef: any) => { }));
  }

  resetPassword(userDetails: any): Observable<string> {
    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/auth/resetPassword`, userDetails, { responseType: 'text' })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  savePassword(userDetails: any): Observable<string> {
    const res: Observable<string> = this.http
      .post(`${this.BASE_API_URL}api/auth/savePassword`, userDetails, { responseType: 'text' })
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

  isSessionExpired(): boolean {
    const token = this.tokenService.getToken();
    return !token ? true : false;
  }

  getRoles(): any {
    const roles = this.tokenService.getRoles();
    if (Array.isArray(roles)) {
      return roles;
    }
    return [];
  }

  isTokenExpired(): boolean {
    const date = this.tokenService.getTokenExpirationDate();
    if (date === null || date === undefined) { return true; }
    return (date.valueOf() <= new Date().valueOf());
  }

  getAuthToken() {
    return this.tokenService.getToken();
  }

  getForceResetToken() {
    return this.force_reset_token;
  }

  saveForceResetToken(resetToken: string) {
    this.force_reset_token = resetToken;
  }

  forcedToReset(userInfo: any) {
    return userInfo && userInfo.forcedReset;
  }

  forcedPasswordReset(resetForm: any) {
    return this.http.post(`${this.BASE_API_URL}api/auth/forcedPasswordReset`, resetForm)
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }
  updatePassword(resetForm: any) {
    return this.http.post(`${this.BASE_API_URL}api/user/updatePassword`, resetForm)
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  refreshAuth() {
    return this.http.get(`${this.BASE_API_URL}api/auth/refresh`, { responseType: 'text' });
  }

  showCaptcha() {
    return this.http.get(`${this.BASE_API_URL}api/auth/showCaptcha`);
  }

  getBaseApiUrl() {
    return this.BASE_API_URL;
  }

  removeUserInfo() {
    this.tokenService.setUserInfo(null);
  }

  async getUserInfo() {
    const info = await this.fetchUserInfo();
    this.tokenService.setUserInfo(info);
    return info;
  }

  fetchUserInfo() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.BASE_API_URL}api/user/info`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  doInit() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isSessionExpired()) {
        this.getUserInfo().then((info) => {
          resolve();
        }).catch((info) => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  verifyGPEmail(userDetail: any) {
    return this.http.post(`${this.BASE_API_URL}api/auth/verify-gp-register`, userDetail)
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  signupGP(userDetail: any) {
    return this.http.post(`${this.BASE_API_URL}api/auth/signup-gp`, userDetail)
      .pipe(
        tap(
          (appRef: any) => { }
        )
      );
  }

  setFirstLogin(isFirstLogin: boolean) {
    this.firstLogin.next(isFirstLogin);
  }

}
