<div class="post-preview-img text-center">
    <img src="{{currentMyFeed.featuredImage?currentMyFeed.featuredImage:'assets/images/news/newsfeed-square.jpg'}}"
        id="imgPostPreview" class="img-fluid width-full" alt="alt">
</div>
<div class="post-preview">
    <h6 id="ttlContentDetails">{{currentMyFeed.title | translate}}</h6>
    <p>{{currentMyFeed.description | translate}}</p>

    <p *ngIf="currentMyFeed.feedUrl" class="mt-4">
        <a href="{{ currentMyFeed.feedUrl }}" target="_blank" id="URL {{currentMyFeed.id}}" rel="noopener">{{
            currentMyFeed.feedUrl }}</a>
    </p>
    <p *ngIf="currentMyFeed.documentUrl" class="mt-4"><a *ngIf="currentMyFeed?.documentUrl"
            href="{{ currentMyFeed?.documentUrl}}" id="{{currentMyFeed.documentUrl}}{{i+1}}" download="true">
            Download</a></p>
    <p *ngIf="currentMyFeed.videoUrl" class="mt-4"> <video width="100%" height="500" controls preload="auto"
            src="{{currentMyFeed.videoUrl}}">Your browser
            does not support the video tag.</video></p>
</div>
<div class="post-preview-footer">
    <div class="d-flex justify-content-between">
        <div *ngIf="showPostPreviewFooter">
            <h6 class="mainTtl" id="ttlPostDetails">Submitted Date</h6>
            <P>{{currentMyFeed.submittedDate}}</P>
        </div>
        <div *ngIf="showPostPreviewFooter && showinfo ">
            <h6 class="mainTtl" id="ttlPostDetails">Last Updated</h6>
            <p>{{ currentMyFeed.updatedTime | translate }}</p>
        </div>
        <div *ngIf="showPostPreviewFooter && showinfo">
            <h6 class="mainTtl" id="ttlStatus">{{ 'FeedMyContent.Status' | translate }}</h6>
            <p class="status">
                <span>{{ FeedStatus[currentMyFeed.reviewStatus] | translate }}</span>
            </p>
        </div>

        <div *ngIf="submittedby">
            <h6 class="mainTtl" id="ttlPostDetails">Submitted by</h6>
            <div class="align-middle  img-content submitted-by">
                <img src="{{currentMyFeed['companyLogo']?currentMyFeed.companyLogo:'assets/images/news/newsfeed-square.jpg'}}"
                    id="img{{ currentMyFeed.id }}" class="wid-70 align-top m-r-15 d-inline-block" alt="feed">
                <!-- <div class="d-inline-block break-all">
                        <h6 id="ttl{{ currentMyFeed.id }}">{{currentMyFeed.companyName}}</h6>
                        <span class="badge"
                            [ngClass]="{'entrepreneur': currentMyFeed.roleName == 'SME', 'growth-partner': currentMyFeed.roleName == 'SERVICE_PROVIDER', 'investor': currentMyFeed.roleName == 'INVESTOR'}">
                            <ng-container *ngIf="currentMyFeed.roleName == 'SME'">Entrepreneur</ng-container>
                            <ng-container *ngIf="currentMyFeed.roleName == 'SERVICE_PROVIDER'">Service
                                Provider</ng-container>
                            <ng-container *ngIf="currentMyFeed.roleName == 'INVESTOR'">Investor</ng-container>
                        </span>
                    </div> -->
            </div>
        </div>

        <div class="d-flex ratingforhelpfulness" *ngIf="showPostcomment">
            <div class="pointer" id="Helpful{{currentMyFeed.id}}">
                <em class="{{currentMyFeed.helpful?'fas':'far'}} fa-thumbs-up"></em>
                <span>{{currentMyFeed.totalHelpful}}</span>
            </div>
            <div class="pointer" id="UnHelpful{{currentMyFeed.id}}">
                <em class="{{currentMyFeed.unHelpful?'fas':'far'}} fa-thumbs-down"></em>
                <span>{{currentMyFeed.totalUnHelpful}}</span>
            </div>
        </div>


        <!-- <div>
            <app-feed-comment [feed]="currentMyFeed" [showComments]="currentMyFeed.toggle"
                [companyProfile]="companyProfile">
            </app-feed-comment>
        </div> -->

        <!-- <span (click)="helpful(currentMyFeed.id)" id="{{currentMyFeed?.rating?.HelpfulId}}" class="pointer ml-3">
            <em class="{{currentMyFeed.helpful?'fas':'far'}} fa-thumbs-up"></em>
            <span>{{currentMyFeed.totalHelpful}}</span>
          </span>
          <span (click)="unHelpful(currentMyFeed.id)" id="{{currentMyFeed?.rating?.UnhelpfulId}}" class="pointer ml-2">
            <em class="{{currentMyFeed.unHelpful?'fas':'far'}} fa-thumbs-down"></em>
            <span>{{currentMyFeed.totalUnHelpful}}</span>
          </span> -->
        <!-- <div class="currentMyFeed-btns mt-4" *ngIf="isCommunityManager">
                <h6 class="mainTtl" id="ttlStatus">Status / Action</h6>
                <div>
                    <button type="button" class="btn btn-secondary mr-3" (click)="onEditFeed();">
                        <em class="fas fa-edit mr-3"></em>
                        <span>Edit</span>
                    </button>
                    <button type="button" class="btn btn-secondary"
                        (click)="onStatusUpdated(currentMyFeed.id,'rejected')" *ngIf="request">
                        <em class="fas fa-times mr-3"></em>
                        <span>Reject</span>
                    </button>
                    <button type="button" class="btn btn-secondary"
                        (click)="onStatusUpdated(currentMyFeed.id,'rejected')" *ngIf="!request">
                        <em class="fas fa-times mr-3"></em>
                        <span>Delete</span>
                    </button>
                    <button type="button" class="btn btn-confirm float-right"
                        (click)="onStatusUpdated(currentMyFeed.id,'approved')" *ngIf="request">
                        <em class="fas fa-check  mr-3"></em>
                        <span>Publish</span>
                    </button>
                </div>
            </div> -->
        <br>
    </div>

    <div *ngIf="showPostcomment">
        <!-- <app-feed-comment [feed]="currentMyFeed" [showComments]="currentMyFeed.toggle"
            [companyProfile]="companyProfile">
        </app-feed-comment> -->
    </div>

</div>