<app-language-selector></app-language-selector>
<div class="row  mx-0">
  <div class="col-md-12">
    <div class="browse-benner" id="div{{ 'Browse.banner.id' | translate }}">
      <div class="row justify-content-md-center">
        <div class="col-md-9">
          <div class="left-block">
            <h3 id="ttl{{ 'Browse.banner.id' | translate }}">{{ 'Browse.banner.title' | translate }}</h3>
            <p id="p{{ 'Browse.banner.id' | translate }}">{{ 'Browse.banner.disc' | translate }}</p>
          </div>
          <button type="button" class="btn btn-confirm"
            id="btn{{ 'Browse.banner.id' | translate }}">{{ 'Browse.banner.button' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>