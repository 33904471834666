<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12 search-filter">
    <ul class="editKeyWord">
      <li class="labl">
        <h5 class="sub-title" id="lblTurnover">{{ "Turnover" | translate }}</h5>
        <div id="divTurnOver">
          <p-dropdown [options]="turnOverOptions" [(ngModel)]="turnover" id="selectTurnOver"
            placeholder="Select Turnover" [showClear]="true" class="ng-select" (onChange)="selectOptionChanged()">
          </p-dropdown>
        </div>
      </li>
      <li class="labl">
        <h5 class="sub-title" id="lblIndustry">{{ "Industry" | translate }}</h5>
        <div id="divIndustryType">
          <p-dropdown [options]="industryTypes" [(ngModel)]="industry" id="selctIndustryType"
            placeholder="Select Industry Type" [showClear]="true" class="ng-select" (onChange)="selectOptionChanged()">
          </p-dropdown>
        </div>
      </li>
      <li class="labl">
        <h5 class="sub-title" id="lblIncorporationYear">{{ "Incorporation Year" | translate }}</h5>
        <div id="divFinancialSupport">
          <p-dropdown [options]="incorporatedOptions" [(ngModel)]="incorporateDate" id="selctFinancialSupport"
            placeholder="Select Incorporation Year" [showClear]="true" class="ng-select"
            (onChange)="selectOptionChanged()">
          </p-dropdown>
        </div>
      </li>
    </ul>
  </div>
</div>