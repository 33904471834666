import { Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientInfo } from '../../models/client-info.model';
import { Constants } from '../../../shared/constants/constants';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class ClientInfoService {
  private clientInfo: ClientInfo;

  constructor(private http: HttpClient,@Inject(DOCUMENT) private document: Document) {}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructue
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  getIPAddress(): string {
    let ip = '';
    if (this.clientInfo && this.clientInfo !== null) {
      ip = this.clientInfo.ip;
    }
    return ip;
  }

  getDomainName(): string {
    return this.document.location.hostname;
  }

  getRootUrlForAPI(): string {
    /*if(environment.externalUrl.includes(this.getDomainName()) || this.getDomainName().includes(environment.externalUrl)){
      return environment.baseApiUrl
    } else*/ return ''
  }

  getKey(): string {
    return environment.key?environment.key:'hmacSMESecretKey'
  }

  fetchIpAddress(): void {
    this.http.get('https://' + Constants.IP_API_URL + '?format=json', {
      headers: new HttpHeaders({
        'Sec-Fetch-Dest': 'document'
      })
    }).pipe(
      tap((appRef: any) => {
      }),
      catchError(this.handleError<string>('fetchIpAddress'))
    ).subscribe((response: ClientInfo) => {
      this.clientInfo = response;
    });
  }
}
