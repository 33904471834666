<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-lg-6 col-xl-6 col-md-8" *ngFor="let item of HealthCheck; index as i">
    <app-card [hidHeader]="true" cardClass="user-card-full health-check-card" blockClass="p-0"
      id="{{item.cardId}}{{i+1}}">
      <img src="{{item?.image?item.image:'assets/images/investment.jpg'}}" id="{{ item?.imgId }}{{i+1}}"
        class="img-fluid card-img rounded-0" alt="alt">
      <div class="card-container">
        <h3 id="{{item.titleId}}{{i+1}}">{{item.name | translate}}</h3>
        <p>{{item.description | translate}}</p>
        <hr>
        <ul>

          <ng-container *ngIf="!(item.dateTaken && item.numberOfDaysBefore>0)">
            <li class="duration">
              <p>{{"Duration" | translate}}</p>
              <span>{{item.duration}}</span>

            </li>
            <li class="cost">
              <p>{{"Cost" | translate}}</p>
              <span>{{item.cost}}</span>
            </li>
            <li class="question">
              <p>{{"Questions" | translate}}</p>
              <span>{{ item.questionsAnswered ? (item.questionsAnswered+" / "+item.numberOfQuestions ) :
                item.numberOfQuestions}}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="item.dateTaken && item.numberOfDaysBefore>0">
            <li class="duration">
              <p>{{ "Date Taken" | translate }}</p>
              <span>{{item.dateTaken}}</span>
            </li>
            <li class="">
              <p>{{ "You can take the health check again in "+item.numberOfDaysBefore+" days" | translate }}</p>
            </li>
          </ng-container>
        </ul>
        <div class="row">
          <div class="col-md-12 col-lg-6" *ngIf="item?.score">
            <div class="overall-score">
              <p>{{"Overall Score" | translate}}</p>
              <div>{{item?.score?.toFixed() | translate}}</div>
            </div>
          </div>
          <div
            class="{{(item.score!=undefined && item.score>0)?'col-md-12 col-lg-6 text-right noHeightButton': 'col-md-12 text-center'}}">

            <button *ngIf="!(item.score!=undefined && item.score>0)" type="button" class="btn btn-primary w-75 "
              data-toggle="modal" (click)="onHealthCheckGetStarted(item)" id="btnGetStarted">
              {{item.hasSavedResponse?"Continue":"Get Started" | translate}}
            </button>
            <button *ngIf="(item.score!=undefined && item.score>0)" type="button"
              class="btn btn-primary mt-4 btnViewReport" data-toggle="modal" (click)="onHealthCheckViewScore(item)"
              id="btnViewReport">
              {{"View Report" | translate}}
            </button>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>