<app-language-selector></app-language-selector>
<div class="sme-profile">
  <div [ngClass]="{'content-viewall':(resultsDisplayTitle)}">

    <h5 *ngIf="resultsDisplayTitle" class="mb-4">
      <ng-container *ngIf="resultsDisplayTitle">{{resultsDisplayTitle}}</ng-container>
    </h5>
    <ng-container>
      <div class="row">
        <div
          [ngClass]="router.url.includes('/search-results') ? 'col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4' : 'col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-4'"
          *ngFor="let item of BrowseCards; index as i">
          <app-card [customHeader]="true" headerClass="border-0 p-0 pb-0" [options]="false" cardClass="browse-card"
            blockClass="pt-0 users-contact" [isCardFooter]="false" id="appCard{{i+1}}"
            [ngClass]="{'browse-service-card' : (listType == 'product' || listType == 'companies' || listType == 'service')}"
            [routerLink]="(listType == 'companies' && fromSearch)?getLink(item.userId? item.userId : item.user_id , item.userRole ? item.userRole : item.name ):getMyPage()"
            [queryParams]="getQueryParams()">

            <div class="body-right card"
              [ngClass]="{'comapany-bg': listType != 'companies', 'company': listType == 'companies'}"
              id="divCard{{i+1}}">
              <div class="card-content">
                <div class="content card-body">
                  <ng-container *ngIf="BrowseGrowthCards ">
                    <div class="browse-growth-cards-block" *ngIf="item?.growthCardTitle">
                      <div class="card-icon-block">
                        <h6 id="pTagCard{{i+1}}" class="mb-1 title pointer"
                          (click)="growthcardDetails(item?.growthCardId, item?.growthCardTitle)">
                          <span>
                            {{ item?.growthCardTitle}}
                          </span>
                        </h6>

                        <div class="app-card-header" [ngClass]="{'growth-card-badge': item.isGrowthCard}">
                          <div class="cover-img-block">
                            <img *ngIf="listType != 'companies' && listType !='searchGrowthPartners'"
                              src="{{item.image?item.image:'assets/images/service.png'}}" alt="logo"
                              class="img-fluid pointer" [ngClass]="{'company-logo': listType == 'companies'}"
                              id="imgCard{{i+1}}"
                              (click)="growthcardDetails(item?.growthCardId, item?.growthCardTitle)">
                            <img *ngIf="listType == 'companies' || listType=='searchGrowthPartners'"
                              src="{{item.logo?item.logo:'assets/images/complogo.png'}}" alt="logo" class="img-fluid"
                              [ngClass]="{'company-logo': listType == 'companies'}" id="imgCard{{i+1}}">
                          </div>
                        </div>

                        <div class="d-flex align-items-center company-logo companypage">
                          <img [id]="'imgcompanylogo' + componentId" src="{{item.growthPartnerLogo}}" alt="logo"
                            class="img-company-logo" *ngIf="item.growthPartnerLogo">
                          <h3 class="browsecompany m-0 mx-2" id="ttlCompanyCard{{i+1}}">
                            {{item.growthPartnerName}}
                          </h3>
                        </div>


                        <div class="d-flex card-data mt-2 mx-2">
                          <div class="mr-3">
                            <p>
                              <em class="fas fa-suitcase mr-1"></em>
                              <span>{{item.costEstimate ? item.costEstimate : item.cost_estimate }} <span>ZAR</span>
                              </span>
                            </p>
                          </div>

                          <div>
                            <p>
                              <em class="fas fa-calendar-alt mr-1"></em>
                              <span>{{item.timeEstimate ? item.timeEstimate : item.time_estimate}}</span>
                            </p>
                          </div>
                        </div>

                        <div class="grouplist" *ngIf="item?.groupsData">
                          <li *ngFor="let imageUrl of item.groupsData">
                            <img [src]="imageUrl?.groupImg" alt="Image">
                          </li>
                        </div>

                        <div class="d-flex justify-content-between align-items-center bottom-data">
                          <div class="company-logo">
                            <!-- <a [id]="'btn' + componentId + 'Details'" class="waves-effect waves-light pointer"
                              (click)="getMoreDetails(item?.serviceDescriptionUrl, item?.growthCardId)">More Details</a> -->
                          </div>
                          <div *ngIf="item?.isOwnGrowthCard !== true">
                            <!-- <button class="btn btn-primary intrestedbutton" type="button" id="btnBookService"
                              *ngIf="!item.booked && !isRM" (click)="doBooking($event)">
                              <em class="fas fa-envelope mx-2"></em>
                              <span class="content-h">{{buttons}}</span></button> -->

                            <a *ngIf="!item.booked && !isRM" class="intrested pointer" (click)="doBooking($event)"><em
                                class="fas fa-envelope mr-1"></em>{{buttons}}</a>

                            <p *ngIf="item.booked && !isRM" class="mt-1 booked"><em
                                class="fas fa-check mr-1"></em>{{'Booked'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!BrowseGrowthCards">
                    <em *ngIf="listType == 'service'"></em>

                    <div class="contents">
                      <div *ngIf="listType == 'companies'" class="bycompany">
                        <!-- <img src="{{item.logo ? item.logo : '../../../../../../assets/images/default.jpg' }}" alt=""> -->
                        <img [src]="isHttpLogo(item.logo) ? item.logo : '../../../../../../assets/images/default.jpg'"
                          alt="">
                      </div>

                      <h6 id="pTagCard{{i+1}}" *ngIf="listType == 'product'" class="title">
                        {{item.serviceName}}
                      </h6>

                      <h6 id="pTagCard{{i+1}}" *ngIf="listType == 'service'" class="title">
                        {{item.productName}}
                      </h6>

                      <h6 id="pTagCard{{i+1}}" class="submitted-by pointer" style=""
                        *ngIf="listType == 'companies' || listType=='searchGrowthPartners'">
                        <span>{{item.companyName}}</span>
                        <img *ngIf="item?.verifiedCompany == true" style="width: 20px; margin: 0px 10px;"
                          src="../../../../assets/images/auth-company-badge.svg" alt="">
                      </h6>
                      <p id="ttlCard{{i+1}}" class="tag" *ngIf="item.industry" style="    font-size: 16px;;">
                        {{item.industry}}
                      </p>

                      <p id="pCard{{i+1}}" *ngIf="item?.about" style="height: 100px;">
                        {{item.about| slice:0:150 }}<ng-container *ngIf="item?.about.length > 150">...</ng-container>
                      </p>

                      <div class="app-card-header">
                        <div class="cover-img-block" *ngIf="listType == 'service'">
                          <img
                            src="{{item.image ? item.image : (item.featured_image ? item.featured_image : '../../../../../../assets/images/default.jpg')}}"
                            alt="logo" class="img-fluid">
                        </div>

                        <div class="cover-img-block" *ngIf="listType == 'product'">
                          <img
                            src="{{item.image ? item.image :  (item.featured_image ? item.featured_image :'../../../../../../assets/images/default.jpg')}}"
                            alt="logo" class="img-fluid">
                        </div>
                      </div>

                    </div>
                  </ng-container>

                  <div class="d-flex justify-content-between my-2"
                    *ngIf="listType == 'service' || listType == 'product'">
                    <div class="company" id="divLogoCard{{i+1}}" *ngIf="!item.companyLogo">
                      {{item.companyName?.trim().slice(0,1) }}
                    </div>

                    <div *ngIf="item.companyLogo" class="d-flex align-items-center pointer companypage">
                      <img src="{{item.companyLogo}}" class="logo img-fluid" alt="alt" />
                      <h3 class="browsecompany" id="ttlCompanyCard{{i+1}}" [ngClass]="{'mb-0 ml-2' : fromSearch}"
                        [routerLink]="getLink(item.userId, item.userType)"
                        *ngIf="fromSearch && !BrowseGrowthCards && !BrowseGrowthPartners">
                        {{item.companyName}}
                        <img style="width: 20px; margin: 0px 10px;" *ngIf="item?.verifiedCompany == true"
                          src="../../../../assets/images/auth-company-badge.svg" alt="">
                      </h3>
                    </div>
                  </div>


                  <ng-container *ngIf="!isSeeAllRoutes">
                    <div class="d-flex align-items-center my-2 mx-1"
                      *ngIf="listType == 'service' || listType == 'product'">
                      <div class="mur-cost mr-3">
                        <p class="d-flex align-items-center" id="ttlMURCard{{i+1}}" *ngIf="listType === 'product'">
                          <em class="fas fa-suitcase mr-1"></em>
                          {{getPrice(item.priceEstimate ? item.priceEstimate : item.cost_estimate)}}

                          <span id="pMURCard{{i+1}}"
                            *ngIf="listType == 'product' && showServiceCurrency(item.priceEstimate) || (item.cost_estimate)">
                            ZAR
                          </span>
                        </p>
                        <p class="d-flex align-items-center" id="ttlMURCard{{i+1}}" *ngIf="listType === 'service'">
                          <em class="fas fa-suitcase mr-1"></em>
                          {{getProductPrice(item.price)}}
                          <span id="pMURCard{{i+1}}"
                            *ngIf="listType=='service' && showProductCurrency(item.price)">ZAR</span>
                        </p>
                      </div>

                      <div *ngIf="item?.serviceName">
                        <div class="">
                          <div>
                            <p id="ttlWeekCard{{i+1}}">
                              <em class="fas fa-calendar-alt mr-1"></em>
                              {{formatTimeEstimate(item.timeEstimate ? item.timeEstimate : item.time_estimate)}}
                              <span id="pWeekCard{{i+1}}">{{getTimeEstimate(item.timeEstimate? item.timeEstimate :
                                item.time_estimate)}}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="company-name bookingservice d-flex justify-content-end">
                    <span class="bookingbtn">
                      <a href="javascript:void(0);" class="intrested pointer"
                        *ngIf="(router.url.includes('/search-results') ? (listType == 'service' && item?.bookingStatus == 0) : (listType == 'service' && item.isOwnService != true  && !item?.booked))"
                        (click)="onBookBtnClickproducts(item)"> I'm Interested</a>
                      <p
                        *ngIf="(router.url.includes('/search-results') ? (listType == 'service' && item?.bookingStatus != 0) : (listType == 'service' && item.isOwnService != true && item?.booked))">
                        Booked</p>
                      <a href="javascript:void(0);" class="intrested pointer"
                        *ngIf="(router.url.includes('/search-results') ? (listType == 'product' && item?.bookingStatus == 0) : (listType == 'product' && item.isOWnProduct != true))"
                        (click)="onBookBtnClick(item)">I'm Interested</a>
                      <p
                        *ngIf="(router.url.includes('/search-results') ? (listType == 'product' && item?.bookingStatus != 0) : (listType == 'product' && item.isOwnService != true && item?.booked))">
                        Booked</p>
                    </span>
                  </div>
                </div>
              </div>



              <div class="card-right-block" *ngIf="BrowseGrowthPartners">
                <button type="button" class="btn btn-primary" id="btnRequestAppointment{{i+1}}"
                  [routerLink]="getLink(item.userId, item.userType?item.userType:item.userRole)">View Page</button>
              </div>
              <!-- <div class="card-right-block" *ngIf="BrowseGrowthCards && !fromSearch"> -->
              <!-- <button type="button" *ngIf="!item.booked && !isRM" class="btn btn-primary"
                  id="btnRequestAppointment{{i+1}}" (click)="onBookBtnClick(item)">Send Request</button> -->
              <!-- <button type="button" class="btn btn-link" id="btnMoreDetails{{i+1}}">
            <a [id]="'btn' + componentId + 'Details'" class="btn waves-effect waves-light btn-link pt-2"
              (click)="getMoreDetails(item.serviceDescriptionUrl, item.growthCardId)">More Details</a>
          </button> -->
              <!-- </div> -->
            </div>
          </app-card>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<p-toast key="app" position="top-center" [baseZIndex]="9999999" id="pAppToastMsg"></p-toast>
<p-dialog header="" [(visible)]="updateBRNPopup" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal product-card-modal"
  [maximizable]="false" [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true"
  [resizable]="true">
  <div class="app-modal-body brn-modal">
    <app-brn-form (closeModal)="updateBRNPopup=false;" (continueBooking)="BRNUpdated($event)"></app-brn-form>
  </div>
</p-dialog>