import { Component, OnInit, Input } from '@angular/core';
import { FieldMetaData, FieldSourceModel, TextAreaFieldMetaData, TextFieldMetaData } from '../../core/models/field-meta-data';
import { FormGroup, FormControl } from '@angular/forms';
import { FileUploadControl } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() options: any = {
    emailField: {},
    descriptionField: {},
    companyField: {},
    websiteField: {},
    phoneField: {}
  };
  @Input() contactForm: FormGroup;
  @Input() submitted:boolean;
  formFields: FieldMetaData[] = [];
  emailField: any;
  companyField: any;
  websiteField: any;
  phoneField: any;
  descriptionField: any;
  uploadText = '';
  public animation: boolean = false;
  public fileUploadControl = new FileUploadControl().setListVisibility(false);

  constructor() { }

  ngOnInit(): void {
    if(!this.contactForm) {
      this.contactForm = new FormGroup({});
    }
    this.buildFields();
    if (this.options.emailField.label != 'commonForm.title.title')
      this.uploadText = (this.options.emailField.label == ('shareDocument.title.title')) ? 'Upload a document' : 'Upload a video';
  }

  buildFields() {
    this.emailField = new TextFieldMetaData(
      this.contactForm.controls["email"] as FormControl,
      this.options.emailField.id,
      this.options.emailField.label,
      this.options.emailField.placeholderText,
      'email',
      this.options.emailField.validMsg,
      this.options.emailField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.emailField.type
    );
    this.descriptionField = new TextAreaFieldMetaData(
      this.contactForm.controls["address"] as FormControl,
      this.options.descriptionField.id,
      this.options.descriptionField.label,
      this.options.descriptionField.placeholderText,
      'description',
      this.options.descriptionField.validMsg,
      this.options.descriptionField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      this.options.descriptionField.type
    );
    this.companyField = new TextFieldMetaData(
      this.contactForm.controls["linkedin"] as FormControl,
      this.options.companyField.id,
      this.options.companyField.label,
      this.options.companyField.placeholderText,
      'company',
      this.options.companyField.validMsg,
      this.options.companyField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.companyField.type
    );
    this.websiteField = new TextFieldMetaData(
      this.contactForm.controls["website"] as FormControl,
      this.options.websiteField.id,
      this.options.websiteField.label,
      this.options.websiteField.placeholderText,
      'website',
      this.options.websiteField.validMsg,
      this.options.websiteField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.websiteField.type
    );
    this.phoneField = new TextFieldMetaData(
      this.contactForm.controls["contactNo"] as FormControl,
      this.options.phoneField.id,
      this.options.phoneField.label,
      this.options.phoneField.placeholderText,
      'phone',
      this.options.phoneField.validMsg,
      this.options.phoneField.required,
      FieldSourceModel.APPLICANT_DETAIL,
      null,
      true,
      this.options.phoneField.type
    );
  }

}
