<app-language-selector></app-language-selector>
<div [ngClass]="className ? 'gridLayout' : 'listLayout'">
  <div class="row">
    <div [ngClass]="className == 'gridLayout' ? 'col-md-6' : 'col-md-12'" *ngFor="let item of newsFeed; index as i">
      <!-- <em class="far fa-trash-alt pointer" *ngIf="isCommunityManager" (click)="handleDeleteClick()"></em> -->

      <app-feed-card id="app{{ item?.id }}{{i+1}}" imgIcon="{{ item?.companyLogo?item.companyLogo:'' }}"
        cardTitle="{{ item.companyName?item.companyName:'Partner Post: KPMG Small Business Services' | translate }}"
        cardCaption="{{ getRelativeTime(item.updatedTime) }}" userPicId="companyLogo{{i+1}}" cardClass="news-card"
        blockClass="p-0" [options]="false">
        <div class="feed-content-new">

          <div class="feed-media-container pointer" (click)="currentMyFeed=item ;showDetail(item.id)">
            <div class="feed-pic text-center" *ngIf="item?.topic != 'video'">
              <img id="{{item.imgId}}{{i+1}}"
                src="{{item?.featuredImage?item.featuredImage:'assets/images/news/newsfeed.jpg'}}"
                class="card-img rounded-0" alt="alt">
            </div>
            <div class="show-video" *ngIf="item?.topic == 'video' && item?.featuredImage && !item.clickedPlayButton"
              (click)="currentItem=item;sanitizeUrl(item.feedUrl);updatePlayButtonClicked(item.id);">
              <img id="{{item.featuredImage}}{{i+1}}"
                src="{{ item?.featuredImage?item.featuredImage:'assets/images/news/newsfeed.jpg'}}"
                class="card-img rounded-0" data-toggle="modal" alt="alt">
              <div class="show-video-play-icon">&nbsp;</div>
            </div>

            <div class="embed-responsive embed-responsive-16by9"
              *ngIf="item?.topic == 'video' && (!item.featuredImage || item.clickedPlayButton)">
              <div *ngIf="item?.topic == 'video' && getYoutubeId(item.feedUrl)">
                <app-custom-youtube feedTitle="{{item.title}}" youtubeId="{{getYoutubeId(item.feedUrl)}}"
                  playButtonPressed="{{item.clickedPlayButton}}"></app-custom-youtube>
              </div>
              <video *ngIf="item?.topic == 'video' && !getYoutubeId(item.feedUrl)" width="100%" height="500" controls
                preload="auto" [src]="item.videoUrl">
                Your browser does not support the video tag.
              </video>
            </div>

            <div class="card-header" *ngIf="className == 'gridLayout'">
              <h5 class="card-title" id="ttl{{ item?.id }}{{i+1}}">
                <span>{{ item?.title | translate }}</span>
              </h5>
            </div>

            <div *ngIf="className == 'gridLayout'">
              <p>{{item?.description}}</p>
            </div>

          </div>

          <div class="card-cntent feed-content-block" *ngIf="className != 'gridLayout'">
            <div class="card-header">
              <h5 class="card-title" id="ttl{{ item?.id }}{{i+1}}">
                <span>{{ item?.title | translate }}</span>
                <span class="updatedTime">{{ getRelativeTime(item.updatedTime) }}</span>
              </h5>
            </div>

            <p class="card-text">{{ item?.description | translate }}</p>

            <div class="d-flex justify-content-between mb-2 mt-2">
              <div class="youtubeLink" *ngIf="className != 'gridLayout'">
                <a *ngIf="item?.topic != 'video'" href="{{ item?.feedUrl }}" target="_blank" rel="noopener"
                  id="{{item.urlId}}{{i+1}}">
                  {{item?.feedUrl | slice:0:50}}
                </a>
                <a *ngIf="item?.topic == 'video'" href="javascript:void(0)" id="{{item.urlId}}{{i+1}}"
                  (click)="showDialog(item)">{{
                  item?.feedUrl| slice:0:50}}</a>
                <span *ngIf="item?.feedUrl?.length>50">...</span>
              </div>
              <div class="text-right downloads" (click)="logViewEvent(item.title)" *ngIf="className != 'gridLayout'">
                <a *ngIf="!item?.documentUrl && item?.feedUrl && item.topic!='video'" target="_blank" rel="noopener"
                  href="{{ item?.feedUrl}}" id="{{item?.urlMoreId}}{{i+1}}" class="btn btn-primary">{{ 'Read Now' |
                  translate }}</a>

                <a *ngIf="item?.documentUrl" href="{{ item?.documentUrl}}" id="{{item.documentUrl}}{{i+1}}"
                  rel="noopener" target="_blank"><em class="far fa-file-pdf"></em>{{ item?.download | translate }}</a>
                <a *ngIf="item?.documentUrl" href="{{ item?.documentUrl}}" id="{{item.documentUrl}}{{i+1}}"
                  download="true" class="btn btn-primary">{{
                  'Download Now' | translate }}</a>
              </div>
            </div>


            <div class="groups-list">
              <ng-container *ngFor="let group of item?.groupsData; let i = index">
                <div class="d-flex group-block pointer" (click)="groupredirection(group?.groupId)">
                  <img class="group-img" src="{{group?.groupImg}}" alt="group_image">
                  <div class="group-info">
                    <p class="group-name">{{ group?.groupName.trim().slice(0,20) }}
                      <ng-container *ngIf="group?.groupName?.length>20">...</ng-container>
                    </p>
                    <div>
                      <div></div>
                      <p>
                        <i class="fas fa-users"></i>
                        {{ group?.membersCount}} members
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

          </div>
          <div class="card-cntent">
            <h5 class="company-name">

              <span class="feed-company-logo"
                *ngIf="item?.companyLogo ==''">{{item.companyName?.trim().slice(0,1)}}</span>
              <span class="feed-company-logo"
                *ngIf="item?.roleName =='COMMUNITY_MANAGER' && item?.companyLogo == null ">{{item.companyName?.trim().slice(0,1)}}</span>
              <span class="imageFluid m-0 pointer" *ngIf="item?.companyLogo && item?.companyLogo !=''"
                (click)="comapanyredirection(item.companyId , item.roleName)">
                <img src="{{ item?.companyLogo?item.companyLogo:'' }}" id="{{userPicId}}" class="img-fluid"
                  alt="logo" />
              </span>

              <span class="company_name">
                <span class="c-name">
                  <span class="c-title mx-2 pointer" (click)="comapanyredirection(item.companyId , item.roleName)">
                    {{ item.companyName?item.companyName:'Partner Post: KPMG Small Business Services' | translate }}

                    <img class="comapany-verification" *ngIf="item?.verifiedCompany == true"
                      src="../../../../assets/images/auth-company-badge.svg" alt="">
                  </span>
                  <span class="helpFul-rating" *ngIf="className != 'gridLayout'">
                    <span class="feed-cmnt">
                      <span *ngIf="item.totalComments" (click)="toggleFeedComment(item)">
                        <em class="fas fa-comment-dots pointer"></em>
                        <span>{{item.totalComments}}</span>
                      </span>
                      <span *ngIf="!item.totalComments">No comments yet</span>
                    </span>
                    <span (click)="helpful(item.id)" id="{{item?.rating?.HelpfulId}}" class="pointer ml-2">
                      <em class="{{item.helpful?'fas':'far'}} fa-thumbs-up"></em>
                      <span>{{item.totalHelpful}}</span>
                    </span>
                    <span (click)="unHelpful(item.id)" id="{{item?.rating?.UnhelpfulId}}" class="pointer ml-2">
                      <em class="{{item.unHelpful?'fas':'far'}} fa-thumbs-down"></em>
                      <span>{{item.totalUnHelpful}}</span>
                    </span>
                  </span>
                </span>
                <span class="company_role mx-2">
                  <p *ngIf="item?.roleName == 'SME'">Entrepreneur</p>
                  <p *ngIf="item?.roleName == 'COMMUNITY_MANAGER'">Community manager</p>
                  <p *ngIf="item?.roleName == 'SERVICE_PROVIDER'">Service provider</p>
                  <p *ngIf="item?.roleName == 'INVESTOR'">Investor</p>
                </span>
              </span>
            </h5>
            <div class="row mt-4" *ngIf="className != 'gridLayout'">
              <div class="col-md-12">
                <app-feed-comment [feed]="item" [showComments]="item.toggle" [companyProfile]="companyProfile">
                </app-feed-comment>
              </div>
            </div>
          </div>

        </div>
      </app-feed-card>
    </div>
  </div>
</div>