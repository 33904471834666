import { Injectable } from '@angular/core';

import { Roles } from "../../../core/Enums/roles";

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

const NavigationItems = [
    {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'feather icon-monitor',
        allowed_roles: ['*'],
        children: [
            {
                id: 'commonFeed',
                title: 'Newsfeed',
                type: 'item',
                url: '/feed',
                classes: 'nav-item',
                icon: 'fas fa-rss-square',
                allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
            },
            {
                id: 'CmFeed',
                title: 'Feed',
                type: 'item',
                url: '/feed',
                icon: 'fas fa-rss-square  ',
                allowed_roles: [Roles.COMMUNITY_MANAGER],
                /* children: [
                    {
                        id: 'commonFeed',
                        title: 'News Feed',
                        type: 'item',
                        url: '/feed',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    },
                    {
                        id: 'Requests',
                        title: 'Requests',
                        type: 'item',
                        url: '/cm-feed',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    },
                    {
                        id: 'Published',
                        title: 'Published',
                        type: 'item',
                        url: '/published-feed',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    }
                ] */
            },
            {
                id: 'Forum',
                title: 'Forum',
                type: 'item',
                url: '/forum',
                classes: 'nav-item',
                icon: 'far fa-comment-alt',
                allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.COMMUNITY_MANAGER, Roles.RELATIONSHIP_MANAGER]
            },
            {
                id: 'Browse',
                title: 'Browse',
                type: 'item',
                url: '/user-browse',
                icon: 'fas fa-search',
                allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER],
                /* children: [
                    {
                        id: 'Entrepreneurs',
                        title: 'Entrepreneurs',
                        type: 'item',
                        url: '/user-browse',
                        classes: 'nav-item',
                        allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                    },
                    {
                        id: 'GrowthPartners',
                        title: 'Service Providers',
                        type: 'item',
                        url: '/growth-partners',
                        classes: 'nav-item',
                        allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                    },
                ] */
            },
            {
                id: 'commonResources',
                title: 'Resources',
                type: 'item',
                url: '/resources',
                classes: 'nav-item',
                icon: 'fas fa-file-alt',
                allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER, Roles.COMMUNITY_MANAGER,]
            },
            {
                id: 'MyGrowth',
                title: 'My Growth',
                type: 'item',
                url: '/growth-cards',
                icon: 'fas fa-chart-line',
                allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER],
                /* children: [
                    {
                        id: 'Growthcard',
                        title: 'Growth Cards',
                        type: 'item',
                        url: '/growth-cards',
                        classes: 'nav-item',
                        allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                    },
                    {
                        id: 'HealthChecks',
                        title: 'Health Checks',
                        type: 'item',
                        url: '/my-growth/health-checks',
                        classes: 'nav-item',
                        allowed_roles: [Roles.SME, Roles.RELATIONSHIP_MANAGER]
                    },
                    {
                        id: 'GrowthPlans',
                        title: 'Growth Plans',
                        type: 'item',
                        url: '/my-growth/growth-plans',
                        classes: 'nav-item',
                        allowed_roles: [Roles.SME, Roles.RELATIONSHIP_MANAGER]
                    }
                ] Kiran*/
            },
            {
                id: 'Opportunities',
                title: 'Opportunities',
                type: 'item',
                url: '/opportunities',
                classes: 'nav-item',
                icon: 'fas fa-briefcase',
                allowed_roles: [Roles.INVESTOR]
            },
            {
                id: 'Admin',
                title: 'Admin',
                type: 'item',
                url: '/admin/users',
                icon: 'fas fa-seedling',
                allowed_roles: [Roles.COMMUNITY_MANAGER],
                /* children: [
                    {
                        id: 'Users',
                        title: 'Users',
                        type: 'item',
                        url: '/admin/users',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    }
                ] */
            },
            {
                id: 'growthCards',
                title: 'Growth Cards',
                type: 'item',
                url: '/cm-growthcard-request',
                icon: 'fas fa-chart-line',
                allowed_roles: [Roles.COMMUNITY_MANAGER],
                /* children: [
                    {
                        id: 'Requests',
                        title: 'Requests',
                        type: 'item',
                        url: '/cm-growthcard-request',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    },
                    {
                        id: 'Published',
                        title: 'Published',
                        type: 'item',
                        url: '/cm-growth-cards',
                        classes: 'nav-item',
                        allowed_roles: [Roles.COMMUNITY_MANAGER]
                    }
                ] */
            },
            // {
            //     id: 'UtilityCategories',
            //     title: 'Utility Categories',
            //     type: 'item',
            //     url: '/utility-categories',
            //     classes: 'nav-item',
            //     icon: 'fas fa-list',
            //     allowed_roles: [Roles.COMMUNITY_MANAGER]
            // },
            {
                id: 'manageYourBusiness',
                title: 'Manage Your Business',
                type: 'item',
                url: '/manage-your-business',
                classes: 'nav-item',
                icon: 'fas fa-business-time',
                allowed_roles: [Roles.SERVICE_PROVIDER, Roles.SME, Roles.COMMUNITY_MANAGER]
            },
            {
                id: 'commonBookings',
                title: 'Bookings',
                type: 'item',
                url: '/bookings',
                classes: 'nav-item',
                icon: 'fas fa-file-invoice',
                allowed_roles: [Roles.SERVICE_PROVIDER, Roles.SME]
            },
            {
                id: 'groups',
                title: 'Groups',
                type: 'item',
                url: '/groups',
                classes: 'nav-item',
                icon: 'fas fa-users',
                allowed_roles: [Roles.SERVICE_PROVIDER, Roles.SME]
            },
            {
                id: 'smeStats',
                title: 'Stats',
                type: 'item',
                url: '/sme-stats',
                classes: 'nav-item',
                icon: 'far fa-sticky-note',
                allowed_roles: [Roles.SME]
            },
            {
                id: 'aiAdvisor',
                title: 'AI Advisor',
                type: 'item',
                url: '/ai-advisor',
                classes: 'nav-item',
                icon: 'fas fa-robot',
                allowed_roles: [Roles.SME]
            }
        ]
    }
];

@Injectable()
export class NavigationItem {
    public get() {
        return NavigationItems;
    }
}
