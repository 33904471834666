import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../client-info/client-info.service';
import { StorageService } from '../storage/storage.service';
import { TokenService } from '../token/token.service';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Constants } from 'src/app/shared/constants/constants';
@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  jwt_token: string;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  getHealthCheckContent(healthCheckId): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/getHealthCheckContent?healthCheckId=` + healthCheckId, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getSavedUserResponses(healthCheckId): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/getSavedUserResponses?healthCheckId=` + healthCheckId, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getHealthChecks(PageNumber): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/getHealthChecks?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + PageNumber, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  saveUserResponse(healthCheckAnswers): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/saveUserResponses`, healthCheckAnswers)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  submitAnswers(healthCheckAnswers): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/submitAnswers`, healthCheckAnswers)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  viewReport(healthCheckId): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/health-check/viewReport?healthCheckId=` + healthCheckId, {})
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }

}
