import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { ServiceCardService } from 'src/app/core/services/service-card-service/service-card.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-score-service-card',
  templateUrl: './score-service-card.component.html',
  styleUrls: ['./score-service-card.component.scss']
})
export class ScoreServiceCardComponent implements OnInit {
  responsiveOptions: any;
  public serviceCardData: any;
  @Input() healthCards: any;

  constructor(
    private commonService: CommonService,
    private serviceCardService: ServiceCardService,
    private messageService: MessageService,
    private utility: UtilityService,
    private gaService: GoogleAnalyticsService
  ) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.serviceCardData = res.servicesCard.data;
      });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
  }
  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }
  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }
  onBookBtnClick(service: any) {
    if (service.booking || service.booked) {
      return false;
    }
    service.booking = true;
    this.gaService.logCustomEvent("Booked", "Growth Cards", service.name);
    this.serviceCardService.bookService(service.serviceId).subscribe(
      data => {
        service.booked = true;
        service.booking = false;
        if (service.growthCard) {
          this.toastMessage("Book Growth Card", "A request has been successfully sent to the Service Provider", "success");
        }
        else {
          this.toastMessage("Book Service", "Service booked successfully", "success");
        }
      },
      error => {
        service.booking = false;
        if (error.error.toLowerCase().indexOf("brn") > -1) {
          this.updateBRNPopup = true;
          this.currentBookingItem = service;
        } else {
          if (service.growthCard) {
            this.toastMessage("Book Growth Card", "Unable to book Growth Card! Please try after some time.", "error");
          }
          else {
            this.toastMessage("Book Service", "Unable to book service! Please try after some time.", "error");
          }
        }
      }
    );
  }
  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }
  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
