import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-startup-card',
  templateUrl: './startup-card.component.html',
  styleUrls: ['./startup-card.component.scss']
})
export class StartupCardComponent implements OnInit {

  @Input() cardTitle: string;
  @Input() iconPath: string;
  @Input() subTitle: string;
  @Input() description: string;
  @Input() btnLabel: string;
  @Input() componentId: string;
  @Input() modalTitle: string;

  @Output() btnClick: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onBtnClick() {
    this.btnClick.emit();
  }

}
