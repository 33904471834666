import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {tap} from "rxjs/operators";

import {TokenService} from "../token/token.service";
import {ClientInfoService} from "../client-info/client-info.service";

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();

  constructor(
    private http: HttpClient, 
    private tokenService: TokenService,
    private clientInfoService: ClientInfoService
  ) {}

  getFaqs(page: number, size: number) {
    const params = new HttpParams() 
                  .set('pageNumber', page.toString())
                  .set('pageSize', size.toString());
    return this.http.get<any>(`${this.BASE_API_URL}api/faq/getFaqContent`, 
      {
        params: params,
        headers: this.tokenService.getHttpHeader()
      }
    )
    .pipe(
      tap(
        (appRef: any) => { }
      )
    );
  }
  
  askCustomQuestion(data: any) {
    return this.http.post<any>(`${this.BASE_API_URL}api/faq/askCustomQuestion`, data,
      {
        headers: this.tokenService.getHttpHeader()
      }  
    )
    .pipe(
      tap(
        (appRef: any) => { }
      )
    );
  }

}
