import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common/common.service';


@Component({
  selector: 'app-view-health-score',
  templateUrl: './view-health-score.component.html',
  styleUrls: ['./view-health-score.component.scss']
})
export class ViewHealthScoreComponent {
  @Input() showMyScore: any;
  isOpen = true;
  oneAtATime: boolean = true;

  public serviceCardData: any;


  constructor(private commonService: CommonService) {
    this.commonService.getCommonData().subscribe(
      res => {
      })
  }


}
