<app-language-selector></app-language-selector>

<app-feed-share (newFeedAdded)="newFeedAdded($event)" [feedForm]="shareType" [feedModal]="editFeedModal"
  (onCancel)="hideEditFeedModal()" [selectedFeedData]="selectedFeedData" class="edi-feed-form-block"></app-feed-share>

<div class="content-body sme-stats" *ngIf="current=='cmFeed'">
  <div class="row" #MyContentPostDetails>
    <div class="col-md-12">
      <div class="">
        <div class="card-body">

          <p-dataView [value]="myFeedContent" [paginator]="totalPages > 1" [rows]="10" [totalRecords]="totalRecords"
            [loading]="loading" [style]="{width: '100%'}" class="feeds-data-table" [lazy]="true"
            (onLazyLoad)="getFeedForRejected($event)">
            <ng-template let-rowData pTemplate="listItem">
              <div class="feed-item pending-feed row">
                <div (click)="currentMyFeed = rowData; feedCmArticalViewModal = true"
                  class="feed-header col-4 text-center">
                  <img src="{{rowData['featuredImage']?rowData.featuredImage:'assets/images/news/newsfeed-square.jpg'}}"
                    class="feed-img" alt="alt">
                </div>
                <div class="feed-company col-5">

                  <div class="feed-title">
                    <h6 class="feed-title-text" (click)="currentMyFeed = rowData; feedCmArticalViewModal = true">
                      {{rowData.title}}</h6>
                    <p class="feed-description">{{rowData.description}}</p>
                  </div>

                  <div class="d-flex mt-3">
                    <img src="{{rowData['companyLogo']?rowData.companyLogo:'assets/images/news/newsfeed-square.jpg'}}"
                      class="feed-company-logo" alt="alt">
                    <div class="feed-company-info mx-1">
                      <h6 class="feed-company-name">{{rowData.companyName}}</h6>
                      <span class="feed-company-role badge"
                        [ngClass]="{'entrepreneur': rowData.roleName == 'SME', 'growth-partner': rowData.roleName == 'SERVICE_PROVIDER', 'investor': rowData.roleName == 'INVESTOR'}">
                        <ng-container *ngIf="rowData.roleName == 'SME'">Entrepreneur</ng-container>
                        <ng-container *ngIf="rowData.roleName == 'SERVICE_PROVIDER'">Service Provider</ng-container>
                        <ng-container *ngIf="rowData.roleName == 'INVESTOR'">Investor</ng-container>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="feed-status col-3">
                  <div class="dropdown pending" *ngIf="rowData.reviewStatus=='rejected'">
                    <div class="dropdown-content">
                      <a href="javascript: void(0)" id="btnFeedEdit" (click)="editFeads(rowData);"><em
                          class="fas fa-pencil-alt"></em></a>
                      <a href="javascript: void(0)"
                        (click)="onStatusUpdate({feedId: rowData.id, reviewStatus: 'approved'})" id="btnFeedPublish">
                        <em class="fas fa-check"></em></a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>


        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="" [(visible)]="feedCmArticalViewModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [dismissableMask]="true"
  [transitionOptions]="'250ms cubic-bezier(1, 0, 0.2, 1)'" class="feedFormModal shareFeed_dialog" [maximizable]="false"
  [draggable]="false" [resizable]="false" id="appContributeCommunityModal" [blockScroll]="true" [resizable]="true">


  <div class="view-feed">
    <div class="text-center">
      <h5 class="modal-title" id="ttlViewMyContent">
        {{'FeedMyContent.popupContent.title' | translate }}
      </h5>
    </div>

    <div class="">
      <app-feed-popup-view [feed]="currentMyFeed?currentMyFeed:{}" [CMView]="true" [showPostPreviewFooter]="true"
        (onStatusUpdate)="onStatusUpdate($event)" (onEditPopup)="editFeads($event);"></app-feed-popup-view>

    </div>
  </div>
</p-dialog>
<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>