<div class="comment-box-group mx-1">
  <div contenteditable="true" class="comment-box" [attr.placeholder]="placeholder" [id]="'commentBox'+idPrefix"
    [mentionConfig]="mentionConfig" [mentionListTemplate]="mentionListTemplate" [mention]="httpItems | async"
    (searchTerm)="onSearchTerm($event)" (itemSelected)="onItemSelect($event)"
    [ngClass]="{'invalid':commentText && commentText.length>maxlength}" (paste)="onPaste($event)" #commentBox1
    (input)="onInputDiv($event, commentBox1)" *ngIf="!disableMention">
  </div>
  <div contenteditable="true" class="comment-box" [attr.placeholder]="placeholder" [id]="'commentBox'+idPrefix"
    [ngClass]="{'invalid':commentText && commentText.length>=maxlength}" (paste)="onPaste($event)" #commentBox2
    (input)="onInputDiv($event, commentBox2)" *ngIf="disableMention">
  </div>
  <div class="comment-status" *ngIf="commentText && commentText.length && commentText.length < maxlength">
    {{maxlength - commentText.length}} characters left
  </div>
  <div class="comment-status" *ngIf="commentText && commentText.length==maxlength">You have reached the character limit
  </div>
  <div class="comment-error" *ngIf="commentText && commentText.length>maxlength">You have exceed the character limit
  </div>
</div>

<ng-template #mentionListTemplate let-item="item">
  <div class="taggable-users">
    <div class="company-logo-img" *ngIf="item.companyLogo">
      <img [src]="item?.companyLogo" class="img-fluid" width="30" height="30" alt="company_logo">
    </div>
    <div class="company-logo-txt" *ngIf="!item.companyLogo">
      {{item[mentionLabelKey] | slice:0:1}}
    </div>
    <span class="company-label">{{item[mentionLabelKey]}}</span>
    <span class="badge" *ngIf="item.userType"
      [ngClass]="{'entrepreneur': item.userType == 'sme', 'growth-partner': item.userType == 'service_provider', 'investor': item.userType == 'investor'}">
      <ng-container *ngIf="item.userType == 'sme'">SME</ng-container>
      <ng-container *ngIf="item.userType == 'service_provider'">Service Provider</ng-container>
      <ng-container *ngIf="item.userType == 'investor'">Investor</ng-container>
    </span>
  </div>
</ng-template>