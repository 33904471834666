import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { GrowthCardService } from 'src/app/core/services/growth-card/growth-card.service';
import { Constants } from 'src/app/shared/constants/constants';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-growth-card',
  templateUrl: './growth-cards.component.html',
  styleUrls: [
    './growth-cards.component.scss',
    '../../../assets/landing/css/style.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class GrowthCardsComponent implements OnInit {
  @Output() totalRecordsChanged = new EventEmitter<number>();
  @Output() growthcardsfavorite = new EventEmitter<boolean>();
  cols: any[];
  checked: boolean;
  first = 0;
  rows = 9;
  utilityCategories: any[];
  utilityCategorySelected: any;
  growthCardsData: any[] = [];
  growthCardsModal: boolean;
  utilityCategoriesSelectForm: FormGroup;
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  limtitRecords = 9;
  firstRecord = 0;

  constructor(
    private commonService: CommonService,
    private growthCardsService: GrowthCardService,
    private messageService: MessageService,
    private formBuilder: FormBuilder
  ) {
    this.utilityCategoriesSelectForm = this.formBuilder.group({
      selectedCategories: new FormArray([])
    });
  }

  ngOnInit() {
    this.cols = [
      { field: 'growthCardTitle', header: 'Growth Card title', sortable: true },
      { field: 'growthPatnerName', header: 'Service Provider', sortable: true },
      { field: 'utilityCategoryList', header: 'Utility Category', sortable: false },
      { field: 'featured', header: 'Featured', sortable: true }
    ];
    this.sortOptions = [
      { label: 'Price High to Low', value: '!price' },
      { label: 'Price Low to High', value: 'price' }
    ];
    this.getAllUtilityCategories();

    this.growthCardsService.gcfeatured.subscribe(value => {
      this.getAllGrowthCards({ first: this.firstRecord, rows: this.limtitRecords });
    });
  }
  get utilityFormSelectedControl() {
    return this.utilityCategoriesSelectForm.controls.selectedCategories as FormArray;
  }

  private addCheckboxesToForm() {
    if (Array.isArray(this.utilityCategories))
      this.utilityCategories.forEach(() => this.utilityFormSelectedControl.push(new FormControl(false)));
  }
  currentItem;
  setCurrentItem(item) {
    this.currentItem = item;
    if (Array.isArray(this.utilityCategories) && Array.isArray(this.currentItem.utilityCategoryList)) {
      const curCats = this.currentItem.utilityCategoryList.map(e => { return e.utilityCategoryId; });
      if (curCats.length > 0) {
        this.invalid = false;
      }
      const temp = this.utilityCategories.map((cat) => {
        if (curCats.indexOf(cat.id) != -1) {
          return true;
        }
        return false;
      });
      this.utilityCategoriesSelectForm.patchValue({ selectedCategories: temp });
    }

  }
  pageNumber = 0;
  totalPages = 1;
  totalpublishedRecords: number;
  getAllGrowthCards(event) {
    // console.log('event', event)
    let sortColumn = event.sortField ? event.sortField : "growthCardTitle";
    let sortOrder = (event.sortOrder && event.sortOrder == 1) ? "asc" : "desc";
    this.growthCardsService.getAllGrowthCards((event.first / event.rows), this.utilityCategorySelected ? this.utilityCategorySelected.id : 0, sortColumn, sortOrder, "false").subscribe(res => {
      this.growthCardsData = res.content;
      // console.log( "done", this.growthCardsData)
      this.totalpublishedRecords = res.totalElements;
      this.totalPages = Math.ceil(this.totalpublishedRecords / Constants.PAGE_LIMIT);
      window.scrollTo(0, 0);
      this.totalRecordsChanged.emit(this.totalpublishedRecords);
    });
  }
  getAllUtilityCategories() {
    this.growthCardsService.getAllUtilityCategories().subscribe(res => {
      this.utilityCategories = res.map(card => {
        return { id: card.utilityCategoryId, name: card.utilityCategoryName };
      });
      this.addCheckboxesToForm();
    });
  }
  utilityCategoriesChanged() {
    this.getAllGrowthCards({ first: 0, rows: 10 });
  }
  changeFeaturedStatus(item) {
    const data = { growthCardId: item.growthCardId, featured: item.featured ? 1 : 0 };
    if (item.utilityCategoryList.length != 0)
      this.growthCardsService.changeFeaturedStatus(data).subscribe(res => {
        if (res.error) {
          this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: res.error, detail: '' });
        } else {
          const status = item.featured ? "activated" : "deactivated";
          this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: "Growth card featured status " + status + " successfully", detail: '' });
          this.growthCardsService.updatecurrentfeaturedValue(status);
          //this.growthcardsfavorite.emit(this.favoritegc);
        }
      });
  }
  clearData() {
    this.showError = false;
    this.currentItem = null;
    this.utilityCategoriesSelectForm.reset();
    this.growthCardsModal = false;
    this.invalid = true;
  }
  showError = false;
  invalid = true;
  checkItemsSelected() {
    const selectedCategories = this.utilityFormSelectedControl.value
      .map((checked, i) => checked ? true : null)
      .filter(v => v !== null);
    if (selectedCategories.length == 0 || selectedCategories.length > 3) {
      this.showError = true;
      this.invalid = true;
      return false;
    }
    this.showError = false;
    this.invalid = false;
    return true;
  }
  updateUtilityCategoriesForSelectedItem() {
    this.showError = false;
    if (!this.checkItemsSelected()) {
      return false;
    }
    const selectedCategories = this.utilityFormSelectedControl.value
      .map((checked, i) => checked ? this.utilityCategories[i].id : null)
      .filter(v => v !== null);
    const data = { growthCardId: this.currentItem.growthCardId, utilityCategoryIdList: selectedCategories };
    this.growthCardsService.saveCategoryGrowthCardMapping(data).subscribe(res => {
      if (res.error) {
        this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: res.error, detail: '' });
      } else {
        this.messageService.add({ key: 'c', life: 3000, severity: 'success', summary: "Utility category and Growth card mapping process updated successfully", detail: '' });
      }
    });
    this.growthCardsData = this.growthCardsData.map(card => {
      if (card.growthCardId == this.currentItem.growthCardId) {
        card.utilityCategoryList = this.utilityCategories.map(uc => {
          if (selectedCategories.indexOf(uc.id) != -1) {
            return { utilityCategoryId: uc.id, utilityCategoryName: uc.name }
          } else {
            return null
          }
        }).filter(v => v !== null);
      }
      return card;
    });
    this.clearData();
  }

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }


}