import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { UiModalComponent } from '../../../shared/components/modal/ui-modal/ui-modal.component';
import { ProfileService } from '../../../core/services/profile-service/profile.service';
import { CustomValidators } from '../../../shared/helpers/custom.validators';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  userId: any;
  isPublic: boolean = false;
  story: any = {};
  loading: boolean;
  isSubmit: boolean;
  buttonPressed: boolean;
  storyForm: FormGroup;
  editMyStoryModal: boolean;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.storyForm = new FormGroup({
      pastTitle: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      past: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      nowTitle: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      now: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      futureTitle: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      future: new FormControl('', [Validators.required, CustomValidators.removeSpaces])
    });
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.getUserStory();
    });
  }

  getUserStory() {
    this.loading = true;
    this.profileService.getUserStory(this.userId).subscribe(
      (data) => {
        if (data && !data["error"] && data["myStory"]) {
          this.story = data["myStory"];
        } else {
          this.story = {};
        }
        this.loading = false;
      },
      (error) => {
        this.story = {};
        this.loading = false;
        console.log(error);
      }
    );
  }

  onEdiMyStoryClick() {
    this.storyForm.patchValue(this.story);
    this.editMyStoryModal = true;
  }

  saveStory() {
    this.isSubmit = true;
    if (this.storyForm.invalid || this.buttonPressed) {
      return false;
    }

    this.buttonPressed = true;
    let formData = this.storyForm.value;

    this.profileService.saveUserStory(formData).subscribe(
      data => {
        this.editMyStoryModal = false;
        this.getUserStory();
        this.buttonPressed = false;
        this.isSubmit = false;
        this.storyForm.reset();
      },
      error => {
        this.buttonPressed = false;
      }
    );
  }

}
