import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-ready-to-signup',
  templateUrl: './ready-to-signup.component.html',
  styleUrls: ['./ready-to-signup.component.scss']
})
export class ReadyToSignupComponent implements OnInit {

  public AccountType: any;

  constructor(private router: Router, private commonService: CommonService) {
    this.commonService.getCommonData().subscribe(
      res => {
        this.AccountType = res.accountType.typeData;
      });
  }

  ngOnInit() {
  }
  redirectToSignup(accountType) {
    this.router.navigate(['/signup'], {
      queryParams: { accountType: accountType }
    });
  }


}
