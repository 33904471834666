import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('searchForm')
  searchForm: NgForm;

  @Output()
  searchEvent = new EventEmitter();

  @Input() searchParam = '';
  previousSearchText = '';

  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.searchParam = params.searchText;
      }
    );
  }
  submitted=false;
  clearText() {
    this.searchParam = '';
    if (this.previousSearchText.length > 0) {
      this.searchEvent.emit(this.searchParam);
    }
    this.previousSearchText = '';
    this.submitted=false;
  }

  search() {
    this.submitted=true;
    if (this.searchForm.valid) {
      this.searchParam = this.searchParam.trim()
      if (this.searchParam.length > 2 || this.previousSearchText.length > 2) {
        this.searchEvent.emit(this.searchParam);
        this.previousSearchText = this.searchParam;      
        this.router.navigate(['/search-results'], { queryParams: { searchText: this.searchParam } });
      }
    }
  }

  sendEventIfEmpty() {
    if (this.searchParam.length === 0) {
      this.clearText();
    }
  }
}
