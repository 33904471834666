import { Component, OnInit, Renderer2 } from '@angular/core';
import {BroadcastService} from "../../core/services/broadcast/broadcast.service";

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {
  loading = true;
  MODAL_OPEN_CLASS = 'modal-open';
  constructor(public broadcastService: BroadcastService, public renderer: Renderer2) {}

  ngOnInit() {
    this.broadcastService.httpLoader.asObservable().subscribe(values => {
      this.loading = values;
      this.toggleModal(values);
    });
  }
  toggleModal(loading) {
    this.renderer[loading ? 'addClass' : 'removeClass'](document.body, this.MODAL_OPEN_CLASS);
  }
}
