import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../client-info/client-info.service';
import { StorageService } from '../storage/storage.service';
import { TokenService } from '../token/token.service';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Constants } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class GrowthPlanService {

  jwt_token: string;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private storageService: StorageService,
    private router: Router,
    private clientInfoService: ClientInfoService,
    private location: Location
  ) {
    this.jwt_token = tokenService.getToken();
  }

  readonly BASE_API_URL: string = this.clientInfoService.getRootUrlForAPI();
  addCustomGrowthPlan(growthPlan: any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/growth-plans/addCustomGrowthPlan`, growthPlan)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  searchGrowthPlans(pageNumber, searchData: any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/growth-plans/searchGrowthPlans?pageSize=` + Constants.PAGE_LIMIT + `&pageNumber=` + pageNumber, searchData)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  selectGrowthPlan(planData: any): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/growth-plans/selectGrowthPlan`, planData)
      .pipe(tap((appRef: any) => {
      }));
    return res;
  }
  getMoreDetails(growthPlanId): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/growth-plans/getMoreDetails?growthPlanId=` + growthPlanId)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  getCheckList(growthPlanId): Observable<any> {
    const res: Observable<any> = this.http
      .get(`${this.BASE_API_URL}api/growth-plans/getCheckList?growthPlanId=` + growthPlanId)
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
  applyForGrowthPlan(growthPlanId, criteria): Observable<any> {
    const res: Observable<any> = this.http
      .post(`${this.BASE_API_URL}api/growth-plans/applyForGrowthPlan`, { growthPlanId: growthPlanId, criteriaSelected: criteria.toString() })
      .pipe(tap((appRef: any) => {

      }))
    return res;
  }
}
