import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from 'src/app/shared/constants/constants';
import { MessageService } from 'primeng/api';
import { CmService } from 'src/app/core/services/cm/cm.service';
import { saveAs } from 'file-saver';
import { GrowthCardService } from 'src/app/core/services/growth-card/growth-card.service';


interface ApprovalPendingGcResponse {
  gcId: string
  title: string
  createdBy: string
  approvalStatus: string
  createdTime: string
  updatedTime: string
  description: string
  image: string
}

@Component({
  selector: 'app-cm-rejectedgc',
  templateUrl: './cm-rejectedgc.component.html',
  styleUrls: ['./cm-rejectedgc.component.scss']
})
export class CmRejectedgcComponent implements OnInit {
  @ViewChild('MyContentPostDetails', { static: false }) MyContentPostDetails: ElementRef;
  @Output() totalRecordsrejected = new EventEmitter<number>();
  loader: boolean;
  showLoader = true;
  dtOptions: DataTables.Settings = {};
  approvalPendingGcResponse: ApprovalPendingGcResponse[];

  totalRecords: number;
  limtitRecords = 10;
  firstRecord = 0;
  loading: boolean;
  selectedFeedData: any;
  favoriteChanged: boolean;





  pageNumber = 0;
  totalPages = 1;

  actionName = "Edit";
  openGCModel: boolean = false;
  cols = [
    { field: 'title', header: 'Growth Card Title', sortable: true },
    { field: 'createdBy', header: 'Submitted by', sortable: true },
    { field: 'createdTime', header: 'Date submitted', sortable: true },
    { field: 'updatedTime', header: 'Last updated', sortable: true },
    { field: 'approvalStatus', header: 'Status', sortable: true },
    { field: 'Actions', header: 'Actions', sortable: true }
  ];
  totalpublishedRecords: number;
  totalutilityRecords: number;
  favoriterecords: number;
  rejectedGcResponse: any;
  rejectedgcRecords: number;

  constructor(
    private messageService: MessageService,
    private cmService: CmService,
    private growthCardsService: GrowthCardService,
  ) {
  }
  ngOnInit(): void {
    // this.getGrowthCardsRejected({ first: this.firstRecord, rows: this.limtitRecords });

    this.growthCardsService.gcfeatured.subscribe(value => {
      this.getGrowthCardsRejected({ first: this.firstRecord, rows: this.limtitRecords });
    });
  }
  getGrowthCardsRejected(event) {
    this.loading = true;
    this.firstRecord = event.first;
    this.limtitRecords = event.rows;
    this.cmService.getGrowthCardsRejected((event.first / event.rows)).subscribe(
      res => {
        this.rejectedGcResponse = res;
        this.rejectedgcRecords = res.length;
        this.totalPages = Math.ceil(this.rejectedgcRecords / 100);
        this.loading = false;
        this.totalRecordsrejected.emit(this.rejectedgcRecords);
        window.scrollTo(0, 0);
      })
  }
  previewGc(gcId: number, gcName: string) {
    let contentType = 'application/pdf;charset=UTF-8';
    this.cmService.downloadGCPreviewPdf(gcId).subscribe(
      res => {
        const data: Blob = new Blob([res], {
          type: contentType
        });
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, '_blank');
      }
    );
  }
  approveOrRejectGC(gcId: number, approveOrReject: string) {
    let detail = "Growth card approved successfully.";
    this.growthCardsService.updatecurrentfeaturedValue(detail);
    this.currentGcId = gcId;
    if ("Rejected" == approveOrReject)
      detail = "Growth card rejected successfully.";
    this.growthCardsService.updatecurrentfeaturedValue(detail);
    this.cmService.approveOrRejectGC(gcId, approveOrReject).subscribe(
      res => {
        let emitInfo = new Object();
        emitInfo["gcId"] = this.currentGcId;
        emitInfo["action"] = approveOrReject;
        this.gcUpdated(emitInfo);
        this.messageService.add({ key: "c", severity: "success", summary: "Growth Card", detail: detail });
        window.scrollTo(0, 0);
      });
  }
  deleteGc(gcId: number, gcName: string) {
    this.currentGcId = gcId;
    this.cmService.deleteGrowthCard(gcId).subscribe(
      res => {
        this.messageService.add({ key: "c", severity: "success", summary: "Growth Card", detail: "Growth card deleted successfully." });
        window.scrollTo(0, 0);
        let emitInfo = new Object();
        emitInfo["gcId"] = this.currentGcId;
        emitInfo["action"] = "Deleted";
        this.gcUpdated(emitInfo);
      });
  }

  currentGcId: number;
  editGCForm(gcId: number) {
    this.actionName = 'Edit';
    this.currentGcId = gcId;
    this.openGCModel = true;
  }

  gcUpdated(emitInfo) {
    this.approvalPendingGcResponse.forEach(gc => {
      if (gc["gcId"] == emitInfo?.gcId) {
        if (emitInfo?.action == "update") {
          for (let key in emitInfo.updatedGC) {
            gc[key] = emitInfo.updatedGC[key];
          }
          return gc;
        } else
          return gc["approvalStatus"] = emitInfo?.action;
      }
    });
  }

  onTotalRecordsChanged(totalpublishedRecords: number) {
    this.totalpublishedRecords = totalpublishedRecords;
  }

  totalutilityRecordsChanged(totalutilityRecords: number) {
    this.totalutilityRecords = totalutilityRecords;
  }
  totalRecordsfavoriteChanged(favoriterecords: number) {
    this.favoriterecords = favoriterecords;
  }


}
