import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { AuthComponent } from './layout/auth/auth.component';
import { TranslateModule } from "@ngx-translate/core";
import { NormalLayoutComponent } from './layout/normal-layout/normal-layout.component';
import { LandingComponent } from './layout/landing/landing.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
//import { RedirectGuard } from './core/guards/redirect.guard';
import { Roles } from "./core/Enums/roles";

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
        children: [
            {
                path: '',
                redirectTo: 'landing',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('./pages/authentication/authentication.module').then(module => module.AuthenticationModule)
            },
            {
                path: 'landing',
                loadChildren: () => import('./pages/landing/landing.module').then(module => module.LandingModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'smeconnectURL'}
            },
            {
                path: 'faq',
                loadChildren: () => import('./pages/faq/faq.module').then(module => module.FaqModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'faqURL'}
            },
            {
                path: 'about-us',
                loadChildren: () => import('./pages/landing-about/landing-about.module').then(module => module.LandingAboutModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'aboutURL'}
            },
            {
                path: 'terms-and-conditions',
                loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(module => module.TermsAndConditionsModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'termsURL'}
            },
            {
                path: 'cookie-policy',
                loadChildren: () => import('./pages/cookie-policy/cookie-policy.module').then(module => module.CookiePolicyModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'cookieURL'}
            },
            {
                path: 'privacy-statement',
                loadChildren: () => import('./pages/privacy-statement/privacy-statement.module').then(module => module.PrivacyStatementModule)
                //canActivate: [RedirectGuard],
                //component: RedirectGuard,
                //data: {externalUrl: 'privacyURL'}
            },
            {
                path: 'browse',
                loadChildren: () => import('./pages/browse/browse.module').then(module => module.BrowseModule)
            }
        ]
    },
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [

            {
                path: 'sme-profile',
                loadChildren: () => import('./pages/sme-profile/sme-profile.module').then(module => module.SmeProfileModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME]
                }
            },
            {
                path: 'all-produtcs',
                loadChildren: () => import('./pages/view-all-produtcs/view-all-produtcs.module').then(module => module.ViewAllProdutcsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR]
                }
            },
            {
                path: 'all-services',
                loadChildren: () => import('./pages/view-all-services/view-all-services.module').then(module => module.ViewAllServicesModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR]
                }
            },
            {
                path: 'manage-your-business',
                loadChildren: () => import('./pages/manage-your-business/manage-your-business.module').then(module => module.ManageYourBusinessModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.COMMUNITY_MANAGER, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'investor',
                loadChildren: () => import('./pages/investor-dashboard/investor-dashboard.module').then(module => module.InvestorDashboardModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.INVESTOR]
                }
            },
            {
                path: 'groups',
                loadChildren: () => import('./pages/sme-groups/groups.module').then(module => module.GroupsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER,]
                }
            },
            {
                path: 'sme-stats',
                loadChildren: () => import('./pages/sme-stats/sme-stats.module').then(module => module.SmeStatsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME]
                }
            },
            {
                path: 'ai-advisor',
                loadChildren: () => import('./pages/sme-openai/sme-openai.module').then(module => module.SmeOpenaiModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME]
                }
            },
            {
                path: 'search-results',
                loadChildren: () => import('./pages/search-results/search-results.module').then(module => module.SearchResultsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.COMMUNITY_MANAGER, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'user-browse',
                loadChildren: () => import('./pages/user-browse/user-browse.module').then(module => module.UserBrowseModule)
            },
            {
                path: 'forum',
                loadChildren: () => import('./pages/forum/forum.module').then(module => module.ForumModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.COMMUNITY_MANAGER, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'feed',
                loadChildren: () => import('./pages/feed/feed.module').then(module => module.FeedModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.INVESTOR, Roles.SERVICE_PROVIDER, Roles.COMMUNITY_MANAGER, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'cm-feed',
                loadChildren: () => import('./pages/cm/cm-feed/cm-feed.module').then(module => module.CmFeedModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'published-feed',
                loadChildren: () => import('./pages/cm/published-feed/published-feed.module').then(module => module.PublishedFeedModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(module => module.AdminModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'cm-growth-cards',
                loadChildren: () => import('./pages/growth-cards/growth-cards.module').then(module => module.GrowthCardsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'cm-growthcard-request',
                loadChildren: () => import('./pages/cm/cm-growthcard/cm-growthcard.module').then(module => module.CmGrowthcardModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'utility-categories',
                loadChildren: () => import('./pages/utility-categories/utility-categories.module').then(module => module.UtilityCategoriesModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'resources',
                loadChildren: () => import('./pages/sme-resources/sme-resources.module').then(module => module.SmeResourcesModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.INVESTOR, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER, Roles.COMMUNITY_MANAGER]
                }
            },
            {
                path: 'growth-partners',
                loadChildren: () => import('./pages/growth-partners/growth-partners.module').then(module => module.GrowthPartnersModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'growth-cards',
                loadChildren: () => import('./pages/sme-growthcard/sme-growthcard.module').then(module => module.SmeGrowthcardModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER, Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'bookings',
                loadChildren: () => import('./pages/sp-bookings/sp-bookings.module').then(module => module.SpBookingsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER]
                }
            },
            {
                path: 'my-growth',
                loadChildren: () => import('./pages/my-growth/my-growth.module').then(module => module.MyGrowthModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.INVESTOR, Roles.SERVICE_PROVIDER, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'opportunities',
                loadChildren: () => import('./pages/opportunities/opportunities.module').then(module => module.OpportunitiesModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.INVESTOR, Roles.RELATIONSHIP_MANAGER]
                }
            },
            {
                path: 'service-provider',
                loadChildren: () => import('./pages/service-provider/service-provider.module').then(module => module.ServiceProviderModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SERVICE_PROVIDER]
                }
            },
            {
                path: 'details/:id',
                loadChildren: () => import('./pages/groups-details/groups-details.module').then(module => module.GroupsDetailsModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SME, Roles.SERVICE_PROVIDER]
                }
            },
            {
                path: 'all-growthcards',
                loadChildren: () => import('./pages/view-all-growthcards/view-all-growthcards.module').then(module => module.ViewAllGrowthcardModule),
                canActivate: [RoleGuard],
                data: {
                    allowed_roles: [Roles.SERVICE_PROVIDER]
                }
            },
            {
                path: 'my-profile',
                loadChildren: () => import('./pages/my-profile/my-profile.module').then(module => module.MyProfileModule)
            },
            { path: '**', redirectTo: 'error-404-page-not-found' }
        ]
    },
    {
        path: '',
        component: NormalLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '**', redirectTo: 'error-404-page-not-found' }
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/authentication/authentication.module').then(module => module.AuthenticationModule)
            }
        ]
    },
    {
        path: 'error-403-page-forbidden',
        loadChildren: () => import('./pages/error/error.module').then(module => module.ErrorModule)
    },
    {
        path: 'error-404-page-not-found',
        loadChildren: () => import('./pages/error/error.module').then(module => module.ErrorModule)
    },
    { path: '**', redirectTo: 'error-404-page-not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
