<app-language-selector></app-language-selector>
<div class="row justify-content-md-center">
  <div class="col-md-10">
    <div class="browse-categories" id="div{ 'Browse.id' | translate }}">
      <h3 id="ttl{ 'Browse.id' | translate }}">{{ 'Browse.title' | translate }}</h3>
      <div class="row">
        <div class="col-md-6 mb-4" *ngFor="let item of categories?.data">
          <div class="categories-box" id="div{{item.id}}">
            <h6 id="ttl{{item.id}}">
              <span>{{ item.title }}</span>
              <a href="javascript:void(0);" id="anc{{item.id}}" *ngIf="item?.link">{{ item?.link }}</a>
            </h6>
            <p id="p{{item.id}}">{{ item.disc }}</p>
            <button type="button" class="btn btn-primary" id="btn{{item.id}}"
              [routerLink]="[item.url]">{{item.button}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>