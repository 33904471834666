<app-language-selector></app-language-selector>
<div class="row">
  <div class="col-md-12">
    <div class="analytics">
      <h3 class="title">{{ "analytics.title" | translate }}</h3>
      <p class="discp">{{ "analytics.description" | translate }}</p>
      <div class="counts">
        <div class="row">
          <div class="col-md-12">
            <ul>
              <li>
                <span class="num">{{ "analytics.num1" | translate }}</span><em class="fas fa-arrow-up"></em>
                <span class="txt">{{ "analytics.block1" | translate }}</span>
              </li>
              <li>
                <span class="num">{{ "analytics.num2" | translate }}</span><em class="fas fa-arrow-up"></em>
                <span class="txt">{{ "analytics.block2" | translate }}</span>
              </li>
              <li>
                <span class="num">{{ "analytics.num3" | translate }}</span><em class="fas fa-arrow-up"></em>
                <span class="txt">{{ "analytics.block3" | translate }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>