<div class="client-section">
    <div class="container text-center">
        <h2 class="wow fadeInUp" data-wow-delay="0s" id="ttl{{'landing.landing.OurPartners.id' | translate}}">
            {{'landing.landing.OurPartners.title' | translate}}</h2>
        <p class="wow fadeInUp" data-wow-delay="0s" id="p{{'landing.landing.OurPartners.id' | translate}}">
            {{'landing.landing.OurPartners.disp' | translate}}</p>
        <div class="clients">
            <p-carousel [value]="ourPartners" styleClass="custom-carousel" [numVisible]="6" [numScroll]="5"
                [circular]="true" [autoplayInterval]="6000" [responsiveOptions]="responsiveOptions"
                id="carouselOurPartners">
                <ng-template let-partners pTemplate="item">
                    <div class="car-details">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-12">
                                <img src="assets/images/partners-logos/{{partners.logo}}" class="carousel-img"
                                    id="img{{'landing.landing.OurPartners.id' | translate}}{{partners.id}}" />
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
</div>