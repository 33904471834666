import { Component } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-your-specialisation',
  templateUrl: './your-specialisation.component.html',
  styleUrls: ['./your-specialisation.component.scss']
})
export class YourSpecialisationComponent {

  constructor(private dragula: DragulaService) { }



}
