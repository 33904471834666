<div class="d-flex">
  <ng-content select="[labelTemplate]"></ng-content>
</div>
<label *ngIf="field && field.showLabel && field.labelText !== '' " [for]="'lbl' + (field.name + componentId | translate)"
  [id]="'lbl' + (field.name + componentId | translate)">
  {{ field.labelText | translate }}
  <span class="mandatory" *ngIf="field.mandatory">
    {{ 'CONSTANTS.MANDATORY' | translate }}
  </span>
</label>
<textarea #textArea *ngIf="!disabled" cols="60" [rows]="field.rows" [name]="'txt' + field.name + componentId"
  [formControl]="field.control" class="form-control" [id]="'txt' + (field.name + componentId | translate)"
  placeholder="{{field.placeholderText | translate}}" (keyup)="updateCharacterLength($event)"
  (change)="onChange(field.propertyInModel,textArea.value)"></textarea>
<div class="float-right" *ngIf="showRemaining && maxLength > 0">
  {{ (maxLength-characterLength)>-1?maxLength-characterLength:0 }} characters left
</div>
<div *ngIf="field.control.invalid && (field.control.dirty || submitted )">
  <div [hidden]="!field.control.errors" class="error-container">
    <div class="error-msg" [id]="'lblErrorMsg' + (componentId + field.name | translate)"
      *ngIf="!field.control.errors.minlength && !field.control.errors.maxlength">
      {{ field.errorMsg | translate }}</div>
    <div class="error-msg" id="feedInputErrorMinLength" *ngIf="field.control.errors.minlength">Should contain minimum
      {{minLength}} characters</div>
    <div class="error-msg" id="feedInputErrorMaxLength" *ngIf="field.control.errors.maxlength">Should not exceed
      {{maxLength}} characters</div>
  </div>
</div>