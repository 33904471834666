export class Common {
    public static tabWidth = 992;
    public static discp = "";
    public static brnRegEx = new RegExp('^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$');
    public static mentionRegEx = /{([^}]+)}/g;
    public static mentionLabelKey = 'companyName';
    public static mentionIdKey = 'userId';
    public static costRegEx = /^[0-9]{0,8}$/;
    public static timeRegEx = /^[0-9]{0,2}$/;

    public static opportunityItem = {
        "establishedVal": 2010,
        "profitabilityVal1": "35.5%",
        "profitabilityVal2": "24.7%",
        "MUR": "20, 000, 000 ZAR"
    }
    public static helpBusinesses = [
        "Setup their eCommerce website",
        "Help entrepreneur Structure & Manage their business",
        "Help businesses manage their contracts & agreements"
    ]
    public static opportunitiesPerformance = {
        "MUR": "20, 000, 000 ZAR",
        "RETURN": "8%"
    }
    public static nOfweeks = [
        "Days",
        "Weeks",
        "Months"
    ]
    public static isValidBRN = (brn: string) => {
        let brnRegEx = new RegExp('^[A-Z][0-9]{8}$');
        return brnRegEx.test(brn);
    }
    public static isBRNErrors = (res: any) => {
        if (res && res.status == 400) {
            if (res.error == "Fail -> BRNNumber does not exist!") {
                return true;
            }
        }
        return false;
    }
    public static replaceURLs(comment: string) {
        if (!comment) return '';
        let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return comment.replace(urlRegex, function (url) {
            let hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
        });
    }
    // public static replaceMentions(comment: string) {
    //     if (!comment) return '';
    //     const mentionList = comment.match(this.mentionRegEx);
    //     if (mentionList) {
    //         for (let mention of mentionList) {
    //             try {
    //                 const mentionObj = JSON.parse(mention);
    //                 comment = comment.replace('@' + mention, '<span class="mention-user" contenteditable="false"><span>@</span>' + mentionObj[this.mentionLabelKey] + '</span>');
    //             } catch (e) { }
    //         }
    //     }
    //     // console.log(mentionList, 'text')
    //     return comment;
    // }

    public static replaceMentions(comment: string) {
        if (!comment) return '';
        const mentionList = comment.match(this.mentionRegEx);
        if (mentionList) {
            for (let mention of mentionList) {
                console.log(mention, "mention")
                try {
                    const mentionObj = JSON.parse(mention);
                    const mentionName = mentionObj[this.mentionLabelKey];
                    const mentionId = mentionObj['userId']; // Assuming 'id' is the key for the mention's ID
                    const mentionLink = `<a href= "user-browse/details/sme/${mentionId}" target="_self" rel="noopener noreferrer">${mentionName}</a>`;
                    comment = comment.replace(mention, mentionLink);
                } catch (e) { }
            }
        }
        return comment;
    }
    public static getMentions(comment: string) {
        let retObj = { mentionIds: [], mentions: [], comment: comment, formatted: comment };
        if (comment) {
            const mentionList = comment.match(this.mentionRegEx);
            if (mentionList) {
                for (let mention of mentionList) {
                    try {
                        const mentionObj = JSON.parse(mention);
                        retObj.mentionIds.push(mentionObj[this.mentionIdKey]);
                        retObj.mentions.push(mentionObj);
                        retObj.formatted = retObj.formatted.replace('@' + mention, '<span class="mention-user" contenteditable="false" data-id="' + mentionObj[this.mentionIdKey] + '"><span>@</span>' + mentionObj[this.mentionLabelKey] + '</span>');
                        retObj.comment = retObj.comment.replace('@' + mention, '@' + mentionObj[this.mentionLabelKey]);
                    } catch (e) { }
                }
            }
        }
        return retObj;
    }
}
