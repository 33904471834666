<div class="">
  <div class="">
    <div class="content-body sme-stats">
      <div class="row">
        <div class="col-lg-12">
          <div class="content-body">
            <div class="">
              <div class="">
                <p-dataView [value]="rejectedGcResponse" [layout]="'grid'" [totalRecords]="rejectedgcRecords"
                  (onLazyLoad)="getGrowthCardsRejected($event)" [lazy]="true" [paginator]="totalPages>1" [rows]="10"
                  class="gpfeed-data-table">
                  <ng-template let-rowData pTemplate="gridItem">
                    <div class="pendingapprovalCard">
                      <div class="card approvalgc">
                        <div class="card-body">
                          <div *ngIf="rowData['rejectedStatus']=='Rejected'">
                            <div *ngIf="rowData['title']" class="align-middle  img-content pointer" data-toggle="modal"
                              (click)="currentMyFeed=rowData; feedCmArticalViewModal = true">
                              <div class="">

                                <h6 id="ttl{{ rowData.id }}" class="mb-1 title cursor-pointer">
                                  <span pTooltip='{{rowData.title}}' tooltipPosition="top">
                                    {{ rowData['title'] | slice:0:28 }}
                                    <span *ngIf="rowData['title'].length > 28">...</span>
                                  </span>
                                </h6>
                              </div>

                              <div class="feed-item p-0">
                                <div class="feed-status">
                                  <div class="dropdown d-flex">
                                    <a href="javascript: void(0)" id="btnDelete" (click)="editGCForm(rowData.gcId);"><em
                                        class="fas fa-pencil-alt"></em></a>
                                    <a href="javascript: void(0)" id="btnDelete"
                                      (click)="approveOrRejectGC(rowData.gcId,'Approved')"><em
                                        class="fas fa-check"></em></a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 pendingapprovalimg p-0"
                                (click)="previewGc(rowData.gcId,rowData.title)">
                                <img src="{{rowData['image']?rowData.image:'assets/images/news/newsfeed-square.jpg'}}"
                                  id="img{{ rowData.id }}" class="wid-70 align-top m-r-15 d-inline-block" alt="feed">
                              </div>
                            </div>
                            <div *ngIf="!rowData['title']">{{rowData['description']}}</div>
                            <div *ngIf="rowData['rejectedStatus']=='Rejected'">Item has been rejected</div>

                            <div *ngIf="rowData['Actions']" class="category-action">

                              <div class="dropdown text-left">
                                <button class="dropbtn" id="btnDropbtn"><em class="fas fa-ellipsis-h"></em></button>
                                <div class="dropdown-content">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-dataView>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<app-growthcard-form [action]="actionName" (onGCUPdate)="gcUpdated($event)" [gcFormTitle]="'Edit Growth Card'"
  [(openGCForm)]="openGCModel" [currentGcId]="currentGcId"></app-growthcard-form>

<p-toast position="top-center" key="c" [baseZIndex]="9999999" id="pToastMsg"></p-toast>