import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Common } from '../../utility/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ServiceCardService } from '../../core/services/service-card-service/service-card.service';
import { UserAuthService } from '../../core/services/auth-service/user-auth.service';
import { SelectOptionService } from '../../shared/components/select/select-option.service';
import { CustomValidators } from '../../shared/helpers/custom.validators';
import { Duration } from "../../core/Enums/duration";
import { Currency } from "../../core/Enums/currency"
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { CommonService } from '../../services/common/common.service';


export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

@Component({
  selector: 'app-sme-services',
  templateUrl: './sme-services.component.html',
  styleUrls: ['./sme-services.component.scss'],
  animations: [fadeAnimation]
})
export class SmeServicesComponent implements OnInit {
  userId: any;
  isPublic: boolean = false;
  isServiceProvider: boolean;
  growthCardService: any;
  hasItems = false;
  description = Common.discp;
  public keyupLabelOn = false;
  public keydownLabelOn = false;
  public someRange2: number[] = [0, 250000];
  public noOfweeks: number[] = [0, 3];
  responsiveOptions: any;
  maxAllowedSize = 1 * 1024 * 1024;

  public weeksConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: [0, 4],
    keyboard: true,
    step: 1,
    pageSteps: 10,
    range: {
      min: 0,
      max: 5
    },
    pips: {
      mode: 'count',
      density: 10,
      values: 6,
      stepped: true
    }
  };

  format = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: Currency.MUR,
    minimumFractionDigits: 0,
  }
  );

  public ServicePriceRangeConfig: any = {
    start: [0, 250000],
    connect: true,
    step: 1000,
    range: {
      min: 0,
      max: 1000000
    },
    pips: {
      mode: 'count',
      values: 2
    },
    format: {
      to: function (value) {
        return Currency.RS + (new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: Currency.MUR,
          minimumFractionDigits: 0,
        }).format(Math.floor(value))).replace("MUR", "");
      },
      from: function (value) {
        return Number(value.replace('Rs', '').trim().replace(/\,/g, ''));
      }
    }
  };

  businesses = Common.helpBusinesses;
  selectedBusinesses = '';
  nOfweeks = Common.nOfweeks;
  selectednOfweeks = '';
  serviceForm: FormGroup;
  isSubmit: boolean;
  buttonPressed: boolean;
  loading: boolean;
  isLoggedIn: boolean;
  editMode: boolean;
  selectedService: any;
  services: any = [];
  imagePath: any;
  previewURL: any;
  categoriesOptions: Array<any> = [];
  @ViewChild('myNgForm', { static: true }) myNgForm: NgForm;
  addServiceModal: boolean;
  addGrowthModal: boolean;
  deleteServiceModal: boolean;
  isSME: boolean = true;
  isSP: boolean = true;


  constructor(
    private serviceCardService: ServiceCardService,
    private selectOptionService: SelectOptionService,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private utility: UtilityService,
    private gaService: GoogleAnalyticsService,
    private commonService: CommonService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.commonService.getCommonData().subscribe(
      res => {
        this.growthCardService = res.growthCardService.content;
      })

    this.route.params.subscribe(params => {
      const type = params.type;
      this.isSME = type == 'sme';
      this.isSP = type == 'service_provider';
    });
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.authService.isSessionExpired();
    this.serviceForm = new FormGroup({
      serviceName: new FormControl("", [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(100)]),
      description: new FormControl(this.description, [Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(3),
      Validators.maxLength(500)
      ]),
      category: new FormControl('', [Validators.required, CustomValidators.removeSpaces]),
      costEstimate: new FormControl(this.someRange2),
      timeEstimate: new FormControl(this.noOfweeks),
      duration: new FormControl('', [Validators.required]),
    });

    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.isPublic = params.id ? true : false;
      this.loadLatestServiceList();
    });
    this.selectOptionService.getServicecardCategories().subscribe((data: any) => {
      this.categoriesOptions = data.map(v => {
        return { value: v.category, label: v.category };
      });
    });
    this.isServiceProvider = this.authService.isServiceProvider();
  }

  onChangeBusinesses(newValue) {
    this.selectedBusinesses = newValue;
  }

  onOfweeks(newValue) {
    this.selectednOfweeks = newValue;
  }

  blinkKeyupLabel() {
    this.keyupLabelOn = true;
    setTimeout(() => {
      this.keyupLabelOn = false;
    }, 450);
  }

  blinkKeydownLabel() {
    this.keydownLabelOn = true;
    setTimeout(() => {
      this.keydownLabelOn = false;
    }, 450);
  }


  loadServiceList(event: any) {
    this.loading = true;
    let q = '';
    let sortAsc = event.sortField && event.sortOrder == 1 ? event.sortField : '';
    let sortDesc = event.sortField && event.sortOrder == -1 ? event.sortField : '';
    this.serviceCardService.getServiceCards((event.first / event.rows), event.rows, q, sortAsc, sortDesc, this.userId).subscribe(
      (data) => {
        this.services = data.content;
        if (Array.isArray(this.services) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.services = [];
        this.loading = false;
        console.log(error);
      }
    );
  }

  loadLatestServiceList() {
    this.loading = true;
    this.serviceCardService.getServiceCards(0, 100, "", "", "", this.userId).subscribe(
      (data) => {
        this.services = data.content;
        if (Array.isArray(this.services) && this.services.length > 0) {
          this.hasItems = true;
        }
        this.loading = false;
      },
      (error) => {
        this.services = [];
        this.loading = false;
        console.log(error);
      }
    );
  }

  saveService() {
    let msgSummary = (this.editMode ? "Update" : "Create") + " Service Card";
    this.isSubmit = true;

    if (this.serviceForm.invalid || this.buttonPressed) {
      return false;
    }

    if (!this.editMode && (!this.imagePath || (Array.isArray(this.imagePath[0]) && this.imagePath.length == 0))) {
      this.toastMessage(msgSummary, "Please add feature image", "error");
      return false;
    }

    this.buttonPressed = true;
    let formData = this.serviceForm.value;
    formData.timeEstimate = Array.isArray(formData.timeEstimate) ? formData.timeEstimate.join(' - ') + (formData.duration ? ' ' + formData.duration : '') : '';
    formData.costEstimate = Array.isArray(formData.costEstimate) ? formData.costEstimate.join(' - ') : '';
    if (formData.duration) delete formData.duration;
    if (this.editMode) {
      formData.id = this.selectedService.id;
    }

    let action = this.editMode ? "updateServiceCard" : "saveServiceCard";
    this.serviceCardService[action](this.getFormData(formData)).subscribe(
      data => {
        this.buttonPressed = false;
        if (data.error) {
          this.toastMessage(msgSummary, data.error, "error");
        } else {
          this.toastMessage(msgSummary, "Service card saved successfully", "success");
          this.hasItems = true;
          this.addServiceModal = false;
          this.loadLatestServiceList();
          this.clearFields();
          this.serviceForm.patchValue({
            serviceName: "",
            description: this.description,
            costEstimate: this.someRange2,
            timeEstimate: this.noOfweeks,
            category: '',
            duration: ''
          });
        }
      },
      error => {
        this.buttonPressed = false;
        this.toastMessage(msgSummary, "Unable to save service! Please try after some time.", "error");
      }
    );
  }
  clearFields() {
    this.imagePath = null;
    this.previewURL = null;
    this.isSubmit = false;
    this.myNgForm.resetForm();
  }
  get f() { return this.serviceForm.controls; }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted);
  }

  preview(files: any) {
    if (files.length === 0) {
      this.imagePath = null;
      this.previewURL = null;
      return;
    }
    if (files[0].size > this.maxAllowedSize) {
      this.messageService.add({ key: 'c', life: 3000, severity: 'error', summary: "Featured image size should not exceed 1MB.", detail: '' });
      return;
    }

    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.imagePath = null;
      this.previewURL = null;
      return;
    }

    let reader = new FileReader();
    this.imagePath = [...files];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.previewURL = reader.result;
    }
  }

  getFormData(item: any) {
    let payload = new FormData();
    payload.append('data', JSON.stringify(item));
    if (this.imagePath) {
      payload.append('file', this.imagePath[0]);
    }
    return payload;
  }

  formatTimeEstimate(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.formatTimeEstimate(newStr);
  }
  formatTimeEstimatePreview(value) {
    return this.utility.formatTimeEstimate(value);
  }
  getDurationType(service: any) {
    let newStr = service["timeEstimate"] ? service["timeEstimate"] : '';
    return this.utility.getDurationType(newStr);
  }
  onAddBtnClick() {
    this.isSubmit = false;
    this.editMode = false;
    this.selectedService = null;
    this.previewURL = null;
    this.serviceForm.reset();
    this.serviceForm.patchValue({
      serviceName: "",
      description: this.description,
      costEstimate: this.someRange2,
      timeEstimate: this.noOfweeks,
      duration: '',
      category: ''
    });
    if (this.isServiceProvider) {
      this.addGrowthModal = true;
    }
    else {
      this.addServiceModal = true;
    }

  }

  onEditBtnClick(s: any) {
    this.isSubmit = false;
    this.editMode = true;
    this.selectedService = s;
    let service = { ...{}, ...s };
    console.log(service);
    service.serviceName = service.name;
    service.costEstimate = service.priceEstimate.split(" - ");
    let d = "Weeks";
    d = this.getDurationType(service);
    service.duration = d;
    service.timeEstimate = this.formatTimeEstimate(service).split(" - ");
    this.previewURL = service.image;
    this.serviceForm.reset();
    this.serviceForm.patchValue(service);
    this.markFormGroupTouched(this.serviceForm);
    this.addServiceModal = true;
  }
  currentService;
  onDeleteClick(s: any) {
    this.currentService = s;
    this.deleteServiceModal = true;
  }
  deleteConfirmed() {
    if (this.currentService.deleting) {
      return false;
    }
    this.currentService.deleting = true;
    this.serviceCardService.deleteService(this.currentService.id).subscribe(
      data => {
        this.deleteServiceModal = false;
        this.currentService.deleting = false;
        this.services = this.services.filter(s => { return this.currentService.id != s.id; });
        this.toastMessage("Delete Service", "Your Service Card has been removed", "success");
      },
      error => {
        this.deleteServiceModal = false;
        this.currentService.deleting = false;
        this.toastMessage("Delete Service", "Unable to delete service! Please try after some time.", "error");
      }
    );
  }

  userCanBook(service: any) {
    // if (service.isOwnService = false) {
    return this.isPublic && this.isLoggedIn && !service.booked;
    // }
  }

  bookingdoneuser(service: any) {
    return this.isPublic && this.isLoggedIn && service.booked;
  }



  onBookBtnClick(service: any) {
    if (service.booking || service.booked) {
      return false;
    }
    service.booking = true;
    this.gaService.logCustomEvent("Booked", "Growth Cards", service.name);
    this.serviceCardService.bookService(service.id).subscribe(
      data => {
        service.booked = true;
        service.booking = false;
        console.log(service);
        if (service.growthCard) {
          this.toastMessage("Book Growth Card", "A request has been successfully sent to the Service Provider", "success");
        }
        else {
          this.toastMessage("Book Service", "Service booked successfully", "success");
        }
      },
      error => {
        service.booking = false;
        if (Common.isBRNErrors(error)) {
          this.updateBRNPopup = true;
          this.currentBookingItem = service;
        } else {
          if (service.growthCard) {
            this.toastMessage("Book Growth Card", "Unable to book Growth Card! Please try after some time.", "error");
          }
          else {
            this.toastMessage("Book Service", "Unable to book service! Please try after some time.", "error");
          }
        }
      }
    );
  }
  updateBRNPopup = false;
  currentBookingItem;
  BRNUpdated(continueFlag) {
    if (continueFlag) {
      this.onBookBtnClick(this.currentBookingItem);
    }
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.value)
        control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  toastMessage(summary: string, detail: string, type: string) {
    this.messageService.add({ key: "app", severity: type, summary: summary, detail: detail });
  }

}
