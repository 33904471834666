import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {TextAreaFieldMetaData} from "../../../core/models/field-meta-data";

@Component({
  selector: 'app-input-textarea-reactive-field',
  templateUrl: './input-textarea-reactive-field.component.html',
  styleUrls: ['./input-textarea-reactive-field.component.scss']
})
export class InputTextareaReactiveFieldComponent implements OnInit {
  @Input()
  field: TextAreaFieldMetaData;

  @Input()
  disabled = false;

  @Input()
  componentId = '';

  @Input() maxLength:number;
  @Input() minLength:number;
  @Input() submitted=false;
  @Input() showRemaining=false;

  @Output()
  blurEvent = new EventEmitter();
  @Output() getData = new EventEmitter();
  characterLength = 0;
  constructor() { }

  ngOnInit() {
    if (this.field !== undefined && this.field.control.value !== undefined) {
      this.characterLength = this.field.control.value?.length?this.field.control.value?.length:0;
      this.field.control.valueChanges.subscribe(val => {
        this.characterLength = val?val.length:0;
        if(this.maxLength && val && val.length > this.maxLength) {
          this.field.control.setValue(val.substring(0, this.maxLength));
          this.characterLength = this.maxLength;
        }
      });
    } else {
      this.characterLength = 0;
    }
    if(!this.field) {
      this.field = new TextAreaFieldMetaData(new FormControl(''), 'dummy', 'Dummy', 'dummy', 'dummy');
    }
  }

  updateCharacterLength(event: any) {
    this.characterLength = event.target.value.length;
  }

  blur() {
    this.blurEvent.emit();
  }

  onChange(name: any, value: any){
    this.getData.emit({name:name, value:value});
  }
}
