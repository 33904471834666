const defaultErrorItem: ErrorItems = {
  selector: 'FORBIDDEN',
  icon: 'error-type-1.svg',
  buttonText: 'Ok',
  subtitle: 'Try again.',
  clickType: 'close'
};
export class ErrorModel {
  hasError: boolean;
  items: ErrorItems;
  code: number;
  type: string;
  constructor(
    hasError: boolean = false,
    code: number = null,
    type: string = null,
    items: ErrorItems = defaultErrorItem
  ) {
    this.hasError = hasError;
    this.items = items;
    this.code = code;
    this.type = type;
  }
}

export class ErrorItems {
  selector: string;
  icon: string;
  buttonText: string;
  subtitle: string;
  clickType: string;
}
