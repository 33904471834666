import { Component, VERSION, AfterViewChecked, ElementRef, ViewChild, OnInit } from "@angular/core";
import { STATUSES, Message } from "./models/model";
import { USERS, RANDOM_MSGS, getRandom } from "./models/data";
import { MessengerService } from "src/app/core/services/messenger/messenger.service";


@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit {
  statuses = STATUSES;
  activeUser;
  // users = USERS;
  users: any[] = [];
  expandStatuses = false;
  expanded = false;
  showmessagedUsers: boolean = true;
  ableSearch: boolean = true;
  searchUser: string;
  searchResults: any;

  messageReceivedFrom = {
    img: 'https://cdn.livechat-files.com/api/file/lc/img/12385611/371bd45053f1a25d780d4908bde6b6ef',
    name: 'Media bot'
  }

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  ngOnInit() {
    this.setUserActive(USERS[0])
    this.scrollToBottom();

  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  getallmessager() {
    this.messageService.getmessagingUsers().subscribe(
      (data: any) => {
        this.users = data;
      },
      (error) => {
      }
    );
  }

  constructor(private messageService: MessengerService) { }

  addNewMessage(inputField: HTMLInputElement) {
    const val = inputField.value?.trim();
    if (val.length) {
      const messageBody = {
        content: val,
        receiverId: this.activeUser?.userId,
        senderId: 0,
      };

      this.messageService.postMessages(messageBody)
        .subscribe(response => {
          this.setUserActive(this.activeUser);
        }, error => {
        });
    }
    inputField.value = '';
  }

  scrollToBottom(): void { }

  setUserActive(user) {
    this.activeUser = user;
    this.ableSearch = true;
    this.messageService.getMessages(user?.userId, 0)
      .subscribe(response => {
        this.activeUser.messages = response;
        this.scrollToBottom();
      }, error => {
      });

    this.connectToWS();
    this.getallmessager()
  }

  searchContacts() {
    if (this.searchUser) {
      this.messageService.getsearchUsers(this.searchUser, this.searchUser).subscribe((response) => {
        this.searchResults = response;
      });
    }
  }

  connectToWS() {
    if (this.activeUser.ws && this.activeUser.ws.readyState !== 1) {
      this.activeUser.ws = null;
      this.activeUser.status = STATUSES.OFFLINE;
    }
    if (this.activeUser.ws) {
      return;
    }
    const ws = new WebSocket('wss://compute.hotelway.ai:4443/?token=TESTTOKEN');
    this.activeUser.ws = ws;
    ws.onopen = (event) => this.onWSEvent(event, STATUSES.ONLINE);
    ws.onclose = (event) => this.onWSEvent(event, STATUSES.OFFLINE);
    ws.onerror = (event) => this.onWSEvent(event, STATUSES.OFFLINE);
    ws.onmessage = (result: any) => {
      const data = JSON.parse(result?.data || {});
      const userFound = this.users.find(u => u.id === data.id);
      if (userFound) {
        userFound.messages.push(
          new Message('replies', data.message)
        )
      }
    };
  }

  onWSEvent(event, status: STATUSES) {
    this.users.forEach(u => u.ws === event.target ? u.status = status : null)
  }

  showsearch() {
    this.ableSearch = false;
  }
}
function heartbeat() {
  clearTimeout(this.pingTimeout);
  this.pingTimeout = setTimeout(() => {
    this.terminate();
  }, 30000 + 1000);
}


